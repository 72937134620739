import { React, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    InputLabel,
    Stack,
    Backdrop,
    Snackbar,
    Alert,
} from "@mui/material";
import {
    Add,
    Delete,
    BorderColorOutlined,
    Archive,
    Unarchive
} from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import MyCompanies from "../../api/MyCompanies";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import ButtonControla from "../../components/buttons/buttonController.jsx";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla.jsx";


import { useNavigate } from "react-router-dom";
import TableControla from "../../components/tables/table-controla.jsx";

const purple = "#8B8BD8";

function Companies() {

    const navigate = useNavigate(); 

    //Message Alert
    const [stateAlert, setStateAlert] = useState({
        openAlert: false,
        vertical: "top",
        horizontal: "center",
        severityAlert: "success",
        messageAlert: "",
    });

    const { severityAlert, messageAlert, vertical, horizontal, openAlert } = stateAlert;

    const handleOpenAlert = (severityAlert, messageAlert) => {
        setStateAlert({
            openAlert: true,
            vertical: "top",
            horizontal: "right",
            severityAlert: severityAlert,
            messageAlert: messageAlert,
        });
    }

    const handleCloseAlert = () => {
        setStateAlert({ ...stateAlert, openAlert: false });
    }
    //
    /*backdrop */
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    }
    //----modal
    const[ openModalCompany, setOpenModalCompany] = useState(false);
    const[idCompany, setIdCompany] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");

    const handleOpenModalCompany = (company, actionButton) => {
        setOpenModalCompany(true);
        setButtonActionType(actionButton);

        if (company !== "") {
            setNameCompany(company.name);
            setRuc(company.ruc);
            setCampusCompany("");
            setIdCompany(company.id_companies);
        } else {
            //limpiar
            setNameCompany("");
            setRuc("");
            setCampusCompany("");
        }

    }
    const handleCloseModalCompany = () => {
        setOpenModalCompany(false);
    }

    const handleActionModal = (event) => {
        switch (buttonActionType) {
            case "agregar empresa":
                handlePostCompany (event);
                break;
            case "editar empresa":
                handleUpdateCompany(event);
        }
    }

    //confirmación
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");


    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, buttonActionType, id) => {
        setOpenModalConfirm(true);

        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setIdCompany(id); 
    }
    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }
    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case "desactivar empresa":
                handleDeleteCompany();
            case "activar empresa":
                handleActivateCompany();
                break;
        }

    }

    const [nameCompany, setNameCompany] = useState("");
    const [campusCompany, setCampusCompany] = useState("");
    const [ruc, setRuc] = useState("");
    const [listCompanies, setListCompanies] = useState([]);
    const [loading, setloading] = useState(false);

    const handleGetCompany = async() =>{
        setloading(true)
        const resp = await MyCompanies.getCompany();
        setListCompanies(resp.data);
        setloading(false)
    }

    const handlePostCompany = async(event)=>{
        event.preventDefault(); 
        setOpenBackdrop(true);
        const data = {
            "name": nameCompany,
            "ruc": ruc
        }
        const resp = await MyCompanies.postCompany(data);

        if(resp.success === true){
            handleGetCompany();
            handleOpenAlert("success", "Se creó la empresa exitosamente");
        }else{
            handleCloseAlert("error", "Ocurrió un error");
        }

        handleCloseBackdrop();
        handleCloseModalCompany();
    }
    const handleUpdateCompany = async(event)=> {
        event.preventDefault();
       setOpenBackdrop(true);

        const data = {
            "name": nameCompany,
            "ruc":  ruc
        }

        const resp = await MyCompanies.updateCompany(data, idCompany);

        if(resp.success === true){
            handleGetCompany();
            handleOpenAlert("success", "Se actualizó la información con éxito");
            
        }else{
            handleOpenAlert("error", "Ocurrió un error");
        }

        handleCloseBackdrop();
        handleCloseModalCompany();

    }

    const handleDeleteCompany = async() => {
        setOpenBackdrop(true);

        const resp = await MyCompanies.deleteCompany(idCompany);

        if(resp.success === true){
            handleGetCompany();
            handleOpenAlert("success", "Se eliminó la empresa con éxito");

        }else{
            handleOpenAlert("error", resp.message);
        }

        handleCloseBackdrop();
        handleCloseModalConfirm();
    }

    const handleActivateCompany = async() => {
        setOpenBackdrop(true);

        const data = {
            "active": 1
        }

        const resp = await MyCompanies.updateCompany(data, idCompany);

        if(resp.success === true){
            handleGetCompany();
            handleOpenAlert("success", "Se actualizó la información con éxito");
            
        }else{
            handleOpenAlert("error", "Ocurrió un error");
        }

        handleCloseBackdrop();
        handleCloseModalConfirm();
    }

    useEffect(() => {
        handleGetCompany();
    }, []);

    const listActives = listCompanies.filter(c => c.active == 1)
    const listDesactives = listCompanies.filter(c => c.active == 0)

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

             {/* componente Alert */}
             <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={3000}
            >
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleCloseAlert();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={severityAlert}
                    size="small"
                >
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item md={12}>
                            <ButtonMailboxControla
                                originUrl={"/company"}
                            />
                        </Grid>
                    </Grid>

                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                    >
                        <Box>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Creación de empresa
                            </InputLabel>
                        </Box>
                        <Box>
                            <ButtonControla
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agregar empresa"}
                                functionOnClick={() => {
                                    handleOpenModalCompany(
                                        "",
                                        "agregar empresa"
                                    )
                                }}
                            />
                        </Box>
                    </Stack>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item md={12}>
                            <TableControla
                                loading={loading}
                                tabs={[
                                    {
                                        label: "Activos",
                                        columnNames: ["Empresa", "RUC"]
                                    },
                                    {
                                        label: "Desactivos",
                                        columnNames: ["Empresa", "RUC"]
                                    }
                                ]}
                            >
                                <TableBody>
                                        {
                                            listActives.map((c) => (
                                                <TableRow
                                                    hover
                                                    key={c.id_companies}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {c.name}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {c.ruc}
                                                    </TableCell>
                                                    {/* <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {c.address}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {c.department}
                                                    </TableCell> */}
                                                    <TableCell align="right">
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignContent="center"
                                                        >
                                                            <ButtonIconControla
                                                                icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                                                backgroundColor={"#2D9CDB"}
                                                                backgroundColorHover={"#33AEF4"}
                                                                textTooltip={"Editar"}
                                                                functionOnClick={() => {
                                                                    handleOpenModalCompany(
                                                                        c,
                                                                        "editar empresa",
                                                                    )
                                                                }}
                                                            />
                                                            <ButtonIconControla
                                                                icon={<PersonIcon sx={{ color: "white" }} />}
                                                                backgroundColor={"#169073"}
                                                                backgroundColorHover={"#1AA483"}
                                                                textTooltip={"Activar usuario"}
                                                                isNavegationOrigin={true}
                                                                directionUrl={"/userCompany"}
                                                                paramsIntUrl={{
                                                                    id_companies: c.id_companies,
                                                                    name_company: c.name
                                                                }}
                                                            />
                                                            <ButtonIconControla
                                                                icon={<Archive sx={{ color: "white" }} />}
                                                                backgroundColor={"#EB5757"}
                                                                backgroundColorHover={"#FF4040"}
                                                                textTooltip={"Desactivar"}
                                                                functionOnClick={() => {
                                                                    handleOpenModalConfirm(
                                                                        "¿Estás seguro de desactivar la empresa seleccionada?",
                                                                        "Una vez desactivado no se podrá iniciar sesion en el sistema.",
                                                                        "Sí, desactivar",
                                                                        "desactivar empresa",
                                                                        c.id_companies
                                                                    )
                                                                }}
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }

                                </TableBody>

                                <TableBody>
                                        {
                                            listDesactives.map((c) => (
                                                <TableRow
                                                    hover
                                                    key={c.id_companies}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {c.name}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {c.ruc}
                                                    </TableCell>
                                                    {/* <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {c.address}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {c.department}
                                                    </TableCell> */}
                                                    <TableCell align="right">
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignContent="center"
                                                        >
                                                            <ButtonIconControla
                                                                icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                                                backgroundColor={"#2D9CDB"}
                                                                backgroundColorHover={"#33AEF4"}
                                                                textTooltip={"Editar"}
                                                                functionOnClick={() => {
                                                                    handleOpenModalCompany(
                                                                        c,
                                                                        "editar empresa",
                                                                    )
                                                                }}
                                                            />
                                                            <ButtonIconControla
                                                                icon={<PersonIcon sx={{ color: "white" }} />}
                                                                backgroundColor={"#169073"}
                                                                backgroundColorHover={"#1AA483"}
                                                                textTooltip={"Activar usuario"}
                                                                isNavegationOrigin={true}
                                                                directionUrl={"/userCompany"}
                                                                paramsIntUrl={{
                                                                    id_companies: c.id_companies,
                                                                    name_company: c.name
                                                                }}
                                                            />
                                                            <ButtonIconControla
                                                                icon={<Unarchive sx={{ color: "white" }} />}
                                                                backgroundColor={"#F2994A"}
                                                                backgroundColorHover={"#FF881E"}
                                                                textTooltip={"Activar"}
                                                                functionOnClick={() => {
                                                                    handleOpenModalConfirm(
                                                                        "¿Estás seguro de activar la empresa seleccionada?",
                                                                        "Una vez activada podra iniciar sesion en el sistema.",
                                                                        "Sí, activar",
                                                                        "activar empresa",
                                                                        c.id_companies
                                                                    )
                                                                }}
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }

                                </TableBody>
                            </TableControla>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            {/* modal de creación empresa */}
            <Dialog
                open={openModalCompany}
                onClose={handleCloseModalCompany}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText={"Agregar empresa"}
                    functionOnClose={handleCloseModalCompany}
                />
                <form onSubmit={handleActionModal}>
                    <DialogContent>
                        <InputControlaVertical
                            text={"Razón social"}
                            inputType={"text"}
                            inputValue={setNameCompany}
                            modalType={true}
                            value={nameCompany || ''}
                            required
                        />
                        <InputControlaVertical
                            text={"RUC"}
                            inputType={"number"}
                            inputValue={setRuc}
                            modalType={true}
                            value={ruc || ''}
                            required
                        />
                      
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalCompany}
                                />
                            </Box>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Guardar"}
                                    typeButton={"submit"}
                                />
                            </Box>
                        </Stack>
                    </DialogActions>

                </form>

            </Dialog>

            {/* mostrar loading  */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 9999,
                }}
                open={openBackdrop}
            >
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>
    );

}

export default Companies;