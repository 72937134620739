import { React, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TitleText from "../components/text/titleText";
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    Stack,
    Typography,
    MenuItem,
    Backdrop,
    Snackbar,
    Alert,
} from "@mui/material";
import {
    Add,
    Delete,
    BorderColorOutlined
} from "@mui/icons-material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import DialogConfirmControla from "../components/dialog/dialogConfirmControla";
import DialogTitleControla from "../components/dialog/dialogTitleControla";
import ButtonIconControla from "../components/buttons/buttonIconControla";
import ButtonControla from "../components/buttons/buttonController.jsx";
import MyMemberships from "../api/MyMemberships.jsx";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import SelectControla from "../components/selects/selectControla";
import ButtonMailboxControla from "../components/buttons/buttonMailboxControla.jsx";
import MyCompanies from "../api/MyCompanies.jsx";

const purple = "#8B8BD8";

function CompanyMember() {
    //Message Alert
    const [stateAlert, setStateAlert] = useState({
        openAlert: false,
        vertical: "top",
        horizontal: "center",
        severityAlert: "success",
        messageAlert: "",
    });

    const { severityAlert, messageAlert, vertical, horizontal, openAlert } = stateAlert;

    const handleOpenAlert = (severityAlert, messageAlert) => {
        setStateAlert({
            openAlert: true,
            vertical: "top",
            horizontal: "right",
            severityAlert: severityAlert,
            messageAlert: messageAlert,
        });
    }

    const handleCloseAlert = () => {
        setStateAlert({ ...stateAlert, openAlert: false });
    }
    //
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");
    const [idCompanyMemberships, setIdCompanyMemberships] = useState(null);
    const [titleModalCompanyMemberships, setTitleModalCompanyMemberships] = useState("");

    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, buttonActionType, id) => {
        setOpenModalConfirm(true);

        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setId(id);
    }


    const [companieSelect, setCompanieSelect] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [typeMemberships, setTypeMemberships] = useState("");
    const [id, setId] = useState("");


    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }
    const handleActionConfirm = () => {
        switch (buttonActionType) {
            
            case "eliminar membresia empresa":
                handleDeleteCompanyMemberships();
                break;
        }
    }

    const [openModalCompanyMemberships, setOpenModalCompanyMemberships] = useState(false);
    const [memberships, setMemberships] = useState([]);

    const [companyMemberships, setCompanyMemberships] = useState([]);
    const [companies, setCompanies] = useState([]);

    const handleOpenModalCompanyMemberships = (CompanyMemberships, titleModalCompanyMemberships, buttonActionType, buttonTextConfirm) => {

        setIdCompanyMemberships(CompanyMemberships.id_company_memberships);
        setCompanieSelect(CompanyMemberships.id_companies);
        setStartDate(CompanyMemberships.start_date);
        setEndDate(CompanyMemberships.end_date);
        setTypeMemberships(CompanyMemberships.id_memberships);
        setTitleModalCompanyMemberships(titleModalCompanyMemberships);
        setButtonActionType(buttonActionType);
        setButtonTextConfirm(buttonTextConfirm);

        setOpenModalCompanyMemberships(true);
    }
    const handleCloseModalCompanyMemberships = () => {
        setOpenModalCompanyMemberships(false);
    }

    const handleActionModal = (event) => {
        switch (buttonActionType) {
            case "editar":
                hanledUpdateCompanyMemberships(event);
                break;
            case "agregar":
                handlePostCompanyMemberships(event);
                break;
        }
    }

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    }
    const handlePostCompanyMemberships = async (event) => {
        event.preventDefault();
        setOpenBackdrop(true);

        const data = {
            "id_memberships": typeMemberships,
            "start_date": startDate,
            "end_date": endDate,
            "id_companies": companieSelect
        }
        const res = await MyMemberships.postCompanyMemberships(data);

        if (res.success === true) {
            getCompanyMemberships();
            handleOpenAlert("success", "Se registró la membresía de la empresa con éxito");

        } else {
            handleOpenAlert("error", "Ocurrió un error");
        }

        handleCloseBackdrop();
        handleCloseModalCompanyMemberships();
    }

    const getCompanyMemberships = async () => {
        const res = await MyMemberships.getCompanyMemberships();
    
        setCompanyMemberships(res.data);

        const resp = await MyMemberships.getCompanies();
        setCompanies(resp.data);
    }
    const getMemberships = async () => {
        const res = await MyMemberships.getMemberships();
        setMemberships(res.data);
    }
    const hanledUpdateCompanyMemberships = async (event) => {
        event.preventDefault();
        setOpenBackdrop(true);

        const data = {
            "id_memberships": typeMemberships,
            "start_date": startDate,
            "end_date": endDate,
            "id_companies": companieSelect,
        }

        const res = await MyMemberships.updateCompanyMemeberships(idCompanyMemberships, data);

        if (res.success === true) {
            getCompanyMemberships();
            handleOpenAlert("success", "Se actualizó membresía empresa con éxito");

        } else {
            handleOpenAlert("error", "Ocurrió un error");

        }

        handleCloseBackdrop();
        handleCloseModalCompanyMemberships();
    }

    const handleDeleteCompanyMemberships = async () => {
        setOpenBackdrop(true);

        const res = await MyMemberships.deleteCompanyMemberships(id);

        if (res.success === true) {
            getCompanyMemberships();
            handleOpenAlert("success", "Se eliminó membresía de la empresa con éxito");

        } else {
            handleOpenAlert("error", "Ocurrió un error");
        }

        handleCloseBackdrop();
        handleCloseModalConfirm();
    }

    useEffect(() => {
        getMemberships();
        getCompanyMemberships();
    }, []);

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

            {/* componente Alert */}
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={3000}
            >
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleCloseAlert();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={severityAlert}
                    size="small"
                >
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item md={12}>
                            <ButtonMailboxControla
                                originUrl={"/companyMember"}
                            />
                        </Grid>
                    </Grid>
                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                    >
                        <Box>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Listado de membresías de empresa
                            </InputLabel>
                        </Box>
                        <Box>
                            {/* <ButtonControla
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agregar membresía a empresa"}
                                functionOnClick={()=>{handleOpenModalCompanyMemberships(
                                    "",
                                    "Agregar membresia a empresa",
                                    "agregar",
                                    "Guardar"
                                )}}
                            /> */}
                            <Button
                                variant="contained"
                                type={"button"}
                                sx={{
                                    backgroundColor: "#169073",
                                    textTransform: "none",
                                    boxShadow: "none",
                                    borderRadius: "16px",
                                    "&:hover": {
                                        backgroundColor: "#1BAA88"
                                    },

                                }}
                                onClick={() => {
                                    handleOpenModalCompanyMemberships(
                                        "",
                                        "Agregar membresia a empresa",
                                        "agregar",
                                        "Guardar"
                                    )
                                }}
                                startIcon={<Add sx={{ color: "white" }} />}
                                // href={url}
                                target={""}
                            >
                                {"Agregar membresía a empresa"}
                            </Button>
                        </Box>
                    </Stack>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item md={12}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Empresa
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Tipo de membresia
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Fecha de inicio
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Fecha de fin
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color: "#1638F2",
                                                    fontSize: "18px",
                                                    width: "14%",
                                                }}
                                            >
                                                Acciones
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {companyMemberships.map((company) => (
                                            <TableRow
                                                hover
                                                key={company.id_company_memberships}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{ color: purple }}
                                                    align="center"
                                                >
                                                    {company.company_name}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{ color: purple }}
                                                    align="center"
                                                >
                                                    {memberships.filter((m) => m.id_memberships == company.id_memberships)[0]?.name}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{ color: purple }}
                                                    align="center"
                                                >
                                                    {company.start_date}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{ color: purple }}
                                                    align="center"
                                                >
                                                    {company.end_date}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="end"
                                                        alignContent="center"
                                                    >
                                                        <ButtonIconControla
                                                            icon={
                                                                <BorderColorOutlined
                                                                    sx={{ color: "white" }}
                                                                />
                                                            }
                                                            backgroundColor={"#2D9CDB"}
                                                            backgroundColorHover={"#33AEF4"}
                                                            textTooltip={"Editar"}
                                                            functionOnClick={() => {
                                                                handleOpenModalCompanyMemberships(
                                                                    company,
                                                                    "Editar membresía de empresa",
                                                                    "editar",
                                                                    "Actualizar"
                                                                )
                                                            }}
                                                        />
                                                        <ButtonIconControla
                                                            icon={<Delete sx={{ color: "white" }} />}
                                                            backgroundColor={"#EB5757"}
                                                            backgroundColorHover={"#FF4040"}
                                                            textTooltip={"Eliminar"}
                                                            functionOnClick={() => {
                                                                handleOpenModalConfirm(
                                                                    "¿Estás seguro de eliminar esta membresía de la empresa seleccionada?",
                                                                    "Una vez eliminado no se podrá recuperar la información",
                                                                    "Sí, eliminar",
                                                                    "eliminar membresia empresa",
                                                                    company.id_company_memberships)
                                                            }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            {/* modal de editar y guardar membresia empresa */}
            <Dialog
                open={openModalCompanyMemberships}
                onClose={handleCloseModalCompanyMemberships}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText={titleModalCompanyMemberships}
                    functionOnClose={handleCloseModalCompanyMemberships}
                />
                <form onSubmit={handleActionModal}>
                    <DialogContent>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                                text="Empresa:"
                                inputValue={setCompanieSelect}
                                modalType={true}
                                required
                                value={companieSelect || ''}
                                childrenRows={companies.map((rows) => (
                                    <MenuItem key={rows.id_companies} value={rows.id_companies}>
                                        {rows.name}
                                    </MenuItem>
                                ))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                                text="Membresia:"
                                inputValue={setTypeMemberships}
                                modalType={true}
                                required
                                value={typeMemberships || ''}
                                childrenRows={memberships.map((rows) => (
                                    <MenuItem key={rows.id_memberships} value={rows.id_memberships}>
                                        {rows.name}
                                    </MenuItem>
                                ))}
                            />
                        </Grid>

                        <InputControlaVertical
                            text={"Fecha de inicio"}
                            inputType={"date"}
                            inputValue={setStartDate}
                            modalType={true}
                            value={startDate || ''}
                            required
                        />
                        <InputControlaVertical
                            text={"Fecha de fin"}
                            inputType={"date"}
                            inputValue={setEndDate}
                            modalType={true}
                            value={endDate || ''}
                            required
                        />


                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                {/* <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalCompanyMemberships}
                                /> */}

                                <Button
                                    variant="contained"
                                    type={"button"}
                                    sx={{
                                        backgroundColor: "#CBCBFF",
                                        textTransform: "none",
                                        boxShadow: "none",
                                        borderRadius: "16px",
                                        "&:hover": {
                                            backgroundColor: "#CBCBFF"
                                        },
                                    }}
                                    onClick={handleCloseModalCompanyMemberships}
                                    // startIcon={iconButton}
                                    // href={url}
                                    target={""}
                                >
                                    {"Cancelar"}
                                </Button>
                            </Box>
                            <Box>
                                {/* <ButtonControla
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={buttonTextConfirm}
                                    typeButton={"submit"}
                                /> */}

                                <Button
                                    variant="contained"
                                    type={"submit"}
                                    sx={{
                                        backgroundColor: "#169073",
                                        textTransform: "none",
                                        boxShadow: "none",
                                        borderRadius: "16px",
                                        "&:hover": {
                                            backgroundColor: "#1BAA88"
                                        },
                                    }}
                                    // onClick={functionOnClick}
                                    // startIcon={iconButton}
                                    // href={url}
                                    target={""}
                                >
                                    {buttonTextConfirm}
                                </Button>
                            </Box>
                        </Stack>

                    </DialogActions>

                </form>

            </Dialog>

            {/* mostrar loading  */}
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 9999,
                }}
                open={openBackdrop}
            >
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>

    );

}
export default CompanyMember;