import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Close,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
  PictureAsPdf,
  Print,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import CardUserControla from "../../../../components/cards/cardUserControla";
import styled from "styled-components";
import MyRegistersAudits from "../../../../api/MyRegistersAudits";
import InputControlaVerticalIcon from "../../../../components/textfields/inputControlaVerticalIcon";
import { useAppContext } from "../../../../context/AppContext";
import MyTasksNew from "../../../../api/MyTasksNew";
import DialogInformativeControla from "../../../../components/dialog/dialogInformativeControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const red = "#EB5757";
const StyledTypography = styled(Typography)({
  color: "#305AD9",
  fontSize: 16,
  fontWeight: 600,
  gap: "2%",
  padding: "0.5em",
});

function Edit() {
  const { permissions } = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idRegisterAudit = params.get("idRegisterAudit");

  const [idRegisterAuditWorkers, setidRegisterAuditWorkers] = useState();

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;
  const [idRegisterInspectionWorkers, setidRegisterInspectionWorkers] =
    useState();

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = (idRegisterAccidentsWorkersValue) => {
    setOpenDelete(true);
    setidRegisterInspectionWorkers(idRegisterAccidentsWorkersValue);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");
  ////
  // id del responsable
  const [idRegisterResponsible, setIdRegisterResponsible] = useState("");
  //// id auditor (tabla 1)
  const [idAuditor, setIdAuditor] = useState("");
  const [idAuditoringAudit, setIdAuditoringAudit] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setidRegisterAuditWorkers(id);
    setIdAccidentsMeasures(id);
    setidRegisterAuditResponsible(id);
    setIdAuditoringAudit(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    setIdRegisterResponsible(id);
    setIdAuditor(id);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteAuditors":
        handleDeleteAuditors(idAuditor);
        break;
      case "deleteMeasures":
        handleDeleteCorrectivesActions();
        break;
      case "deleteResponsible":
        handleDeleteResponsibleAudit({ idUsers: idRegisterResponsible });
        // updatestatusesPets();
        break;
      case "deleteAudit":
        handleDeleteAuditoringAudit();
        break;
    }
    setOpenModalConfirm(false);
  };
  // Dialogos de confirmacion FIN

  // modal añadir auditor
  const [modalAddAuditor, setModalAddAuditor] = useState(false);
  const handleOpenModalAddAuditor = () => {
    setAuditorName("");
    setRegisterNumber("");
    setModalAddAuditor(true);
  };
  const handleCloseModalAddAuditor = () => {
    setModalAddAuditor(false);
  };

  // modal añadir auditoria
  const [modalAddAudit, setModalAddAudit] = useState(false);
  const handleOpenModalAddAudit = () => {
    setAuditoringProcess("");
    setAuditoringDate();
    setResponsibleCorrectiveActions(null);
    setModalAddAudit(true);
  };

  // agregar medidas correctivas DIALOG
  const [
    openModalUploadCorrectiveActions,
    setOpenModalUploadCorrectiveActions,
  ] = useState(false);

  const handleOpenModalUploadCorrectiveActions = () => {
    setTimestampStart(null);
    setTimestampEnd(null);
    setDescription(null);
    setStatusCorrectivesActions(null);
    handleGetStatusCorrectivesActions();
    handleGetSearchsCorrectivesActionWorkers();
    setOpenModalUploadCorrectiveActions(true);
  };

  const handleCloseModalUploadCorrectiveActions = () => {
    setResponsibleCorrectiveActions("");
    handleGetCorrectivesActions();
    setOpenModalUploadCorrectiveActions(false);
  };

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadCResponsibleRegistration(true);
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    // handleGetResponsibleWorkers();
    setOpenModalUploadCResponsibleRegistration(false);
  };

  // ACCIDENTE DE TRABAJO

  // Gravedad del accidente de trabajo
  const [severityAccidents, setSeverityAccidents] = useState(0);
  const [rowsSeverityWorkAccidents, setRowsSeverityWorkAccidents] = useState(
    []
  );

  const handleGetSeverityAccidents = async () => {
    const response = await MyRegistersAccidents.severityWorkAccident();
    if (response.success === false) {
    } else {
      setRowsSeverityWorkAccidents(response);
    }
    return response;
  };

  const [code, setCode] = useState("");
  const [affectedWorkers, setAffectedWorkers] = useState(0);
  const [causesDescription, setcausesDescription] = useState("");
  const [descriptionWork, setDescriptionWork] = useState("");
  const [injuredBodyPartDescription, setInjuredBodyPartDescription] =
    useState("");
  const [location, setLocation] = useState("");
  const [medicalLeaveDays, setMedicalLeaveDays] = useState(0);
  const [timestampAccident, setTimestampAccident] = useState("");
  const [timestampInvestigation, setTimestampInvestigation] = useState("");
  const [documentUrl1, setDocumentUrl1] = useState("");
  const [documentUrl2, setDocumentUrl2] = useState("");
  const [documentUrl3, setDocumentUrl3] = useState("");
  const [conformityNumber, setConformityNumber] = useState("");

  // severidad del accidente
  const [severityIncidents, setSeverityIncidents] = useState(0);
  const [rowsSeverityIncidents, setRowsSeverityIncidents] = useState([]);

  const handleGetSeverityInccidents = async () => {
    const response = await MyRegistersAccidents.severityIncidents();
    if (response.success === false) {
    } else {
      setRowsSeverityIncidents(response);
    }
    return response;
  };

  // BACKDROP
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  // get Usuarios a buscar al agregar trabajadores

  // update register accidents
  const handleUpdateRegistersAccidents = async (event) => {
    event.preventDefault();
    const response = await MyRegistersAccidents.updateRegistersAccidents({
      idRegisterAudit,
      code: code,
      timestamp_accident: timestampAccident,
      timestamp_investigation: timestampInvestigation,
      location: location,
      id_registers_accidents_severities: severityAccidents,
      id_registers_accidents_severities_incident: severityIncidents,
      medical_leave_days: medicalLeaveDays,
      affected_workers: affectedWorkers,
      injured_body_part_desciption: injuredBodyPartDescription,
      description: descriptionWork,
      document_url_1: documentUrl1,
      document_url_2: documentUrl2,
      document_url_3: documentUrl3,
      causes_description: causesDescription,
    });

    if (response.success === false) {
      console.log("error al actualizar");
    } else {
      // handleGetAccidentsWorkers();
    }
    return response;
  };

  // edit register acidents
  const handleEditRegistersAccidents = async () => {
    const response = await MyRegistersAccidents.editRegistersAccidents({
      idRegisterAudit,
    });

    if (response.success === false) {
      alert("Error");
    } else {
      setRegisterStatus(
        response.id_registers_accidents_statuses === 1 ? true : false
      );
      setCode(response.code);
      setAffectedWorkers(response.affected_workers);
      setcausesDescription(response.causes_description);
      setInjuredBodyPartDescription(response.injured_body_part_desciption);
      setLocation(response.location);
      setMedicalLeaveDays(response.medical_leave_days);
      setTimestampAccident(
        response.timestamp_accident === null
          ? ""
          : response.timestamp_accident.substring(0, 16)
      );
      setTimestampInvestigation(
        response.timestamp_investigation === null
          ? ""
          : response.timestamp_investigation.substring(0, 10)
      );
      setDocumentUrl1(response.document_url_1);
      setDocumentUrl2(response.document_url_2);
      setDocumentUrl3(response.document_url_3);
      setDescriptionWork(response.description);
      setSeverityAccidents(response.id_registers_accidents_severities);
      setSeverityIncidents(response.id_registers_accidents_severities_incident);
    }
    return response;
  };

  // TRABAJADORES ACCIDENTADOS
  const [openModalUploadInvolvedWorkers, setOpenModalUploadInvolvedWorkers] =
    useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadInvolvedWorkers(true);
  };
  // crear trabajadores accidentados

  const [accidentsWorkers, setAccidentsWorkers] = useState([]);
  const [area, setArea] = useState("");
  const [shift, setShift] = useState("");

  const [workExperience, setWorkExperience] = useState("");
  const [workHoursBefore, setWorkHoursBefore] = useState("");

  // mostrar trabajadores accidentados
  // const handleGetAccidentsWorkers = async () => {
  //   const response = await MyRegistersAccidents.getAccidentsWorkers({
  //     idRegisterAudit,
  //   });
  //   if (response.success === false) {
  //     alert("Error");
  //   } else {
  //     setAccidentsWorkers(response);
  //   }
  // };

  // eliminar trabajadores accidentados
  const handleDeleteAccidentsWorkers = async () => {
    const response = await MyRegistersAccidents.deleteAccidentsWorkers({
      id_registers_accidents_workers: idRegisterAuditWorkers,
    });
    if (response.success === true) {
      // handleGetAccidentsWorkers();
      handleCloseDelete();
    } else {
    }
    return response;
  };

  /***************************  MEDIDAS CORRECTIVAS  *****************************/
  // buscar usuario por ID
  const [responsibleCorrectiveActions, setResponsibleCorrectiveActions] =
    useState(null);
  const [idResponsible, setIdResponsible] = useState(" ");

  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    // handleOpenLoading();
    setOpenBackdrop(!openBackdrop);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setResponsibleCorrectiveActions(
        response.first_name + " " + response.last_name
      );
      setOpenModalUpload(false);
      setOpenModalUploadInvolvedWorkers(false);
      handleCloseBackdrop();
      setOpenModalResponsible(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  const [idUserLogged, setIdUserLogged] = useState();

  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };

  // abrir modal de busqueda de usuarios de medidas correctivas
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModalResponsible, setOpenModalResponsible] = useState(false);
  const handleOpenModalResponsible = () => {
    setResponsibleInput("");
    setOpenModalResponsible(true);
  };

  // buscar usuario por ID y setear sus datos
  const [nameResponsibleInput, setResponsibleInput] = useState(null);
  const [responsibleDocumentInput, setResponsibleDocumentInput] =
    useState(null);
  // const handleSelectUsersResponsibles = async (idUsers) => {
  //   setIdResponsible(idUsers);
  //   handleOpenLoading();

  //   const response = await MyUsers.editUsers({ idUsers: idUsers });
  //   if (response.success !== false) {
  //     setResponsibleInput(response.first_name + " " + response.last_name);
  //     setResponsibleDocumentInput(response.document);
  //     setOpenModalResponsible(false);
  //     handleOpenAlert(response.msg, "success");
  //   } else {
  //   }
  // };

  // mostrar estado de la medida correctiva
  const [rowsStatusCorrectivesActions, setRowsStatusCorrectivesActions] =
    useState([]);
  const handleGetStatusCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getStatusCorrectivesActions();
    if (response.success === false) {
    } else {
      setRowsStatusCorrectivesActions(response);
    }
    return response;
  };

  // estado de la medida correctiva
  const [statusCorrectivesActions, setStatusCorrectivesActions] =
    useState(null);

  // crear medidas correctivas
  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [description, setDescription] = useState("");

  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();

    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAudits.createCorrectiveActions({
      idRegistersAudits: idRegisterAudit,
      idAuditsCorrectiveActionsStatus: statusCorrectivesActions,
      idUsers: idResponsible,
      timeStampStart: timestampStart,
      timeStampEnd: timestampEnd == null ? "" : timestampEnd,
      description: description,
    });

    // agregar a tareas

    const data = {
      date_start: timestampStart,
      date_end: timestampEnd,
      description: description,
      id_status: 1,
      id_users: idResponsible,
      id_tasks_types: 2,
      title: "Tarea asignada en registro de auditoría",
      origin_url: `documentation/registers/generals/audits/edit?idRegisterAudit=${idRegisterAudit}`,
    };

    const resp = await MyTasksNew.postTasks(data);

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleGetCorrectivesActions();
      setModalAddAuditor(false);
      handleCloseBackdrop();
      handleCloseModalUploadCorrectiveActions();
    }
    return response;
  };

  // mostrar medidas correctivas
  const [correctivesActions, setCorrectivesActions] = useState([]);
  const handleGetCorrectivesActions = async () => {
    const response = await MyRegistersAudits.getCorrectiveActions({
      idRegistersAudits: idRegisterAudit,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setCorrectivesActions(response);
    }
  };

  // USUARIOS ACTIVOS
  const [rowsUsersSearchs, setRowsUsersSearchs] = useState([]);
  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyUsers.users();
    setRowsUsersSearchs(response.users_actives);
  };
  // listar trabajdores para buscar en medidas correctivas

  const [
    rowsSearchsCorrectivesActionWorkers,
    setSearchsCorrectivesActionWorkers,
  ] = useState([]);

  const handleGetSearchsCorrectivesActionWorkers = async () => {
    const response =
      await MyRegistersAccidents.getSearchsCorrectivesActionWorkers({
        idRegisterAudit,
      });
    setSearchsCorrectivesActionWorkers(response);
  };

  // eliminar medidas correctives
  const [idAccidentsMeasures, setIdAccidentsMeasures] = useState(0);

  const handleDeleteCorrectivesActions = async () => {
    const response = await MyRegistersAudits.deleteCorrectiveActions({
      idRegistersAudits: idRegisterAuditResponsible,
    });
    if (response.success === true) {
      handleGetCorrectivesActions();
      handleCloseDelete();
    } else {
    }
    return response;
  };

  // TRABAJADORES RESPONSABLES
  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  // const handleGetResponsibleWorkers = async () => {
  //   const response = await MyRegistersAccidents.getResponsibleWorkers({
  //     idRegisterAudit,
  //   });
  //   if (response.success === false) {
  //     alert("Error");
  //   } else {
  //     setResponsibleActions(response);
  //     setFirm(response.firm);
  //   }
  // };

  //crear trabajadores responsables
  const [firm, setFirm] = useState();
  const handleStoreResponsibleWorkers = async (idUsers) => {
    const response = await MyRegistersAccidents.createResponsibleWorkers({
      idRegisterAudit: idRegisterAudit,
      id_users: idUsers,
      firm: firm,
    });
    if (response.success === false) {
      console.log("error", response);
    } else {
      // handleGetResponsibleWorkers();
      handleCloseModalUploadResponsibleRegistration();
      handleCloseModalUploadCorrectiveActions();
    }
    return response;
  };

  // eliminar trabajador responsable
  const [idRegisterAuditResponsible, setidRegisterAuditResponsible] =
    useState(0);

  const handleDeleteResponsibleWorkers = async () => {
    const response = await MyRegistersAccidents.deleteResponsibleWorkers({
      id_registers_accidents_responsible: idRegisterAuditResponsible,
    });
    if (response.success === true) {
      // handleGetResponsibleWorkers();
      handleCloseDelete();
    } else {
    }
    return response;
  };

  // Buscar trabajadores en medidas correctivas
  const [rowsSearchsResponsiblesWorkers, setSearchsResponsiblesWorkers] =
    useState([]);

  const handleGetSearchsResponsiblesWorkers = async () => {
    const response = await MyRegistersAccidents.getSearchsResponsiblesWorkers({
      idRegisterAudit,
    });
    setSearchsResponsiblesWorkers(response);
  };

  // firmar responsable

  const [openModalSignResponsible, setOpenModalSignResponsible] =
    useState(false);
  ///usersid
  const [idUserIdResponsible, setIdUserIdResponsible] = useState(0);
  const [idAccidentsResponsible, setIdAccidentsResponsible] = useState(0);

  const handleOpenModalSignResponsible = (
    idUserResposible,
    idUserIdResposible
  ) => {
    setIdUserIdResponsible(idUserIdResposible);
    setIdAccidentsResponsible(idUserResposible);
    setOpenModalSignResponsible(true);
  };

  const handleSignResponsible = async (event) => {
    event.preventDefault();
    const response = await MyRegistersAccidents.signResponsible({
      id_accidents_responsible: idAccidentsResponsible,
    });
    if (response.success !== false) {
      setOpenModalSignResponsible(false);
      // handleGetResponsibleWorkers();
    } else {
    }
  };
  //modal de información
  const[openModalInformative, setOpenModalInformative] = useState(false);
  const [message, setMessage] = useState("");

  const handleCloseModalInformative = ()=>{
    setOpenModalInformative(false);
  }

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(true);

  const handleGetRegisterStatus = async () => {
    //verificar firma
    if (responsibleWorkers.filter((u) => u.firm == 0).length == 0) {
      setOpenBackdrop(!openBackdrop);
      const response = await MyRegistersAudits.getRegisterStatus({
        idRegister: idRegisterAudit,
      });

      if (response.success === false) {
        alert("Error");
      } else {
        handleEditRegisterAudit();
        handleCloseBackdrop();
      }
      return response;

    } else {
      setOpenModalInformative(true);
      setMessage("Falta firma de algún responsable del registro");
    }
   

  };

  const [openModalDatesUsers, setOpenModalDatesUers] = useState(false);

  const [userJsonDatesUsers, setuserJsonDatesUsers] = useState(false);
  const [documentDatesUsers, setDocumentDatesUsers] = useState(false);
  const [emailDatesUsers, setEmailDatesUsers] = useState(false);
  const [sexDatesUsers, setSexDatesUsers] = useState(false);
  const [phoneDatesUsers, setPhoneDatesUsers] = useState(false);
  const [ageDatesUsers, setAgeDatesUsers] = useState(false);
  const [currentTimeDatesUsers, setCurrentTimeDatesUsers] = useState(false);
  const [campusDatesUsers, setCampusDatesUsers] = useState(false);
  const [jobTypeDatesUsers, setJobTypeDatesUsers] = useState(false);

  const handleOpenModalDataUsers = (
    userJson,
    document,
    email,
    phone,
    sex,
    age,
    currentTime,
    campus,
    jobType
  ) => {
    setuserJsonDatesUsers(userJson);
    setDocumentDatesUsers(document);
    setEmailDatesUsers(email);
    setPhoneDatesUsers(phone);
    setSexDatesUsers(sex);
    setAgeDatesUsers(age);
    setCurrentTimeDatesUsers(currentTime);
    setCampusDatesUsers(campus);
    setJobTypeDatesUsers(jobType);
    setOpenModalDatesUers(true);
  };

  const handleStoreAccidentsWorkers = async (event) => {
    event.preventDefault();
    const response = await MyRegistersAccidents.creatAccidentsWorkers({
      idRegisterAudit: idRegisterAudit,
      id_users: idResponsible,
      area: area,
      shift: shift,
      work_experience: workExperience,
      work_hours_before: workHoursBefore,
    });
    if (response.success === false) {
      console.log("error", response);
    } else {
      setModalAddAuditor(false);
      // handleGetAccidentsWorkers();
    }
    return response;
  };

  /////////////////////////////////

  const [responsibleAuditors, setResponsibleAuditors] = useState([]);
  const [auditorName, setAuditorName] = useState("");
  const [registerNumber, setRegisterNumber] = useState("");

  const [auditDate, setAuditDate] = useState("");
  const [auditProcess, setAuditProcess] = useState("");
  const [auditProcessResponsible, setAuditProcessResponsible] = useState("");

  // const handleGetAuditorsResponsible = async (event) => {
  // event.preventDefault();
  ///Auditores y Registro

  const handleGetAuditors = async () => {
    const response = await MyRegistersAudits.getAuditorsAudit({
      idRegister: idRegisterAudit,
    });
    setAccidentsWorkers(response);
  };

  const handleAddAuditors = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAudits.addAuditorsAudit({
      idRegister: idRegisterAudit,
      auditName: auditorName,
      registerNumber: registerNumber,
    });
    handleGetAuditors();
    handleCloseModalAddAuditor();
    handleCloseBackdrop();
    return response;
  };

  const handleDeleteAuditors = async () => {
    const response = await MyRegistersAudits.deleteAuditorsAudit({
      idSubregister: idRegisterAuditWorkers,
    });
    handleGetAuditors();
  };

  ///Auditores y Registro Responsables
  const handleGetAuditorsResponsible = async () => {
    const response = await MyRegistersAudits.getAuditorsResponsibles({
      idRegister: idRegisterAudit,
    });
    setResponsibleAuditors(response);
  };

  const handleAddAuditorResponsible = async () => {
    const response = await MyRegistersAudits.addAuditorsResponsibles({
      idRegister: idRegisterAudit,
      date: auditDate,
      auditProcesses: auditProcess,
      responsibleName: auditProcessResponsible,
    });
    handleGetAuditorsResponsible();
    return response;
  };

  const handleDeleteAuditorResponsible = async () => {
    const response = await MyRegistersAudits.deleteAuditorsResponsibles({});
  };

  //Responsables del registro y de la investigación
  //obtiene responsables
  const handleGetResponsibleAudit = async () => {
    const response = await MyRegistersAudits.getResponsibleAudit({
      idRegister: idRegisterAudit,
    });
    setResponsibleActions(response);
  };
  //agrega responsables
  const handleStoreResponsibleAudit = async (idUsers) => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAudits.addResponsibleAddAudit({
      idUser: idUsers,
      idRegister: idRegisterAudit,
    });
    if (response != null) {
      handleGetResponsibleAudit();
      handleCloseModalUploadResponsibleRegistration();
      handleCloseBackdrop();
    }
  };
  //elimina responsables
  const handleDeleteResponsibleAudit = async ({ idUsers }) => {
    const response = await MyRegistersAudits.deleteResponsibleAudit({
      idUser: idUsers,
      idRegister: idRegisterAudit,
    });
    handleGetResponsibleAudit();
    return response;
  };
  //firma responsables
  const handleSignResponsibleAudit = async (event) => {
    event.preventDefault();
    const response = await MyRegistersAudits.signResponsibleAudit({
      idUserId: idUserIdResponsible,
      idUser: idAccidentsResponsible,
    });
    handleGetResponsibleAudit();
    setOpenModalSignResponsible(false);
  };
  //responsables del registro y de la investigación

  const handleGetAddAuditioners = async () => {
    const response = await MyRegistersAudits.getAddAuditioners({
      idRegister: idRegisterAudit,
    });
    setAccidentsWorkers(response);
  };

  const handleEditRegisterAudit = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAudits.editRegisterAudit({
      idRegister: idRegisterAudit,
    });

    if (response != null) {
      setRegisterStatus(response.register_status === 1 ? true : false);
      setCode(response.code);
      setConformityNumber(response.non_conformities_number);
      setcausesDescription(response.non_conformity_causes);
      setDescriptionWork(response.non_conformity_description);
      setDocumentUrl1(response.document_url_1);
      setDocumentUrl2(response.document_url_2);
      handleCloseBackdrop();
    } else {
      console.log("Error, no se pudo obtener datos del registro");
    }
  };

  const handleUpdateRegistersAudit = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAudits.updateRegisterAudit({
      idRegister: idRegisterAudit,
      code: code,
      nonConformitiesNumber: conformityNumber,
      nonConformityDescription: descriptionWork,
      nonConformityCauses: causesDescription,
      documentUrl1: documentUrl1,
      documentUrl2: documentUrl2,
    });


    if (response.success === false) {
      console.log("error al actualizar");
    } else {
      // handleGetAccidentsWorkers();
      handleEditRegisterAudit();
      handleCloseBackdrop();
    }
    return response;
  };
  ///auditoringaudit

  const [auditoringAudit, setAuditoringAudit] = useState([]);

  const handleGetAuditoringAudit = async () => {
    const response = await MyRegistersAudits.getAuditoringAudit({
      idRegistersAudits: idRegisterAudit,
    });

    setAuditoringAudit(response);
  };

  const [auditoringProcess, setAuditoringProcess] = useState("");
  const [auditoringDate, setAuditoringDate] = useState("");
  // usa  responsibleCorrectiveActions

  const handleCreateAuditoringAudit = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAudits.createAuditoringAudit({
      idRegistersAudits: idRegisterAudit,
      idUsers: idResponsible,
      auditoringProcess: auditoringProcess,
      auditoringDate: auditoringDate,
    });
    handleGetAuditoringAudit();
    handleCloseBackdrop();
    setModalAddAudit(false);

  };

  const handleDeleteAuditoringAudit = async () => {
    const response = await MyRegistersAudits.deleteAuditoringAudit({
      idAuditoringAudit: idAuditoringAudit,
    });
    handleGetAuditoringAudit();
  };

  useEffect(() => {
    handleUserActive();
    // handleEditRegistersAccidents();
    handleEditRegisterAudit();
    // handleEditRegisterAudit();
    handleGetAddAuditioners();
    handleGetSeverityAccidents();
    handleGetSeverityInccidents();
    // handleGetAccidentsWorkers();
    handleGetResponsibleAudit();
    // medidas correctivas
    handleGetCorrectivesActions();
    handleGetAuditors();
    handleGetAuditorsResponsible();
    handleGetUsersSearchsAddsWorkers();
    handleGetAuditoringAudit();
    // trabajadores responsables
    // handleGetResponsibleWorkers();

    // handleSelectUsersResponsible();
    // handleGetUsersActives();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      {/* <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      /> */}
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText text={"Registro de Auditorías"}></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegistersAudit}>
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nro. registro"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Auditores y registros
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                textAlign="right"
                mt={2}
              >
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar auditores"}
                    functionOnClick={handleOpenModalAddAuditor}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N° de registro
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                  // sx={{ display: documentType !== 1 ? "none" : "" }}
                  >
                    {accidentsWorkers.map((rowsAccidentsWorkers, key) => (
                      <TableRow
                        key={key}
                        hover
                        // key={rowsAccidentsWorkers.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rowsAccidentsWorkers.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowsAccidentsWorkers.register_number}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rowsAccidentsWorkers.id,
                                    "¿Esta seguro de eliminar los datos del trabajador?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador",
                                    "Si, Eliminar",
                                    "deleteAuditors"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                {/* <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Auditores y registros
                </InputLabel> */}
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                textAlign="right"
                mt={2}
              >
                {registerStatus === true ? (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AUDITORIAS_AGREGAR_DATOS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModalAddAudit}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de auditoria
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Procesos auditados
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre de los responsables de los procesos auditados
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                  // sx={{ display: documentType !== 1 ? "none" : "" }}
                  >
                    {auditoringAudit.map((auditoringAudit) => (
                      <TableRow
                        hover
                        key={auditoringAudit.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {auditoringAudit.auditoring_date}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {auditoringAudit.auditoring_process}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {auditoringAudit.first_name +
                            " " +
                            auditoringAudit.last_name}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonIconControla
                              icon={<Delete sx={{ color: "white" }} />}
                              backgroundColor={"#EB5757"}
                              backgroundColorHover={"#FF4040"}
                              textTooltip={"Eliminar"}
                              functionOnClick={() => {
                                handleOpenModalConfirm(
                                  auditoringAudit.id,
                                  "¿Esta seguro de eliminar los datos del trabajador?",
                                  "Una vez eliminado no se podra recuperar los datos de la auditoría",
                                  "Si, Eliminar",
                                  "deleteAudit"
                                );
                              }}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} mt={3}>
                  <InputControlaVertical
                    text={"Nro. De no conformidades"}
                    inputType={"number"}
                    inputValue={setConformityNumber}
                    value={conformityNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SubtitleText text={"Información a adjuntar"} color={blue} />
                </Grid>

                <Grid item xs={11} md={11}>
                  <InputControlaVerticalIcon
                    text={"a) Informe de auditoría"}
                    inputType={"file"}
                    inputValue={setDocumentUrl1}
                    textTooltip={
                      "Indicar los hallazgos encontrados, así como no conformidades, observaciones, entre otros, con la respectiva firma del auditor o auditores."
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ButtonIconControla
                    icon={<VisibilityOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#305AD9"}
                    backgroundColorHover={"#0E4AFF"}
                    textTooltip={"Ver documento"}
                    directionUrl={documentUrl1}
                    directionTarget={"_blank"}
                    // functionOnClick={() => ""}
                  />
                </Grid>
                <Grid item xs={11} md={11}>
                  <InputControlaVerticalIcon
                    text={
                      "b) Plan de acción para cierre de no conformidades (posterior a la auditoría)."
                    }
                    inputType={"file"}
                    inputValue={setDocumentUrl2}
                    textTooltip={
                      "El plan de acción contiene la descripción de las causas que originaron cada no conformidad, propuesta de las medidas correctivas para cada no conformidad, responsable de implementación, fecha de ejecución, estado de la acción correctiva."
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ButtonIconControla
                    icon={<VisibilityOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#305AD9"}
                    backgroundColorHover={"#0E4AFF"}
                    textTooltip={"Ver documento"}
                    directionUrl={documentUrl2}
                    directionTarget={"_blank"}
                    // functionOnClick={() => ""}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SubtitleText
                    text={
                      "Modelo de encabezados para el plan de acción de cierre de no conformidades"
                    }
                    color={blue}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputControlaVertical
                    text={"Descripción de la no conformidad"}
                    inputType={"textArea"}
                    value={descriptionWork}
                    inputValue={setDescriptionWork}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputControlaVertical
                    text={"Causas de la no conformidad"}
                    inputType={"textArea"}
                    value={causesDescription}
                    inputValue={setcausesDescription}
                  />
                </Grid>
              </Grid>

              {/********************************  medidas correctivas  ********************************************** */}
              <Grid container>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Medidas correctivas
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es obligatorio
                    </Typography>
                  </Box>
                  <Box>
                    {registerStatus === true ? (
                      <ButtonControla
                        roles={permissions.DOC_REGIS_AUDITORIAS_AGREGAR_MEDIDAS}
                        iconButton={<Add sx={{ color: "white" }} />}
                        backgroundColor={"#169073"}
                        backgroundColorHover={"#1BAA88"}
                        textButton={"Agregar medidas correctivas"}
                        functionOnClick={handleOpenModalUploadCorrectiveActions}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Stack>
              </Grid>

              <Grid container sx={{ marginTop: "4px" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Descripción de la medida correctiva
                        </TableCell>
                        {/* Descripción de la
                          medida correctiva a implementarse para eliminar la
                          causa y prevenir la consecuencia */}

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Responsable
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Fecha de inicio
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Fecha de fin
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {/* Completar en la fecha de ejecución propuesta, el
                          ESTADO de la implementación de la medida correctiva
                          (realizada, pendiente, en ejecución)*/}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {correctivesActions.map((rows) => (
                        <TableRow
                          hover
                          key={rows.id_users}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {rows.description}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {rows.first_name + " " + rows.last_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {rows.timestamp_start.substring(0, 10)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                              {
                                rows.timestamp_end  == "" ? "No definido" : rows.timestamp_end
                              }
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {rows.status}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + rows.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                              {registerStatus === true ? (
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      rows.id,
                                      "¿Esta seguro de eliminar los datos de la medida correctiva?",
                                      "Una vez eliminado no se podra recuperar los datos de la medida correctiva",
                                      "Si, Eliminar",
                                      "deleteMeasures"
                                    );
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* responsables del registro y de la investigación */}
              <Grid container>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Responsables del registro y de la investigación
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es obligatorio
                    </Typography>
                  </Box>
                  <Box>
                    {registerStatus === true ? (
                      <ButtonControla
                        roles={permissions.DOC_REGIS_AUDITORIAS_AGREGAR_RESPON}
                        iconButton={<Add sx={{ color: "white" }} />}
                        backgroundColor={"#169073"}
                        backgroundColorHover={"#1BAA88"}
                        textButton={"Agregar responsables"}
                        functionOnClick={
                          handleOpenModalUploadResponsibleRegistration
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Stack>
              </Grid>

              <Grid container sx={{ marginTop: "4px" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Nombre y Apellido
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Cargo
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Fecha de firma
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Firma
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {responsibleWorkers.map((row) => (
                        <TableRow
                          hover
                          key={row.id_users}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.first_name + " " + row.last_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.position_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.firm_date === null ||
                            row.firm_date === undefined
                              ? ""
                              : row.firm_date.substring(0, 19)}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.firm === 0 ? (
                              "No firmado" && row.id_users === idUserLogged ? (
                                <ButtonIconControla
                                  icon={
                                    <HistoryEduOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#F1C40F"}
                                  backgroundColorHover={"#F4D03F"}
                                  textTooltip={"Firmar"}
                                  functionOnClick={() =>
                                    handleOpenModalSignResponsible(
                                      row.id,
                                      row.id_users
                                    )
                                  }
                                />
                              ) : (
                                "No firmado"
                              )
                            ) : (
                              <img
                                src={row.firm_url}
                                alt="Firma no encontrada"
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                              {registerStatus === true ? (
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                      "Una vez eliminado no se podra recuperar los datos del trabajador responsable",
                                      "Si, Eliminar",
                                      "deleteResponsible"
                                    );
                                  }}
                                  // functionOnClick={() =>
                                  //   handleDeleteResponsibleWorkers(
                                  //     row.id_registers_accidents_responsible
                                  //   )
                                  // }
                                />
                              ) : (
                                ""
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* Botones de impresión, guardar y cancelar */}
              <Stack
                spacing={2}
                sx={{
                  display: "flex",
                  width: "100%",
                  paddingY: 4,
                  justifyContent: "center",
                }}
                direction="row"
              >
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<ArrowBack sx={{ color: "white" }} />}
                    backgroundColor={"#EB5757"}
                    backgroundColorHover={"#FF4040"}
                    textButton={"Regresar"}
                    isNavegationOrigin={true}
                    url={"/documentation/registers/generals/audits"}
                  />
                </Grid>
                {registerStatus === true ? (
                  <Grid item xs={12} md={2}>
                    <ButtonControla
                      roles={permissions.DOC_REGIS_AUDITORIAS_ACTUALIZAR_REGIS}
                      iconButton={<EditOutlined sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Actualizar registro"}
                      typeButton="submit"
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {registerStatus === false ? (
                  <Grid item xs={12} md={2}>
                    <ButtonControla
                      //todos
                      roles={[1, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13]}
                      iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                      backgroundColor={"#F2994A"}
                      backgroundColorHover={"#FF881E"}
                      textButton={"Ver documento PDF"}
                      typeButton={"button"}
                      url={
                        process.env.REACT_APP_API_DJANGO +
                        "report/" +
                        idRegisterAudit
                      }
                      target={"_blank"}
                    />
                  </Grid>
                ) : (
                  ""
                )}

                {registerStatus === true ? (
                  <Grid item xs={12} md={2}>
                    <ButtonControla
                      roles={permissions.DOC_REGIS_AUDITORIAS_CERRAR_REGIS}
                      iconButton={<CloseIcon fontSize="inherit" />}
                      backgroundColor={"black"}
                      backgroundColorHover={"#2C2B29"}
                      textButton={"Cerrar registro"}
                      functionOnClick={handleGetRegisterStatus}
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </Stack>
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog agregar nuevo auditor */}
      <Dialog
        open={modalAddAuditor}
        onClose={() => setModalAddAuditor(false)}
        maxWidth="xs"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar auditores
          </Typography>
        </DialogTitle>
        <form onSubmit={handleAddAuditors}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre del auditor:"}
                  inputType={"text"}
                  // inputValue={setArea}
                  // value={area}
                  inputValue={setAuditorName}
                  value={auditorName}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"N° de registro:"}
                  inputType={"text"}
                  //   inputValue={setWorkHoursBefore}
                  //   value={workHoursBefore}
                  inputValue={setRegisterNumber}
                  value={registerNumber}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setModalAddAuditor(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog para agregar nueva auditoria */}
      <Dialog
        open={modalAddAudit}
        onClose={() => setModalAddAudit(false)}
        maxWidth="xs"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar auditoría
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateAuditoringAudit}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Fecha de la auditoría:"}
                  inputType={"date"}
                  inputValue={setAuditoringDate}
                  value={auditoringDate}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Proceso auditado:"}
                  inputType={"text"}
                  inputValue={setAuditoringProcess}
                  value={auditoringProcess}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SearchButtonControla
                    titleLabel="Responsable: "
                    inputValue={responsibleCorrectiveActions}
                    functionClick={handleOpenModalResponsible}
                    modalType={true}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setModalAddAudit(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalUploadCorrectiveActions}
        onClose={handleCloseModalUploadCorrectiveActions}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setTimestampStart}
                  value={timestampStart}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimestampEnd}
                  value={timestampEnd}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Estado de medida correctiva:"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  required
                  value={statusCorrectivesActions}
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={rows.id_registers_accidents_measures_statuses}
                      value={rows.id_registers_accidents_measures_statuses}
                    >
                      {rows.statuses}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsible}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadCorrectiveActions}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables
      <Dialog
        open={openModalResponsible}
        onClose={() => {
          setOpenModalResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersSearchs}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      Dialog de agregar repsonsables  del registro  y de la invesatigación
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog> */}

      {/* Dialogo de buscar trabajadores responsables */}
      <Dialog
        open={openModalUploadInvolvedWorkers}
        onClose={() => {
          setOpenModalUploadInvolvedWorkers(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsResponsiblesWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUploadInvolvedWorkers(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar responsable */}
      <Dialog
        open={openModalSignResponsible}
        onClose={() => {
          setOpenModalSignResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsibleAudit}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* modal users information */}
      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleAudit}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* users information */}
      <Dialog
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        open={openModalDatesUsers}
        onClose={() => setOpenModalDatesUers(false)}
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent>
          <CardUserControla
            users_json={userJsonDatesUsers}
            document={documentDatesUsers}
            email={emailDatesUsers}
            phone={phoneDatesUsers}
            sex={sexDatesUsers}
            age={ageDatesUsers}
            current_time={currentTimeDatesUsers}
            campus={campusDatesUsers}
            job_type={jobTypeDatesUsers}
          />{" "}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalDatesUers(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables */}
      <Dialog
        open={openModalResponsible}
        onClose={() => {
          setOpenModalResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersSearchs}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* modal de información */}
      <DialogInformativeControla
        open={openModalInformative}
        onClose={handleCloseModalInformative}
        message={message}
      />

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
