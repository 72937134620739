// colocar lo de Workaccidents.jsx
import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import SideNav from "../../components/sidenav/sideNav";
import SubtitleText from "../../components/text/subtitleText";
import BannerControla from "../../components/cards/bannerControla";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import MyLegalMatrix from "../../api/MyLegalMatrix";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import { useAppContext } from "../../context/AppContext";
import TableControla from "../../components/tables/table-controla";
import ListLegalMatrix from "./components/list-legalMatrix";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";

const colorTitle = "#9191B0";
const purple = "#8B8BD8";

function Index() {
  const {permissions} = useAppContext();
  // alerts
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
  const [titleText, setTitleText] = React.useState("");
  const [contentText, setContentText] = React.useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState("");
  const [buttonActionType, setButtonActionType] = React.useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdLegalMatrix(id);    
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteLegalMatrix":
        handleDeleteLegalMatrix();
        break;   
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN


  const [openModalAddMatrix, setOpenModalAddMatrix] = React.useState(false);
  const handleOpenModalAddMatrix = () => {
    setName('');
    setOpenModalAddMatrix(true);
  };

  const [legalMatrix, setLegalMatrix] = React.useState([]);
  const [name, setName] = React.useState("");
  const [file, setFile] = React.useState("");

  const handleAddMatrix = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyLegalMatrix.createLegalMatrix({name, file});
    handleGetLegalMatrix();
    setOpenModalAddMatrix(false);
    handleOpenAlert("Se crearon los datos de forma correcta", "success");
    return response;
  };

  const handleGetLegalMatrix = async () => {
    handleOpenLoading();

    const response = await MyLegalMatrix.getLegalMatrix();
    setLegalMatrix(response);

    setOpenLoading(false);

    return response;
  };
  
  // eliminar 
  const [idLegalMatrix, setIdLegalMatrix] = React.useState();

  const handleDeleteLegalMatrix= async () => {
    handleOpenLoading();
    const response = await MyLegalMatrix.deleteLegalMatrix({
      idLegalMatrix: idLegalMatrix,
    });
      handleGetLegalMatrix();
      handleOpenAlert("Los datos fueron eliminados de forma correcta", "success"); 
    return response;
  };


  React.useEffect(() => {
    handleGetLegalMatrix();
  }, []);


  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <ButtonMailboxControla
             originUrl={"/documentation/legalmatrix"}
            />
            
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannerlegalmatrix.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Matriz legal"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                roles = {permissions.DOC_MATRIZ_LEGAL_AGREGAR}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar nueva matriz"}
                functionOnClick={handleOpenModalAddMatrix}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid item md={12}>

                    <TableControla
                     loading={openLoading}
                     tabs={[
                       { 
                         columnNames: ["Nombre del documento", "Estado"]
                       }
                      ]}
                    >
                      <ListLegalMatrix
                       legalMatrix={legalMatrix}
                       colorCell={purple}
                       handleOpenModalConfirm={handleOpenModalConfirm}
                      />

                    </TableControla>

              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalAddMatrix}
        onClose={() => setOpenModalAddMatrix(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
           Crear nueva matriz
          </Typography>
        </DialogTitle>
        <form onSubmit={handleAddMatrix}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre:"}
                  inputType={"text"}
                  inputValue={setName}
                  value={name}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Archivo"}
                  inputType={"file"}
                  inputValue={setFile}
                  value={file}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalAddMatrix(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Index;
