import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
    Grid,
    Button,
    Tabs,
    Tab,
    Stack,
    InputLabel,
    InputBase,
    DialogActions,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import {
    Add,
    BorderColorOutlined,
    Delete,
    ArrowBackIosNew,
    ArrowForwardIos,
    Upload,
    Archive,
    FileUploadOutlined,
    Send,
    Unarchive,
    Publish,
    AssignmentTurnedIn,
    InfoOutlined,
} from "@mui/icons-material";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import SideNav from "../../components/sidenav/sideNav";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../components/text/subtitleText";
import BannerControla from "../../components/cards/bannerControla";
import ButtonControla from "../../components/buttons/buttonController";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import styled from "styled-components";
import MySsoPolitics from "../../api/MySsoPolitics";
import MyRit from "../../api/MyRit";

const blue = "#034AFF";
const purple = "#8B8BD8";

function Fulfillments() {
    const params = new URLSearchParams(window.location.search);
    const idRit = params.get("idRit");

    // variables
    // cumplimientos
    const [fulfillments, setFulfillments] = useState([]);

    /////////
    const [workerEntrance, setWorkerEntrance] = useState("");
    const handleChangeWorkerEntrance = (event) => {
        setWorkerEntrance(event.target.value);
    }

    const [workingHours, setWorkingHours] = useState("");
    const handleChangeWorkingHours = (event) => {
        setWorkingHours(event.target.value);
    }

    const [attendanceControl, setAttendanceControl] = useState("");
    const handleChangeAttendance = (event) => {
        setAttendanceControl(event.target.value);
    }

    const [permanenceRules, setPermanenceRules] = useState("");
    const handleChangePermanenceRules = (event) => {
        setPermanenceRules(event.target.value);
    }

    const [weeklyLeave, setWeeklyLeave] = useState("");
    const handleChangeWeeklyLeave = (event) => {
        setWeeklyLeave(event.target.value);
    }

    const [employerRightsResponsabilities, setEmployerRightsResponsabilities] = useState("");
    const handleChangeEmployerRightsResponsabilities = (event) => {
        setEmployerRightsResponsabilities(event.target.value);
    }

    const [employeeRightsResponsabilities, setEmployeeRightsResponsabilities] = useState("");
    const handleChangeEmployeeResponsabilities = (event) => {
        setEmployeeRightsResponsabilities(event.target.value);
    }

    const [employerEmployeeHarmonyRules, setEmployerEmployeeHarmonyRules] = useState("");
    const handleChangeEmployerEmployeeHarmony = (event) => {
        setEmployerEmployeeHarmonyRules(event.target.value);
    }

    const [disciplinaryMeasures, setDisciplinaryMeasures] = useState("");
    const handleChangeDisciplinaryMeasures = (event) => {
        setDisciplinaryMeasures(event.target.value);
    }

    const [laboralMattersEntity, setLaboralMattersEntity] = useState("");
    const handleChangeLaboralMattersEntity = (event) => {
        setLaboralMattersEntity(event.target.value);
    }

    const [fundamentalRulesActivities, setFundamentalRulesActivities] = useState("");
    const handleChangeFundamentalRulesActivites = (event) => {
        setFundamentalRulesActivities(event.target.value);
    }

    const [accidentAvoidanceRules, setAccidentAvoidanceRules] = useState("");
    const handleChangeAccidentAvoidRules = (event) => {
        setAccidentAvoidanceRules(event.target.value);
    }

    const [authorityEntityApproved, setAuthorityEntityApproved] = useState("");
    const handleChangeAuthorityEntityApproved = (event) => {
        setAuthorityEntityApproved(event.target.value);
    }

    const [sucamec, setSucamec] = useState("");
    const handleChangeSucamec = (event) => {
        setSucamec(event.target.value);
    }

    const [tuberculosisAntidiscriminationRules, setTuberculosisAntidiscriminationRules] = useState("");
    const handleChangeTuberculosisAntidiscriminationRules = (event) => {
        setTuberculosisAntidiscriminationRules(event.target.value);
    }

    const [smokingProhibition, setSmokingProhibition] = useState("");
    const handleChangeSmokingProhibition = (event) => {
        setSmokingProhibition(event.target.value);
    }

    const [sexualHarassmentPolicies, setSexualHarassmentPolicies] = useState("");
    const handleChangeSexualHarassmentPolicies = (event) => {
        setSexualHarassmentPolicies(event.target.value);
    }

    const [investigationSanction, setInvestigationSanction] = useState("");
    const handleChangeInvestigtionSanction = (event) => {
        setInvestigationSanction(event.target.value);
    }

    const [hivPrevention, setHivPrevention] = useState("");
    const handleChangeHivPrevention = (event) => {
        setHivPrevention(event.target.value);
    }

    const [lactancyTime, setLactancyTime] = useState("");
    const handleChangeLactancyTime = (event) => {
        setLactancyTime(event.target.value);
    }

    const [maternityLeave, setMaternityLeave] = useState("");
    const handleChangeMaternityLeave = (event) => {
        setMaternityLeave(event.target.value);
    }

    const [bikeUsage, setBikeUsage] = useState("");
    const handleChangeBikeUsage = (event) => {
        setBikeUsage(event.target.value);
    }

    const [uniformFlexibility, setUniformFlexibility] = useState("");
    const handleChangeUniformFlexibility = (event) => {
        setUniformFlexibility(event.target.value);
    }

    const [diabetesAntidiscrimination, setDiabetesAntidiscrimination] = useState("");
    const handleChangeDiabetesAntidiscrimination = (event) => {
        setDiabetesAntidiscrimination(event.target.value);
    }

    const [ritTopics, setRitTopics] = useState("");
    const handleChangeRitTopics = (event) => {
        setRitTopics(event.target.value);
    }

    // funciones

    const handleGetFulfillments = async () => {
        const response = await MyRit.editRit({ idRit });
        setWorkerEntrance(response.workers_entrance);
        setWorkingHours(response.working_hours);
        setAttendanceControl(response.attendance_control);
        setPermanenceRules(response.permanence_rules);
        setWeeklyLeave(response.weekly_leave);
        setEmployerRightsResponsabilities(response.employer_rights_responsabilities);
        setEmployeeRightsResponsabilities(response.employee_rights_responsabilities);
        setEmployerEmployeeHarmonyRules(response.employer_employee_harmony_rules);
        setDisciplinaryMeasures(response.disciplinary_measures);
        setLaboralMattersEntity(response.laboral_matters_entity);
        setFundamentalRulesActivities(response.fundamental_rules_activities);
        setAccidentAvoidanceRules(response.accident_avoidance_rules);
        setAuthorityEntityApproved(response.authority_entity_approved);
        setSucamec(response.sucamec);
        setTuberculosisAntidiscriminationRules(response.tuberculosis_antidiscrimination_rules);
        setSmokingProhibition(response.smoking_prohibition);
        setSexualHarassmentPolicies(response.sexual_harassment_policies);
        setInvestigationSanction(response.investigation_sanction);
        setHivPrevention(response.hiv_prevention);
        setLactancyTime(response.lactancy_time);
        setMaternityLeave(response.maternity_leave);
        setBikeUsage(response.bike_usage);
        setUniformFlexibility(response.uniform_flexibility);
        setDiabetesAntidiscrimination(response.diabetes_antidiscrimination);
        setRitTopics(response.rit_topics);
        console.log(response);
    };

    const handleSaveFulfillments = async (event) => {
        event.preventDefault();
        const response = await MyRit.updateRit({
            idRit: idRit, workerEntrance: workerEntrance, workingHours: workingHours, attendanceControl: attendanceControl, permanenceRules: permanenceRules, weeklyLeave: weeklyLeave, employeeRightsResponsabilities: employeeRightsResponsabilities, employerRightsResponsabilities: employerRightsResponsabilities, employerEmployeeHarmonyRules: employerEmployeeHarmonyRules, disciplinaryMeasures: disciplinaryMeasures, laboralMattersEntity: laboralMattersEntity, fundamentalRulesActivities: fundamentalRulesActivities, accidentAvoidanceRules: accidentAvoidanceRules, authorityEntityApproved: authorityEntityApproved, sucamec: sucamec, tuberculosisAntidiscrimnationRules: tuberculosisAntidiscriminationRules, smokingProhibition: smokingProhibition, sexualHarassmentPolicies: sexualHarassmentPolicies, investigationSanction: investigationSanction, hivPrevention: hivPrevention, lactancyTime: lactancyTime, maternityLeave: maternityLeave, bikeUsage: bikeUsage, uniformFlexibility: uniformFlexibility, diabetesAntidiscrimination: diabetesAntidiscrimination, ritTopics: ritTopics
        })
        console.log(response);
        window.location.href = "/documentation/rit";
    };

    useEffect(() => {
        handleGetFulfillments();
    }, []);

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack="/documentation/rit" />
            <Container>
                <Box>
                    <form onSubmit={handleSaveFulfillments}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <SubtitleText
                                    text={"Cumplimientos"}
                                    color={"#9191B0"}
                                ></SubtitleText>
                                <SubtitleText
                                    text={
                                        "A continuación te presentamos una lista para que verifiques si el RIT cumple los requisitos legales:"
                                    }
                                    color={blue}
                                ></SubtitleText>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2, paddingTop: 2 }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Requisito de Cumplimiento
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Cumple
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                No Cumple
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        ></TableRow>
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>
                                                        Admisión o ingreso de los trabajadores.
                                                    </Grid>
                                                    {/* <Grid item>
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "La firma puede ser en original, digital o escaneada y la debe hacer el Gerente General o su equivalente, algunas organizaciones lo nombran Director, Presidente, CEO, etc."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>

                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={workerEntrance == "true"}
                                                        value={true}
                                                        onChange={handleChangeWorkerEntrance}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={workerEntrance == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeWorkerEntrance}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>Las jornadas y horarios de trabajo; tiempo de la alimentación principal.</Grid>
                                                    {/* <Grid item>
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "Debe ser fecha cierta (día, mes y año) considerando también que la fecha de la firma de la Política debe ser posterior a la fecha en que se presentó al Comité o Supervisor SSO."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={workingHours == "true"}
                                                        value={true}
                                                        onChange={handleChangeWorkingHours}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={workingHours == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeWorkingHours}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item> Normas de control de asistencia al trabajo</Grid>
                                                    {/* <Grid item>
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "Su contenido es específico, adecuado y apropiado para la organización."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={attendanceControl == "true"}
                                                        value={true}
                                                        onChange={handleChangeAttendance}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={attendanceControl == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeAttendance}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>Normas de permanencia en el puesto conteniendo: permisos, licencias e inasistencias.</Grid>
                                                    {/* <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "Recuerda que la Política debe estar alineada con la misión, la visión, los objetivos y los valores corporativos."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={permanenceRules == "true"}
                                                        value={true}
                                                        onChange={handleChangePermanenceRules}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={permanenceRules == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangePermanenceRules}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>
                                                        Modalidad de los descansos semanales.
                                                    </Grid>
                                                    {/* <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "El compromiso de proteger no es sólo a los trabjadores en planilla sino a todos los miembros de la organización, incluidos: tercerizados, contratistas, recibo por honorarios, practicantes e incluye prevención de lesiones, dolencias, enfermedades e incidentes relacionados."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={weeklyLeave == "true"}
                                                        value={true}
                                                        onChange={handleChangeWeeklyLeave}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={weeklyLeave == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeWeeklyLeave}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>
                                                        Derecho y obligaciones del empleador
                                                    </Grid>
                                                    {/* <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "Incluye requisitos legales y acuerdos voluntarios que la organizacióno suscriba."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={employerRightsResponsabilities == "true"}
                                                        value={true}
                                                        onChange={handleChangeEmployerRightsResponsabilities}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={employerRightsResponsabilities == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeEmployerRightsResponsabilities}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                ><Grid item>
                                                        Derecho y obligaciones del trabajador
                                                    </Grid>
                                                    {/* <Grid item>
                                                        Derechos y obligaciones del trabajador
                                                    </Grid>
                                                    <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "Tener en cuenta siempre la opinión de los trabajadores, sindicatos, Comités o Supervisores SST."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={employeeRightsResponsabilities == "true"}
                                                        value={true}
                                                        onChange={handleChangeEmployeeResponsabilities}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={employeeRightsResponsabilities == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeEmployeeResponsabilities}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>
                                                        Normas tendientes al fomento y mantenimiento de la armonía entre trabajadores y empleadores.
                                                    </Grid>
                                                    {/* <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "La revisión de la política se debe efectuar al menos una vez al año, en la revisión se debe verificar si la política se adecúa a la situación actual de la organización. Permite plantear nuevos objetivos cuando los antiguos han sido alcanzados, introducir elementos nuevos o precisar los ya existentes. La revisión forma parte del proceso de mejora continua y por eso debe asumirse con el mayor detalle y un alto compromiso. Colocar versión 01 y así sucesivamente; al igual que el documento inicial, las siguientes versiones deben estar documentadas."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={employerEmployeeHarmonyRules == "true"}
                                                        value={true}
                                                        onChange={handleChangeEmployerEmployeeHarmony}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={employerEmployeeHarmonyRules == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeEmployerEmployeeHarmony}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>Medidas disciplinarias.</Grid>
                                                    {/* <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "La empresa debe contar con indicadores y metas cada vez más altas y exigentes."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={disciplinaryMeasures == "true"}
                                                        value={true}
                                                        onChange={handleChangeDisciplinaryMeasures}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={disciplinaryMeasures == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeDisciplinaryMeasures}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>
                                                        Persona o dependencia encargada de atender los asuntos laborales y la tramitación de los mismos.
                                                    </Grid>
                                                    {/* <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "La revisión de la política se debe efectuar al menos una vez al año, en la revisión se debe verificar si la política se adecúa a la situación actual de la organización. Permite plantear nuevos objetivos cuando los antiguos han sido alcanzados, introducir elementos nuevos o precisar los ya existentes. La revisión forma parte del proceso de mejora continua y por eso debe asumirse con el mayor detalle y un alto compromiso."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={laboralMattersEntity == "true"}
                                                        value={true}
                                                        onChange={handleChangeLaboralMattersEntity}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={laboralMattersEntity == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeLaboralMattersEntity}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>Normas elementales que se deben observar dentro del desarrollo de la actividad laboral para prevenir la higiene, salud y seguridad ocupacional.</Grid>
                                                    <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "Entre las principales tenemos: Ley N° 29783, Ley de Seguridad y Salud en el Trabajo. Decreto Supremo N° 005-2012-TR, Reglamento de la Ley de Seguridad y Salud en el Trabajo"
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={fundamentalRulesActivities == "true"}
                                                        value={true}
                                                        onChange={handleChangeFundamentalRulesActivites}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={fundamentalRulesActivities == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeFundamentalRulesActivites}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>
                                                        Indicaciones para evitar accidentes y otros riesgos profesionales, así como las instrucciones respectivas para la prestación de primeros auxilios.
                                                    </Grid>
                                                    <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "Se puede incluir aquí el procedimiento de primeros auxilios que se tiene en la empresa, así como los estándares generales de seguridad y salud ocupacional."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={accidentAvoidanceRules == "true"}
                                                        value={true}
                                                        onChange={handleChangeAccidentAvoidRules}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={accidentAvoidanceRules == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeAccidentAvoidRules}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>
                                                        Está debidamente aprobado por la autoridad administrativa de trabajo.
                                                    </Grid>
                                                    <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "Recuerda que tanto el RIT inicial, así como sus modificaciones y/o actualizaciones deben ser presentadas a la autoridad administrativa de trabajo."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={authorityEntityApproved == "true"}
                                                        value={true}
                                                        onChange={handleChangeAuthorityEntityApproved}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={authorityEntityApproved == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeAuthorityEntityApproved}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))} */}

                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>
                                                        Está visado por la autoridad competente (SUCAMEC)
                                                    </Grid>
                                                    <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "Este requisito es para las empresas que prestan servicios de seguridad privada y cuya autoridad es la SUCAMEC."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={sucamec == "true"}
                                                        value={true}
                                                        onChange={handleChangeSucamec}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={sucamec == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeSucamec}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>
                                                        Contiene las medidas para prevenir y sancionar los actos discriminatorios hacia una persona afectada por tuberculosis.
                                                    </Grid>
                                                    <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "Recuerda que el acto de discriminación contra una persona afectada por tuberculosis se considera un acto de hostilidad equiparable al despido."
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={tuberculosisAntidiscriminationRules == "true"}
                                                        value={true}
                                                        onChange={handleChangeTuberculosisAntidiscriminationRules}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={tuberculosisAntidiscriminationRules == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeTuberculosisAntidiscriminationRules}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                        {/* */}
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="left">
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                    }}
                                                >
                                                    <Grid item>
                                                        Debe incluir la prohibición expresa de fumar en todas sus instalaciones, y los mecanismos internos para denunciar a quienes fmen donde esté prohibido
                                                    </Grid>
                                                    {/* <Grid item>
                                                        {" "}
                                                        <ButtonIconControla
                                                            icon={<InfoOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#FFC300"}
                                                            backgroundColorHover={"#FACB32"}
                                                            textTooltip={
                                                                "La política debe estas publicada de manera visible tanto en la página web de la empresa, un sistema de gestión, en sus instalaciones (ubícala en un punto por el que ingresan todos a las instalaciones, si tienen varios ingresos podría haber una copia en cada uno de ellos). La publicación responde a la imagen corporativa de la empresa"
                                                            }
                                                        // functionOnClick={handleOpenModalTypeAgentsTable}
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={smokingProhibition == "true"}
                                                        value={true}
                                                        onChange={handleChangeSmokingProhibition}
                                                        name="radio-buttons"
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl>
                                                    <Radio
                                                        checked={smokingProhibition == "false"}
                                                        name="radio-buttons"
                                                        onChange={handleChangeSmokingProhibition}
                                                        value={false}
                                                    ></Radio>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    {/* */}
                                    <TableRow>
                                        <TableCell sx={{ color: purple }} align="left">
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <Grid item>
                                                    Contiene una política para prevenir y sancionar el hostigamiento sexual, especificando los canales y par la presentación de quejas o denuncias.
                                                </Grid>
                                                {/* <Grid item>
                                                    {" "}
                                                    <ButtonIconControla
                                                        icon={<InfoOutlined sx={{ color: "white" }} />}
                                                        backgroundColor={"#FFC300"}
                                                        backgroundColorHover={"#FACB32"}
                                                        textTooltip={
                                                            "Recomendamos verificar el procedimiento y sus plazos en la Resolución Ministerial N° 223-2019-TR que aprueba la Guía práctica para prevención y sanción del hostigamiento sexual en el lugar de trabajo en el sector privado y público."
                                                        }
                                                    // functionOnClick={handleOpenModalTypeAgentsTable}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={sexualHarassmentPolicies == "true"}
                                                    value={true}
                                                    onChange={handleChangeSexualHarassmentPolicies}
                                                    name="radio-buttons"
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={sexualHarassmentPolicies == "false"}
                                                    name="radio-buttons"
                                                    onChange={handleChangeSexualHarassmentPolicies}
                                                    value={false}
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    {/* */}
                                    <TableRow>
                                        <TableCell sx={{ color: purple }} align="left">
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <Grid item>
                                                    Establece el procedimiento de investigación y sanción, así como los plazos de cada etapa en caso de denuncia por hostigamiento sexual.
                                                </Grid>
                                                <Grid item>
                                                    {" "}
                                                    <ButtonIconControla
                                                        icon={<InfoOutlined sx={{ color: "white" }} />}
                                                        backgroundColor={"#FFC300"}
                                                        backgroundColorHover={"#FACB32"}
                                                        textTooltip={
                                                            "Recomendamos verificar el procedimiento y sus plazos en la Resolución Ministerial N° 223-2019-TR que aprueba la Guía práctica para prevención y sanción del hostigamiento sexual en el lugar de trabajo en el sector privado y público."
                                                        }
                                                    // functionOnClick={handleOpenModalTypeAgentsTable}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={investigationSanction == "true"}
                                                    value={true}
                                                    onChange={handleChangeInvestigtionSanction}
                                                    name="radio-buttons"
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={investigationSanction == "false"}
                                                    name="radio-buttons"
                                                    onChange={handleChangeInvestigtionSanction}
                                                    value={false}
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    {/* */}
                                    <TableRow>
                                        <TableCell sx={{ color: purple }} align="left">
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <Grid item>
                                                    Contiene una políþica de prevencióñ y control de VIH/SIDA, así como sobre sancionar cualuier actitud o acción respecto de a discriminación y rechzo de las personas con real o supuestamente VIH positivo.
                                                </Grid>
                                                {/* <Grid item>
                                                    {" "}
                                                    <ButtonIconControla
                                                        icon={<InfoOutlined sx={{ color: "white" }} />}
                                                        backgroundColor={"#FFC300"}
                                                        backgroundColorHover={"#FACB32"}
                                                        textTooltip={
                                                            "La política debe estas publicada de manera visible tanto en la página web de la empresa, un sistema de gestión, en sus instalaciones (ubícala en un punto por el que ingresan todos a las instalaciones, si tienen varios ingresos podría haber una copia en cada uno de ellos). La publicación responde a la imagen corporativa de la empresa"
                                                        }
                                                    // functionOnClick={handleOpenModalTypeAgentsTable}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={hivPrevention == "true"}
                                                    value={true}
                                                    onChange={handleChangeHivPrevention}
                                                    name="radio-buttons"
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={hivPrevention == "false"}
                                                    name="radio-buttons"
                                                    onChange={handleChangeHivPrevention}
                                                    value={false}
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    {/* */}
                                    <TableRow>
                                        <TableCell sx={{ color: purple }} align="left">
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <Grid item>
                                                    Se puede incluir dentro del RIT las acciones para usar (por ejemplo tiempo de uso de 1 hora) y acceder al servicio del lactario, así como para su mantenimiento, registros que se deben llevar y responsable de los mismos.
                                                </Grid>
                                                {/* <Grid item>
                                                    {" "}
                                                    <ButtonIconControla
                                                        icon={<InfoOutlined sx={{ color: "white" }} />}
                                                        backgroundColor={"#FFC300"}
                                                        backgroundColorHover={"#FACB32"}
                                                        textTooltip={
                                                            "La política debe estas publicada de manera visible tanto en la página web de la empresa, un sistema de gestión, en sus instalaciones (ubícala en un punto por el que ingresan todos a las instalaciones, si tienen varios ingresos podría haber una copia en cada uno de ellos). La publicación responde a la imagen corporativa de la empresa"
                                                        }
                                                    // functionOnClick={handleOpenModalTypeAgentsTable}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={lactancyTime == "true"}
                                                    value={true}
                                                    onChange={handleChangeLactancyTime}
                                                    name="radio-buttons"
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={lactancyTime == "false"}
                                                    name="radio-buttons"
                                                    onChange={handleChangeLactancyTime}
                                                    value={false}
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    {/* */}
                                    <TableRow>
                                        <TableCell sx={{ color: purple }} align="left">
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <Grid item>
                                                    También puedes incluir el trámite a realizar por el permiso para lactancia materna hasta que el niño cumpla un año de edad.
                                                </Grid>
                                                {/* <Grid item>
                                                    {" "}
                                                    <ButtonIconControla
                                                        icon={<InfoOutlined sx={{ color: "white" }} />}
                                                        backgroundColor={"#FFC300"}
                                                        backgroundColorHover={"#FACB32"}
                                                        textTooltip={
                                                            "La política debe estas publicada de manera visible tanto en la página web de la empresa, un sistema de gestión, en sus instalaciones (ubícala en un punto por el que ingresan todos a las instalaciones, si tienen varios ingresos podría haber una copia en cada uno de ellos). La publicación responde a la imagen corporativa de la empresa"
                                                        }
                                                    // functionOnClick={handleOpenModalTypeAgentsTable}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={maternityLeave == "true"}
                                                    value={true}
                                                    onChange={handleChangeMaternityLeave}
                                                    name="radio-buttons"
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={maternityLeave == "false"}
                                                    name="radio-buttons"
                                                    onChange={handleChangeMaternityLeave}
                                                    value={false}
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    {/* */}
                                    <TableRow>
                                        <TableCell sx={{ color: purple }} align="left">
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <Grid item>
                                                    Con relación al uso de la bicicleta, como medio de transporte al centro laboral, se recomienda contar con una política interna para la promoción del uso de la bicicleta, que puede estar incluida en el RIT, o en documento aparte. Esta política debe incluir la flexibilización del horario de trabajo, otorgamiento de horas o días libres remunerados y las medidas necesarias para verificar el uso de la bicicleta.
                                                </Grid>
                                                {/* <Grid item>
                                                    {" "}
                                                    <ButtonIconControla
                                                        icon={<InfoOutlined sx={{ color: "white" }} />}
                                                        backgroundColor={"#FFC300"}
                                                        backgroundColorHover={"#FACB32"}
                                                        textTooltip={
                                                            "La política debe estas publicada de manera visible tanto en la página web de la empresa, un sistema de gestión, en sus instalaciones (ubícala en un punto por el que ingresan todos a las instalaciones, si tienen varios ingresos podría haber una copia en cada uno de ellos). La publicación responde a la imagen corporativa de la empresa"
                                                        }
                                                    // functionOnClick={handleOpenModalTypeAgentsTable}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={bikeUsage == "true"}
                                                    value={true}
                                                    onChange={handleChangeBikeUsage}
                                                    name="radio-buttons"
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={bikeUsage == "false"}
                                                    name="radio-buttons"
                                                    onChange={handleChangeBikeUsage}
                                                    value={false}
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    {/* */}
                                    <TableRow>
                                        <TableCell sx={{ color: purple }} align="left">
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <Grid item>
                                                    La flexibilización en el uso de la vestimenta formal o uniforme para los usuarios de bicicletas como medio de transporte as su centro laboral, sin perjuicio de las medidas de seguridad y salud ocupacional.</Grid>
                                                {/* <Grid item>
                                                    {" "}
                                                    <ButtonIconControla
                                                        icon={<InfoOutlined sx={{ color: "white" }} />}
                                                        backgroundColor={"#FFC300"}
                                                        backgroundColorHover={"#FACB32"}
                                                        textTooltip={
                                                            "La política debe estas publicada de manera visible tanto en la página web de la empresa, un sistema de gestión, en sus instalaciones (ubícala en un punto por el que ingresan todos a las instalaciones, si tienen varios ingresos podría haber una copia en cada uno de ellos). La publicación responde a la imagen corporativa de la empresa"
                                                        }
                                                    // functionOnClick={handleOpenModalTypeAgentsTable}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={uniformFlexibility == "true"}
                                                    value={true}
                                                    onChange={handleChangeUniformFlexibility}
                                                    name="radio-buttons"
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={uniformFlexibility == "false"}
                                                    name="radio-buttons"
                                                    onChange={handleChangeUniformFlexibility}
                                                    value={false}
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    {/* */}
                                    <TableRow>
                                        <TableCell sx={{ color: purple }} align="left">
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <Grid item>
                                                    Se puede incluir dentro del RIT la politica de no discriminación por razón de diabetes de acuerdo con el Decreto Supremo Nro 008-2023-SA
                                                </Grid>
                                                {/* <Grid item>
                                                    {" "}
                                                    <ButtonIconControla
                                                        icon={<InfoOutlined sx={{ color: "white" }} />}
                                                        backgroundColor={"#FFC300"}
                                                        backgroundColorHover={"#FACB32"}
                                                        textTooltip={
                                                            "La política debe estas publicada de manera visible tanto en la página web de la empresa, un sistema de gestión, en sus instalaciones (ubícala en un punto por el que ingresan todos a las instalaciones, si tienen varios ingresos podría haber una copia en cada uno de ellos). La publicación responde a la imagen corporativa de la empresa"
                                                        }
                                                    // functionOnClick={handleOpenModalTypeAgentsTable}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={diabetesAntidiscrimination == "true"}
                                                    value={true}
                                                    onChange={handleChangeDiabetesAntidiscrimination}
                                                    name="radio-buttons"
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={diabetesAntidiscrimination == "false"}
                                                    name="radio-buttons"
                                                    onChange={handleChangeDiabetesAntidiscrimination}
                                                    value={false}
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    {/* */}
                                    <TableRow>
                                        <TableCell sx={{ color: purple }} align="left">
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                }}
                                            >
                                                <Grid item>
                                                    Recuerda considerar incluir en el RIT los siguientes temas:
                                                    <ol type="a">
                                                        <li>
                                                            Licencia por descanso prenatal y postnatal de la trabajadora gestante.
                                                        </li>
                                                        <li>
                                                            Permiso por lactancia materna, que es diferente al uso del lactario.
                                                        </li>
                                                        <li>
                                                            Licencia por partenidad.
                                                        </li>
                                                        <li>
                                                            Licencia en los casos de tener un hijo, padre o madre, cónyuge o conviviente enfermo diagnosticado en estado grave o terminal.

                                                        </li>
                                                        <li>
                                                            Permiso en caso el trabajador  tenga familiares directos y cuidadores a cargo de la atención de los pacientes con enfermedad de Alzheimer y otras demencias.
                                                        </li>
                                                        <li>
                                                            Licencia para la asistencia médica y la terapia de rehabilitación que requieran los hijos menores con discapacidad, menores con discapacidad sujetos a su tutela, mayores de edad con discapacidad en condición de dependencia o sujetos a su curatela.
                                                        </li>
                                                        <li>
                                                            Licencia en caso el trabajador sea peticionario de adopción.
                                                        </li>
                                                        <li>
                                                            Permiso en caso del trabajador con tuberculosis a fin de recibir su respectivo tratamiento.
                                                        </li>
                                                        <li>
                                                            Licencia por fallecimiento de cónyuge/conviviente, padres, hijos o hermanos.
                                                        </li>
                                                        <li>
                                                            Otros permisos y licencias que la empresa establezca o que la normativa laboral señale.
                                                        </li>
                                                    </ol>
                                                </Grid>
                                                {/* <Grid item>
                                                    {" "}
                                                    <ButtonIconControla
                                                        icon={<InfoOutlined sx={{ color: "white" }} />}
                                                        backgroundColor={"#FFC300"}
                                                        backgroundColorHover={"#FACB32"}
                                                        textTooltip={
                                                            "La política debe estas publicada de manera visible tanto en la página web de la empresa, un sistema de gestión, en sus instalaciones (ubícala en un punto por el que ingresan todos a las instalaciones, si tienen varios ingresos podría haber una copia en cada uno de ellos). La publicación responde a la imagen corporativa de la empresa"
                                                        }
                                                    // functionOnClick={handleOpenModalTypeAgentsTable}
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={ritTopics == "true"}
                                                    value={true}
                                                    onChange={handleChangeRitTopics}
                                                    name="radio-buttons"
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="center">
                                            <FormControl>
                                                <Radio
                                                    checked={ritTopics == "false"}
                                                    name="radio-buttons"
                                                    onChange={handleChangeRitTopics}
                                                    value={false}
                                                ></Radio>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>

                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid container sx={{ mt: 4, paddingBottom: 10 }}>
                            <Grid item xs={3} sm={3} md={10} lg={10} xl={10} />
                            <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                                <ButtonControla
                                    textButton={"Guardar"}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1AA483"}
                                    typeButton={"submit"}
                                />
                            </Grid>

                            <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                                <ButtonControla
                                    textButton={"Regresar"}
                                    backgroundColor={purple}
                                    functionOnClick={() =>
                                        (window.location.href = "/documentation/rit")
                                    }
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </Box>
    );
}
export default Fulfillments;
