import { React, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SideNav from "../../components/sidenav/sideNav";
import {
    Alert,
    Container,
    Grid,
    MenuItem,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    Snackbar,
    InputLabel,
    // InputBase,
    Table,
    Paper,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Select
} from "@mui/material";
import {
    Add,
    Edit,
    DeleteForever,
  } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SubtitleText from "../../components/text/subtitleText";
import TitleText from "../../components/text/titleText";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import SelectControla from "../../components/selects/selectControla";
import ButtonControla from "../../components/buttons/buttonController";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SearchUsersAndOtherControla from "../../components/search/searchUsersAndAddOtherControla";
import { styled } from "@mui/system";
import MyMeeting from "../../api/MyMeetings";
import MyUsers from "../../api/MyUsers";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import MyMembersCommitte from "../../api/MyMembersCommittee";
import {useLocation, useNavigate} from "react-router-dom";
import LoadingControla from "../../components/load/loadingControla";


//colores
const grey = "#9191B0";
const skyblue = "#2D9CDB";
const blue = "#034AFF";

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em",
  });

function EditScheduleMeeting() {
    const location = useLocation();
    const navigate = useNavigate();

    const readOnly = location.state.readOnly;
    const [title, setTitle] = useState("");

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    //fin de alerts

    //dialogo de confirmación
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [openModalConfirm, setOpenModalConfirm] = useState(false);

    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm) => {
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        handleUpdateMeeting();
    }


    //obtener el id de la reunión por el enlace 
    const params = new URLSearchParams(window.location.search);
    const id_meeting = params.get("id_meeting");

    const searchMeeting = async (id_meeting) => {
        handleOpenLoading();

        if (readOnly) {
            setTitle("Detalle información de reunión");
        } else {
            setTitle("Editar información de reunión");
        }

        const response = await MyMeeting.searchMeeting(id_meeting);

        setDateMeeting(response.data.date);
        setDescriptionMeeting(response.data.description);
        setHourStart(response.data.start_time.substr(0, 5));
        setHourEnd(response.data.end_time.substr(0, 5));
        setSession(response.data.session);
        setModality(response.data.modality);
        setPlace(response.data.location);

        setOpenLoading(false);
    }

    //array que muestra en el select 
    const typeSession = [{ id_session: 1, nombre: "ordinario" }, { id_session: 2, nombre: "extraordinario" }];
    const typeModality = [{ id_modality: 1, nombre: "presencial" }, { id_modality: 2, nombre: "remoto" }];

    //datos para la agenda de la reunión
    const [dateMeeting, setDateMeeting] = useState("");
    const [descriptionMeeting, setDescriptionMeeting] = useState("");
    const [hourStart, setHourStart] = useState("");
    const [hourEnd, setHourEnd] = useState("");
    const [session, setSession] = useState("");
    const [modality, setModality] = useState("");
    const [place, setPlace] = useState("");

    //activar o desactivar modal de invitados
    const [openModalCreateGuest, setOpenModalCreateGuest] = useState(false);

    //array de usuarios del sistema
    const [users, setUsers] = useState([]);

    const handleOpenModalCreateGuests = async () => {
        setOpenModalCreateGuest(true);

        //traer data de todo los usuarios del sistema
        const result = await MyUsers.users();
        setUsers(result.users_actives);
    }

    const handleCloseModalCreateGuest = () => {
        setOpenModalCreateGuest(false);
    }

    const handleUpdateMeeting = async () => {
        handleOpenLoading()
        try {
            //actualizar la data del meeting
            const dataMeeting = {
                id_committee_meeting: id_meeting,
                code: null,
                location: place,
                date: dateMeeting,
                start_time: hourStart,
                end_time: hourEnd,
                session: session,
                modality: modality,
                number_of_participants: null,
                quorum: null,
                id_users_created: getUser().id_users,
                id_users_modified: getUser().id_users,
                url_session: null,
                status: 1,
                description: descriptionMeeting
            }
            await MyMeeting.updateMeeting(id_meeting, dataMeeting);
            handleCloseModalConfirm();
            handleOpenAlert("Se actualizó correctamente", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }

    }


    const [emailUsers, setEmailUsers] = useState("");
    const [nameUsers, setNameUsers] = useState("");
    const [arrayGuest, setArrayGuest] = useState([]);


    const onSearchValueChangeName = (event) => {
        setNameUsers(event.target.value);
    }
    const onSearchValueChangeEmail = (event) => {
        setEmailUsers(event.target.value);
    }

    const getUser = () => {
        return MyConfigurationApi.userData();
    }

    const handleGetGuestMeeting = async () => {
        const response = await MyMeeting.getGuestByMeeting(id_meeting);
        setArrayGuest(response.data);
    }


    const handleAddGuest = async (idUser) => {
        handleOpenLoading();
        try {
            let data = {
                name: nameUsers,
                email: emailUsers,
                id_user: null,
                id_committee_meeting: id_meeting,
                id_users_created: getUser().id_users,
                id_users_modified: getUser().id_users
            };

            if (idUser !== null) {
                const user = users.filter((u) => u.id_users === idUser);

                data.name = user[0].first_name + user[0].last_name;
                data.email = user[0].email;
                data.id_user = idUser;
            }

            await MyMeeting.createGuest(data);

            handleGetGuestMeeting();
            //  cerrar modal de agregar invitados
            handleCloseModalCreateGuest();
            handleOpenAlert("Se agregó correctamente", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }

    }

    const handleDeleteGuest = async (idGuest) => {
        handleOpenLoading();
        try {
            await MyMeeting.deleteGuest(idGuest);
            handleGetGuestMeeting();
            handleOpenAlert("Se eliminó correctamente", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const [participants, setParticipants] = useState([]);

    const handleGetParticipants = async () => {
        //traer participantes nuevo
        const res = await MyMeeting.getParticipantsByMeetingSmallCompany(id_meeting);

        // const respo = await MyMembersCommitte.getMembers();
        setParticipants(res.data)
    }

    //modal de añadir temas de la agenda
    const [openModalAddThemes, setOpenopenModalAddThemes] = useState(false);

    const handleOpenModalAddThemes = () => {
        setOpenopenModalAddThemes(true);
    }

    const handleCloseModalAddThemes = () => {
        setOpenopenModalAddThemes(false);
    }

    const [themes, setThemes] = useState([]);

    const getThemes = async () => {
        const response = await MyMeeting.getThemesSmallCompany();
        setThemes(response.data);
    }


    const getThemesByMeeting = async () => {
        const response = await MyMeeting.getThemesByMeetingId(id_meeting);
        setListThemesSelected(response.data);
    }

    const [themeSelected, setThemeSelected] = useState("");
    const [listThemesSelected, setListThemesSelected] = useState([]);
    const [titleTheme, setTitleTheme] = useState("");

    const handleAddTheme = async () => {
        handleOpenLoading();
        try {
            // guardar el tema 
            const dataTheme = {
                id_committee_theme: themeSelected,
                id_committee_meeting: id_meeting,
                name: null,
                id_users_created: getUser().id_users,
                id_users_modified: getUser().id_users
            }
            if (dataTheme.id_committee_theme === 37) {
                dataTheme.name = titleTheme;
            }

            const response = await MyMeeting.postThemeByMeeting(dataTheme);

            getThemesByMeeting();
            handleOpenAlert("Se guardó correctamente", "success");
            // cerrar modal de seleccionar tema
            handleCloseModalAddThemes();

            //limpiar el select, input de título
            setThemeSelected("");
            setTitleTheme("");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleDeleteThemeOption = async (id_theme_meeting) => {
        handleOpenLoading();
        const response = await MyMeeting.deleteThemeByMeeting(id_theme_meeting);
        if (response.success === true) {
            getThemesByMeeting();
            handleOpenAlert("Se eliminó el tema con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const [idThemeEdit, setIdThemeEdit] = useState(null);

    const handleEditThemeOption = async (event) => {
        handleOpenLoading();
        event.preventDefault();
        const dataTheme = {
            name: titleTheme,
            id_users_modified: getUser().id_users
        }

        const response = await MyMeeting.updateThemeByMeeting(idThemeEdit, dataTheme);
        if (response.success === true) {
            handleOpenAlert("Se actualizó correctamente", "success");
            handleCloseModalEditTheme();
            getThemesByMeeting();
            //limpiar
            setTitleTheme("");
        } else {
            handleOpenAlert("Ha ocurrido un error", "success");
        }
    }

    //modal de editar tema
    const [openModalEditTheme, setOpenModalEditTheme] = useState(false);

    const handleOpenModalEditTheme = () => {
        setOpenModalEditTheme(true);
    }
    const handleCloseModalEditTheme = () => {
        setOpenModalEditTheme(false);
    }

    useEffect(() => {
        searchMeeting(id_meeting);
        handleGetGuestMeeting();
        handleGetParticipants();
        getThemes();
        getThemesByMeeting();
    }, [users]);

    return (

        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack="" />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />


            <Container sx={{ paddingBottom: 4 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SubtitleText
                                text={"Reuniones"}
                                color={grey}
                            ></SubtitleText>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TitleText
                                text={title}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                                text={"Fecha de la reunión"}
                                inputType={"date"}
                                inputValue={setDateMeeting}
                                modalType={false}
                                value={dateMeeting}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={8} md={8} lg={8} xl={8}>
                            <InputControlaVertical
                                text={"Descripción"}
                                inputType={"text"}
                                inputValue={setDescriptionMeeting}
                                modalType={false}
                                value={descriptionMeeting}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <InputControlaVertical
                                text={"Hora de Inicio"}
                                inputType={"time"}
                                inputValue={setHourStart}
                                modalType={false}
                                value={hourStart}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <InputControlaVertical
                                text={"Hora de Fin"}
                                inputType={"time"}
                                inputValue={setHourEnd}
                                modalType={false}
                                value={hourEnd}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <SelectControla
                                text="Sesión:"
                                inputValue={setSession}
                                modalType={false}
                                required
                                value={session || ''}
                                childrenRows={typeSession.map((rows) => (
                                    <MenuItem key={rows.id_session} value={rows.id_session}>
                                        {rows.nombre}
                                    </MenuItem>
                                ))}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <SelectControla
                                text="Modalidad:"
                                inputValue={setModality}
                                modalType={false}
                                required
                                value={modality || ''}
                                childrenRows={typeModality.map((rows) => (
                                    <MenuItem key={rows.id_modality} value={rows.id_modality}>
                                        {rows.nombre}
                                    </MenuItem>
                                ))}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                                display={modality === "1" || modality === 1 ? 'block' : 'none'}
                                roles={[1, 7, 8, 9, 15]}
                                text={"Lugar"}
                                inputType={"text"}
                                inputValue={setPlace}
                                modalType={false}
                                value={place}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Invitados de la Reunión
                            </StyledTypography>
                        </Grid>
                        {
                            readOnly ? "" : (
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                    <ButtonControla
                                        roles={[1, 7, 8, 15]}
                                        textButton={"Agregar Invitados"}
                                        iconButton={<Add />}
                                        backgroundColor={skyblue}
                                        backgroundColorHover={"#58C0FB"}
                                        functionOnClick={() => handleOpenModalCreateGuests()}
                                    />
                                </Grid>
                            )
                        }

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Nombre
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Email
                                            </TableCell>
                                            {
                                                readOnly ? "" : (
                                                    <TableCell sx={{ color: blue }} align="center">
                                                        Acciones
                                                    </TableCell>
                                                )
                                            }

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {arrayGuest.map((guest) => (
                                            <TableRow
                                                hover
                                                key={guest.id_committee_guest}
                                            >
                                                <TableCell align="center">
                                                    {guest.name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {guest.email}
                                                </TableCell>
                                                {
                                                    readOnly ? "" : (
                                                        <TableCell align="center">
                                                            <ButtonControla
                                                                roles={[1, 7, 8]}
                                                                textButton={"Eliminar"}
                                                                backgroundColor={"#EB5757"}
                                                                backgroundColorHover={"#FF4040"}
                                                                iconButton={<DeleteForever />}
                                                                functionOnClick={() => handleDeleteGuest(guest.id_committee_guest)}
                                                            ></ButtonControla>
                                                        </TableCell>

                                                    )
                                                }

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>


                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Participantes de la Reunión
                            </StyledTypography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Nombre
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Email
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {participants.map((participant) => (
                                            <TableRow
                                                hover
                                                key={participant.id_users}
                                            >
                                                <TableCell align="center">
                                                    {participant.name + " " + participant.last_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {participant.email}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Temas a tratar
                            </StyledTypography>
                        </Grid>
                        {
                            readOnly ? "" : (
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                    <ButtonControla
                                        roles={[1, 7, 8, 15]}
                                        textButton={"Agregar Tema"}
                                        iconButton={<Add />}
                                        backgroundColor={skyblue}
                                        backgroundColorHover={"#58C0FB"}
                                        functionOnClick={() => handleOpenModalAddThemes()}
                                    />
                                </Grid>
                            )
                        }

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Descripción
                                            </TableCell>
                                            {
                                                readOnly ? "" : (
                                                    <TableCell sx={{ color: blue }} align="center">
                                                        Acciones
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listThemesSelected.map((t, i) => (
                                            <TableRow
                                                hover
                                                key={i}
                                            >
                                                <TableCell align="center">
                                                    {t.name}
                                                </TableCell>
                                                {
                                                    readOnly ? "" : (
                                                        <TableCell align="center">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignContent="center"
                                                            >
                                                                {
                                                                    t.id_committee_theme === 37 ? (
                                                                        <ButtonControla                                                                       
                                                                            roles={[1, 7, 8]}
                                                                            textButton={"Editar"}
                                                                            backgroundColor={"#2D9CDB"}
                                                                            backgroundColorHover={"#33AEF4"}
                                                                            iconButton={<Edit />}
                                                                            functionOnClick={() => {
                                                                                handleOpenModalEditTheme();
                                                                                setIdThemeEdit(t.id_committee_meeting_theme);
                                                                                setTitleTheme(t.name);
                                                                            }}
                                                                        />

                                                                    ) : ""
                                                                }

                                                                <ButtonControla
                                                                    roles={[1, 7, 8]}
                                                                    textButton={"Eliminar"}
                                                                    backgroundColor={"#EB5757"}
                                                                    backgroundColorHover={"#FF4040"}
                                                                    iconButton={<DeleteForever />}
                                                                    functionOnClick={() => { handleDeleteThemeOption(t.id_committee_meeting_theme) }}
                                                                />
                                                            </Stack>
                                                        </TableCell>

                                                    )
                                                }

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction={"row"} spacing={3} justifyContent={"center"}>
                                <Box>
                                    <ButtonControla
                                        backgroundColor={"#CBCBFF"}
                                        textButton={"Regresar"}
                                        backgroundColorHover={"#CBCBFF"}
                                        typeButton={"button"}
                                        functionOnClick={() => {
                                            navigate(-1)
                                        }}
                                    />
                                </Box>
                                {
                                    readOnly ? "" : (
                                        <Box>
                                            <ButtonControla
                                                roles={[1, 7, 8, 9, 15]}
                                                iconButton={<Edit sx={{ color: "white" }} />}
                                                backgroundColor={"#169073"}
                                                backgroundColorHover={"#1BAA88"}
                                                textButton={"Actualizar reunión"}
                                                typeButton={"button"}
                                                functionOnClick={() => handleOpenModalConfirm(
                                                    "Modificar Registro",
                                                    "¿Estás seguro de modificar los datos?",
                                                    "Si, actualizar"
                                                )}
                                            />
                                        </Box>

                                    )
                                }

                            </Stack>
                        </Grid>
                    </Grid>

                    {/* //modal asignar invitados*/}
                    <Dialog
                        open={openModalCreateGuest}
                        onClose={handleCloseModalCreateGuest}
                        maxWidth="sm"
                        fullWidth={true}
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                    >
                        <DialogTitleControla
                            titleText="Agregar Invitado"
                            functionOnClose={handleCloseModalCreateGuest}
                        />
                        <DialogContent>

                            <SearchUsersAndOtherControla
                                rowsUser={users}
                                showInputAlternative={true}
                                onSearchValueChangeName={onSearchValueChangeName}
                                onSearchValueChangeEmail={onSearchValueChangeEmail}
                                handleUsersActionButton={(id_users) => { handleAddGuest(id_users) }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Stack direction={"row"} spacing={2}>
                                <Box>
                                    <ButtonControla
                                        backgroundColor={"#CBCBFF"}
                                        textButton={"Cancelar"}
                                        backgroundColorHover={"#CBCBFF"}
                                        typeButton={"button"}
                                        functionOnClick={() => handleCloseModalCreateGuest()}
                                    />
                                </Box>
                            </Stack>
                        </DialogActions>
                    </Dialog>

                    {/* modal de agregar temas */}
                    <Dialog
                        open={openModalAddThemes}
                        onClose={handleCloseModalAddThemes}
                        maxWidth="sm"
                        fullWidth={true}
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                    >
                        <DialogTitleControla
                            titleText="Agregar Tema a la Agenda"
                            functionOnClose={handleCloseModalAddThemes}
                        />
                        <DialogContent>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}
                                container
                                columnSpacing={2}
                                alignItems="center"
                            >

                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <InputLabel
                                        sx={{
                                            marginTop: '20px',
                                            padding: '8px',
                                            fontSize: '18px',
                                            color: '#305AD9',
                                            fontWeight: 'bold'
                                        }}
                                        xs={4}
                                    >Temas</InputLabel>

                                </Grid>
                                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <Select
                                        size="small"
                                        value={themeSelected || ""}
                                        displayEmpty
                                        onChange={(event) => {
                                            setThemeSelected(event.target.value)
                                        }}
                                        sx={{
                                            marginTop: '20px',
                                            fontFamily: 'Roboto',
                                            width: "100%",
                                            backgroundColor: ('#FFFFFF'),
                                            height: "42px",
                                            color: '#305AD9',
                                        }}
                                    >
                                        {themes.map((t) => (
                                            <MenuItem
                                                key={t.id_committee_theme} value={t.id_committee_theme}>
                                                {t.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                    sx={{ display: themeSelected !== 37 ? "none" : " " }}
                                >
                                    <InputControlaVertical
                                        text={"Título"}
                                        inputType={"text"}
                                        inputValue={setTitleTheme}
                                        modalType={true}
                                        value={titleTheme || ''}
                                        readOnly={false}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Stack direction={"row"} spacing={2}>
                                <Box>
                                    <ButtonControla
                                        backgroundColor={"#CBCBFF"}
                                        textButton={"Cancelar"}
                                        backgroundColorHover={"#CBCBFF"}
                                        typeButton={"button"}
                                        functionOnClick={() => handleCloseModalAddThemes()}
                                    />
                                </Box>
                                <Box>
                                    <ButtonControla
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar"}
                                        typeButton={"button"}
                                        functionOnClick={() => handleAddTheme()}
                                    />
                                </Box>

                            </Stack>
                        </DialogActions>
                    </Dialog>

                    {/* modal de editar titulo del tema */}
                    <Dialog
                        open={openModalEditTheme}
                        onClose={handleCloseModalEditTheme}
                        maxWidth="sm"
                        fullWidth={true}
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                    >
                        <DialogTitleControla
                            titleText="Editar Tema"
                            functionOnClose={handleCloseModalEditTheme}
                        />

                        <form onSubmit={handleEditThemeOption}>
                            <DialogContent>
                                <InputControlaVertical
                                    text={"Título"}
                                    inputType={"text"}
                                    inputValue={setTitleTheme}
                                    modalType={true}
                                    value={titleTheme || ''}
                                    required
                                />
                            </DialogContent>
                            <DialogActions>
                                <Stack direction={"row"} spacing={2}>
                                    <Box>
                                        <ButtonControla
                                            backgroundColor={"#CBCBFF"}
                                            textButton={"Cancelar"}
                                            backgroundColorHover={"#CBCBFF"}
                                            typeButton={"button"}
                                            functionOnClick={() => handleCloseModalEditTheme()}
                                        />
                                    </Box>
                                    <Box>
                                        <ButtonControla
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Actualizar"}
                                            typeButton={"submit"}
                                        />
                                    </Box>
                                </Stack>
                            </DialogActions>
                        </form>

                    </Dialog>

                </Box>
            </Container>
        </Box>
    );
}

export default EditScheduleMeeting;
