import MyConfigurationApi from "./MyConfigurationApi";

function createProceedingsBook (dataBook){
    const url = "minutes_book/store";
    
    return MyConfigurationApi.connectApi({
        dataJson: dataBook,
        method: "POST",
        url: url,
    });
}

function listProceedingsBook (){
    const url = "minutes_book/get";

    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function updateProceedingsBook (idBook,dataBook){
    const url = "minutes_book/update/" + idBook;
    return MyConfigurationApi.connectApi({
        dataJson: dataBook,
        method: "PUT",
        url: url,
    });
}

function createProceedings(dataMeeting){
    const url = "minutes/store";
    return MyConfigurationApi.connectApi({
        dataJson: dataMeeting,
        method: "POST",
        url: url,
    });
}

function listProceedings (){
    const url = "minutes/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}
function updateProceedings (data_proceeding, id_proceeding){
    const url = "minutes/update/" + id_proceeding ;
    return MyConfigurationApi.connectApi({
        dataJson: data_proceeding,
        method: "PUT",
        url: url,
    });
}


function updateStatusProceeding(status_proceeding, id_proceeding){
    const url = "minutes/updateStatus/" + id_proceeding ;
    return MyConfigurationApi.connectApi({
        dataJson: status_proceeding,
        method: "PUT",
        url: url,
    });
}
function deleteProceeding(id_proceeding){
    const url = "minutes/remove/" + id_proceeding ;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}
function getProceedingById (id_proceeding){
    const url = "minutes/get/" + id_proceeding;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postParticipantsProceedings(data_idProceeding){
    const url = "minutes/participant/store";
    return MyConfigurationApi.connectApi({
        dataJson: data_idProceeding,
        method: "POST",
        url: url,
    });
}

function postParticipantsProceedingSmallCompany(data_idProceeding){
    const url = "minutes/participant/small-company/store";
    return MyConfigurationApi.connectApi({
        dataJson: data_idProceeding,
        method: "POST",
        url: url,
    });
}

function getParticipantsByProceedings(id_procedding){
    const url = "minutes/participant/get/"+ id_procedding;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function getParticipantsByProceedingSmallCompany(id_procedding){
    const url = "minutes/participant/small-company/get/"+ id_procedding;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });

}

function updateAttendedMember(id_proceedings, dataAttended){
    const url = "minutes/participant/update-attended/"+ id_proceedings;
    return MyConfigurationApi.connectApi({
        dataJson: dataAttended,
        method: "PUT",
        url: url,
    });
}

function getThemesByProceedingId(id_proceeding){
    const url = "committee/minutes/themes/get/minutes/"+ id_proceeding;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}
function postThemesForProceeding (data_theme){
    const url = "committee/minutes/themes/store";
    return MyConfigurationApi.connectApi({
        dataJson: data_theme,
        method: "POST",
        url: url,
    });
}
function updateThemeForProceeding(id_committee_minutes_theme, data_info_theme){
    const url = "committee/minutes/themes/update/" + id_committee_minutes_theme;
    return MyConfigurationApi.connectApi({
        dataJson: data_info_theme,
        method: "PUT",
        url: url,
    });

}
function postGuestForProceeding (data_guest){
    const url = "committee/minutes/guest/store";
    return MyConfigurationApi.connectApi({
        dataJson: data_guest,
        method: "POST",
        url: url,
    });
}

function getGuestByProceeding (id_proceeding){
    const url = "committee/minutes/guest/get/minute/"+id_proceeding ;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}
function deleteGuestByProceeding (id_guest){
    const url = "committee/minutes/guest/remove/"+ id_guest;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });

}

function deleteThemeForProceeding (id_theme){
    const url = "committee/minutes/themes/remove/" + id_theme;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function getRecommendationsByTheme (id_theme){
    const url = "minutes/recommendations/get/minutes/" + id_theme;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function postRecommendations (data_recommendation){
    const url = "minutes/recommendations/store";
    return MyConfigurationApi.connectApi({
        dataJson: data_recommendation,
        method: "POST",
        url: url,
    });
}

function deleteRecommendation (id_recommendation){
    const url = "minutes/recommendations/remove/" + id_recommendation;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "DELETE",
        url: url,
    });
}

function getMinutesByIdBook (id_book){
    const url = "minutes/get-by-id-book/" + id_book;
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });

}


const MyProceedingsBook = {
    createProceedingsBook,
    listProceedingsBook,
    updateProceedingsBook,
    createProceedings,
    listProceedings,
    updateProceedings,
    updateStatusProceeding,
    deleteProceeding,
    getProceedingById,
    postParticipantsProceedings,
    postParticipantsProceedingSmallCompany,
    getParticipantsByProceedings,
    getParticipantsByProceedingSmallCompany,
    updateAttendedMember,
    getThemesByProceedingId,
    postThemesForProceeding,
    updateThemeForProceeding,
    postGuestForProceeding,
    getGuestByProceeding,
    deleteGuestByProceeding,
    deleteThemeForProceeding,
    getRecommendationsByTheme,
    postRecommendations,
    deleteRecommendation,
    getMinutesByIdBook
}

export default MyProceedingsBook;
