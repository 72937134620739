import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
  VisibilityOutlined,
  PictureAsPdf
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import MyAreas from "../../../../api/MyAreas";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import MyRegistersMonitoring from "../../../../api/MyRegistersMonitoring";
import SelectYesNotControla from "../../../../components/selects/selectYesNotControla";
import InputControlaVerticalIcon from "../../../../components/textfields/inputControlaVerticalIcon";
import { useAppContext } from "../../../../context/AppContext";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const red = "#EB5757";

function Edit() {
  const {permissions} = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idRegistersMonitoring = params.get("idRegistersMonitoring");

  // BACKDROP
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;
  const [idRegistersAccidentsWorkers, setIdRegistersAccidentsWorkers] =
    useState();

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = (idRegisterAccidentsWorkersValue) => {
    setOpenDelete(true);
    setIdRegistersAccidentsWorkers(idRegisterAccidentsWorkersValue);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  // id del responsable
  const [idRegisterResponsible, setIdRegisterResponsible] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdRegistersAccidentsWorkers(id);
    setIdAccidentsMeasures(id);
    setIdRegistersAccidentsResponsible(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    setIdRegisterResponsible(id);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteWorkers":
        handleDeleteAccidentsWorkers();
        break;
      case "deleteMeasures":
        handleDeleteCorrectivesActions();
        break;
      case "deleteResponsible":
        handleDeleteResponsible({ idUsers: idRegisterResponsible });
        // updatestatusesPets();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  // DIALOG

  //  abrir diálogo del buscador de responsable de medidas correctivas

  // agregar datos del trabajador DIALOG
  const [openModalUploadAddDates, setOpenModalUploadAddDates] = useState(false);
  const handleOpenModalUploadAddDates = () => {
    setWorkExperience(null);
    setArea(null);
    setShift(null);
    setWorkHoursBefore(null);
    setResponsibleCorrectiveActions("");
    handleGetUsersSearchsAddsWorkers();
    setOpenModalUploadAddDates(true);
  };

  const handleCloseModalUploadAddDates = () => {
    handleGetAccidentsWorkers();
    setOpenModalUploadAddDates(false);
  };

  // agregar medidas correctivas DIALOG
  const [
    openModalUploadCorrectiveActions,
    setOpenModalUploadCorrectiveActions,
  ] = useState(false);

  const handleOpenModalUploadCorrectiveActions = () => {
    setTimestampStart(null);
    setTimestampEnd(null);
    setDescription(null);
    setStatusCorrectivesActions(null);
    handleGetStatusCorrectivesActions();
    handleGetSearchsCorrectivesActionWorkers();
    setOpenModalUploadCorrectiveActions(true);
  };

  const handleCloseModalUploadCorrectiveActions = () => {
    setResponsibleCorrectiveActions("");
    handleGetCorrectivesActions();
    setOpenModalUploadCorrectiveActions(false);
  };

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadCResponsibleRegistration(true);
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    handleGetResponsibleWorkers();
    setOpenModalUploadCResponsibleRegistration(false);
  };

  // ACCIDENTE DE TRABAJO

  // Gravedad del accidente de trabajo
  const [severityAccidents, setSeverityAccidents] = useState(0);
  const [rowsSeverityWorkAccidents, setRowsSeverityWorkAccidents] = useState(
    []
  );

  const handleGetSeverityAccidents = async () => {
    const response = await MyRegistersAccidents.severityWorkAccident();
    if (response.success === false) {
    } else {
      setRowsSeverityWorkAccidents(response);
    }
    return response;
  };
  //variables
  const [code, setCode] = useState("");
  const [monitoringStartDate, setMonitoringStartDate] = useState("");
  const [riskType, setRiskType] = useState("");
  const [riskDetail, setRiskDetail] = useState("");
  const [monitoringProgram, setMonitoringProgram] = useState("");
  const [monitoringFrecuency, setMonitoringFrecuency] = useState("");
  const [exposedWorkers, setExposedWorkers] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [monitoringResults, setMonitoringResults] = useState("");
  const [monitoringDescription, setMonitoringDescription] = useState("");
  const [monitoringConclusions, setMonitoringConclusions] = useState("");

  const [areasData, setAreasData] = useState([]);
  const [riskTypeData, setRiskTypeData] = useState([
    "Físicos",
    "Químicos",
    "Biológicos",
    "Psicosociales",
    "Disergonómicos",
    "Otros",
  ]);

  const [monitoringAnualProgramUrl, setMonitoringAnualProgramUrl] =
    useState("");
  const [monitoringResultReportUrl, setMonitoringResultReportUrl] =
    useState("");
  //variables
  const [affectedWorkers, setAffectedWorkers] = useState(0);
  const [causesDescription, setcausesDescription] = useState("");
  const [descriptionWork, setDescriptionWork] = useState("");
  const [injuredBodyPartDescription, setInjuredBodyPartDescription] =
    useState("");
  const [location, setLocation] = useState("");
  const [medicalLeaveDays, setMedicalLeaveDays] = useState(0);
  const [timestampAccident, setTimestampAccident] = useState("");
  const [timestampInvestigation, setTimestampInvestigation] = useState("");

  const [documentUrl1, setDocumentUrl1] = useState("");
  const [documentUrl2, setDocumentUrl2] = useState("");
  const [documentUrl3, setDocumentUrl3] = useState("");

  // severidad del accidente
  const [severityIncidents, setSeverityIncidents] = useState(0);
  const [rowsSeverityIncidents, setRowsSeverityIncidents] = useState([]);

  const handleGetSeverityInccidents = async () => {
    const response = await MyRegistersAccidents.severityIncidents();
    if (response.success === false) {
    } else {
      setRowsSeverityIncidents(response);
    }
    return response;
  };

  // get Usuarios a buscar al agregar trabajadores
  const [rowsUsersSearchsAddsWorkers, setRowsUsersSearchsAddsWorkers] =
    useState([]);

  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyRegistersAccidents.getUserSearchsAddsWorkers({
      idRegistersMonitoring,
    });
    setRowsUsersSearchsAddsWorkers(response);
  };
  // update register accidents
  const handleUpdateRegistersAccidents = async (event) => {
    setOpenBackdrop(!openBackdrop);
    event.preventDefault();
    const response = await MyRegistersAccidents.updateRegistersAccidents({
      idRegistersMonitoring,
      code,
      timestampAccident,
      timestampInvestigation,
      location,
      severityAccidents,
      severityIncidents,
      medicalLeaveDays,
      affectedWorkers,
      injuredBodyPartDescription,
      description: descriptionWork,
      documentUrl1,
      documentUrl2,
      documentUrl3,
      causesDescription,
    });

    if (response.success === false) {
      console.log("error al actualizar");
    } else {
      handleGetAccidentsWorkers();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se guardaron de forma correcta los datos del registro"
      );
    }
    return response;
  };

  const handleUpdateMonitoring = async (event) => {
    setOpenBackdrop(!openBackdrop);
    event.preventDefault();
    const response = await MyRegistersMonitoring.updateRegisterMonitoring({
      idRegister: idRegistersMonitoring,
      code: code,
      area: area,
      date: monitoringStartDate,
      riskType: riskType,
      riskDetail: riskDetail,
      monitoringProgram: monitoringProgram,
      monitoringFrecuency: monitoringFrecuency,
      exposedWorkers: exposedWorkers,
      organizationName: organizationName,
      results: monitoringResults,
      description: description,
      conclusionsRecomendations: monitoringConclusions,
      document_1: documentUrl1,
      document_2: documentUrl2,
      document_3: documentUrl3,
      registerStatus: 1,
    });

    if (response != null) {
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se guardaron de forma correcta los datos del registro"
      );
      handleGetEditRegistersMonitoring();
    } else {
      handleCloseBackdrop();
      handleClickAlert(
        "error",
        "Error, no se pudo guardar los datos del registro"
      );
    }
  };

  const handleCloseMonitoringRegister = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    if (responsibles.length >= 1) {
      const found = responsibles.find((item) => item.firm === 0);
      if (found === undefined) {
        const response = await MyRegistersMonitoring.updateRegisterMonitoring({
          idRegister: idRegistersMonitoring,
          code: code,
          area: area,
          date: monitoringStartDate,
          riskType: riskType,
          riskDetail: riskDetail,
          monitoringProgram: monitoringProgram,
          monitoringFrecuency: monitoringFrecuency,
          exposedWorkers: exposedWorkers,
          organizationName: organizationName,
          results: monitoringResults,
          description: description,
          conclusionsRecomendations: monitoringConclusions,
          document_1: documentUrl1,
          document_2: documentUrl2,
          document_3: documentUrl3,
          registerStatus: 2,
        });

        if (response != null) {
          handleCloseBackdrop();
          handleClickAlert("success", "Se cerró el registro de forma correcta");
          handleGetEditRegistersMonitoring();
        }
      } else {
        handleCloseBackdrop();
        handleClickAlert(
          "error",
          "Complete las secciones obligatorias (Trabajador responsable)"
        );
      }
    } else {
      handleCloseBackdrop();
      handleClickAlert(
        "error",
        "Complete las secciones obligatorias (Trabajador responsable)"
      );
    }
  };

  const handleGetEditRegistersMonitoring = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersMonitoring.editRegisterMonitoring({
      idRegister: idRegistersMonitoring,
    });
    console.log(response);
    if (response !== "") {
      setArea(response.area);
      setCode(response.code);
      setMonitoringStartDate(response.date);
      setRiskType(response.risk_type);
      setMonitoringProgram(response.monitoring_program);
      setMonitoringFrecuency(response.monitoring_frecuency);
      setExposedWorkers(response.exposed_workers);
      setOrganizationName(response.organization_name);
      setMonitoringResults(response.results);
      setDescription(response.description);
      setMonitoringConclusions(response.conclusions_recomendations);
      setRiskDetail(response.risk_detail);
      setDocumentUrl1(response.document_1);
      setDocumentUrl2(response.document_2);
      setDocumentUrl3(response.document_3);
      if (response.register_status === 1) {
        setRegisterStatus(false);
      } else {
        setRegisterStatus(true);
      }
    }
    handleCloseBackdrop();
  };

  // TRABAJADORES ACCIDENTADOS
  const [openModalUploadInvolvedWorkers, setOpenModalUploadInvolvedWorkers] =
    useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadInvolvedWorkers(true);
  };
  // crear trabajadores accidentados

  const yearActually = new Date().getFullYear();

  const [accidentsWorkers, setAccidentsWorkers] = useState([]);
  const [area, setArea] = useState("");
  const [shift, setShift] = useState("");

  const handleChangeShift = (event) => {
    setShift(event.target.value);
  };
  const [workExperience, setWorkExperience] = useState("");
  const [workHoursBefore, setWorkHoursBefore] = useState("");

  const handleStoreAccidentsWorkers = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.creatAccidentsWorkers({
      idRegistersMonitoring: idRegistersMonitoring,
      id_users: idResponsible,
      area: area,
      shift: shift,
      work_experience: workExperience,
      work_hours_before: workHoursBefore,
    });
    if (response.success === false) {
      console.log("error", response);
    } else {
      handleCloseModalUploadAddDates();
      handleGetAccidentsWorkers();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente los datos del trabajador"
      );
    }
    return response;
  };

  // mostrar trabajadores accidentados
  const handleGetAccidentsWorkers = async () => {
    const response = await MyRegistersAccidents.getAccidentsWorkers({
      idRegistersMonitoring,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setAccidentsWorkers(response);
    }
  };

  // eliminar trabajadores accidentados
  const handleDeleteAccidentsWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.deleteAccidentsWorkers({
      id_registers_accidents_workers: idRegistersAccidentsWorkers,
    });
    if (response.success === true) {
      handleGetAccidentsWorkers();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se elimino correctamente el trabajador accidentado"
      );
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  /***************************  MEDIDAS CORRECTIVAS  *****************************/
  // buscar usuario por ID
  const [responsibleCorrectiveActions, setResponsibleCorrectiveActions] =
    useState(null);
  const [idResponsible, setIdResponsible] = useState(" ");

  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setResponsibleCorrectiveActions(
        response.first_name + " " + response.last_name
      );
      setOpenModalUpload(false);
      setOpenModalUploadInvolvedWorkers(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  const [idUserLogged, setIdUserLogged] = useState();

  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };

  // abrir modal de busqueda de usuarios de medidas correctivas
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const handleOpenModalResponsible = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUpload(true);
  };

  // mostrar estado de la medida correctiva
  const [rowsStatusCorrectivesActions, setRowsStatusCorrectivesActions] =
    useState([]);
  const handleGetStatusCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getStatusCorrectivesActions();
    if (response.success === false) {
    } else {
      setRowsStatusCorrectivesActions(response);
    }
    return response;
  };

  // estado de la medida correctiva
  const [statusCorrectivesActions, setStatusCorrectivesActions] =
    useState(null);

  // crear medidas correctivas
  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [description, setDescription] = useState("");

  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.createCorrectivesActions({
      idRegistersMonitoring: idRegistersMonitoring,
      id_registers_accidents_measures_statuses: statusCorrectivesActions,
      id_users: idResponsible,
      timestamp_start: timestampStart,
      timestamp_end: timestampEnd,
      description: description,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleGetCorrectivesActions();
      handleCloseModalUploadAddDates();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente la medida correctiva"
      );
    }
    return response;
  };

  // mostrar medidas correctivas
  const [correctivesActions, setCorrectivesActions] = useState([]);
  const handleGetCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getCorrectivesActionWorkers({
      idRegistersMonitoring,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setCorrectivesActions(response);
    }
  };

  // listar trabajdores para buscar en medidas correctivas

  const [
    rowsSearchsCorrectivesActionWorkers,
    setSearchsCorrectivesActionWorkers,
  ] = useState([]);

  const handleGetSearchsCorrectivesActionWorkers = async () => {
    const response =
      await MyRegistersAccidents.getSearchsCorrectivesActionWorkers({
        idRegistersMonitoring,
      });
    setSearchsCorrectivesActionWorkers(response);
  };

  // eliminar medidas correctives
  const [idAccidentsMeasures, setIdAccidentsMeasures] = useState(0);

  const handleDeleteCorrectivesActions = async () => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyRegistersAccidents.deleteCorrectivesActions({
      id_registers_accidents_measures: idAccidentsMeasures,
    });
    if (response.success === true) {
      handleGetCorrectivesActions();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert("success", "Se elimino  la medida correctiva");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // TRABAJADORES RESPONSABLES
  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyRegistersAccidents.getResponsibleWorkers({
      idRegistersMonitoring,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setResponsibleActions(response);
      setFirm(response.firm);
    }
  };

  //crear trabajadores responsables
  const [firm, setFirm] = useState();
  const handleStoreResponsibleWorkers = async (idUsers) => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.createResponsibleWorkers({
      idRegistersMonitoring: idRegistersMonitoring,
      id_users: idUsers,
      firm: firm,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleGetResponsibleWorkers();
      handleCloseModalUploadResponsibleRegistration();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert("success", "Se agregó correctamente ");
    }
    return response;
  };

  // eliminar trabajador responsable
  const [idRegistersAccidentsResponsible, setIdRegistersAccidentsResponsible] =
    useState(0);

  // Buscar trabajadores en medidas correctivas
  const [rowsSearchsResponsiblesWorkers, setSearchsResponsiblesWorkers] =
    useState([]);

  const handleGetSearchsResponsiblesWorkers = async () => {
    const response = await MyRegistersAccidents.getSearchsResponsiblesWorkers({
      idRegistersMonitoring,
    });
    setSearchsResponsiblesWorkers(response);
  };

  // firmar responsable
  const [openModalSignResponsible, setOpenModalSignResponsible] =
    useState(false);
  const [idAccidentsResponsible, setIdAccidentsResponsible] = useState(0);
  const [idUserIdResponsible, setIdUserIdResponsible] = useState(0);
  /////
  const handleOpenModalSignResponsible = (
    idUserResponsible,
    idUserIdResponsible
  ) => {
    setIdAccidentsResponsible(idUserResponsible);
    setIdUserIdResponsible(idUserIdResponsible);
    setOpenModalSignResponsible(true);
  };

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(true);

  const handleGetRegisterStatus = async () => {
    const response = await MyRegistersAccidents.getRegisterStatus({
      idRegistersMonitoring: idRegistersMonitoring,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      // handleEditRegistersAccidents();
      handleGetEditRegistersMonitoring();
    }
    return response;
  };

  //Responsables del registro y de la investigación

  const [responsibles, setResponsibles] = useState([]);
  const [idUserId, setIdUserId] = useState("");

  //obtiene responsables
  const handleGetResponsible = async () => {
    const response = await MyRegistersMonitoring.getResponsible({
      idRegister: idRegistersMonitoring,
    });
    setResponsibles(response);
  };
  //agrega responsables
  const handleStoreResponsible = async (idUsers) => {
    const response = await MyRegistersMonitoring.addResponsible({
      idUser: idUsers,
      idRegister: idRegistersMonitoring,
    });
    if (response != null) {
      handleGetResponsible();
      handleCloseModalUploadResponsibleRegistration();
    }
  };
  //elimina responsables
  const handleDeleteResponsible = async ({ idUsers }) => {
    const response = await MyRegistersMonitoring.deleteResponsible({
      idUser: idUsers,
      idRegister: idRegistersMonitoring,
    });
    handleGetResponsible();
    return response;
  };
  //firma responsables
  const handleSignResponsible = async (event) => {
    event.preventDefault();
    const response = await MyRegistersMonitoring.signResponsible({
      idUserId: idUserIdResponsible,
      idUser: idAccidentsResponsible,
    });
    console.log(response);
    handleGetResponsible();
    setOpenModalSignResponsible(false);
  };
  //responsables del registro y de la investigación

  // const handleCloseRegister = async () => {
  //   const response = await MyRegistersMonitoring.closeRegisterMonitoring({
  //     idRegister: idRegistersMonitoring,
  //   });
  //   handleGetResponsible();
  // };

  // Obtener areas
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setAreasData(response);
    return response;
  };

  //// docstatus
  useEffect(() => {
    handleUserActive();

    handleGetEditRegistersMonitoring();
    // handleEditRegistersAccidents();

    handleGetSeverityAccidents();
    handleGetSeverityInccidents();
    handleGetAccidentsWorkers();

    // medidas correctivas
    handleGetCorrectivesActions();

    // trabajadores responsables
    handleGetResponsibleWorkers();
    handleGetResponsible();
    handleGetAreas();

    // handleSelectUsersResponsible();
    // handleGetUsersActives();
  }, []);
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText text={"Monitoreo de agentes"}></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Nro. Registro"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                readOnly={registerStatus}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={4} xl={4} />
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleCloseMonitoringRegister}>
            <Grid>
              <SubtitleText text={"Datos del monitoreo"} color={blue} />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <SelectControla
                  text={"Área monitoreo"}
                  inputType={"text"}
                  inputValue={setArea}
                  value={area}
                  modalType={false}
                  required
                  readOnly={registerStatus}
                  childrenRows={areasData.map((row) => (
                    <MenuItem key={row.id_areas} value={row.id_areas}>
                      {row.area}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de Monitoreo"}
                  inputType={"date"}
                  inputValue={setMonitoringStartDate}
                  value={monitoringStartDate}
                  required
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <SelectControla
                  text={"Tipo de riesgo a monitorear "}
                  inputType={"text"}
                  inputValue={setRiskType}
                  value={riskType}
                  required
                  readOnly={registerStatus}
                  childrenRows={riskTypeData.map((rows) => (
                    <MenuItem key={rows} value={rows}>
                      {rows}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Detalle del tipo de riesgo"}
                  inputType={"text"}
                  inputValue={setRiskDetail}
                  value={riskDetail}
                  required
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <SelectYesNotControla
                  text={"Cuenta con programa de monitoreo"}
                  value={monitoringProgram}
                  inputValue={setMonitoringProgram}
                  modalType={false}
                  required
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Frecuencia de monitoreo"}
                  inputType={"text"}
                  inputValue={setMonitoringFrecuency}
                  value={monitoringFrecuency}
                  required
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <InputControlaVertical
                  text={"Nro. de trabajadores expuestos en el centro laboral"}
                  inputType={"number"}
                  inputValue={setExposedWorkers}
                  value={exposedWorkers}
                  required
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={
                    "Nombre de la organización que realiza el monitoreo. (De ser el caso)"
                  }
                  inputType={"text"}
                  inputValue={setOrganizationName}
                  value={organizationName}
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Resultado del monitoreo"}
                  inputType={"textArea"}
                  inputValue={setMonitoringResults}
                  value={monitoringResults}
                  required
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={
                    "Descripción de las causas ante desviaciones presentadas"
                  }
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  required
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={
                    "Conclusiones y recomendaciones sobre resultados del monitoreo "
                  }
                  inputType={"textArea"}
                  inputValue={setMonitoringConclusions}
                  value={monitoringConclusions}
                  required
                  readOnly={registerStatus}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText text={"Adjuntar"} color={blue} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={11} md={11}>
                <InputControlaVertical
                  text={"Programa anual de monitoreo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl1}
                />
              </Grid>
              <Grid
                item
                xs={1}
                md={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonIconControla
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl1}
                  directionTarget={"_blank"}
                  // functionOnClick={() => ""}
                />
              </Grid>
              <Grid item xs={11} md={11}>
                <InputControlaVerticalIcon
                  text={"Informe con resultados de las mediciones de monitoreo"}
                  inputType={"file"}
                  textTooltip={
                    "Informe con relación de agentes o factores que son objetos de la muestra, límite permisible del agente monitoreado, metodología empleada, tamaño de muestra, relación de instrumentos utilizados, entre otros."
                  }
                  inputValue={setDocumentUrl2}
                />
                {/* <Box sx={{ marginRight: 60 }}>
                  <ButtonIconControla
                    icon={<InfoOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#FFC300"}
                    backgroundColorHover={"#FACB32"}
                    textTooltip={"Informe con relación de agentes o factores que son objetos de la muestra, límite permisible del agente monitoreado, metodología empleada, tamaño de muestra, relación de instrumentos utilizados, entre otros."}
                  // functionOnClick={handleOpenModalTypeAgentsTable}
                  />
                </Box> */}
              </Grid>
              <Grid
                item
                xs={1}
                md={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonIconControla
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl2}
                  directionTarget={"_blank"}
                  // functionOnClick={() => ""}
                />
              </Grid>
              <Grid item xs={11} md={11}>
                <InputControlaVertical
                  text={
                    "Copia del certificado de calibración de los instrumentos de monitoreo, de ser el caso."
                  }
                  inputType={"file"}
                  inputValue={setDocumentUrl3}
                />
              </Grid>
              <Grid
                item
                xs={1}
                md={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonIconControla
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl3}
                  directionTarget={"_blank"}
                  // functionOnClick={() => ""}
                />
              </Grid>
            </Grid>

            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === false ? (
                  <ButtonControla
                    roles ={permissions.DOC_REGIS_MONITOREO_AGENT_AGREGAR_RESPON}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={
                      handleOpenModalUploadResponsibleRegistration
                    }
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibles.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignResponsible(
                                    row.id,
                                    row.id_users
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            "Firmado"
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                            {registerStatus === false ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                                // functionOnClick={() =>
                                //   handleDeleteResponsibleWorkers(
                                //     row.id_registers_accidents_responsible
                                //   )
                                // }
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {/* tabla para mostrar accidents workers */}

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/monitoring"}
                />
              </Grid>
              {registerStatus === false ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles = {permissions.DOC_REGIS_MONITOREO_AGENT_ACTUALIZAR_REGIS}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                    functionOnClick={handleUpdateMonitoring}
                  />
                </Grid>
              ) : (
                ""
              )}
              {registerStatus === false ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles = {permissions.DOC_REGIS_MONITOREO_AGENT_CERRAR_REGIS}
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    typeButton="submit"
                  />
                </Grid>
              ) : (
                ""
              )}
              {registerStatus === true ? (
                <Grid item xs={12} md={3}>
                  <ButtonControla
                    roles={[1, 3, 4, 5, 6, 7, 8, 10, 12, 13]}
                    iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textButton={"Ver documento PDF"}
                    typeButton={"button"}
                    url={process.env.REACT_APP_API_DJANGO + 'report/' + idRegistersMonitoring}
                    target={"_blank"}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog agregar trabajador involucrado */}
      <Dialog
        open={openModalUploadAddDates}
        onClose={handleCloseModalUploadAddDates}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar trabajador involucrado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreAccidentsWorkers}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Tiempo de experiencia en el puesto de trabajo"}
                  inputType={"text"}
                  inputValue={setWorkExperience}
                  value={workExperience}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Área"}
                  inputType={"text"}
                  inputValue={setArea}
                  value={area}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                {/* <InputControlaVertical
                  text={"Turno"}
                  inputType={"text"}
                  inputValue={setShift}
                  value={shift}
                  modalType={true}
                  required
                /> */}
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Turno
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={shift}
                  onChange={handleChangeShift}
                >
                  <MenuItem value={"Dia"}>Dia</MenuItem>
                  <MenuItem value={"Tarde"}>Tarde</MenuItem>
                  <MenuItem value={"Noche"}>Noche</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"N.º de horas trabajadas antes del accidente"}
                  inputType={"text"}
                  inputValue={setWorkHoursBefore}
                  value={workHoursBefore}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Trabajador involucrado: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsibleInvolvedWorkers}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadAddDates}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalUploadCorrectiveActions}
        onClose={handleCloseModalUploadCorrectiveActions}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setTimestampStart}
                  value={timestampStart}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimestampEnd}
                  value={timestampEnd}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Estado de medida correctiva:"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  required
                  value={statusCorrectivesActions}
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={rows.id_registers_accidents_measures_statuses}
                      value={rows.id_registers_accidents_measures_statuses}
                    >
                      {rows.statuses}
                    </MenuItem>
                  ))}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsible}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadCorrectiveActions}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          severity: severityAlert,
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
        open={openDelete}
        onClose={handleCloseDelete}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
          }}
        >
          Eliminar datos del trabajador accidentado
        </DialogTitle>
        <DialogContent>
          <Typography>
            Los datos del trabajador accidentado se eliminará de forma
            permanente
          </Typography>
          <Typography>¿Deseas continuar?.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button onClick={handleDeleteAccidentsWorkers}>Si, eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar usuarios de medidas correctivas */}
      <Dialog
        open={openModalUpload}
        onClose={() => {
          setOpenModalUpload(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsCorrectivesActionWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUpload(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables */}
      <Dialog
        open={openModalUploadInvolvedWorkers}
        onClose={() => {
          setOpenModalUploadInvolvedWorkers(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersSearchsAddsWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUploadInvolvedWorkers(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar responsable */}
      <Dialog
        open={openModalSignResponsible}
        onClose={() => {
          setOpenModalSignResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsible}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* modal users information */}
      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsible}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          severity: severityAlert,
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
        open={openDelete}
        onClose={handleCloseDelete}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
          }}
        >
          Eliminar datos del trabajador accidentado
        </DialogTitle>
        <DialogContent>
          <Typography>
            Los datos del trabajador accidentado se eliminará de forma
            permanente
          </Typography>
          <Typography>¿Deseas continuar?.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button onClick={handleDeleteAccidentsWorkers}>Si, eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* users information */}

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
