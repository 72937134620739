import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Close,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
  InfoOutlined,
  Print,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import CardUserControla from "../../../../components/cards/cardUserControla";
import MyRegistersMonitoring from "../../../../api/MyRegistersMonitoring";
import SelectYesNotControla from "../../../../components/selects/selectYesNotControla";
import MyRegistersCompetencyCertificate from "../../../../api/MyRegistersCompetencyCertificate";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import { useAppContext } from "../../../../context/AppContext";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const red = "#EB5757";
const skyblue = "#2D9CDB";

function Edit() {
  const {permissions} = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idRegistersCertificate = params.get("idRegistersCertificate");

  //Styled
  const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: skyblue,
    borderRadius: 16,
  });
  const StyledModalTitle = styled("h2")({
    color: blue,
  });
  const StyledModalSubtitle = styled("h3")({
    fontWeight: "bold",
  });

  // BACKDROP
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;
  const [idRegistersAccidentsWorkers, setIdRegistersAccidentsWorkers] =
    useState();

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = (idRegisterAccidentsWorkersValue) => {
    setOpenDelete(true);
    setIdRegistersAccidentsWorkers(idRegisterAccidentsWorkersValue);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  // id del responsable
  const [idRegisterResponsible, setIdRegisterResponsible] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdRegistersAccidentsWorkers(id);
    setIdAccidentsMeasures(id);
    setIdRegistersAccidentsResponsible(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    setIdRegisterResponsible(id);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteWorkers":
        handleDeleteAccidentsWorkers();
        break;
      case "deleteMeasures":
        handleDeleteCorrectivesActions();
        break;
      case "deleteResponsible":
        handleDeleteResponsible({ idUsers: idRegisterResponsible });
        // updatestatusesPets();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  // DIALOG

  //  abrir diálogo del buscador de responsable de medidas correctivas

  // agregar datos del trabajador DIALOG
  const [openModalUploadAddDates, setOpenModalUploadAddDates] = useState(false);
  const handleOpenModalUploadAddDates = () => {
    setWorkExperience(null);
    setArea(null);
    setShift(null);
    setWorkHoursBefore(null);
    setResponsibleCorrectiveActions("");
    handleGetUsersSearchsAddsWorkers();
    setOpenModalUploadAddDates(true);
  };

  const handleCloseModalUploadAddDates = () => {
    handleGetAccidentsWorkers();
    setOpenModalUploadAddDates(false);
  };

  // agregar medidas correctivas DIALOG
  const [
    openModalUploadCorrectiveActions,
    setOpenModalUploadCorrectiveActions,
  ] = useState(false);

  const handleOpenModalUploadCorrectiveActions = () => {
    setTimestampStart(null);
    setTimestampEnd(null);
    setDescription(null);
    setStatusCorrectivesActions(null);
    handleGetStatusCorrectivesActions();
    handleGetSearchsCorrectivesActionWorkers();
    setOpenModalUploadCorrectiveActions(true);
  };

  const handleCloseModalUploadCorrectiveActions = () => {
    setResponsibleCorrectiveActions("");
    handleGetCorrectivesActions();
    setOpenModalUploadCorrectiveActions(false);
  };

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadCResponsibleRegistration(true);
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    handleGetResponsibleWorkers();
    setOpenModalUploadCResponsibleRegistration(false);
  };

  // ACCIDENTE DE TRABAJO

  // Gravedad del accidente de trabajo
  const [severityAccidents, setSeverityAccidents] = useState(0);
  const [rowsSeverityWorkAccidents, setRowsSeverityWorkAccidents] = useState(
    []
  );

  const handleGetSeverityAccidents = async () => {
    const response = await MyRegistersAccidents.severityWorkAccident();
    if (response.success === false) {
    } else {
      setRowsSeverityWorkAccidents(response);
    }
    return response;
  };
  //variables
  const [code, setCode] = useState("");
  const [monitoringStartDate, setMonitoringStartDate] = useState("");
  const [riskType, setRiskType] = useState("");
  const [monitoringProgram, setMonitoringProgram] = useState("");
  const [monitoringFrecuency, setMonitoringFrecuency] = useState("");
  const [exposedWorkers, setExposedWorkers] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [monitoringResults, setMonitoringResults] = useState("");
  const [monitoringDescription, setMonitoringDescription] = useState("");
  const [monitoringConclusions, setMonitoringConclusions] = useState("");

  const [monitoringAnualProgramUrl, setMonitoringAnualProgramUrl] =
    useState("");
  const [monitoringResultReportUrl, setMonitoringResultReportUrl] =
    useState("");
  //variables
  const [affectedWorkers, setAffectedWorkers] = useState(0);
  const [causesDescription, setcausesDescription] = useState("");
  const [descriptionWork, setDescriptionWork] = useState("");
  const [injuredBodyPartDescription, setInjuredBodyPartDescription] =
    useState("");
  const [location, setLocation] = useState("");
  const [medicalLeaveDays, setMedicalLeaveDays] = useState(0);
  const [timestampAccident, setTimestampAccident] = useState("");
  const [timestampInvestigation, setTimestampInvestigation] = useState("");

  const [documentUrl1, setDocumentUrl1] = useState("");
  const [documentUrl2, setDocumentUrl2] = useState("");
  const [documentUrl3, setDocumentUrl3] = useState("");

  // severidad del accidente
  const [severityIncidents, setSeverityIncidents] = useState(0);
  const [rowsSeverityIncidents, setRowsSeverityIncidents] = useState([]);

  const handleGetSeverityInccidents = async () => {
    const response = await MyRegistersAccidents.severityIncidents();
    if (response.success === false) {
    } else {
      setRowsSeverityIncidents(response);
    }
    return response;
  };

  // get Usuarios a buscar al agregar trabajadores
  const [rowsUsersSearchsAddsWorkers, setRowsUsersSearchsAddsWorkers] =
    useState([]);

  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyRegistersAccidents.getUserSearchsAddsWorkers({
      idRegistersCertificate,
    });
    setRowsUsersSearchsAddsWorkers(response);
  };
  // update register accidents
  const handleUpdateRegistersAccidents = async (event) => {
    setOpenBackdrop(!openBackdrop);
    event.preventDefault();
    const response = await MyRegistersAccidents.updateRegistersAccidents({
      idRegistersCertificate,
      code,
      timestampAccident,
      timestampInvestigation,
      location,
      severityAccidents,
      severityIncidents,
      medicalLeaveDays,
      affectedWorkers,
      injuredBodyPartDescription,
      description: descriptionWork,
      documentUrl1,
      documentUrl2,
      documentUrl3,
      causesDescription,
    });

    if (response.success === false) {
      console.log("error al actualizar");
    } else {
      handleGetAccidentsWorkers();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se guardaron de forma correcta los datos del registro"
      );
    }
    return response;
  };

  const handleUpdateMonitoring = async (event) => {
    setOpenBackdrop(!openBackdrop);
    event.preventDefault();
    console.log(exposedWorkers);
    const response = await MyRegistersMonitoring.updateRegisterMonitoring({
      idRegister: idRegistersCertificate,
      code: code,
      area: area,
      date: monitoringStartDate,
      riskType: riskType,
      monitoringProgram: monitoringProgram,
      monitoringFrecuency: monitoringFrecuency,
      exposedWorkers: exposedWorkers,
      organizationName: organizationName,
      results: monitoringResults,
      description: description,
      conclusionsRecomendations: monitoringConclusions,
      document_url_1: documentUrl1,
      document_url_2: documentUrl2,
      document_url_3: documentUrl3,
    });
    console.log("respuesta: ");
    console.log(response);

    if (response != null) {
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se guardaron de forma correcta los datos del registro"
      );
    } else {
      handleCloseBackdrop();
      handleClickAlert(
        "error",
        "Error, no se pudo guardar los datos del registro"
      );
    }
  };

  // edit register acidents
  // const handleEditRegistersAccidents = async () => {
  //   const response = await MyRegistersAccidents.editRegistersAccidents({
  //     idRegistersCertificate,
  //   });
  //   if (response.success === false) {
  //   } else {
  //     setRegisterStatus(
  //       response.id_registers_accidents_statuses === 1 ? true : false
  //     );
  //     setCode(response.code);
  //     setAffectedWorkers(response.affected_workers);
  //     setcausesDescription(response.causes_description);
  //     setInjuredBodyPartDescription(response.injured_body_part_desciption);
  //     setLocation(response.location);
  //     setMedicalLeaveDays(response.medical_leave_days);
  //     setTimestampAccident(
  //       response.timestamp_accident === null
  //         ? ""
  //         : response.timestamp_accident.substring(0, 16)
  //     );
  //     setTimestampInvestigation(
  //       response.timestamp_investigation === null
  //         ? ""
  //         : response.timestamp_investigation.substring(0, 10)
  //     );
  //     setDocumentUrl1(response.document_url_1);
  //     setDocumentUrl2(response.document_url_2);
  //     setDocumentUrl3(response.document_url_3);
  //     setDescriptionWork(response.description);
  //     setSeverityAccidents(response.id_registers_accidents_severities);
  //     setSeverityIncidents(response.id_registers_accidents_severities_incident);
  //   }
  //   return response;
  // };

  // GET Monitoring DATA

  const handleGetEditRegistersMonitoring = async () => {
    console.log(idRegistersCertificate);
    const response = await MyRegistersMonitoring.editRegisterMonitoring({
      idRegister: idRegistersCertificate,
    });
    console.log(response);
    if (response !== "") {
      setArea(response.area);
      setCode(response.code);
      setMonitoringStartDate(response.date);
      setRiskType(response.risk_type);
      setMonitoringProgram(response.monitoring_program);
      setMonitoringFrecuency(response.monitoring_frecuency);
      setExposedWorkers(response.exposed_workers);
      setOrganizationName(response.organization_name);
      setMonitoringResults(response.results);
      setDescription(response.description);
      setMonitoringConclusions(response.conclusions_recomendations);
      if (response.register_status === 1) {
        setRegisterStatus(false);
      } else {
        setRegisterStatus(true);
      }
    }
  };

  // TRABAJADORES ACCIDENTADOS
  const [openModalUploadInvolvedWorkers, setOpenModalUploadInvolvedWorkers] =
    useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadInvolvedWorkers(true);
  };
  // crear trabajadores accidentados

  const yearActually = new Date().getFullYear();

  const [accidentsWorkers, setAccidentsWorkers] = useState([]);
  const [area, setArea] = useState("");
  const [shift, setShift] = useState("");

  const handleChangeShift = (event) => {
    setShift(event.target.value);
  };
  const [workExperience, setWorkExperience] = useState("");
  const [workHoursBefore, setWorkHoursBefore] = useState("");

  const handleStoreAccidentsWorkers = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.creatAccidentsWorkers({
      idRegistersCertificate: idRegistersCertificate,
      id_users: idResponsible,
      area: area,
      shift: shift,
      work_experience: workExperience,
      work_hours_before: workHoursBefore,
    });
    if (response.success === false) {
      console.log("error", response);
    } else {
      handleCloseModalUploadAddDates();
      handleGetAccidentsWorkers();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente los datos del trabajador"
      );
    }
    return response;
  };

  // mostrar trabajadores accidentados
  const handleGetAccidentsWorkers = async () => {
    const response = await MyRegistersAccidents.getAccidentsWorkers({
      idRegistersCertificate,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setAccidentsWorkers(response);
    }
  };

  // eliminar trabajadores accidentados
  const handleDeleteAccidentsWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.deleteAccidentsWorkers({
      id_registers_accidents_workers: idRegistersAccidentsWorkers,
    });
    if (response.success === true) {
      handleGetAccidentsWorkers();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se elimino correctamente el trabajador accidentado"
      );
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  /***************************  MEDIDAS CORRECTIVAS  *****************************/
  // buscar usuario por ID
  const [responsibleCorrectiveActions, setResponsibleCorrectiveActions] =
    useState(null);
  const [idResponsible, setIdResponsible] = useState(" ");

  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setResponsibleCorrectiveActions(
        response.first_name + " " + response.last_name
      );
      setOpenModalUpload(false);
      setOpenModalUploadInvolvedWorkers(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  const [idUserLogged, setIdUserLogged] = useState();

  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };

  // abrir modal de busqueda de usuarios de medidas correctivas
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const handleOpenModalResponsible = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUpload(true);
  };

  // mostrar estado de la medida correctiva
  const [rowsStatusCorrectivesActions, setRowsStatusCorrectivesActions] =
    useState([]);
  const handleGetStatusCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getStatusCorrectivesActions();
    if (response.success === false) {
    } else {
      setRowsStatusCorrectivesActions(response);
    }
    return response;
  };

  // estado de la medida correctiva
  const [statusCorrectivesActions, setStatusCorrectivesActions] =
    useState(null);

  // crear medidas correctivas
  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [description, setDescription] = useState("");

  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.createCorrectivesActions({
      idRegistersCertificate: idRegistersCertificate,
      id_registers_accidents_measures_statuses: statusCorrectivesActions,
      id_users: idResponsible,
      timestamp_start: timestampStart,
      timestamp_end: timestampEnd,
      description: description,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleGetCorrectivesActions();
      handleCloseModalUploadAddDates();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente la medida correctiva"
      );
    }
    return response;
  };

  // mostrar medidas correctivas
  const [correctivesActions, setCorrectivesActions] = useState([]);
  const handleGetCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getCorrectivesActionWorkers({
      idRegistersCertificate,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setCorrectivesActions(response);
    }
  };

  // listar trabajdores para buscar en medidas correctivas

  const [
    rowsSearchsCorrectivesActionWorkers,
    setSearchsCorrectivesActionWorkers,
  ] = useState([]);

  const handleGetSearchsCorrectivesActionWorkers = async () => {
    const response =
      await MyRegistersAccidents.getSearchsCorrectivesActionWorkers({
        idRegistersCertificate,
      });
    setSearchsCorrectivesActionWorkers(response);
  };

  // eliminar medidas correctives
  const [idAccidentsMeasures, setIdAccidentsMeasures] = useState(0);

  const handleDeleteCorrectivesActions = async () => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyRegistersAccidents.deleteCorrectivesActions({
      id_registers_accidents_measures: idAccidentsMeasures,
    });
    if (response.success === true) {
      handleGetCorrectivesActions();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert("success", "Se elimino  la medida correctiva");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // TRABAJADORES RESPONSABLES
  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyRegistersAccidents.getResponsibleWorkers({
      idRegistersCertificate,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setResponsibleActions(response);
      setFirm(response.firm);
    }
  };

  //crear trabajadores responsables
  const [firm, setFirm] = useState();
  const handleStoreResponsibleWorkers = async (idUsers) => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.createResponsibleWorkers({
      idRegistersCertificate: idRegistersCertificate,
      id_users: idUsers,
      firm: firm,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleGetResponsibleWorkers();
      handleCloseModalUploadResponsibleRegistration();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert("success", "Se agregó correctamente ");
    }
    return response;
  };

  // eliminar trabajador responsable
  const [idRegistersAccidentsResponsible, setIdRegistersAccidentsResponsible] =
    useState(0);

  const handleDeleteResponsibleWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.deleteResponsibleWorkers({
      id_registers_accidents_responsible: idRegistersAccidentsResponsible,
    });
    if (response.success === true) {
      handleGetResponsibleWorkers();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert("success", "Se elimino  al trabajador responsable");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // Buscar trabajadores en medidas correctivas
  const [rowsSearchsResponsiblesWorkers, setSearchsResponsiblesWorkers] =
    useState([]);

  const handleGetSearchsResponsiblesWorkers = async () => {
    const response = await MyRegistersAccidents.getSearchsResponsiblesWorkers({
      idRegistersCertificate,
    });
    setSearchsResponsiblesWorkers(response);
  };

  // firmar responsable
  const [openModalSignResponsible, setOpenModalSignResponsible] =
    useState(false);
  const [idAccidentsResponsible, setIdAccidentsResponsible] = useState(0);
  const [idUserIdResponsible, setIdUserIdResponsible] = useState(0);
  /////
  const handleOpenModalSignResponsible = (
    idUserResponsible,
    idUserIdResponsible
  ) => {
    setIdAccidentsResponsible(idUserResponsible);
    setIdUserIdResponsible(idUserIdResponsible);
    setOpenModalSignResponsible(true);
  };

  // const handleSignResponsible = async (event) => {
  //   event.preventDefault();
  //   setOpenBackdrop(!openBackdrop);
  //   const response = await MyRegistersAccidents.signResponsible({
  //     id_accidents_responsible: idAccidentsResponsible,
  //   });
  //   if (response.success !== false) {
  //     handleCloseBackdrop();
  //     setOpenModalSignResponsible(false);
  //     handleGetResponsibleWorkers();
  //     handleClickAlert("success", "Se registro la firma");
  //   } else {
  //     alert("Error al obtener el usuario!");
  //   }
  // };

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(true);

  const handleGetRegisterStatus = async () => {
    const response = await MyRegistersAccidents.getRegisterStatus({
      idRegistersCertificate: idRegistersCertificate,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      // handleEditRegistersAccidents();
      handleGetEditRegistersMonitoring();
    }
    return response;
  };

  // users
  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
    }
  };
  const [openModalDatesUsers, setOpenModalDatesUers] = useState(false);

  const [userJsonDatesUsers, setuserJsonDatesUsers] = useState(false);
  const [documentDatesUsers, setDocumentDatesUsers] = useState(false);
  const [emailDatesUsers, setEmailDatesUsers] = useState(false);
  const [sexDatesUsers, setSexDatesUsers] = useState(false);
  const [phoneDatesUsers, setPhoneDatesUsers] = useState(false);
  const [ageDatesUsers, setAgeDatesUsers] = useState(false);
  const [currentTimeDatesUsers, setCurrentTimeDatesUsers] = useState(false);
  const [campusDatesUsers, setCampusDatesUsers] = useState(false);
  const [jobTypeDatesUsers, setJobTypeDatesUsers] = useState(false);

  const handleOpenModalDataUsers = (
    userJson,
    document,
    email,
    phone,
    sex,
    age,
    currentTime,
    campus,
    jobType
  ) => {
    setuserJsonDatesUsers(userJson);
    setDocumentDatesUsers(document);
    setEmailDatesUsers(email);
    setPhoneDatesUsers(phone);
    setSexDatesUsers(sex);
    setAgeDatesUsers(age);
    setCurrentTimeDatesUsers(currentTime);
    setCampusDatesUsers(campus);
    setJobTypeDatesUsers(jobType);
    setOpenModalDatesUers(true);
  };

  //Responsables del registro y de la investigación

  const [responsibles, setResponsibles] = useState([]);
  const [idUserId, setIdUserId] = useState("");

  //obtiene responsables
  const handleGetResponsible = async () => {
    const response = await MyRegistersMonitoring.getResponsible({
      idRegister: idRegistersCertificate,
    });
    setResponsibles(response);
  };
  //agrega responsables
  const handleStoreResponsible = async (idUsers) => {
    const response = await MyRegistersMonitoring.addResponsible({
      idUser: idUsers,
      idRegister: idRegistersCertificate,
    });
    if (response != null) {
      handleGetResponsible();
      handleCloseModalUploadResponsibleRegistration();
    }
  };
  //elimina responsables
  const handleDeleteResponsible = async ({ idUsers }) => {
    const response = await MyRegistersMonitoring.deleteResponsible({
      idUser: idUsers,
      idRegister: idRegistersCertificate,
    });
    handleGetResponsible();
    return response;
  };
  //firma responsables
  const handleSignResponsible = async (event) => {
    event.preventDefault();
    const response = await MyRegistersMonitoring.signResponsible({
      idUserId: idUserIdResponsible,
      idUser: idAccidentsResponsible,
    });
    handleGetResponsible();
    setOpenModalSignResponsible(false);
  };

  const handleCloseRegister = async () => {
    const response = await MyRegistersMonitoring.closeRegisterMonitoring({
      idRegister: idRegistersCertificate,
    });
    console.log(response);
    handleGetResponsible();
  };
  //responsables del registro y de la investigación

  ////Certificados
  ////Variables
  const [workerName, setWorkerName] = useState(0);
  const [examType, setExamType] = useState(["Pre-ocupacional", "Periódico"]);
  const [examDate, setExamDate] = useState("");
  const [result, setResult] = useState("");
  const [certificate, setCertificate] = useState("");
  const [observations, setObservations] = useState("");
  const [examNextDate, setExamNextDate] = useState("");
  const [workerGender, setWorkerGender] = useState("");
  const [workerDocument, setWorkerDocument] = useState("");
  const [idUser, setIdUser] = useState("");

  const [workerList, setWorkerList] = useState([]);
  const [examTypeList, setExamTypeList] = useState([
    "Pre-ocupacional",
    "Periódico",
    "de Retiro",
    "Por cambios de ocupación o puesto de trabajo",
    "Por reincorporación laboral",
    "Por contratos temporales de corta duracion",
    "Otros(complementarios, generales, específicos, auxiliares)",
  ]);

  const [resultTypeList, setResultTypeList] = useState([
    "Apto",
    "Apto con restricciones",
    "No apto",
    "Observado",
  ]);

  ////Funciones

  const handleGetWorkerList = async () => {
    const response = await MyUsers.users();
    setWorkerList(response.users_actives);
    console.log(response.users_actives);
  };
  const handleEditRegisterCertificate = async () => {
    setOpenBackdrop(!openBackdrop);
    console.log(idRegistersCertificate)

    const response =
      await MyRegistersCompetencyCertificate.editRegisterCertificate({
        idRegister: idRegistersCertificate,
      });
    console.log(response);
    setCode(response.code);
    setWorkerName(response.worker);
    setExamType(response.exam_type);
    setExamDate(response.exam_date);
    setResult(response.result);
    setCertificate(response.certificate);
    setObservations(response.observations);
    setExamNextDate(response.next_exam_date);
    setIdUser(response.id_users);
    setDocument(response.document);
    setDocumentUrl(response.document_url);
    if (response.register_status === 1) {
      setRegisterStatus(false);
    } else {
      setRegisterStatus(true);
    }
    handleGetWorkerList();
    handleGetUsersInspectionResponsible(response.id_users);
    handleCloseBackdrop();
  };

  const handleUpdateRegisterCertificate = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyRegistersCompetencyCertificate.updateRegisterCertificate({
        idRegister: idRegistersCertificate,
        code: code,
        workerName: workerName,
        examType: examType,
        examDate: examDate,
        result: result,
        certificate: certificate,
        observations: observations,
        nextExamDate: examNextDate,
        workerDocument: workerDocument,
        workerGender: workerGender,
        idUser: idUser,
        documentName: documentName,
        documentUrl: documentUrl,
      });
    handleEditRegisterCertificate();
    handleClickAlert("success", "Se actualizó el documento correctamente");
    return response;
  };

  const handleCloseRegisterCertificate = async () => {
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyRegistersCompetencyCertificate.closeRegisterCertificate({
        idRegister: idRegistersCertificate,
      });
    handleClickAlert("success", "Se cerró el documento correctamente");
    handleEditRegisterCertificate();
    return response;
  };

  ///
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const handleOpenModalInfo = () => {
    setOpenModalInfo(true);
  };

  const handleCloseModalInfo = () => {
    setOpenModalInfo(false);
  };
  ///

  const [openModalAddFile, setOpenModalAddFile] = useState(false);
  const handleOpenModalAddFile = () => {
    setOpenModalAddFile(true);
  };
  const handleCloseModalAddFile = () => {
    setOpenModalAddFile(false);
  };

  const [documentName, setDocumentName] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [document, setDocument] = useState("");

  const handleUploadCertificateFile = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    console.log(idRegistersCertificate);
    const response =
      await MyRegistersCompetencyCertificate.UploadCertificateFile({
        idRegister: idRegistersCertificate,
        document: document,
        documentName: documentName,
      });
    handleCloseBackdrop();
    handleCloseModalAddFile();
    handleClickAlert("success", "Se subió el documento correctamente");
    handleEditRegisterCertificate();
  };

  ////

  const [openModalCertificateWorker, setOpenModalCertificateWorker] =
    useState(false);

  const handleOpenModalCertificateWorker = () => {
    setOpenModalCertificateWorker(true);
  };

  // jalar y mostrar datos del usuario preparado
  const [idUsersCertificateWorker, setIdUsersCertificateWorker] =
    useState("");

  const [selectedWorker, setSelectedWorker] = useState("");

  const handleGetUsersInspectionResponsible = async (idUser) => {
    console.log(idUser)
    setOpenBackdrop(!openBackdrop);

    if(idUser !== ""){
      const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setSelectedWorker(response.first_name + " " + response.last_name);
      setIdUser(response.id_users);
      setOpenModalCertificateWorker(false);
    }

    }

    
    handleCloseBackdrop();
  };

  //// docstatus
  useEffect(() => {
    handleUserActive();
    // handleGetEditRegistersMonitoring();
    // // handleEditRegistersAccidents();

    // handleGetSeverityAccidents();
    // handleGetSeverityInccidents();
    // handleGetAccidentsWorkers();

    // // medidas correctivas
    // handleGetCorrectivesActions();

    // // trabajadores responsables
    // handleGetResponsibleWorkers();
    // handleGetResponsible();
    handleEditRegisterCertificate();

    // handleSelectUsersResponsible();
    handleGetUsersActives();
  }, []);
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      {/* <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      /> */}
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={8} sm={8} md={7} lg={7} xl={7}>
              <SubtitleText text={"Registro"} color={colorTitle}></SubtitleText>
              <TitleText
                text={"Registro de certificados de aptitud"}
              ></TitleText>
            </Grid>
            <Grid xs={8} sm={8} md={3} lg={3} xl={3}>
              <StyledAddButton
                variant="text"
                startIcon={<InfoOutlined />}
                disableElevation
                size="large"
                style={{
                  backgroundColor: "#305AD9",
                  borderRadius: "15px",
                  color: "white",
                  padding: "8px 25px",
                }}
                onClick={() => handleOpenModalInfo()}
              >
                Tipo de examen
              </StyledAddButton>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <InputControlaVertical
                text={"Nro registro"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                readOnly={registerStatus}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={4} xl={4} />
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegisterCertificate}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {/* <SelectControla
                  text={"Trabajador"}
                  value={idUser}
                  inputValue={setIdUser}
                  // childrenRows={workerList}
                  // inputValue={handleSetWorkerData()}
                  modalType={false}
                  readOnly={registerStatus}
                  childrenRows={workerList.map((person) => (
                    <MenuItem key={person.id_users} value={person.id_users}>
                      {person.last_name + " " + person.first_name}
                    </MenuItem>
                  ))}
                /> */}
                <SearchButtonControla
                  titleLabel="Trabajador "
                  inputValue={selectedWorker}
                  functionClick={handleOpenModalCertificateWorker}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <SelectControla
                  text={"Tipo de exámen"}
                  value={examType}
                  inputValue={setExamType}
                  modalType={false}
                  readOnly={registerStatus}
                  childrenRows={examTypeList.map((type, key) => (
                    <MenuItem key={key} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  inputType={"date"}
                  text={"Fecha de último examen"}
                  value={examDate}
                  inputValue={setExamDate}
                  modalType={false}
                  readOnly={registerStatus}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {/* <InputControlaVertical
                  text={"Resultado"}
                  inputType={"textArea"}
                  inputValue={setResult}
                  value={result}
                  required  
                  readOnly={registerStatus}
                /> */}
                <SelectControla
                  text={"Resultado"}
                  value={result}
                  inputValue={setResult}
                  modalType={false}
                  readOnly={registerStatus}
                  childrenRows={resultTypeList.map((type, key) => (
                    <MenuItem key={key} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <InputControlaVertical
                  text={"Certificado"}
                  inputType={"file"}
                  inputValue={setCertificate}
                  value={certificate}
                  readOnly={registerStatus}
                /> */}
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  {"Certificado"}{" "}
                </InputLabel>
              </Grid>
              <Grid item>
                <ButtonControla
                  textButton={"Adjuntar archivo"}
                  backgroundColor={"#838282"}
                  functionOnClick={() => handleOpenModalAddFile()}
                />
              </Grid>
              <Grid item>
                <ButtonIconControla
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl}
                  directionTarget={"_blank"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Observaciones"}
                  inputType={"textArea"}
                  inputValue={setObservations}
                  value={observations}
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Fecha del próximo examen"}
                  inputType={"date"}
                  inputValue={setExamNextDate}
                  value={examNextDate}
                  readOnly={registerStatus}
                />
              </Grid>
            </Grid>

            {/* tabla para mostrar accidents workers */}

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={6} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/competencycertificate"}
                />
              </Grid>
              {registerStatus === false ? (
                <Grid item xs={6} md={2}>
                  <ButtonControla
                    roles = {permissions.DOC_REGIS_CERTIF_APTITUD_EXAM_MEDICO_ACTUALIZAR}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                  />
                </Grid>
              ) : (
                ""
              )}
              {/* {registerStatus === false ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    functionOnClick={handleCloseRegisterCertificate}
                  />
                </Grid>
              ) : (
                ""
              )} */}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog agregar trabajador involucrado */}
      <Dialog
        open={openModalUploadAddDates}
        onClose={handleCloseModalUploadAddDates}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar trabajador involucrado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreAccidentsWorkers}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Tiempo de experiencia en el puesto de trabajo"}
                  inputType={"text"}
                  inputValue={setWorkExperience}
                  value={workExperience}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Área"}
                  inputType={"text"}
                  inputValue={setArea}
                  value={area}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                {/* <InputControlaVertical
                  text={"Turno"}
                  inputType={"text"}
                  inputValue={setShift}
                  value={shift}
                  modalType={true}
                  required
                /> */}
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Turno
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={shift}
                  onChange={handleChangeShift}
                >
                  <MenuItem value={"Dia"}>Dia</MenuItem>
                  <MenuItem value={"Tarde"}>Tarde</MenuItem>
                  <MenuItem value={"Noche"}>Noche</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"N.º de horas trabajadas antes del accidente"}
                  inputType={"text"}
                  inputValue={setWorkHoursBefore}
                  value={workHoursBefore}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Trabajador involucrado: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsibleInvolvedWorkers}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadAddDates}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalUploadCorrectiveActions}
        onClose={handleCloseModalUploadCorrectiveActions}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setTimestampStart}
                  value={timestampStart}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimestampEnd}
                  value={timestampEnd}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Estado de medida correctiva:"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  required
                  value={statusCorrectivesActions}
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={rows.id_registers_accidents_measures_statuses}
                      value={rows.id_registers_accidents_measures_statuses}
                    >
                      {rows.statuses}
                    </MenuItem>
                  ))}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsible}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadCorrectiveActions}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          severity: severityAlert,
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
        open={openDelete}
        onClose={handleCloseDelete}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
          }}
        >
          Eliminar datos del trabajador accidentado
        </DialogTitle>
        <DialogContent>
          <Typography>
            Los datos del trabajador accidentado se eliminará de forma
            permanente
          </Typography>
          <Typography>¿Deseas continuar?.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button onClick={handleDeleteAccidentsWorkers}>Si, eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar usuarios de medidas correctivas */}
      <Dialog
        open={openModalUpload}
        onClose={() => {
          setOpenModalUpload(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsCorrectivesActionWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUpload(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables */}
      <Dialog
        open={openModalUploadInvolvedWorkers}
        onClose={() => {
          setOpenModalUploadInvolvedWorkers(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersSearchsAddsWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUploadInvolvedWorkers(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar responsable */}
      <Dialog
        open={openModalSignResponsible}
        onClose={() => {
          setOpenModalSignResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsible}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* modal users information */}
      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsible}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          severity: severityAlert,
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
        open={openDelete}
        onClose={handleCloseDelete}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
          }}
        >
          Eliminar datos del trabajador accidentado
        </DialogTitle>
        <DialogContent>
          <Typography>
            Los datos del trabajador accidentado se eliminará de forma
            permanente
          </Typography>
          <Typography>¿Deseas continuar?.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button onClick={handleDeleteAccidentsWorkers}>Si, eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* users information */}
      <Dialog
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        open={openModalDatesUsers}
        onClose={() => setOpenModalDatesUers(false)}
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent>
          <CardUserControla
            users_json={userJsonDatesUsers}
            document={documentDatesUsers}
            email={emailDatesUsers}
            phone={phoneDatesUsers}
            sex={sexDatesUsers}
            age={ageDatesUsers}
            current_time={currentTimeDatesUsers}
            campus={campusDatesUsers}
            job_type={jobTypeDatesUsers}
          />{" "}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalDatesUers(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      {/* Info */}

      <Dialog
        open={openModalInfo}
        onClose={handleCloseModalInfo}
        maxWidth="lg"
        fullWidth={true}
        sx={{
          padding: "20px",
        }}
      >
        <DialogTitleControla titleText="Tipo de Examen" />
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              <StyledModalSubtitle>Pre-ocupacional:</StyledModalSubtitle>
            </Grid>
            <Grid item>
              Es la evaluación médica que tiene por objetivo determinar el
              estado de salud al momento del ingreso, y su aptitud al puesto de
              trabajo.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>Periódico:</StyledModalSubtitle>
            </Grid>
            <Grid item>
              Se realiza con el fin de monitorear la exposición a factores de
              riesgo e identificar en forma precoz, posibles alteraciones
              temporales, permanentes o agravadas del estado de salud del
              trabajador, que se asocien al puesto de trabajo y los estados pre
              patogénicos.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>De retiro:</StyledModalSubtitle>
            </Grid>
            <Grid item>
              Evaluación médica realizada al trabajador respecto de su estado y
              condición de salud días previos al cese laboral. Mediante este
              examen se busca detectar enfermedades relacionadas al trabajo,
              secuelas de accidentes de trabajo y en general lo agravado por el
              trabajo.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                Por cambios de ocupación o puesto de trabajo:
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Esta evaluación se realiza al trabajador cada vez que éste cambie
              de ocupación y/o de puesto de trabajo, de funciones, tareas o
              exposición a nuevos o mayores factores de riesgo, en los que se
              detecte un incremento de su magnitud, intensidad o frecuencia.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                Por reincorporación laboral:
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Evaluación que se realiza posterior a incapacidad temporal
              prolongada.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                Por contratos temporales de corta duración:
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Esta evaluación se realiza al trabajador para determinar el estado
              de salud al momento del ingreso, y su aptitud al puesto de
              trabajo. Se puede solicitar copia de los Exámenes Médico
              Ocupacionales con 3 meses de antigüedad (previa autorización del
              trabajador) al Servicio de Salud Ocupacional que atendió al
              trabajador por última vez.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                Otros (complementarios generales, específicos, auxiliares):
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Están enfocados a determinar el estado de salud basal del
              trabajador desde su evaluación pre-ocupacional y los cambios que
              ayuden a detectar de manera precoz la presencia de una patología
              asociada al trabajo o los estados pre patológicos.
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalInfo}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      {/* Dialog subir archivo */}
      <Dialog
        open={openModalAddFile}
        onClose={() => setOpenModalAddFile(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Certificado{" "}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleUploadCertificateFile}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre del documento"}
                  inputType={"text"}
                  inputValue={setDocumentName}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalAddFile(false)}
            >
              Cancelar
            </Button>
            <Button
              // type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalAddFile}
            >
              Subir certificado
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={openModalCertificateWorker}
        onClose={() => {
          setOpenModalCertificateWorker(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Trabajador
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersInspectionResponsible}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalCertificateWorker(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
