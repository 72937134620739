import * as React from "react";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import Container from "@mui/material/Container";
import DocCard from "../components/cards/docCard";
import Link from "@mui/material/Link";
import SideNav from "../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import { CircularProgress, Grid } from "@mui/material";
import CardControla from "../components/cards/cardControla";
//import { useAppContext } from "../context/AppContext";

const colorTitle = "#9191B0";
const colorOneCardOne = "#e7e7e7";

function Committee() {
  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F4F6" }}>
      <SideNav returnBack="/committee" />    

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid xs={12} md={12}>
              <SubtitleText text={"Comité"} color={colorTitle}></SubtitleText>
              <TitleText text={"Comité de SSO"} color={colorTitle}></TitleText>
            </Grid>
            <Grid xs={12} md={12}>
              <img
                src="/assets/images/committeeBanner.png"
                style={{ width: "100%" }}
                alt="Banner"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/committee/scheduleMeetings/"}
                text={"Agendar reuniones"}
                img={"/assets/images/banners/committee/agendarReuniones.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/committee/proceedingsBook/"}
                text={"Libro de Actas"}
                img={"/assets/images/banners/committee/minuteBook.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/committee/presentCommittee/"}
                text={"Documentos e Investigación"}
                img={"/assets/images/banners/committee/docsInvestigation.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/committee/presentCommittee/"}
                text={"Comité"}
                img={"/assets/images/banners/committee/presentCommittee.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/committee/electionProcess"}
                text={"Proceso de elecciones"}
                img={"/assets/images/banners/committee/elecciones.png"}
              />
            </Grid>
            
            
            
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Committee;
