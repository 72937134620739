import { React, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, Grid, InputLabel, MenuItem, Modal, Paper, Rating, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add, DeleteForever, BorderColorOutlined, Search, Print, Edit as EditIcon, HistoryEduOutlined, Delete, DownloadForOffline, PictureAsPdf } from '@mui/icons-material';
import SideNav from '../../components/sidenav/sideNav';
import SubtitleText from '../../components/text/subtitleText';
import TitleText from '../../components/text/titleText';
import MyAts from '../../api/MyAts';
import MyUsers from '../../api/MyUsers';
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import CompanyLogoControla from '../../components/images/companyLogoControla';
import ButtonControla from '../../components/buttons/buttonController';
import SearchUsersControla from '../../components/search/searchUsersControla';
import DialogTitleControla from '../../components/dialog/dialogTitleControla';
import LoadingControla from '../../components/load/loadingControla';
import MyConfigurationApi from '../../api/MyConfigurationApi';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import SearchButtonControla from '../../components/search/searchButtonControla';
import SearchEppsControla from '../../components/search/searchEppsControla';
import DialogConfirmControla from '../../components/dialog/dialogConfirmControla';
import MyTrainings from '../../api/MyTrainings';
import MyCampus from '../../api/MyCampus';
import SelectControla from '../../components/selects/selectControla';
import { Link } from 'react-router-dom';

const grey = "#9191B0";
const blue = "#034AFF";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";


const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
})

function Edit() {

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    // Pantallas de carga y alertas FIN


    // Dialogos de confirmacion INICIO
    const [idAction, setIdAction] = useState('');

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState('');
    const [contentText, setContentText] = useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = useState('');
    const [buttonActionType, setButtonActionType] = useState('');


    const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
        setIdAction(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case 'updateStatuses':
                handleUpdateStatuses()
                break;
            default:
                break;
        }
        setOpenModalConfirm(false);
    }

    // Dialogos de confirmacion FIN

    const params = new URLSearchParams(window.location.search);
    const id_trainings = params.get('id_trainings');

    const [idSubject, setIdSubject] = useState("");
    const [name, setName] = useState("");
    const [idType, setIdType] = useState("");
    const [idStatus, setIdStatus] = useState("");
    const [idModality, setIdModality] = useState("");
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [idCampus, setIdCampus] = useState("");
    const [manager, setManager] = useState("");
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const [idResponsible, setIdResponsible] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [observations, setObservations] = useState("");
    const [tabVisible, setTabVisible] = useState(false);

    const [rowsTypes, setRowsTypes] = useState([]);
    const handleGetTypes = async () => {
        const response = await MyTrainings.getTypes();
        setRowsTypes(response);
    };

    const [rowSubjects, setRowSubjects] = useState([]);
    const handleGetSubjects = async () => {
        const response = await MyTrainings.getSubjects();
        setRowSubjects(response);
    };

    const [rowStatuses, setRowStatuses] = useState([]);
    const handleGetStatuses = async () => {
        const response = await MyTrainings.getStatuses();
        setRowStatuses(response);
    };

    const [rowModalities, setRowModalities] = useState([]);
    const handleGetModalities = async () => {
        const response = await MyTrainings.getModalities();
        setRowModalities(response);
    };

    const [rowResponsible, setRowResponsible] = useState([]);
    const handleGetResponsible = async () => {
        const response = await MyUsers.usersTrust();
        setRowResponsible(response);
    };

    const [rowCampus, setRowCampus] = useState([]);
    const handleGetCampus = async () => {
        const response = await MyCampus.campus();
        setRowCampus(response);
    };

    const [rowYears, setRowYears] = useState([]);
    const handleGetYears = async () => {
        const today = new Date();
        const year = today.getFullYear();
        const margin = year - 2022;
        let years = [];
        for (let i = 0; i < margin; i++) {
            years.push(year - i);
        }
        years.push(year + 1);
        const response = years.sort();
        setRowYears(response);
    };

    const [rowMonths, setRowMonths] = useState([]);
    const handleGetMonths = async () => {
        const response = [
            { 'idMonth': 1, 'month': 'Enero' },
            { 'idMonth': 2, 'month': 'Febrero' },
            { 'idMonth': 3, 'month': 'Marzo' },
            { 'idMonth': 4, 'month': 'Abril' },
            { 'idMonth': 5, 'month': 'Mayo' },
            { 'idMonth': 6, 'month': 'Junio' },
            { 'idMonth': 7, 'month': 'Julio' },
            { 'idMonth': 8, 'month': 'Agosto' },
            { 'idMonth': 9, 'month': 'Septiembre' },
            { 'idMonth': 10, 'month': 'Octubre' },
            { 'idMonth': 11, 'month': 'Noviembre' },
            { 'idMonth': 12, 'month': 'Diciembre' }
        ];

        setRowMonths(response);
    }

    const handleGetTraining = async () => {
        const response = await MyTrainings.getTrainings({ id_trainings: id_trainings });
        setIdSubject(response.data.data[0].id_trainings_subjects);
        setName(response.data.data[0].name);
        setIdType(response.data.data[0].id_trainings_types);
        setIdStatus(response.data.data[0].id_trainings_statuses);
        setIdModality(response.data.data[0].id_trainings_modalities);
        setYear(response.data.data[0].year);
        setMonth(response.data.data[0].month);
        setIdCampus(response.data.data[0].id_campus);
        setIdResponsible(response.data.data[0].id_users_responsible);
        setStart(response.data.data[0].timestamp_start);
        setEnd(response.data.data[0].timestamp_end);
        setManager(response.data.data[0].manager);
        setHours(response.data.data[0].hours);
        setMinutes(response.data.data[0].minutes);
        setObservations(response.data.data[0].observations);
    }

    const [rating, setRating] = useState(0);
    const handleGetRating = async () => {
        const response = await MyTrainings.getRating({ id_trainings: id_trainings });
        setRating(Math.ceil(response[0].rating));
    }

    useEffect(() => {
        handleGetTraining();
        handleGetRating();
        handleGetSubjects();
        handleGetTypes();
        handleGetStatuses();
        handleGetModalities();
        handleGetResponsible();
        handleGetCampus();
        handleGetYears();
        handleGetMonths();
    }, []);

    useEffect(() => {
        const currentDate = new Date();
        const startDate = new Date(start);
        const endDate = new Date(end);

        // Comprueba si la fecha actual está entre la fecha de inicio y fin
        const isVisible = currentDate >= startDate && currentDate <= endDate;

        setTabVisible(isVisible);
    }, [start, end]);

    const handleUpdateTrainings = async (event) => {
        event.preventDefault();
        const response = await MyTrainings.updateTrainings({ id_trainings: id_trainings, id_trainings_subjects: idSubject, name: name, manager: manager, hours: hours, minutes: minutes, observations: observations, id_trainings_types: idType, id_trainings_statuses: idStatus, id_trainings_modalities: idModality, id_users_responsible: idResponsible, timestamp_start: start, timestamp_end: end, year: year, month: month });

        if (response.success == true) {
            alert(response.msg);
        } else {
            alert('error');
        }
    }

    const handleUpdateStatuses = async () => {
        handleOpenLoading();
        const response = await MyTrainings.updateTrainingsStatuses({ id_trainings: id_trainings, id_trainings_statuses: idAction });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleGetTraining();
            handleOpenAlert(response.msg, 'success');
        }
    }

    console.log("mi visible ", tabVisible);

    return (
        <>
            <Box sx={{ display: "flex", height: "100%" }}>

                <SideNav returnBack='/trainings/personal' />

                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />

                <Container sx={{ paddingBottom: 4 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item md={10}>
                                <SubtitleText text={"Capacitaciones"} color={grey}></SubtitleText>
                                <TitleText text={"Detalles"} />

                            </Grid>
                            {/* <Grid item md={2}>

                            </Grid> */}
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                                <CompanyLogoControla />
                            </Grid>
                        </Grid>
                        <Stack
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems="center"
                            sx={{ mt: .5, mb: 2 }}
                        >
                            <Tabs value={0} aria-label="Tipos de documento" >
                                <Tab label="Detalles" href={"/trainings/personal/edit?id_trainings=" + id_trainings} />
                                { tabVisible && (
                                    <>
                                        <Tab label="Materiales" href={"/trainings/personal/materials?id_trainings=" + id_trainings} />
                                        <Tab label="Examenes" href={"/trainings/personal/exams?id_trainings=" + id_trainings} />
                                    </>
                                )}
                            </Tabs>
                        </Stack>
                        <form onSubmit={handleUpdateTrainings}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectControla text={'Tema de capacitación:'} name="id_trainings_subjects"
                                        inputValue={setIdSubject} childrenRows={
                                            rowSubjects.map((row) => (
                                                <MenuItem key={row.id_trainings_subjects}
                                                    value={row.id_trainings_subjects}
                                                >
                                                    {row.subject}
                                                </MenuItem>
                                            ))
                                        } value={idSubject} readOnly
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <InputControlaVertical text={'Nombre de la capacitación:'} inputType={'text'} inputValue={setName} value={name} readOnly />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <SelectControla text={'Responsable de capacitación:'}
                                        inputValue={setIdResponsible} childrenRows={
                                            rowResponsible.map((row) => (
                                                <MenuItem key={row.id_users}
                                                    value={row.id_users}
                                                >
                                                    {row.first_name + ' ' + row.last_name}
                                                </MenuItem>
                                            ))
                                        } value={idResponsible} readOnly
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <InputControlaVertical text={'Encargado:'} inputType={'text'} inputValue={setManager} value={manager} readOnly />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <SelectControla text={'Tipo de capacitación:'}
                                        inputValue={setIdType} childrenRows={
                                            rowsTypes.map((row) => (
                                                <MenuItem key={row.id_trainings_types}
                                                    value={row.id_trainings_types}
                                                >
                                                    {row.type}
                                                </MenuItem>
                                            ))
                                        } value={idType} readOnly
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <SelectControla text={'Modalidad de capacitación:'}
                                        inputValue={setIdModality} childrenRows={
                                            rowModalities.map((row) => (
                                                <MenuItem key={row.id_trainings_modalities}
                                                    value={row.id_trainings_modalities}
                                                >
                                                    {row.modality}
                                                </MenuItem>
                                            ))
                                        } value={idModality} readOnly
                                        textTooltip={'Virtual: capacitación que se desarrolla en tiempo real.\nRemota: capacitación que se sube al sistema y se desarrolla dentro de un límite de tiempo señalado por el capacitador.'}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <SelectControla text={'Sede de capacitación:'} name="id_campus"
                                        inputValue={setIdCampus} modalType={true} childrenRows={
                                            rowCampus.map((row) => (
                                                <MenuItem key={row.id_campus}
                                                    value={row.id_campus}
                                                >
                                                    {row.name}
                                                </MenuItem>
                                            ))
                                        } value={idCampus}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <InputControlaVertical text={'Fecha y hora inicio de capacitación:'} inputType={'datetime-local'} inputValue={setStart} value={(start != null) ? start.substr(0, 19) : ''} readOnly />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <InputControlaVertical text={'Fecha y hora fin de capacitación:'} inputType={'datetime-local'} inputValue={setEnd} value={(end != null) ? end.substr(0, 19) : ''} readOnly />
                                </Grid>


                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <InputControlaVertical text={'Horas:'} inputType={'number'} inputValue={setHours} value={hours} readOnly />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <InputControlaVertical text={'Minutos:'} inputType={'number'} inputValue={setMinutes} value={minutes} readOnly />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <SelectControla text={'Mes de capacitación:'}
                                        inputValue={setMonth} childrenRows={
                                            rowMonths.map((row) => (
                                                <MenuItem key={row.idMonth}
                                                    value={row.idMonth}
                                                >
                                                    {row.month}
                                                </MenuItem>
                                            ))
                                        } value={month} readOnly
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <SelectControla text={'Año de capacitación:'}
                                        inputValue={setYear} childrenRows={
                                            rowYears.map((row) => (
                                                <MenuItem key={row}
                                                    value={row}
                                                >
                                                    {row}
                                                </MenuItem>
                                            ))
                                        } value={year} readOnly
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <SelectControla text={'Estado de capacitación:'}
                                        inputValue={setIdStatus} childrenRows={
                                            rowStatuses.map((row) => (
                                                <MenuItem key={row.id_trainings_statuses}
                                                    value={row.id_trainings_statuses}
                                                >
                                                    {row.status}
                                                </MenuItem>
                                            ))
                                        } value={idStatus} readOnly
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
                                    <InputControlaVertical text={'Observaciones:'} inputType={'textArea'} inputValue={setObservations} value={observations} readOnly />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                                    <InputLabel
                                        sx={{
                                            padding: '8px',
                                            fontSize: '18px',
                                            color: '#305AD9',
                                            fontWeight: 'bold'
                                        }}> Calificación </InputLabel>
                                    <Rating value={rating} size="large" readOnly />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Stack direction='row' spacing={2} justifyContent="end">
                                        <Box>
                                            <ButtonControla
                                                roles={[1, 7, 8, 9]}
                                                backgroundColor={"#CBCBFF"}
                                                backgroundColorHover={"#CBCBFF"}
                                                textButton={"Atras"}
                                                typeButton={"button"}
                                                url={'/trainings/personal'}
                                            />
                                        </Box>

                                    </Stack>
                                </Grid>

                            </Grid>
                        </form>
                    </Box>
                </Container>
            </Box >
        </>
    )
}

export default Edit