import { React, useState, useEffect } from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Grid,
  Stack,
  MenuItem,
  Paper,
  CardMedia,
  Typography,
  CardContent,
  Card,
} from "@mui/material";
import { Add, ArrowBackOutlined, Edit } from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import { styled } from "@mui/system";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import MyPets from "../../../../api/MyPets";
import ButtonControla from "../../../../components/buttons/buttonController";
import SearchEppsControla from "../../../../components/search/searchEppsControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import LoadingControla from "../../../../components/load/loadingControla";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import MySessionStorage from "../../../../api/MySessionStorage";
import { useAppContext } from "../../../../context/AppContext";
import MyAreas from "../../../../api/MyAreas";
import SelectControla from "../../../../components/selects/selectControla";
import { useNavigate } from "react-router-dom";

const grey = "#9191B0";

const StyledTypography = styled(Typography)({
  color: "#305AD9",
  fontSize: 16,
  fontWeight: 600,
  gap: "2%",
  padding: "0.5em",
});

function Model() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const id_pets = params.get("idPets");

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Pantallas de carga y alertas FIN

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [handleActionConfirm, setHandleActionConfirm] = useState();

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonAction
  ) => {
    // set(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setOpenModalConfirm(true);
    setHandleActionConfirm(buttonAction);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  const [area, setArea] = useState(null);
  const [code, setCode] = useState(null);
  const [version, setVersion] = useState(null);
  const [titulo, setTitulo] = useState(null);
  const [staff, setStaff] = useState(null);
  const [tools, setTools] = useState(null);
  const [procedures, setProcedures] = useState(null);
  const [restrictions, setRestrictions] = useState(null);
  const [observation, setObservation] = useState(null);

  // const [flag, setFlag] = useState(false);

  const handlePostEditPets = async (event) => {
    event.preventDefault();
    handleOpenLoading();
   
    const response = await MyPets.editPets({
      id_pets,
      area: areaSelected,
      name: titulo,
      code: code,
      version,
      staff: staff,
      tools: tools,
      procedure: procedures,
      restriction: restrictions,
      observation: observation,
    });
 
    if (response.success !== false) {
      handleOpenAlert(response.msg, "success");
      // setFlag(true);
      // window.location.href = "/documentation/documents/procedures/pets";
    } else {
      handleOpenAlert("Hubo un error al actualizar", "success");
    }
    return response;
  };

  const handleSearchPets = async () => {
    const response = await MyPets.searchPets({ id_pets });
    if (response.success === false) {
    } else {
      setAreaSelected(response.area);

      setCode(response.code);
      setVersion(response.version);
      setTitulo(response.name);
      setStaff(response.staff);
      setTools(response.tools);
      setProcedures(response.procedure);
      setRestrictions(response.restriction);
      setObservation(response.observation);
    }
    return response;
  };

  // agregar EPPS
  const [openModalEpps, setOpenModalEpps] = useState(false);
  const [rowsEpps, setRowsEpps] = useState([]);

  const handleOpenModalEpps = () => {
    handlePetsNoEpps();
    setOpenModalEpps(true);
  };

  const handleCloseModalEpps = () => {
    setOpenModalEpps(false);
  };

  const handleSelectEpps = async (idEpps) => {
    handleOpenLoading();
    const response = await MyPets.eppsStore({
      id_pets: id_pets,
      id_epps: idEpps,
    });

    if (response.success !== false) {
      setRowsEpps([]);
      setOpenModalEpps(false);
      handlePetsNoEpps();
      handlePetsEpps();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Hubo un error", "error");
    }
    return response;
  };

  const handleSelectDeleteEpps = async (idPetsEpps) => {
    handleOpenLoading();
    const response = await MyPets.eppsDelete({ id_pets_epps: idPetsEpps });

    if (response.success !== false) {
      setRowsEpps([]);
      setOpenModalEpps(false);
      handlePetsNoEpps();
      handlePetsEpps();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Hubo un error", "error");
    }
    return response;
  };

  const handlePetsNoEpps = async () => {
    // handleOpenLoading();
    const response = await MyPets.eppsNoPetsGet({ id_pets: id_pets });
    if (response.success === false) {
      alert("Error");
    } else {
      setRowsEpps(response);
    }
    return response;
  };

  const [rowsEppsPets, setRowsEppsPets] = useState([]);

  const handlePetsEpps = async () => {
    const response = await MyPets.eppsPetsGet({ id_pets: id_pets });
    if (response.success === false) {
      alert("Error listando los epps");
    } else {
      setRowsEppsPets(response);
    }
    return response;
  };

  // usar modelo
  const handleStorePetsModel = async () => {
    handleOpenLoading();
    const response = await MyPets.storePetsModel({ id_pets: id_pets });
    if (response.success !== false) {
      // handleOpenAlert(response.msg, 'success');
      MySessionStorage.setSession("messageAlert", response.msg);
      MySessionStorage.setSession("typeAlert", "success");

        navigate("/documentation/documents/procedures/pets/edit?idPets=" + response.id_pets);
    } else {
      handleOpenAlert("Hubo un error al actualizar", "success");
    }
    return response;
  };

  //cargar areas de la empresa
  const [areas, setAreas] = useState([]);
  const [areaSelected, setAreaSelected] = useState("");

  const getAreas = async()=>{
    const response = await MyAreas.getAreas();
    setAreas(response);
  } 


  useEffect(() => {
    handleSearchPets();
    handlePetsEpps();
    getAreas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/documents/procedures/pets" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <SearchEppsControla
        openModalEpps={openModalEpps}
        handleCloseModalEpps={handleCloseModalEpps}
        handleClickSelectEpps={handleSelectEpps}
        rowsEpps={rowsEpps}
      />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handlePostEditPets}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              alignItems="center"
            >
              <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
                <SubtitleText text={"PETS"} color={grey}></SubtitleText>
                <TitleText
                  text={
                    "Editar o usar modelo de Procedimiento Escrito de Trabajo Seguro"
                  }
                />
              </Grid>
              <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                <CompanyLogoControla />
              </Grid>

              <Grid item xs={12} md={6}>
                {/* <InputControlaVertical
                  text={"Área"}
                  inputType={"text"}
                  inputValue={setArea}
                  value={area}
                  modalType={false}
                  required
                /> */}
                <SelectControla
                 text = {"Área"}
                 inputValue={setAreaSelected}
                 value={areaSelected}
                 childrenRows={areas.map((a)=>(
                  <MenuItem key={a.id_areas} value={a.id_areas}>
                    {a.area}
                  </MenuItem>
                 ))}
                 required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Código"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Versión"}
                  inputType={"text"}
                  inputValue={setVersion}
                  value={version}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitulo}
                  value={titulo}
                  modalType={false}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Personal"}
                  inputType={"textArea"}
                  inputValue={setStaff}
                  value={staff}
                  modalType={false}
                  required
                />
              </Grid>

              {/* Equipos de proteccion personal */}
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <StyledTypography
                  sx={{
                    padding: "8px",
                    fontSize: "20px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  EPPS
                </StyledTypography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                <ButtonControla
                  roles={permissions.DOC_HG_PROC_PETS_MOD_AGREGAR_MODELO_EPP}
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Agregar EPP"}
                  functionOnClick={handleOpenModalEpps}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper sx={{ overflow: "hidden" }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ width: "100%", margin: "auto" }}
                  >
                    {rowsEppsPets.map((rowsEpps) => (
                      <Grid
                        item
                        key={rowsEpps.id_epps}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        sx={{ p: 2 }}
                      >
                        <Card
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            height: 80,
                            "&:hover": {
                              cursor: "pointer",
                              border: "1px solid #0F1255",
                              boxShadow: "0px 4px #0F1255",
                            },
                          }}
                          onClick={() => {
                            handleSelectDeleteEpps(rowsEpps.id_pets_epps);
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <Typography sx={{ fontSize: "14px" }}>
                                {rowsEpps.epp}
                              </Typography>
                            </CardContent>
                          </Box>
                          <CardMedia
                            component="img"
                            sx={{
                              maxWidth: 80,
                              marginLeft: 0,
                              maxHeight: 80,
                              objectFit: "contain",
                            }}
                            image={rowsEpps.photo_url}
                            alt="Live from space album cover"
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>

              {/* Equipos/ herramientas/materiales */}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Equipos/herramientas/materiales"}
                  inputType={"textArea"}
                  inputValue={setTools}
                  value={tools}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Procedimiento"}
                  inputType={"textArea"}
                  inputValue={setProcedures}
                  value={procedures}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Restricciones"}
                  inputType={"textArea"}
                  inputValue={setRestrictions}
                  value={restrictions}
                  modalType={false}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Observaciones:"}
                  inputType={"textArea"}
                  inputValue={setObservation}
                  modalType={false}
                  value={observation}
                />
              </Grid>

              {/* BOTONES DE CANCELAR USAR MODELOS ACTUALIZAR MODELO */}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                  <Box>
                    <ButtonControla
                      //todos
                      roles = {permissions.DOC_HG_PROC_PETS_MOD_REGRESAR}
                      iconButton={<ArrowBackOutlined sx={{ color: "white" }} />}
                      backgroundColor={"#CBCBFF"}
                      textButton={"Regresar"}
                      backgroundColorHover={"#CBCBFF"}
                      typeButton={"button"}
                      isNavegationOrigin={true}
                      url={"/documentation/documents/procedures/pets?idTabs=0"}                    
                    />
                  </Box>
                  <Box>
                    <ButtonControla
                      roles={ permissions.DOC_HG_PROC_PETS_MOD_ACTUALIZAR_MODELO}
                      iconButton={<Edit sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Actualizar modelo"}
                      typeButton={"submit"}
                    />
                  </Box>
                  <Box>
                    {/* {flag !==true ? '':  */}
                    <ButtonControla
                      roles={permissions.DOC_HG_PROC_PETS_MOD_USAR_MODELO}
                      backgroundColor={"#F2994A"}
                      backgroundColorHover={"#FF881E"}
                      textButton={"Usar modelo"}
                      typeButton={"button"}
                      functionOnClick={handleStorePetsModel}
                    />
                          {/* } */}
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
}

export default Model;
