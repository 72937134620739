import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import { Add, Close, UploadFile } from "@mui/icons-material";
import SideNav from "../components/sidenav/sideNav";
import SubtitleText from "../components/text/subtitleText";
import DocBanner from "../components/cards/docBanner";
import MyBaseline from "../api/MyBaseline";
import TitleText from "../components/text/titleText";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import ButtonControla from "../components/buttons/buttonController";
import LoadingControla from "../components/load/loadingControla";
import DialogConfirmControla from "../components/dialog/dialogConfirmControla";
import { useAppContext } from "../context/AppContext";
import TableControla from "../components/tables/table-controla";
import ListCurrent from "./components/list-current";
import ListInProgress from "./components/list-inProgress";
import ListArchived from "./components/list-archived";
import ButtonMailboxControla from "../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";
import { useModulesFree } from "./hooks/userModulesFree";

const grey = "#9191B0";
const blue = "#034AFF";
const purple = "#8B8BD8";

const StyledSimpleButton = styled(Button)({
  textTransform: "none",
  borderRadius: 14,
  backgroundColor: "#169073",
  color: "white",
  width: "100%",
});

const AddButton = styled(Button)({
  textTransform: "none",
  borderRadius: 6,
  backgroundColor: "#ffc107",
  color: "black",
});

function BaselineDiagnosis() {
  const { permissions, sectionsModules } = useAppContext();
  const navigate = useNavigate();

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const [idConfigurationModule, setIdConfigurationModule] = useState(null);
  const { isBaselineFree } = useModulesFree();

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Pantallas de carga y alertas FIN

  const [baselineActiveRows, setBaselineActiveRows] = useState([]);
  const [baselineArchivedRows, setBaselineArchivedRows] = useState([]);
  const [baselineInProgressRows, setBaselineProgressRows] = useState([]);

  const handleGetBaselineDiagnosis = async () => {
    setOpenLoading(true);
    const response = await MyBaseline.baselineDiagnosis();

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      const archivedBaselines = response.baseLine_archived;
      const inProgressBaselines = response.baseLine_in_progress;

      const tempActiveBaselines = response.baseLine_current[0];
      if (tempActiveBaselines === null || tempActiveBaselines === undefined) {
      } else {
        const activeBaselines = [];
        activeBaselines.push(tempActiveBaselines);
        handleDownloadImprovementActions(activeBaselines[0].id_baseline);
        setBaselineActiveRows(activeBaselines);
      }

      // TODO
      setOpenLoading(false);
      setBaselineActiveRows(response.baseLine_current);
      setBaselineArchivedRows(archivedBaselines);
      setBaselineProgressRows(inProgressBaselines);
    }
    return response;
  };

  const [name, setName] = useState(null);
  const [document_url, setDocument_url] = useState(null);
  const [delete_id, setDelete_id] = useState(null);

  const [openHaveDiagnosticModal, setOpenHaveDiagnosticModal] = useState(false);
  const handleCloseHaveDiagnosticModal = () =>
    setOpenHaveDiagnosticModal(false);
  const handleOpenHaveDiagnosticModal = () => setOpenHaveDiagnosticModal(true);

  const [openStartBaselineProcess, setOpenStartBaselineProcess] =
    useState(false);
  const handleCloseStartBaselineProcess = () =>
    setOpenStartBaselineProcess(false);
  const handelOpenStartBaselineProcess = () =>
    setOpenStartBaselineProcess(true);

  const [openEditDiagnosticModal, setOpenEditDiagnosticModal] = useState(false);
  const handleCloseEditDiagnosticModal = () =>
    setOpenEditDiagnosticModal(false);
  const handleOpenEditDiagnosticModal = () => setOpenEditDiagnosticModal(true);

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const handleCloseAlertModal = () => setOpenAlertModal(false);
  const handleOpenAlertModal = (id) => {
    setDelete_id(id);
    setOpenAlertModal(true);
  };

  // TODO
  const handlePostCreateBaseline = async () => {
    handleOpenLoading();
    const response = await MyBaseline.storeBaseline({
      name,
      document_url,
      id_configuration_modules: idConfigurationModule,
    });

    if (response.success === true) {
      console.log("Linea Base creada correctamente");
    } else {
      console.log("Error, línea base no se ha creado");
    }

    navigate("/baseline/diagnosis");
  };

  const handlePostStartBaselineProcess = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyBaseline.storeBaseline({
      name,
      id_configuration_modules: idConfigurationModule,
    });
    console.log("Proceso iniciado");
    navigate(`/baseline/answers?id_baseline=${response.id_baseline}`);
  };

  const handleDeleteBaseline = async (idBaseline) => {
    handleOpenLoading();
    const response = await MyBaseline.deleteBaseline({ idBaseline });
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleGetBaselineDiagnosis();
      handleOpenAlert("Se eliminó linea base con éxito", "success");
    }
    handleCloseAlertModal();
  };

  const handleArchiveBaseline = async (idBaseline) => {
    handleOpenLoading();
    const response = await MyBaseline.archiveBaselineDiagnosis({
      idBaseline: idBaseline,
    });
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleOpenAlert(response.msg, "success");
    }
    handleGetBaselineDiagnosis();
  };

  /////////////////////// ALERT

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    // setIdPetar(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      // case 'delete':
      //   handleDeletePetar();
      //   break;
      // case 'sendWorkers':
      //   sendToWorkers(idPetar);
      //   break;
      // case 'public':
      //   publishPetar(idPetar);
      //   break;
      // case 'archived':
      //   toArchive(idPetar);
      //   break;
      // case 'unarchived':
      //   toUnarchive(idPetar);
      //   break;
      case "upload":
        handlePostCreateBaseline();
    }
    setOpenModalConfirm(false);
  };
  /////////////////////// ALERT
  /////descargar acciones de mejora

  const [csvData, setCsvData] = useState([]);
  const handleDownloadImprovementActions = async (idBaseline) => {
    const response = await MyBaseline.downloadImprovements({
      idBaseline: idBaseline,
    });
    setCsvData(response);
    return response;
  };

  // TODO
  const getSectionDiagnostic = () => {
    // code : SIDENAV_BASELINE => LINEA BASE
    // Aqui se obtiene el tipo de modulo de "LINEA BASE"
    const sectionData = sectionsModules.find(
      (item) => item.code === "SIDENAV_BASELINE"
    );

    // Obteniendo Id del modulo para generar baselinea
    const module = sectionData.modules[0];
    setIdConfigurationModule(module.id_configuration_modules);
  };

  const getTabs = () => {
    if (isBaselineFree) {
      return [
        {
          label: "Vigentes",
          columnNames: ["Nombre del documento", "Resultado", "Tipo"],
        },
        {
          label: "En Proceso",
          columnNames: ["Nombre del documento", "Tipo"],
        },
      ];
    }

    return [
      {
        label: "Vigentes",
        columnNames: ["Nombre del documento", "Resultado", "Tipo"],
      },
      {
        label: "En Proceso",
        columnNames: ["Nombre del documento", "Tipo"],
      },
      {
        label: "Archivados",
        columnNames: ["Nombre del documento", "Tipo"],
      },
    ];
  };

  const limitRegistersValidator = () => {
    if (isBaselineFree) {
      const countActiveBaseline = baselineActiveRows.length;
      const countProgressBaseline = baselineInProgressRows.length;
      let totalRegisters = countActiveBaseline + countProgressBaseline;
      if (totalRegisters === 1) {
        return false;
      }
      return true;
    }
    return true;
  };

  useEffect(() => {
    handleGetBaselineDiagnosis();
    getSectionDiagnostic();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <SideNav returnBack="/baseline" />
        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />
        <DialogConfirmControla
          openModalConfirm={openModalConfirm}
          handleCloseModalConfirm={handleCloseModalConfirm}
          titleText={titleText}
          contentText={contentText}
          buttonTextConfirm={buttonTextConfirm}
          handleActionConfirm={handleActionConfirm}
        />
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={3} columnSpacing={6}>
              <Grid item md={12}>
                <ButtonMailboxControla originUrl={"/baseline/diagnosis"} />
              </Grid>

              <Grid item md={12}>
                <SubtitleText text={"Línea Base"} color={grey}></SubtitleText>
                <DocBanner
                  color1={"#8FBCFF"}
                  color2={"#2F67BC"}
                  text={"Mis diagnósticos"}
                ></DocBanner>
              </Grid>
              <Grid
                container
                direction={"row"}
                alignItems="center"
                justifyContent="left"
                gap={2}
                style={{ paddingLeft: "48px", paddingTop: "24px" }}
              >
                <Grid item md={2} alignItems="center" justifyContent={"center"}>
                  {limitRegistersValidator() && (
                    <ButtonControla
                      roles={permissions.LINE_DIAG_VIGE_INICIAR_PROCESO}
                      iconButton={<Add sx={{ color: "white " }} />}
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#33AEF4"}
                      textButton={"Iniciar diagnóstico"}
                      functionOnClick={handelOpenStartBaselineProcess}
                    ></ButtonControla>
                  )}
                </Grid>

                <Grid item md={2} alignItems="center" justifyContent={"center"}>
                  {!isBaselineFree && (
                    <ButtonControla
                      roles={permissions.LINE_DIAG_VIGE_SUBIR_DIAGNOSTICO}
                      iconButton={<UploadFile sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Subir diagnóstico"}
                      functionOnClick={handleOpenHaveDiagnosticModal}
                    >
                      Subir diagnóstico
                    </ButtonControla>
                  )}
                </Grid>
                <Grid item md={3}></Grid>
              </Grid>
              <Grid item md={12}>
                <TableControla loading={openLoading} tabs={getTabs()}>
                  <ListCurrent
                    baselineActiveRows={baselineActiveRows}
                    colorCell={purple}
                    handleArchiveBaseline={handleArchiveBaseline}
                  />

                  <ListInProgress
                    baselineInProgressRows={baselineInProgressRows}
                    colorCell={purple}
                    handleOpenEditDiagnosticModal={
                      handleOpenEditDiagnosticModal
                    }
                    handleOpenAlertModal={handleOpenAlertModal}
                  />

                  {!isBaselineFree && (
                    <ListArchived
                      baselineArchivedRows={baselineArchivedRows}
                      colorCell={purple}
                    />
                  )}
                </TableControla>
              </Grid>

              <Grid item md={12}></Grid>
            </Grid>

            <Dialog
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
              open={openHaveDiagnosticModal}
              onClose={handleCloseHaveDiagnosticModal}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "40%",
                  padding: "10px",
                  borderRadius: "16px",
                },
              }}
              fullWidth={true}
            >
              <DialogTitle>
                <TitleText text={"Cargar Línea Base"} />
                <IconButton
                  aria-label="close"
                  onClick={handleCloseHaveDiagnosticModal}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container rowGap={2}>
                  <Grid item md={12}>
                    <InputControlaVertical
                      text="Nombre de línea base"
                      inputType={"text"}
                      inputValue={setName}
                      modalType={false}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <InputControlaVertical
                      text={"Archivo"}
                      inputType={"file"}
                      inputValue={setDocument_url}
                      modalType={false}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <ButtonControla
                  roles={[1, 3, 7]}
                  textButton={"Subir diagnóstico"}
                  backgroundColor={"#ffc107"}
                  functionOnClick={() =>
                    handleOpenModalConfirm(
                      1,
                      "Alerta",
                      " Recuerda que al subir este archivo al sistema el diagnóstico línea vigente actual se enviará a la sección archivado y este nuevo archivo será el vigente. El diagnóstico línea archivado ya no podrá regresar a vigente.",
                      "Sí, subir",
                      "upload"
                    )
                  }
                />
              </DialogActions>
            </Dialog>

            <Dialog
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
              open={openStartBaselineProcess}
              onClose={handleCloseStartBaselineProcess}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "40%",
                  padding: "10px",
                  borderRadius: "16px",
                },
              }}
              fullWidth={true}
            >
              <form onSubmit={handlePostStartBaselineProcess}>
                <DialogTitle>
                  <TitleText text={"Iniciar Proceso Línea Base"} />
                  <IconButton
                    aria-label="close"
                    onClick={handleCloseStartBaselineProcess}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Grid container>
                    <Grid item md={12}>
                      <InputControlaVertical
                        text="Nombre de línea base"
                        inputType={"text"}
                        inputValue={setName}
                        modalType={false}
                        required
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonControla
                    roles={[1, 3, 7]}
                    textButton={"Iniciar Proceso"}
                    backgroundColor={"#ffc107"}
                    typeButton="submit"
                  />
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
              open={openEditDiagnosticModal}
              onClose={handleCloseEditDiagnosticModal}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "40%",
                  padding: "10px",
                  borderRadius: "16px",
                },
              }}
              fullWidth={true}
            >
              <DialogTitle>
                <TitleText text={"Editar Línea Base"} />
                <IconButton
                  aria-label="close"
                  onClick={handleCloseEditDiagnosticModal}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container rowGap={2}>
                  <Grid item md={12}>
                    <InputControlaVertical
                      text="Nombre de línea base"
                      inputType={"text"}
                      inputValue={setName}
                      modalType={false}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <InputControlaVertical
                      text={"Archivo"}
                      inputType={"file"}
                      inputValue={setDocument_url}
                      modalType={false}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <AddButton
                //onClick={handlePostCreateBaseline}
                >
                  Cargar
                </AddButton>
              </DialogActions>
            </Dialog>

            <Dialog
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
              open={openAlertModal}
              onClose={handleCloseAlertModal}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "40%",
                  padding: "10px",
                  borderRadius: "16px",
                },
              }}
              fullWidth={true}
            >
              <DialogTitle>¿Está seguro de eliminar?</DialogTitle>
              <DialogContent>
                Se eliminarán los lineamientos, todas las respuestas a los
                lineamientos y los resultados de línea base, una vez hecho esto
                no se podrá recuperar dicha información.
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAlertModal}>Cancelar</Button>
                <Button
                  onClick={() => {
                    handleDeleteBaseline(delete_id);
                  }}
                >
                  Eliminar
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default BaselineDiagnosis;
