import { React, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import SubtitleText from "../../../../components/text/subtitleText";
import TitleText from "../../../../components/text/titleText";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";


import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    InputLabel,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {
    Add,
    ArrowBack,
    Delete,
    EditOutlined,
    HistoryEduOutlined,
    BorderColorOutlined
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import MyHealthAndSafetyStatistics from "../../../../api/MyHealthAndSafetyStatistics";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import MyUsers from "../../../../api/MyUsers";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";


const colorTitle = "#9191B0";

function Edit2() {
    const {permissions} = useAppContext();

    const params = new URLSearchParams(window.location.search);
    const idHealthAndSafetyStatistic = params.get("ididHealthAndSafetyStatistic");

    const readOnly = useLocation().state.readOnly;

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Pantallas de carga y alertas FIN


    //modal de responsable
    const [openModalCreateResponsible, setOpenModalCreateResponsible] = useState(false);
    const [rowsUsers, setRowsUsers] = useState([]);

    const handleOpenModalCreateResponsible = () => {
        setOpenModalCreateResponsible(true);
    }

    const handleCloseModalCreateResponsible = () => {
        setOpenModalCreateResponsible(false);
    }

    const handleGetUsers = async () => {
        const resp = await MyUsers.users();
        setRowsUsers(resp.users_actives);
    }

    const user = MyConfigurationApi.userData();
    const idUserLogged = user.id_users;
    //

    const [openModalCreateRegister, setOpenModalCreateRegister] = useState(false);
    const [typeModal, setTypeModal] = useState(null);
    const [typeAction, setTypeAction] = useState(null);
    const [titleModal, setTitleModal] = useState("");
    const [description, setDescription] = useState(null);
    const [idRegister, setIdRegister] = useState(null);

    const handleOpenModalCreateRegister = (titleModal, typeModal, typeAction, register) => {
        setOpenModalCreateRegister(true);

        setTitleModal(titleModal);
        setTypeModal(typeModal);
        setTypeAction(typeAction)

        typeModal == "update" ? setIdRegister(register.id) : setIdRegister(null);
        typeModal == "update" ? setDescription(register.description) : setDescription("");

    }

    const handleCloseModalCreateRegister = () => {
        setOpenModalCreateRegister(false);
    }

    const handleActionModal = (e) => {
        e.preventDefault();
        switch (typeAction) {
            case "postResult":
                handlePostResults();
                break;
            case "updateResult":
                handleUpdateResult();
                break;
            case "postCauses":
                handlePostCauses();
                break;
            case "updateCauses":
                handleUpdateCauses();
                break;
            case "postConclusions":
                handlePostConclusions();
                break;
            case "updateConclusions":
                handleUpdateConclusion();
                break;

        }
    }

    const handleGetResults = async () => {
        handleOpenLoading();
        try {
            const resp = await MyHealthAndSafetyStatistics.getResults(idHealthAndSafetyStatistic) ?? [];
            setRowsResults(resp);

        } catch (e) {
            handleOpenAlert("Problemas al traer resultados estadísticos", "error");
        }

        setOpenLoading(false);
    }

    const handlePostResults = async () => {
        handleOpenLoading();
        const data = {
            description: description
        }
        try {
            const resp = await MyHealthAndSafetyStatistics.postResults(data, idHealthAndSafetyStatistic);
            await handleGetResults();
            handleOpenAlert("Se agregó el resultado con éxito", "success");
        } catch (e) {
            handleOpenAlert("Ocurrió un error al agregar resultado", "error");
        }

        handleCloseModalCreateRegister();
    }

    const handleUpdateResult = async () => {
        handleOpenLoading();
        const data = {
            description: description
        }

        try {
            const resp = await MyHealthAndSafetyStatistics.updateResult(data, idRegister);
            await handleGetResults();
            handleOpenAlert("Se actualizó el resultado con éxito", "success");
        } catch (e) {
            handleOpenAlert("Ocurrió un error al actualizar", "error");
        }

        handleCloseModalCreateRegister();
    }

    const handleDeleteResult = async () => {
        handleOpenLoading();
        try {
            const resp = await MyHealthAndSafetyStatistics.deleteResult(idRegister);
            await handleGetResults();
            handleOpenAlert("Se eliminó el resultado con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error al eliminar", "error");
        }

        handleCloseModalConfirm();
    }

    const handleGetCauses = async () => {
        handleOpenLoading();

        try {
            const resp = await MyHealthAndSafetyStatistics.getCauses(idHealthAndSafetyStatistic) ?? [];
            setRowsAnalysis(resp);
        } catch (e) {
            handleOpenAlert("Problema al traer el análisis de las causas", "error");
        }

        setOpenLoading(false);
    }

    const handlePostCauses = async () => {
        handleOpenLoading()

        const data = {
            description: description
        }
        try {
            const resp = await MyHealthAndSafetyStatistics.postCauses(data, idHealthAndSafetyStatistic);
            await handleGetCauses();
            handleOpenAlert("Se agregó el análisis de las causas con éxito", "success");
        } catch (e) {
            handleOpenAlert("Ocurrió un error al agregar", "error");
        }

        handleCloseModalCreateRegister();
    }

    const handleUpdateCauses = async () => {
        handleOpenLoading();
        const data = {
            description: description
        }

        try {
            const resp = await MyHealthAndSafetyStatistics.updateCauses(data, idRegister);
            await handleGetCauses();
            handleOpenAlert("Se actualizó las causas con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error al actualizar", "error");
        }

        handleCloseModalCreateRegister();
    }

    const handleDeleteCauses = async () => {
        handleOpenLoading();
        try {
            const resp = await MyHealthAndSafetyStatistics.deleteCauses(idRegister);
            await handleGetCauses();
            handleOpenAlert("Se eliminó las causas con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error al eliminar", "error");
        }
        handleCloseModalConfirm();
    }

    const handleGetConclusions = async () => {
        handleOpenLoading();
        try {
            const resp = await MyHealthAndSafetyStatistics.getConclusions(idHealthAndSafetyStatistic) ?? [];
            setRowsConclusions(resp);
        } catch (e) {
            handleOpenAlert("Problema al traer las conclusiones y recomendación", "error");
        }

        setOpenLoading(false);
    }

    const handlePostConclusions = async () => {
        handleOpenLoading();

        const data = {
            description: description
        }

        try {
            const resp = await MyHealthAndSafetyStatistics.postConclusions(data, idHealthAndSafetyStatistic);
            await handleGetConclusions();
            handleOpenAlert("Se agregó conclusión y recomendación con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error al agregar", "error");
        }

        handleCloseModalCreateRegister();
    }

    const handleUpdateConclusion = async () => {
        handleOpenLoading()
        const data = {
            description: description
        }

        try {
            const resp = await MyHealthAndSafetyStatistics.updateConclusions(data, idRegister);
            await handleGetConclusions();
            handleOpenAlert("Se actualizó la conclusión y recomendación con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error al actualizar", "error");
        }

        handleCloseModalCreateRegister();
    }

    const handleDeleteConclusions = async () => {
        handleOpenLoading();
        try {
            const resp = await MyHealthAndSafetyStatistics.deleteConclusions(idRegister);
            await handleGetConclusions();
            handleOpenAlert("Se eliminó la conclusión y recomendación con éxito", "success");
        } catch (e) {
            handleOpenAlert("Ocurrió un error al eliminar", "error");
        }
        handleCloseModalConfirm();
    }

    const handleGetResponsibles = async () => {
        handleOpenLoading();
        try {
            const resp = await MyHealthAndSafetyStatistics.getResponsibles(idHealthAndSafetyStatistic) ?? [];
            setRowsResponsibles(resp);
        } catch (e) {
            handleOpenAlert("Problema al traer los responsables del registro", "error");
        }
        setOpenLoading(false);
    }

    const handlePostUserResponsible = async (idUser) => {
        handleOpenLoading();

        const data = {
            id_users: idUser
        }

        try {
            const resp = await MyHealthAndSafetyStatistics.postResponsible(data, idHealthAndSafetyStatistic);
            await handleGetResponsibles();
            handleOpenAlert("Se agregó responsable con éxito", "success");
        } catch (e) {
            handleOpenAlert("Ocurrió un error al agregar", "error");
        }

        handleCloseModalCreateResponsible();
    }

    const handleSingResponsible = async () => {
        const data = {
            firm: 1,
            id_users: idUserLogged
        }
        try {
            const resp = await MyHealthAndSafetyStatistics.signResponsible(data, idRegister);
            await handleGetResponsibles();
            handleOpenAlert("Responsable firmó con éxito", "success");
        } catch (e) {
            handleOpenAlert("Ocurrió un error al firmar", "error");
        }
        handleCloseModalConfirm();
    }

    const handleDeleteResponsible = async () => {
        handleOpenLoading();

        try {
            const resp = await MyHealthAndSafetyStatistics.deleteResponsible(idRegister);
            await handleGetResponsibles();
            handleOpenAlert("Se eliminó responsable con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error al eliminar", "error");
        }
        handleCloseModalConfirm();
    }




    const [nroRegis, setNroRegis] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [rowsResults, setRowsResults] = useState([]);
    const [rowsAnalysis, setRowsAnalysis] = useState([]);
    const [rowsConclusions, setRowsConclusions] = useState([]);
    const [rowsResponsibles, setRowsResponsibles] = useState([]);

    const getHealthAndSafetyStatisticsById = async () => {
        handleOpenLoading();
        const resp = await MyHealthAndSafetyStatistics.getHealthAndSafetyStatisticsById(idHealthAndSafetyStatistic);
        setNroRegis(resp.nro_register);
        setStartDate(resp.startDate);
        setEndDate(resp.endDate)

        setOpenLoading(false);
    }

    const handleUpdateHealthAndSafetyStatistics = async () => {
        handleOpenLoading();

        const data = {
            nro_register: nroRegis,
            startDate: startDate,
            endDate: endDate
        }

        try {
            const resp = await MyHealthAndSafetyStatistics.updateHealthAndSafetyStatistics(data, idHealthAndSafetyStatistic);
            await getHealthAndSafetyStatisticsById();
            handleOpenAlert("Se actualizó el registro con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error al actualizar", "error");

        }
    }

    const handleCloseHealthAndSafetyStatistics = async () => {
        handleOpenLoading();

        const data = {
            register_status: 2
        }

        try {
            const resp = await MyHealthAndSafetyStatistics.updateHealthAndSafetyStatistics(data, idHealthAndSafetyStatistic);
            await getHealthAndSafetyStatisticsById();
            handleOpenAlert("Se cerró registro con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error al cerrar registro", "error");
        }
    }

    // Dialogos de confirmacion INICIO

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");

    const handleOpenModalConfirm = (
        id,
        titleText,
        contentText,
        buttonTextConfirm,
        buttonActionType
    ) => {
        setIdRegister(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    };

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case "deleteResult":
                handleDeleteResult();
                break;
            case "deleteCauses":
                handleDeleteCauses();
                break;
            case "deleteConclusions":
                handleDeleteConclusions();
                break;
            case "deleteResponsible":
                handleDeleteResponsible();
                break;
            case "singResponsible":
                handleSingResponsible();
                break;
        }
    }

    useEffect(() => {
        getHealthAndSafetyStatisticsById();
        handleGetResults();
        handleGetCauses();
        handleGetConclusions();
        handleGetResponsibles();
        handleGetUsers();
    }, [])


    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack="" />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <SubtitleText
                                text={"Registros"}
                                color={colorTitle}
                            ></SubtitleText>
                            <TitleText
                                text={"Registros de estadísticas de seguridad y salud en el trabajo"}
                            ></TitleText>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={8} sm={8} md={8} lg={4} xl={4} mt={2}>
                            <InputControlaVertical
                                text={"Nro de registro:"}
                                inputType={"text"}
                                inputValue={setNroRegis}
                                value={nroRegis}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={4} xl={4} mt={2}>
                            <InputControlaVertical
                                text={"Fecha de Inicio:"}
                                inputType={"date"}
                                inputValue={setStartDate}
                                value={startDate}
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={4} xl={4} mt={2}>
                            <InputControlaVertical
                                text={"Fecha fin:"}
                                inputType={"date"}
                                inputValue={setEndDate}
                                value={endDate}
                                readOnly={readOnly}
                            />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ marginTop: "4px" }}>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "18px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Resultados estadísticos
                            </InputLabel>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ color: "#F2994A", ml: 1 }}
                            >
                                Este campo es obligatorio
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right" mt={2}>
                            {
                                readOnly ? "" : (
                                    <ButtonControla
                                        roles={permissions.DOC_REGIS_STATISTICS_RECORD_AGREGAR_RESULT}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar resultados"}
                                        functionOnClick={() => handleOpenModalCreateRegister(
                                            "Agregar resultado estadístico",
                                            "create",
                                            "postResult",
                                            null
                                        )}
                                    />
                                )
                            }

                        </Grid>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                color: "#305ad9",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Descripción
                                        </TableCell>
                                        {
                                            readOnly ? "" : (
                                                <TableCell></TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        rowsResults.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                hover
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.description}
                                                </TableCell>
                                                {
                                                    readOnly ? "" : (
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <>
                                                                    <ButtonIconControla
                                                                        icon={
                                                                            <BorderColorOutlined
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"}
                                                                        textTooltip={"Editar"}
                                                                        functionOnClick={() => handleOpenModalCreateRegister(
                                                                            "Actualizar resultados estadísticos",
                                                                            "update",
                                                                            "updateResult",
                                                                            row
                                                                        )}
                                                                    />
                                                                    <ButtonIconControla
                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={() => handleOpenModalConfirm(
                                                                            row.id,
                                                                            "¿Estás seguro de eliminar el resultado estadístico?",
                                                                            "Una vez eliminado no se podrá recuperar la información.",
                                                                            "Si, eliminar",
                                                                            "deleteResult"
                                                                        )}
                                                                    />
                                                                </>
                                                            </Stack>
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>


                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "18px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Análisis de las causas que originaron las desviaciones
                            </InputLabel>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ color: "#F2994A", ml: 1 }}
                            >
                                Este campo es obligatorio
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right" mt={2}>
                            {
                                readOnly ? "" : (
                                    <ButtonControla
                                        roles={permissions.DOC_REGIS_STATISTICS_RECORD_AGREGAR_DATOS}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar datos"}
                                        functionOnClick={() => handleOpenModalCreateRegister(
                                            "Agregar análisis de las causas",
                                            "create",
                                            "postCauses",
                                            null
                                        )}
                                    />

                                )
                            }
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                color: "#305ad9",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Descripción
                                        </TableCell>
                                        {
                                            readOnly ? "" : (
                                                <TableCell></TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        rowsAnalysis.map((a) => (
                                            <TableRow
                                                key={a.id}
                                                hover
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {a.description}
                                                </TableCell>
                                                {
                                                    readOnly ? "" : (
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <>
                                                                    <ButtonIconControla
                                                                        icon={
                                                                            <BorderColorOutlined
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"}
                                                                        textTooltip={"Editar"}
                                                                        functionOnClick={() => handleOpenModalCreateRegister(
                                                                            "Actualizar las causas",
                                                                            "update",
                                                                            "updateCauses",
                                                                            a
                                                                        )}
                                                                    />
                                                                    <ButtonIconControla
                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={() => handleOpenModalConfirm(
                                                                            a.id,
                                                                            "¿Estás seguro de eliminar el análisis de las causas?",
                                                                            "Una vez eliminado no se podrá recuperar la información.",
                                                                            "Si, eliminar",
                                                                            "deleteCauses"
                                                                        )}
                                                                    />
                                                                </>
                                                            </Stack>
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "18px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Conclusiones y recomendaciones
                            </InputLabel>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ color: "#F2994A", ml: 1 }}
                            >
                                Este campo es obligatorio
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right" mt={2}>
                            {
                                readOnly ? "" : (
                                    <ButtonControla
                                        roles={permissions.DOC_REGIS_STATISTICS_RECORD_AGREGAR_DATOS}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar datos"}
                                        functionOnClick={() => handleOpenModalCreateRegister(
                                            "Agregar conclusiones y recomendaciones",
                                            "create",
                                            "postConclusions",
                                            null
                                        )}
                                    />
                                )
                            }
                        </Grid>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                color: "#305ad9",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Descripción
                                        </TableCell>
                                        {
                                            readOnly ? "" : (
                                                <TableCell></TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        rowsConclusions.map((c) => (
                                            <TableRow
                                                key={c.id}
                                                hover
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {c.description}
                                                </TableCell>
                                                {
                                                    readOnly ? "" : (
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <>
                                                                    <ButtonIconControla
                                                                        icon={
                                                                            <BorderColorOutlined
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"}
                                                                        textTooltip={"Editar"}
                                                                        functionOnClick={() => handleOpenModalCreateRegister(
                                                                            "Actualizar conclusión y recomendación",
                                                                            "update",
                                                                            "updateConclusions",
                                                                            c
                                                                        )}
                                                                    />

                                                                    <ButtonIconControla
                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={() => handleOpenModalConfirm(
                                                                            c.id,
                                                                            "¿Estás seguro de eliminar la conclusión y recomendación?",
                                                                            "Una vez eliminado no se podrá recuperar la información.",
                                                                            "Si, eliminar",
                                                                            "deleteConclusions"
                                                                        )}
                                                                    />
                                                                </>
                                                            </Stack>
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>

                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                    >
                        <Box>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "18px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Responsables del registro
                            </InputLabel>
                            <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ color: "#F2994A", ml: 1 }}
                            >
                                Este campo es obligatorio
                            </Typography>
                        </Box>
                        {
                            readOnly ? "" : (
                                <Box>
                                    <ButtonControla
                                        roles={permissions.DOC_REGIS_STATISTICS_RECORD_AGREGAR_RESPON}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar responsables"}
                                        functionOnClick={handleOpenModalCreateResponsible}
                                    />
                                </Box>
                            )
                        }
                    </Stack>

                    <Grid container sx={{ marginTop: "4px" }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                color: "#305ad9",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Nombre y Apellido
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: "#305ad9",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Cargo
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: "#305ad9",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Fecha de firma
                                        </TableCell>

                                        <TableCell
                                            sx={{
                                                color: "#305ad9",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Firma
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: "#305ad9",
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        rowsResponsibles.map((res) => (
                                            <TableRow
                                                hover
                                                key={res.id}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {res.first_name + res.last_name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {res.position_name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {res.firm_date == null ? '' : res.firm_date.substring(0, 19)}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {
                                                        res.firm == 0 ? (
                                                            idUserLogged == res.id_users && !readOnly ? (
                                                                <ButtonIconControla
                                                                    icon={
                                                                        <HistoryEduOutlined sx={{ color: "white" }} />
                                                                    }
                                                                    backgroundColor={"#F1C40F"}
                                                                    backgroundColorHover={"#F4D03F"}
                                                                    textTooltip={"Firmar"}
                                                                    functionOnClick={() => handleOpenModalConfirm(
                                                                        res.id,
                                                                        "Sus datos quedarán registrados de forma permanente",
                                                                        "¿Desea firmar?",
                                                                        "Firmar",
                                                                        "singResponsible"
                                                                    )}
                                                                />
                                                            ) : "No Firmado"
                                                        ) : (
                                                            <img src={res.firm_url} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                                        )
                                                    }
                                                </TableCell>
                                                {
                                                    readOnly ? "" : (
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <>
                                                                    <ButtonIconControla
                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={() => handleOpenModalConfirm(
                                                                            res.id,
                                                                            "¿Estás seguro de eliminar el responsable?",
                                                                            "Una vez eliminado no se podrá recuperar los datos del trabajador responsable",
                                                                            "Si, eliminar",
                                                                            "deleteResponsible"
                                                                        )}
                                                                    />
                                                                </>
                                                            </Stack>
                                                        </TableCell>
                                                    )
                                                }

                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        sx={{
                            display: "flex",
                            whidth: "100%",
                            justifyContent: "space-evenly",
                            paddingY: 4,
                        }}
                    >
                        <Grid item xs={12} md={2}>
                            <ButtonControla
                                iconButton={<ArrowBack sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textButton={"Regresar"}
                                isNavegationOrigin={true}
                                url={"/documentation/registers/generals/statisticaldata/occupationalHealthAndSafetyStatistics"}
                            />
                        </Grid>
                        {
                            readOnly ? "" : (

                                <>
                                    <Grid item xs={12} md={2}>
                                        <ButtonControla
                                            roles ={ permissions.DOC_REGIS_STATISTICS_RECORD_UPDATE}
                                            iconButton={<EditOutlined sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Actualizar registro"}
                                            functionOnClick={handleUpdateHealthAndSafetyStatistics}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={2}>
                                        <ButtonControla
                                            roles={permissions.DOC_REGIS_STATISTICS_RECORD_CERRAR_REGIS}
                                            iconButton={<CloseIcon fontSize="inherit" />}
                                            backgroundColor={"black"}
                                            backgroundColorHover={"#2C2B29"}
                                            textButton={"Cerrar registro"}
                                            functionOnClick={handleCloseHealthAndSafetyStatistics}
                                        />
                                    </Grid>

                                </>
                            )
                        }



                    </Grid>
                </Box>
            </Container>

            {/* modal de resultados */}
            <Dialog
                open={openModalCreateRegister}
                onClose={handleCloseModalCreateRegister}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText={titleModal}
                    functionOnClose={handleCloseModalCreateRegister}
                />
                <form onSubmit={handleActionModal}>
                    <DialogContent>
                        <InputControlaVertical
                            text={"Descripción:"}
                            inputType={"textArea"}
                            inputValue={setDescription}
                            value={description}
                            modalType={true}
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalCreateRegister}
                            />
                            <ButtonControla
                                textButton={typeModal == "update" ? "Actualizar" : "Crear"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>

            </Dialog>

            {/* modal de agregar responsable */}
            <Dialog
                open={openModalCreateResponsible}
                onClose={handleCloseModalCreateResponsible}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Seleccionar responsable del registro"
                    functionOnClose={handleCloseModalCreateResponsible}
                />
                <DialogContent sx={{ paddingTop: "0" }}>
                    <SearchUsersControla
                        rowsUser={rowsUsers}
                        handleUsersActionButton={handlePostUserResponsible}
                    />
                </DialogContent>
                <DialogActions>
                    <Stack direction={"row"} spacing={2}>
                        <ButtonControla
                            textButton={"Cancelar"}
                            backgroundColor={"#CBCBFF"}
                            functionOnClick={handleCloseModalCreateResponsible}
                        />
                    </Stack>
                </DialogActions>
            </Dialog>


        </Box>

    )

}

export default Edit2;