import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import SideNav from "../components/sidenav/sideNav";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import MyBaseline from "../api/MyBaseline";
import MyConfigurationApi from "../api/MyConfigurationApi";
import ButtonControla from "../components/buttons/buttonController";
import { useAppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const grey = "#9191B0";
const blue = "#034AFF";

const BorderLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
});

const StyledPercentageText = styled(Typography)({
  color: "9191B0",
  fontSize: 12,
});

function BaselineAnswers() {
  const { permissions } = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate()
  const id_baseline = params.get("id_baseline");
  const defaultPercentage = {
    percentage: 0,
  };

  const [finishModal, setFinishModal] = useState(false);
  const handleOpenFinishModal = () => {
    setFinishModal(true);
  };
  const handleCloseFinishModal = () => {
    setFinishModal(false);
  };
  const [baselineLineaments, setBaselineLineaments] = useState([]);
  const [baselineName, setBaselineName] = useState("Linea Base");
  const [userName, setUserName] = useState("nombre");

  const [baselineActiveRows, setBaselineActiveRows] = useState([]);
  const [currentBaselineName, setCurrentBaselineName] = useState("");

  const [completionPercentage, setCompletionPercentage] = useState(
    () => defaultPercentage
  );

  const handleGetBaselineLineaments = async () => {
    const response = await MyBaseline.companyLineaments({
      idLineaments: id_baseline,
    });
    setBaselineLineaments(response);
  };

  const handleGetBaselineDiagnosis = async () => {
    const response = await MyBaseline.baselineDiagnosis();

    if (response.success === false) {
      //console.log("error no se puedo traer lois diagnósticos")
    } else {
      // console.log(window.localStorage.getItem('userToken'));
      const activeBaselines = response.baseLine_current;
      console.log(activeBaselines);
      setCurrentBaselineName(activeBaselines[0]?.name);
    }
    return response;
  };

  const handleGetBaselineName = async () => {
    const response = await MyBaseline.editBaseline({ idBaseline: id_baseline });
    console.log(response);
    const fullName =
      MyConfigurationApi.userData().first_name +
      " " +
      MyConfigurationApi.userData().last_name;
    setUserName(fullName);
    setBaselineName(response.name);
  };

  const handleGetCompletionPercentage = async () => {
    const response = await MyBaseline.completionPercentage({
      idBaseline: id_baseline,
    });
    setCompletionPercentage(response);
  };

  const handleBaselineResults = async () => {
    const response = await MyBaseline.baselineResults({
      idBaseline: id_baseline,
    });
    navigate("/baseline/results?id_baseline=" + id_baseline)
    console.log(response);
  };

  useEffect(() => {
    handleGetBaselineDiagnosis();
    handleGetBaselineLineaments();
    handleGetCompletionPercentage();
    handleGetBaselineName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <SideNav returnBack="/baseline/diagnosis" />
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item md={12}>
                <SubtitleText text={"Línea base"} color={grey}></SubtitleText>
                <TitleText text={baselineName}></TitleText>
              </Grid>
              <Grid item md={7}>
                <Box sx={{ width: "100%" }}>
                  <BorderLinearProgress
                    variant="determinate"
                    color={
                      completionPercentage.percentage <= 39
                        ? "error"
                        : completionPercentage.percentage <= 99 &&
                          completionPercentage.percentage > 40
                        ? "warning"
                        : "success"
                    }
                    value={completionPercentage.percentage}
                  />
                </Box>
              </Grid>
              <Grid item md={1}>
                <StyledPercentageText>
                  {completionPercentage.percentage + "%"}
                </StyledPercentageText>
              </Grid>
              <Grid item md={4}>
                {completionPercentage.percentage === 100 ? (
                  <ButtonControla
                    roles={permissions.LINE_DIAG_PROC_FINALIZAR}
                    textButton={"Finalizar"}
                    backgroundColor={"#EB5757"}
                    backgroundColorHover={"#FF4040"}
                    functionOnClick={() => handleOpenFinishModal()}
                  >
                    Finalizar
                  </ButtonControla>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item md={12}>
                <TitleText
                  text={
                    "Lista de evaluación de sistema de gestión de seguridad y salud"
                  }
                />
              </Grid>
              <Grid item md={8}>
                <TitleText text={"Lineamientos"} />
              </Grid>
              <Grid item md={4} justifyContent="right"></Grid>
              <Grid container item rowSpacing={2} columnSpacing={2}>
                {baselineLineaments.map((baselineLineament, key) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={key}>
                    <Card
                      sx={{
                        maxWidth: "100%",
                        borderRadius: "14px",
                        minHeight: 230,
                        display: "flex",
                        textAlign: "center",
                        alignContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        (window.location.href =
                          "answerstwo?id_baseline_companies_lineaments=" +
                          baselineLineament.id_baseline_companies_lineaments +
                          "&id_baseline=" +
                          id_baseline)
                      }
                    >
                      <CardContent
                        sx={{
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <Grid container gap={4}>
                          <Grid item sm={12}>
                            <Typography
                              color={blue}
                              fontSize={20}
                              fontWeight={600}
                            >
                              {baselineLineament
                                .baseline_companie_lineament[0] +
                                baselineLineament.baseline_companie_lineament
                                  .slice(1)
                                  .toLowerCase()}
                            </Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Box
                              sx={{
                                position: "relative",
                                display: "inline-flex",
                              }}
                            >
                              <CircularProgress
                                variant="determinate"
                                value={baselineLineament.percentage}
                                size="4rem"
                                color={
                                  baselineLineament.percentage <= 39
                                    ? "error"
                                    : baselineLineament.percentage <= 99 &&
                                      baselineLineament.percentage > 40
                                    ? "warning"
                                    : "success"
                                }
                                sx={{
                                  boxShadow: "inset 0 0 0 5.5px #d1d1d1",
                                  borderRadius: "50%",
                                }}
                              />
                              <Box
                                sx={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: 0,
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color={
                                    baselineLineament.percentage < 39
                                      ? "red"
                                      : baselineLineament.percentage < 99 &&
                                        baselineLineament.percentage > 40
                                      ? "orange"
                                      : "green"
                                  }
                                >
                                  {baselineLineament.percentage === 100
                                    ? "100%"
                                    : baselineLineament.percentage + "%"}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Dialog
              open={finishModal}
              onClose={handleCloseFinishModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" color={blue}>
                {"¿Esta seguro de finalizar?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Al confirmar la acción este archivo se convertirá en el
                  diagnóstico línea base vigente.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseFinishModal}>Cancelar</Button>
                <Button onClick={handleBaselineResults} autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default BaselineAnswers;
