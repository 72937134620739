import * as React from "react";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import Container from "@mui/material/Container";
import SideNav from "../components/sidenav/sideNav";
import Box from "@mui/material/Box";

import { CircularProgress, Grid } from "@mui/material";
import "../index.css";
import CardControla from "../components/cards/cardControla";
import BannerButtonControla from "../components/cards/bannerButtonControla";
import BannerControla from "../components/cards/bannerControla";
import { useAppContext } from "../context/AppContext";

const colorTitle = "#9191B0";
// Recuerda agendar la reunión mensual de tu Comité

function Index() {
  const { configurationModules } = useAppContext();
  return (
    <Box sx={{ display: "flex" }}>
      <SideNav returnBack="/" />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/* <SubtitleText
                                text={"Configuracion"}
                                color={colorTitle}
                            ></SubtitleText> */}
              <TitleText text={"Configuración"} color={colorTitle}></TitleText>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/* <BannerControla color1={'#3863FB'} color2={'#161A78'} text={'Mantén en orden la organización de tu empresa'} /> */}
              <BannerButtonControla
                color1={"#3863FB"}
                color2={"#161A78"}
                title={"Mantén en orden la organización de tu empresa"}
                textButton={"Comenzar ahora"}
                image={"/assets/images/banners/configuration/banner-main.png"}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/company"}
                text={"Empresa"}
                img={"/assets/images/banners/configuration/empresa.png"}
              />
            </Grid>
           
            {/* <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/campus"}
                text={"Sedes"}
                img={"/assets/images/errors/imgNotImage.jpg"}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/registers"}
                text={"Registros"}
                img={"/assets/images/errors/imgNotImage.jpg"}
              />
            </Grid> */}
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/areas"}
                text={"Áreas"}
                img={"/assets/images/banners/configuration/areas.png"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/positions"}
                text={"Cargos Ocupacionales"}
                img={"/assets/images/banners/configuration/cargos ocupacionales.png"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/users"}
                text={"Usuarios"}
                img={"/assets/images/banners/configuration/usuarios.png"}
              />
            </Grid>
            {<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/password"}
                text={"Contraseña"}
                img={"/assets/images/banners/configuration/informacion-general.png"}
              />
          </Grid>}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Index;
