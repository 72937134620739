import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
    Alert,
    Backdrop,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Stack,
    Snackbar,
} from '@mui/material';
import {
    Add,
    Delete,
    BorderColorOutlined,
} from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import LockIcon from '@mui/icons-material/Lock';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import SideNav from "../../../components/sidenav/sideNav";
import SubtitleText from "../../../components/text/subtitleText";
import TitleText from "../../../components/text/titleText";
import BannerControla from '../../../components/cards/bannerControla';
import ButtonControla from "../../../components/buttons/buttonController";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import MyProceedingsBook from '../../../api/MyProceedingsBook';
import MyMeeting from '../../../api/MyMeetings';
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import MyConfigurationApi from '../../../api/MyConfigurationApi';
import DialogInformativeControla from '../../../components/dialog/dialogInformativeControla';
import LoadingControla from '../../../components/load/loadingControla';
import ButtonMailboxControla from '../../../components/buttons/buttonMailboxControla';

import { useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext';
import MyUsers from '../../../api/MyUsers';

const blue = "#034AFF";
const purple = "#8B8BD8";
const colorTitle = "#9191B0";

function Proceeding() {
    const { permissions } = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();

    const typeBook = location.state.typeBook;

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //fin de alerts

    // *modal de confirmación *
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [idProceeding, setIdProceeding] = useState("");

    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, buttonActionType, idProceeding) => {
        setOpenModalConfirm(true);

        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setIdProceeding(idProceeding);
    }
    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }
    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case "delete":
                handleDeleteProceeding();
                break;
            case "close":
                handleCloseProceeding();
                break;
            case "publish":
                handlePublishProceeding();
                break;
        }
        handleCloseModalConfirm();
    }

    const [openModalListMeeting, setOpenModalListMeeting] = useState(false);

    const handleOpenModalListMeeting = () => {
        setOpenModalListMeeting(true);
    }

    const handleCloseModalListMeeting = () => {
        setOpenModalListMeeting(false);
    }

    const [proceedings, setProceedings] = useState([]);
    const [currentMeeting, setcurrentMeeting] = useState([]);

    const getProceedingsByIdBook = async () => {
        const response = await MyProceedingsBook.getMinutesByIdBook(location.state.id_book);
        setProceedings(response.data);
    }

    const handleAddScheduledProceeding = async () => {
        handleOpenLoading();

        //obtener fecha hoy
        const date = new Date();
        const today = date.getDate();
        const month = date.getMonth() + 1;
        const dateProceeding = `${date.getFullYear()}-${month.toString().length === 1 ? "0" + month.toString() : month}-${today.toString().length === 1 ? "0" + today : today}`;


        //buscar reunión notificada con fecha de hoy
        const response = await MyMeeting.getMeeting();
        const meeting = response.data.filter((m) => m.date === dateProceeding & m.status === 2);

        //traer actas del libro
        const res = await MyProceedingsBook.getMinutesByIdBook(location.state.id_book);

        meeting.forEach((m, index) => {
            res.data.forEach((pro) => {
                if (pro.id_committee_meeting === m.id_committee_meeting) {
                    delete (meeting[index]);
                }
            });
        });

        const meetWhitOutProceeding = meeting.filter((m) => m !== undefined);

        setcurrentMeeting(meetWhitOutProceeding);
        setValue(null);
        setOpenLoading(false);
        handleOpenModalListMeeting();
    }

    const handleDeleteProceeding = async () => {
        handleOpenLoading();

        const res = await MyProceedingsBook.deleteProceeding(idProceeding);
        if (res.success === true) {
            getProceedingsByIdBook();
            handleOpenAlert("Se eliminó el acta con éxito", "success");

        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
        getProceedingsByIdBook();

    }

    const handleCloseProceeding = async () => {
        handleOpenLoading();

        const res = await MyProceedingsBook.getParticipantsByProceedingSmallCompany(idProceeding);
        console.log(res)

        let signedSupervisor = false;

        // verificar que supervisor firmò para cerrar el acta
        res.data.forEach(p => {
            if (p.roles.some((r) => r == 13)) {
                signedSupervisor = (p.signature == 1);
            }
        });
        ;
        console.log(signedSupervisor);

        if (signedSupervisor) {
            //si se puede cerrar acta
            const dataStatusProceeding = {
                status: 2
            }
            const resp = await MyProceedingsBook.updateProceedings(dataStatusProceeding, idProceeding);

            if (resp.success === true) {
                getProceedingsByIdBook();
                handleOpenAlert("Acta cerrada con éxito", "success");
            } else {
                handleOpenAlert("Ocurrió un error","error");
            }

        } else {
            //mostrar modal de que no se puede cerrar acta
            setOpenLoading(false);
            setOpenModalInformative(true);
            setMessageModalInformative("No se puede cerrar el acta, Supervisor de Seguridad y Salud Ocupacional no firmó");
        }
     
    }

    const handlePublishProceeding = async () => {
        handleOpenLoading();
        const statusProceeding = {
            id_status_request: 3,
            is_small_company: true
        }
        const r = await MyProceedingsBook.updateStatusProceeding(statusProceeding, idProceeding);
        if (r.success === true) {
            getProceedingsByIdBook();
            handleOpenAlert("Se notificó el acta con éxito", "success");

        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleAddProceedingsWithoutMeeting = async () => {
        handleOpenLoading();


        if (proceedings.some((proceeding) => proceeding.status == 1)) {
            setOpenModalInformative(true);
            setMessageModalInformative("Debe cerrar el acta anterior para poder generar nueva acta en el sistema");

        } else {
            // solo generar acta sin reunión con estado cerrado

            const data_proceeding = {
                id_committe_meeting: null,
                id_minute_book: location.state.id_book
            }
            const res = await MyProceedingsBook.createProceedings(data_proceeding);

            //generar el responsable de acta (supervisor actual)
            const resp = await MyUsers.getUsersByRole(13);

            const dataMinute = {
                responsible: resp[0].id_users
            }
            //actualizar acta
            const r = await MyProceedingsBook.updateProceedings(dataMinute, res.data.id_minutes);

            //registrar participantes del acta (gerente y supervisor)
            const data_idProceeding = {
                id_minutes: res.data.id_minutes
            }
            const response = await MyProceedingsBook.postParticipantsProceedingSmallCompany(data_idProceeding);

            if (res.success === true && response.success == true) {
                const params = {
                    readOnly: false
                }
                navigate(`/proceedingBook/editProceeding?id_proceeding=${res.data.id_minutes}`, { state: params });
            } else {
                handleOpenAlert("Ocurrió un error", "error");
            }

        }

        setOpenLoading(false);
    }

    const [value, setValue] = useState("");

    const handleChangeMeeting = (event) => {
        setValue(event.target.value);
    }

    const getUseCreated = () => {
        return MyConfigurationApi.userData();
    }

    const handlePostProceedingWithMeeting = async (event) => {
        event.preventDefault();
        //value => id de la reunión seleccionada

        if (value === null) {

        } else {
            handleOpenLoading();
            //verificar si existe un acta que no está cerrada
            if (proceedings.some((proceeding) => proceeding.status == 1)) {
                setOpenLoading(false);
                handleCloseModalListMeeting();
                setOpenModalInformative(true);
                setMessageModalInformative("Debe cerrar el acta anterior para poder generar nueva acta en el sistema");
            } else {
                //crear acta para la reunión
                const idProceedingsMeeting = {
                    id_committe_meeting: value,
                    id_minute_book: location.state.id_book
                }
                const resp = await MyProceedingsBook.createProceedings(idProceedingsMeeting);


                //generar responsable del acta
                const resResponsible = await MyUsers.getUsersByRole(13);
                const dataMinute = {
                    responsible: resResponsible[0].id_users
                }
                //actualizar acta
                const r = await MyProceedingsBook.updateProceedings(dataMinute, resp.data.id_minutes);

                //registrar participantes al acta
                const data_idProceeding = {
                    id_minutes: resp.data.id_minutes
                }
                const response = await MyProceedingsBook.postParticipantsProceedingSmallCompany(data_idProceeding);

                if (resp.success == true && response.success == true) {
                    const params = {
                        readOnly: false
                    }
                    navigate("/proceedingBook/editProceeding?id_proceeding=" + resp.data.id_minutes, { state: params });

                } else {
                    handleOpenAlert("Ha ocurrido un error", "error");
                }
            }
        }
    }

    //modal de información 
    const [openModalInformative, setOpenModalInformative] = useState(false);
    const [messageModalInformative, setMessageModalInformative] = useState("");

    const handleCloseModalInformative = () => {
        setOpenModalInformative(false);
    }

    useEffect(() => {
        getProceedingsByIdBook();
    }, []);

    return (
        <Box sx={{ display: "flex" }}>
            <SideNav returnBack="" />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        {/* <Grid item xs={12} md={12} lg={12} >
                            <ButtonMailboxControla
                                originUrl={"/proceedingBook/proceeding"}
                            />
                        </Grid> */}

                        <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image="/assets/images/banners/committee/bannerActas.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Actas"
                            />
                        </Grid>
                    </Grid>
                    {
                        typeBook === "archivedBook" ? (
                            <Grid container direction={"row"} justifyContent={"center"}>
                                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                                    <TitleText text={"Libro de actas : " + location.state.name_book}></TitleText>
                                    <SubtitleText
                                        text={`Fecha de inicio: ${location.state.date_created} -  Fecha de fin: ${location.state.end_date}`}
                                        color={colorTitle}
                                    ></SubtitleText>
                                </Grid>
                            </Grid>

                        ) : ""
                    }

                    {
                        typeBook === "currentBook" ? (
                            <Grid container sx={{ mt: 5 }}>
                                <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
                                    <Stack direction={"row"} spacing={2}>
                                        <Box>
                                            <ButtonControla
                                                iconButton={<Add sx={{ color: "white" }} />}
                                                textButton={"Agregar acta programada"}
                                                backgroundColor={"#2D9CDB"}
                                                backgroundColorHover={"#1976d2"}
                                                functionOnClick={handleAddScheduledProceeding}
                                            />
                                        </Box>
                                        <Box>
                                            <ButtonControla
                                                iconButton={<Add sx={{ color: "white" }} />}
                                                backgroundColor={"#1cb591"}
                                                backgroundColorHover={"#169073"}
                                                textButton={"Agregar acta no programada"}
                                                tooltipText={"Usar sino programaste fecha en sistema"}
                                                functionOnClick={() => { handleAddProceedingsWithoutMeeting() }}
                                            />
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>

                        ) : ""
                    }

                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: "hidden" }}>
                                <TableContainer>
                                    <Table
                                        stickyHeader
                                        sx={{ width: "100%", minWidth: "700px" }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Fecha
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Tipo de sesión
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Estado
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Acciones
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {proceedings.map((proceeding, index) => (
                                                <TableRow key={index}>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {proceeding.date === null ? "--" : proceeding.date}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {proceeding.session === "1" ? "Ordinario" : (proceeding.session === "2" ? "Extraordinario" : "--")}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {proceeding.status === 1 ? "Creado" : (proceeding.status === 2 ? "Cerrado" : "notificado")}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="right"
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignContent="center"
                                                        >
                                                            {
                                                                proceeding.status === 1 && typeBook === "currentBook" ? (
                                                                    <ButtonIconControla
                                                                        icon={
                                                                            <BorderColorOutlined
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"}
                                                                        textTooltip={"Editar"}
                                                                        isNavegationOrigin={true}
                                                                        directionUrl={"/proceedingBook/editProceeding?id_proceeding=" + proceeding.id_minutes}
                                                                        paramsIntUrl={{readOnly: false}}
                                                                    />

                                                                ) : ""
                                                            }

                                                            <ButtonIconControla
                                                                icon={
                                                                    <VisibilityOutlined
                                                                        sx={{ color: "white" }}
                                                                    />
                                                                }
                                                                backgroundColor={"#305AD9"}
                                                                backgroundColorHover={"#0E4AFF"}
                                                                textTooltip={"Ver"}
                                                                functionOnClick={() => {
                                                                    const params = {
                                                                        readOnly: true
                                                                    }
                                                                    navigate("/proceedingBook/editProceeding?id_proceeding=" + proceeding.id_minutes, { state: params });
                                                                }}
                                                            />
                                                            {
                                                                proceeding.status === 2 && typeBook === "currentBook" ? (
                                                                    <ButtonIconControla

                                                                        icon={
                                                                            <FileUploadOutlinedIcon
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#169073"}
                                                                        backgroundColorHover={"#1AA483"}
                                                                        textTooltip={"Notificar"}
                                                                        functionOnClick={() => {
                                                                            handleOpenModalConfirm(
                                                                                "¿Estás seguro de notificar esta acta?",
                                                                                "Una vez notificado no podrás editar el acta",
                                                                                "Sí, notificar",
                                                                                "publish",
                                                                                proceeding.id_minutes
                                                                            );
                                                                        }}
                                                                    />
                                                                ) : ""
                                                            }

                                                            {
                                                                proceeding.status === 1 && typeBook === "currentBook" ? (
                                                                    <ButtonIconControla

                                                                        icon={<LockIcon sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Cerrar Acta"}
                                                                        functionOnClick={() => {
                                                                            handleOpenModalConfirm(
                                                                                "¿Estás seguro de cerrar esta acta?",
                                                                                "Una vez cerrada no podrás editar el acta",
                                                                                "Sí, cerrar",
                                                                                "close",
                                                                                proceeding.id_minutes
                                                                            );
                                                                        }}
                                                                    />

                                                                ) : ""
                                                            }
                                                            {
                                                                proceeding.status === 1 && typeBook === "currentBook" ? (
                                                                    <ButtonIconControla

                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={() => {
                                                                            handleOpenModalConfirm(
                                                                                "¿Estás seguro de eliminar esta acta?",
                                                                                "Una vez eliminada no podrás recuperar el acta",
                                                                                "Sí, eliminar",
                                                                                "delete",
                                                                                proceeding.id_minutes
                                                                            );

                                                                        }}
                                                                    />

                                                                ) : ""

                                                            }


                                                        </Stack>

                                                    </TableCell>
                                                </TableRow>
                                            ))}


                                        </TableBody>
                                    </Table>

                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            {/* modal de lista de reuniones notificadas de fecha del sistema */}
            <Dialog
                open={openModalListMeeting}
                onClose={handleCloseModalListMeeting}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText={"Lista de reuniones programadas para hoy"}
                    functionOnClose={handleCloseModalListMeeting}
                />
                <form onSubmit={handlePostProceedingWithMeeting}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        {
                            currentMeeting.length === 0 ? (
                                <Typography
                                    id="modal-modal-title"
                                    sx={{
                                        fontSize: '18px',
                                        color: "#1638F2",
                                    }}
                                >
                                    No existe reunión agendada para hoy o ya existen actas generadas
                                </Typography>

                            ) : (
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group"
                                        sx={{
                                            fontSize: '18px',
                                            color: "#1638F2",
                                        }}
                                    >Seleccione solo una de las reuniones para generar acta:</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChangeMeeting}
                                    >
                                        {currentMeeting.map((p, index) => (
                                            <FormControlLabel key={index}
                                                sx={{
                                                    fontSize: '18px',
                                                    color: "#1638F2",
                                                }}
                                                value={p.id_committee_meeting} control={<Radio required={value === null ? true : false} />} label={p.description} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            )
                        }

                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalListMeeting}
                                />
                            </Box>
                            {
                                currentMeeting.length === 0 ? "" : (
                                    <Box>
                                        <ButtonControla
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Crear acta"}
                                            typeButton={"submit"}
                                        />
                                    </Box>

                                )
                            }
                        </Stack>
                    </DialogActions>

                </form>
            </Dialog>

            {/* modal de información */}
            <DialogInformativeControla
                open={openModalInformative}
                onClose={handleCloseModalInformative}
                message={messageModalInformative}
            />

        </Box>
    )

}
export default Proceeding;