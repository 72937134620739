import { React, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import SideNav from "../components/sidenav/sideNav";
import Container from '@mui/material/Container';
import { 
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Stack,
    InputLabel
 } from '@mui/material';
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import Link from '@mui/material/Link';

import BannerControla from '../components/cards/bannerControla';
import LoadingControla from '../components/load/loadingControla';
import TableControla from "../components/tables/table-controla";
import ListSuggestions from "./components/listSuggestions";
import MySuggestionsMailbox from "../api/MySuggestionsMailbox";
import DialogTitleControla from "../components/dialog/dialogTitleControla";
import ButtonControla from "../components/buttons/buttonController";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import ButtonIconControla from "../components/buttons/buttonIconControla";

const purple = "#8B8BD8";

function SuggestionsMailbox (){

 // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  //modal ver detalle sugerencia
  const [openModalSeeDetail, setOpenModalSeeDetail]= useState(false);

  const handleOpenModalSeeDetail =  ()=>{
    //limpiar input
    setDescription("");
    setUser("");
    setCompany("");
    setDateCreate("");
    setDocumentUrl("");
    setUrl("");

    setOpenModalSeeDetail(true);
  }

  const handleCloseModalSeeDetail =()=>{
    setOpenModalSeeDetail(false);
  }

  const [description, setDescription] = useState("");
  const [url, setUrl]= useState("");
  const [documentUrl, setDocumentUrl]= useState("");
  const [user, setUser]= useState("");
  const [company, setCompany]= useState("");
  const [dateCreate, setDateCreate] = useState("");
  //

  const [suggestions, setSuggestions] = useState([]);

  const getSuggestions = async() =>{
    handleOpenLoading();
    const resp = await MySuggestionsMailbox.getSuggestions();
    setSuggestions(resp);
    
    setOpenLoading(false);
  }

  const handleGetSuggestionById = async(idSuggestion)=>{
    handleOpenLoading();
    const resp = await MySuggestionsMailbox.getSuggestionById(idSuggestion);
    
    setDescription(resp.comment);
    setUser(resp.first_name + " " + resp.last_name);
    setCompany(resp.name);
    setDocumentUrl(resp.document_url);
    setUrl(resp.url);
    setDateCreate(resp.created_at);

    setOpenLoading(false);
  }

  const handleDeleteSuggestion = async(idSuggestion) => {
    handleOpenLoading();

    const resp = await MySuggestionsMailbox.deleteSuggestionById(idSuggestion)?? [];
    console.log(resp);

    if(resp.success == true){
        await getSuggestions();
        handleOpenAlert("Se eliminó la sugerencia con éxito", "success");
    }else{
        handleOpenAlert("Ocurrió un error al eliminar", "error");
    }
  }

  useEffect(()=>{
    getSuggestions()
  },[]);

    return (
        <Box sx={{ display: "flex" }}>
            <SideNav returnBack="/committee" />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image="assets/images/banners/suggestionsMailbox.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Buzón de Sugerencias"
                            />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <TableControla
                                loading={openLoading}
                                tabs={[
                                    {
                                        columnNames: ["Descripción","Fecha de creación" , "Origen", "Acción"]
                                    }
                                ]}
                            >
                                <ListSuggestions
                                    colorCell={purple}
                                    suggestions={suggestions}
                                    handleOpenModalSeeDetail={handleOpenModalSeeDetail}
                                    handleGetSuggestionById={handleGetSuggestionById}
                                    handleDeleteSuggestion = {handleDeleteSuggestion}
                                />

                            </TableControla>
                        </Grid>
                    </Grid>

                </Box>
            </Container>

            {/* modal ver detalle */}
            <Dialog
             open={openModalSeeDetail}
             onClose={handleCloseModalSeeDetail}
             maxWidth="sm"
             fullWidth={true}
             sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                 titleText={"Detalle de información de la sugerencia"}
                 functionOnClose={handleCloseModalSeeDetail}
                />
                <DialogContent>
                    <InputControlaVertical
                        text={"Descripción:"}
                        inputType={"textArea"}
                        modalType={true}                     
                        value={description}
                        readOnly
                    />
                    <InputControlaVertical
                        text={"Usuario:"}
                        inputType={"text"}
                        modalType={true}
                        value={user}
                        readOnly
                    />
                    <InputControlaVertical
                        text={"Compañía:"}
                        inputType={"text"}
                        modalType={true}
                        value={company}
                        readOnly
                    />
                    <InputControlaVertical
                        text={"Fecha y hora de registro:"}
                        inputType={"datetime-local"}
                        modalType={true}
                        value={dateCreate.substr(0, 19)}
                        readOnly
                    />
                    <Stack direction={"row"} spacing={2} alignItems="center" mt={2}>
                        <InputLabel
                            sx={{
                                padding: '8px',
                                fontSize: '18px',
                                color: '#305AD9',
                                fontWeight: 'bold'
                            }}>Ver evidencia : </InputLabel>
                        {
                            documentUrl == null ? (
                                <InputLabel
                                    sx={{
                                        padding: '4px 10px',
                                        fontFamily: 'Roboto',
                                        color: '#305AD9',                                    
                                    }}>No adjuntó una imagen</InputLabel>
                            ) : (
                                <ButtonIconControla
                                    icon={
                                        <VisibilityOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver imagen"}
                                    directionUrl={documentUrl}
                                    directionTarget={"_blank"}
                                />
                            )
                        }
                    </Stack>
                    <Stack direction={"row"} spacing={2} alignItems="center" mt={2}>
                    <InputLabel
                            sx={{
                                padding: '8px',
                                fontSize: '18px',
                                color: '#305AD9',
                                fontWeight: 'bold'
                            }}>Ir origen del módulo : </InputLabel>
                    <Link href={url}>Url</Link>

                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Stack direction={"row"} spacing={2}>
                        <ButtonControla
                            backgroundColor={"#CBCBFF"}
                            textButton={"Cancelar"}
                            backgroundColorHover={"#CBCBFF"}
                            typeButton={"button"}
                            functionOnClick={handleCloseModalSeeDetail}
                        />
                    </Stack>

                </DialogActions>


            </Dialog>
        </Box>
    )

}
export default SuggestionsMailbox;