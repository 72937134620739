import { useEffect, useState } from "react";
import MyConfigurationApi from "../api/MyConfigurationApi";
import MyLogin from "../api/MyLogin";
import MyLocalStorage from "../api/MyLocalStorage";
import MyCrypto from "../api/MyCrypto";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import Committee from "../committee/committee";

export const AuthGuard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false); //
  const { getSectionsModules, sectionsModules } = useAppContext();

  useEffect(() => {
    const tokenData = MyConfigurationApi.tokenData();
    const userToken = tokenData.userToken;
    const userTokenRefresh = tokenData.userTokenRefresh;
    const userTokenExpired = tokenData.userTokenExpired;
    if (userToken) {
      var currentTime = new Date();
      var numberOfMlSeconds = currentTime.getTime();
      var addMlSeconds = -5 * 60 * 60000;
      var sumHoursToken = numberOfMlSeconds + addMlSeconds;
      var currentDateTime = new Date(sumHoursToken).toISOString();
      var userTokenRefreshTime = new Date(
        userTokenRefresh.replace(/-/g, "/").replace("T", " ") + " UTC"
      ).toISOString();
      var userTokenExpiredTime = new Date(
        userTokenExpired.replace(/-/g, "/").replace("T", " ") + " UTC"
      ).toISOString();
      setIsAuthenticated(true)
      getSectionsModules()
      setIsAuthChecked(true);

      if (
        userTokenRefreshTime < currentDateTime &&
        currentDateTime < userTokenExpiredTime
      ) {
        const handleRefreshSession = async () => {
          const response = await MyLogin.sessionRefresh();
          if (response.success === true) {
            console.log("response de auth", response);
            MyLocalStorage.clearLocal();
            MyLocalStorage.setLocal(
              "controla",
              MyCrypto.crypt(JSON.stringify(response))
            );
          } else {
            MyLocalStorage.clearLocal();
            setIsAuthenticated(false)
          }
          return response;
        };
        handleRefreshSession();
      } else if (userTokenExpiredTime < currentDateTime) {
        MyLocalStorage.clearLocal();
        setIsAuthenticated(false)
      }
    } else {
      setIsAuthenticated(false)
      setIsAuthChecked(true);
    }
  }, []);

  return isAuthChecked ? (
    isAuthenticated ? (
      <>
        <Outlet>
          <Routes>
            <Route  path="/testing" element={<Committee />} />
          </Routes>
        </Outlet>
      </>
    ) : (
      <Navigate replace to="/login" />
    )
  ) : null;
}