import { React, useEffect, useState } from "react";
// import parse from 'html-react-parser';
import Box from "@mui/material/Box";
import {
    Alert,
    Container,
    Grid,
    Backdrop,
    MenuItem,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    Snackbar,
    InputLabel,
    Table,
    Paper,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Select
} from "@mui/material";
import {
    Add,
    DeleteForever,
  } from "@mui/icons-material";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import SideNav from "../../../components/sidenav/sideNav";
import SubtitleText from "../../../components/text/subtitleText";
import TitleText from "../../../components/text/titleText";
import CompanyLogoControla from "../../../components/images/companyLogoControla";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../components/buttons/buttonController";
import { EditorControla } from "../../../components/editor/editorControla";
import HelpIcon from '@mui/icons-material/Help';
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import SearchUsersControla   from "../../../components/search/searchUsersControla";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import SelectControla from "../../../components/selects/selectControla";
import MyUsers from "../../../api/MyUsers";
import MyProceedingsBook from "../../../api/MyProceedingsBook";
import MyTasksNew from "../../../api/MyTasksNew";
import MyConfigurationApi from "../../../api/MyConfigurationApi";
import { useNavigate, useLocation} from "react-router";
import LoadingControla from "../../../components/load/loadingControla";


const grey = "#9191B0";
const skyblue = "#2D9CDB";
const blue = "#034AFF";

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em",
  });


function InformationThemeProceedingSmallCompany() {

    //obtener el id de la reunión por el enlace 
    const params = new URLSearchParams(window.location.search);
    const id_theme_proceeding = params.get("id_theme");
    const id_proceeding  = params.get("id_proceeding");

    const navigate = useNavigate();
    const location = useLocation();

    const readOnly = location.state.readOnly;
    console.log(readOnly);

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    //fin de alerts

    //modal de confirmación
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText]=useState("");
    const [buttonTextConfirm,setButtonTextConfirm] = useState("");
    const [idRecommendation, setIdRecommendation] = useState("");

    const handleCloseModalConfirm = () =>{
        setOpenModalConfirm(false);
    }
    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm , idRecommendation)=>{
        setOpenModalConfirm(true);

        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setIdRecommendation(idRecommendation)
    }
    //
    const [titleTheme, setTitleTheme] = useState("");
    const [information, setInformation]= useState(``);
    const [contentTemplate, setContentTemplate] = useState(``);
    const [recommendations, setRecommendations] = useState([]);

    const [approval, setApproval] = useState(0);
    const typeApproval = [{ id_approval: 1, name: "Aprobado" },{ id_approval: 2, name: "Desaprobado" }];
    const templateText = (event) => {
        setContentTemplate(event);
    }
    
    const [comment, setComment] = useState("");

    const getThemeByProceeding= async()=>{
        handleOpenLoading();

        const r = await MyProceedingsBook.getThemesByProceedingId(id_proceeding);

        const theme = r.data.filter((theme)=> theme.id_committee_minutes_theme === Number(id_theme_proceeding));
        setTitleTheme(theme[0].name);
        setContentTemplate(theme[0].report);
        const description = {__html:theme[0].description};
        setInformation(description);
        setComment(theme[0].comment);
        setApproval(theme[0].approval);

        setOpenLoading(false);
    }

    const handleSaveInfoTheme = async ()=>{
       handleOpenLoading();
  
        const dataInfoTheme = {
            report: contentTemplate,
            approval: approval,
            comment: comment
        }

        const res  = await MyProceedingsBook.updateThemeForProceeding(id_theme_proceeding, dataInfoTheme);
        
        if(res.success === true){
            handleOpenAlert("Se guardó con éxito", "success");
        } else{
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    //modal recomendación
    const [ openModal, setOpenModal] = useState(false);

    const handleOpenModal= () => {
        setOpenModal(true);
        getUsers();

        // limpiar inputs
        setDateEnd(null);
        setDateStart(null);
        setComment(null);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    //recomendaciones
    const [dateStart, setDateStart] = useState("");
    const [dateEnd, setDateEnd] = useState("");
    const [description, setDescription]= useState("");

    const [users, setUsers]= useState([]);

    const getUsers = async () => {
        const result = await MyUsers.users();
        setUsers(result.users_actives);
    }


    const getRecommendationsByTheme = async() => {
        const res  = await MyProceedingsBook.getRecommendationsByTheme(id_theme_proceeding);
        setRecommendations(res.data)
    }

    const handleAddRecommendation = async (idUser) =>{
        handleOpenLoading();

        const dataRecomendation= {
            id_committee_minutes_theme: id_theme_proceeding,
            start_date: dateStart,
            end_date: dateEnd,
            id_users: idUser,
            description: description,
            origin: `/proceedingBook/editProceeding/informationTheme?id_theme=${id_theme_proceeding}&id_proceeding=${id_proceeding}` 
        }
        const res  = await MyProceedingsBook.postRecommendations(dataRecomendation);

        //agregar tarea
        const data = {
            date_start: dateStart,
            date_end: dateEnd,
            description: description,
            id_status: 1,
            id_users: idUser,
            id_tasks_types: 3,
            title: "Tarea asignada en libro de actas",
            origin_url: `/proceedingBook/editProceeding/informationTheme?id_theme=${id_theme_proceeding}&id_proceeding=${id_proceeding}`
        }

        const resp = await MyTasksNew.postTasks(data);
       
        if(res.success === true & resp.success === true){
            getRecommendationsByTheme();
            handleOpenAlert("La recomendación se agregó con éxito","success");
        } else{
            handleOpenAlert("Ocurrió un error","error");
        }
        setDateStart(null);
        setDateEnd(null);
        setDescription(null);

        handleCloseModal();
    }

    const handleDeleteRecommendation = async() => { 

        handleOpenLoading()
        const res  = await MyProceedingsBook.deleteRecommendation(idRecommendation);
        if(res.success === true){
            getRecommendationsByTheme();
            handleOpenAlert("Se eliminó correctamente","success");
        } else {
            handleOpenAlert("Ocurrió un error","error");
        }
        handleCloseModalConfirm();
    }

    //modal de información del tema
    const [openModalInformationByTheme, setOpenModalInformationByTheme] = useState(false);

    const handleOpenModalInformationByTheme = ()=>{
        setOpenModalInformationByTheme(true);
    }

    const handleCloseModalInformationByTheme = ()=>{
        setOpenModalInformationByTheme(false);
    }

    useEffect(()=>{
        getThemeByProceeding();
        getRecommendationsByTheme();

    },[]);


    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack={""} />
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={() => { handleDeleteRecommendation(idRecommendation) }}
            />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <Container sx={{ paddingBottom: 4 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SubtitleText
                                text={"Tema a tratar en reunión"}
                                color={grey}
                            ></SubtitleText>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TitleText
                                text={"Detalles del tema"}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <InputControlaVertical
                                text={"Tema"}
                                inputType={"text"}
                                inputValue={setTitleTheme}
                                modalType={false}
                                value={titleTheme || ''}
                                readOnly={true}
                            />
                        </Grid>

                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <InputLabel
                                sx={{
                                    padding: '8px',
                                    fontSize: '18px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>Informe</InputLabel>
                        </Grid>
                        {
                            readOnly ? "" : (
                                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <ButtonIconControla
                                        icon={
                                            <HelpIcon
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Información"}
                                        functionOnClick={() => { handleOpenModalInformationByTheme() }}
                                    />
                                </Grid>

                            )
                        }
                        

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <EditorControla
                                dataEdit={contentTemplate}
                                handleDataEditor={templateText}
                                disabled={readOnly} 
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputControlaVertical
                                text={"Comentarios"}
                                inputType={"textArea"}
                                inputValue={setComment}
                                value={comment}
                                modalType={false}
                                required
                                readOnly={readOnly}
                            />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Recomendaciones
                            </StyledTypography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                            {
                                readOnly ? "" : (
                                    <ButtonControla
                                        textButton={"Agregar recomendaciones"}
                                        iconButton={<Add />}
                                        backgroundColor={skyblue}
                                        backgroundColorHover={"#58C0FB"}
                                        functionOnClick={handleOpenModal}
                                    />
                                )
                            }
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Descripción
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Responsable
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Fecha de inicio
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Fecha de Fin
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Estado
                                            </TableCell>
                                            {
                                                readOnly ? "" : (
                                                    <TableCell sx={{ color: blue }} align="center">
                                                        Acciones
                                                    </TableCell>
                                                )
                                            }
                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recommendations.map((r, index) => (
                                            <TableRow>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    {r.description}
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    {r.first_name + " " + r.last_name}
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    {r.start_date}
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    {r.end_date}
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    {r.status === 1 ? "En proceso" : ""}
                                                </TableCell>
                                                {
                                                    readOnly ? "" : (
                                                        <TableCell sx={{ color: blue }} align="center">
                                                            <ButtonControla
                                                                textButton={"Eliminar"}
                                                                backgroundColor={"#EB5757"}
                                                                backgroundColorHover={"#FF4040"}
                                                                iconButton={<DeleteForever />}
                                                                functionOnClick={() => {
                                                                    handleOpenModalConfirm(
                                                                        "¿Estás seguro de eliminar esta recomendación?",
                                                                        "Después de eliminar no se podrá recuperar la información",
                                                                        "Sí, eliminar",
                                                                        r.id_minutes_recomendation)
                                                                }}
                                                            ></ButtonControla>

                                                        </TableCell>

                                                    )
                                                }
                                                
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                            <SelectControla
                                text="Acuerdo:"
                                inputValue={setApproval}
                                modalType={false}
                                required
                                value={approval || ''}
                                childrenRows={typeApproval.map((rows) => (
                                    <MenuItem key={rows.id_approval} value={rows.id_approval}>
                                        {rows.name}
                                    </MenuItem>
                                ))}
                                readOnly={readOnly}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction={"row"} spacing={3} justifyContent={"center"}>
                                <Box>
                                    <ButtonControla
                                        backgroundColor={"#CBCBFF"}
                                        textButton={"Regresar"}
                                        backgroundColorHover={"#CBCBFF"}
                                        typeButton={"button"}
                                        isNavegationOrigin={true}
                                        url={-1}
                                    />
                                </Box>
                                {
                                    readOnly ? "" : (
                                        <Box>
                                            <ButtonControla
                                                // iconButton={<Edit sx={{ color: "white" }} />}
                                                backgroundColor={"#169073"}
                                                backgroundColorHover={"#1BAA88"}
                                                textButton={"Grabar"}
                                                typeButton={"button"}
                                                functionOnClick={() => { handleSaveInfoTheme() }}
                                            />
                                        </Box>
                                    )
                                }                                
                            </Stack>
                        </Grid>

                    </Grid>
                </Box>

            </Container>

            {/* modal de información  */}

            <Dialog
                open={openModalInformationByTheme}
                onClose={handleCloseModalInformationByTheme}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Indicaciones para la redacción"
                    functionOnClose={handleCloseModalInformationByTheme}
                />
                <DialogContent>
                    <div dangerouslySetInnerHTML={information} />
                </DialogContent>

            </Dialog>

            {/* modal de agregar recomendacion */}
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Agregar recomendación"
                    functionOnClose={handleCloseModal}
                />

                <DialogContent>
                    <Grid container spacing={2}
                        justifyContent="space-between"
                        direction="row"
                        alignItems="center">
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                                text={"Fecha de Inicio"}
                                inputType={"date"}
                                inputValue={setDateStart}
                                modalType={true}
                                value={dateStart || ''}
                                readOnly={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <InputControlaVertical
                                text={"Fecha de Fin"}
                                inputType={"date"}
                                inputValue={setDateEnd}
                                modalType={true}
                                value={dateEnd || ''}
                                readOnly={false}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputControlaVertical
                                text={"Descripción de la recomendación"}
                                inputType={"textArea"}
                                inputValue={setDescription}
                                value={description}
                                modalType={true}
                                required
                                readOnly={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SearchUsersControla
                                rowsUser={users}
                                handleUsersActionButton={(id_users) => { handleAddRecommendation(id_users) }}
                            />

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Stack direction={"row"} spacing={2}>
                        <Box>
                            <ButtonControla
                                backgroundColor={"#CBCBFF"}
                                textButton={"Cancelar"}
                                backgroundColorHover={"#CBCBFF"}
                                typeButton={"button"}
                                functionOnClick={handleCloseModal}
                            />
                        </Box>
                    </Stack>
                </DialogActions>
            </Dialog>

        </Box>
    )

}
export default InformationThemeProceedingSmallCompany;