import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputBase,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { React, useState } from "react";
import { Search } from "@mui/icons-material";
import SearchUsersControla from "./searchUsersControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import { validationRoles } from "../../utils/validation_rol";
import ValidationEditByRoles from "../../utils/validation_edit_roles";

function SearchButtonControla({
  titleLabel,
  inputValue = '',
  functionClick,
  modalType = false,
  required = false,
  roles=[],
  disabledButton = false 
}) {

  const isEdit = roles.length == 0 ? true :ValidationEditByRoles(roles);

  // const showButton = validationRoles(roles);
  const showButton = true;

  return showButton && (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <InputLabel
            sx={{
              padding: "8px",
              fontSize: "18px",
              color: "#305AD9",
              fontWeight: "bold",
            }}
          >
            {titleLabel}
          </InputLabel>

          <Stack direction="row">
            <InputBase
              type="text"
              value={inputValue}
              required={required}
              sx={{
                pointerEvents: 'none',
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                fontFamily: "Roboto",
                width: "100%",
                padding: "4px 10px",
                border: "1px solid transparent",
                backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
                color: "#305AD9",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                "&.Mui-focused": {
                  border: "1px solid blue",
                },
              }}
            />
            <Button
              variant="contained"
              type="button"
              sx={{
                borderRadius: "0",
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                height: "42px",
                backgroundColor: "#2D9CDB",
                textTransform: "none",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#33AEF4",
                },
              }}
              onClick={functionClick}

              startIcon={<Search />}
              disabled={isEdit? (disabledButton ? true : false) : true}
            >
              Buscar
            </Button>
          </Stack>

        </Grid>
      </Grid>
    </Box>
  )
}

export default SearchButtonControla;
