import { TableCell, TableRow , TableBody} from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import {
    Stack,
  } from "@mui/material";
import {
    BorderColorOutlined,
    VisibilityOutlined,
  } from "@mui/icons-material";
import { useAppContext } from "../../../../../context/AppContext";

function ListHealthAndSafetyStatistics(props) {
    const {
        rows,
        colorCell
    } = props;

    const {permissions} = useAppContext();

    return (
        <TableBody>
            {
                rows.map((row) => (
                    <TableRow
                        key={row.id}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "4px",
                        }}
                    >
                        <TableCell component="th" scope="row" sx={{ color: colorCell }}>
                            {row.nro_register}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{ color: colorCell }}>
                            {row.register_status == 1 ? "Creado" : "Finalizado"}
                        </TableCell>
                        <TableCell align="right">
                            <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                            >
                                {
                                    row.register_status == 1 ? (
                                        <ButtonIconControla
                                            roles={permissions.DOC_REGIS_STATISTICS_RECORD_EDIT}
                                            icon={
                                                <BorderColorOutlined
                                                    sx={{ color: "white" }}
                                                />
                                            }
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar"}
                                            isNavegationOrigin={true}
                                            directionUrl={`/documentation/registers/generals/statisticaldata/occupationalHealthAndSafetyStatistics/edit?ididHealthAndSafetyStatistic=${row.id}`}
                                        />) : (
                                        <ButtonIconControla
                                            roles={permissions.DOC_REGIS_STATISTICS_RECORD_VER}
                                            icon={
                                                <VisibilityOutlined sx={{ color: "white" }} />
                                            }
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            isNavegationOrigin={true}
                                            directionUrl={`/documentation/registers/generals/statisticaldata/occupationalHealthAndSafetyStatistics/edit?ididHealthAndSafetyStatistic=${row.id}`}
                                            paramsIntUrl={{ readOnly: true }}
                                        />
                                    )
                                }

                            </Stack>
                        </TableCell>

                    </TableRow>
                ))
            }
        </TableBody>
    );

}
export default ListHealthAndSafetyStatistics;