import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import SideNav from "../components/sidenav/sideNav";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import MyBaseline from "../api/MyBaseline";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

const grey = "#9191B0";
const blue = "#034AFF";
const purple = "#8B8BD8";

const StyledBorderLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
});

const StyledPercentageText = styled(Typography)({
  color: "9191B0",
  fontSize: 12,
});

function BaselineImprovementActions() {
  const params = new URLSearchParams(window.location.search);
  const idBaseline = params.get("id_baseline");
  const [baselineQuestions, setBaselineQuestions] = useState([]);
  const [baselineLineaments, setBaselineLineaments] = useState([]);
  const [baselineAnswers, setBaselineAnswers] = useState([]);

  const [baselineResults, setBaselineResults] = useState([]);

  //Obtener los datos
  //   const handleGetBaselineLineaments = async () => {
  //     const response = await MyBaseline.getBaselineShowResults({ idBaseline });
  //     setBaselineResults(response);
  //     return response;
  //   };

  // Obtener data de las acciones de mejora

  const handleGetImprovementActions = async () => {
    const response = await MyBaseline.downloadImprovements({ idBaseline });
    setBaselineResults(response);
    console.log(response);
  };

  useEffect(() => {
    // handleGetBaselineLineaments();
    handleGetImprovementActions();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <SideNav returnBack="/baseline/diagnosis" />
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item md={12}>
                <SubtitleText text={"Línea base"} color={grey}></SubtitleText>
                <TitleText text={"Acciones de mejora"}></TitleText>
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                paddingBottom="40px"
              >
                {/* {Object.keys(baselineResults).map((i)=>(
                 baselineResults[i].baseline_companie_lineament
                ))} */}
                {Object.keys(baselineResults).map((lineament) => (
                  <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Paper sx={{ overflow: "hidden" }}>
                        <TableContainer>
                          <Table
                            stickyHeader
                            aria-label="simple table"
                            // sx={{
                            //   maxWidth: "200px",
                            //   background: "black"
                            // }}
                          >
                            <TableHead>
                              <TableRow>
                              <TableCell align="center" sx={{ color: blue }}>
                                  {baselineResults[lineament].baseline_companie_lineament}
                                </TableCell>
                              </TableRow>
                              
                            </TableHead>
                            <TableBody>
                              {baselineResults[lineament].indicators.map((indicator) => (
                                <TableRow>
                                  <TableCell
                                    sx={{ color: purple }}
                                    align="left"
                                  >
                                    {indicator.actions}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default BaselineImprovementActions;
