import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import {
    BorderColorOutlined,
    Delete,
    Archive,
    FileUploadOutlined,
    Send,
    Email,
} from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";

function ListBorrador(props) {
  const { permissions } = useAppContext();


  const {
    rowsAnnualPlan,
    idUserLogged,
    colorCell,
    handleOpenModalConfirm
  } = props

  return (
    <TableBody >
      {rowsAnnualPlan.map((row) =>
        row.register_status != 4 && row.register_status != 5 ? (
          <TableRow
            hover
            key={row.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell
              sx={{ color: colorCell }}
              component="th"
              scope="row"
            >
              {row.title}
            </TableCell>
            <TableCell sx={{ color: colorCell }}>
              {row.register_status === 1
                ? "Creado"
                : row.register_status === 3
                  ? "Enviado a comité"
                  : row.register_status === 7
                    ? "Enviado a trabajadores"
                    : ""}
            </TableCell>
            <TableCell sx={{ color: colorCell }}>
              <Stack
                direction="row"
                justifyContent="end"
                alignItems="center"
              >
                {row.register_status == 3 ? (
                  <>
                    <ButtonIconControla
                      roles={row.id_users_develop == idUserLogged || row.id_users_revised == idUserLogged || row.id_users_approved == idUserLogged ? [] : permissions.DOC_PLAN_ANUAL_SSO_EDIT}
                      icon={
                        <BorderColorOutlined
                          sx={{ color: "white" }}
                        />
                      }
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#33AEF4"}
                      textTooltip={"Editar"}
                      isNavegationOrigin={true}
                      directionUrl={"/documentation/annualplan/template?idAnnualPlan=" + row.id}
                      paramsIntUrl={{ readOnly: false }}
                    />
                  </>
                ) : (
                  ""
                )}
                {row.register_status == 1 || row.register_status == 7 ? (
                  <>
                    <ButtonIconControla
                      roles={permissions.DOC_PLAN_ANUAL_SSO_EDIT}
                      icon={
                        <BorderColorOutlined
                          sx={{ color: "white" }}
                        />
                      }
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#33AEF4"}
                      textTooltip={"Editar"}
                      isNavegationOrigin={true}
                      directionUrl={"/documentation/annualplan/template?idAnnualPlan=" + row.id}
                      paramsIntUrl={{ readOnly: false }}
                    />
                  </>
                ) : (
                  ""
                )}

                {row.register_status === 1 ? (
                  <ButtonIconControla
                    roles={permissions.DOC_PLAN_ANUAL_SSO_ENVIAR_TRABAJ}
                    icon={<Send sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1AA483"}
                    textTooltip={"Enviar a trabajadores"}
                    functionOnClick={() => {
                      handleOpenModalConfirm(
                        row.id,
                        "¿Estás seguro de enviar a trabajadores?",
                        "",
                        "Si, enviar",
                        "workers"
                      );
                    }}
                  />
                ) : row.register_status === 7 ? (
                  <>
                    <ButtonIconControla
                      roles={permissions.DOC_PLAN_ANUAL_SSO_ENVIAR_COMIT}
                      icon={<Send sx={{ color: "white" }} />}
                      backgroundColor={"#581845"}
                      backgroundColorHover={"#6D1F56"}
                      textTooltip={"Enviar a comité"}
                      functionOnClick={() => {
                        handleOpenModalConfirm(
                          row.id,
                          "¿Estás seguro de enviar a comité?",
                          "Recuerda que para enviar al comité, debes haber revisado el buzón de sugerencias de los trabajadores.",
                          "Si, enviar",
                          "committee"
                        );
                      }}
                    />
                    <ButtonIconControla
                      roles={permissions.DOC_PLAN_ANUAL_SSO_BUZON_SUGE}
                      icon={<Email sx={{ color: "white" }} />}
                      backgroundColor={"#FFC300"}
                      backgroundColorHover={"#FFC300"}
                      textTooltip={"Buzón de Sugerencias"}
                      directionUrl="/mailbox/consults"
                      directionTarget="_blank"
                    />
                  </>
                ) : row.register_status === 3 ? (
                  <ButtonIconControla
                    roles={permissions.DOC_PLAN_ANUAL_SSO_PUBLICAR}
                    icon={
                      <FileUploadOutlined
                        sx={{ color: "white" }}
                      />
                    }
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1AA483"}
                    textTooltip={"Publicar"}
                    functionOnClick={() => {
                      handleOpenModalConfirm(
                        row.id,
                        "¿Estás seguro de publicar?",
                        "Ingresa en el documento la fecha del acta del comité en que se aprueba el Plan Anual de SSO, antes de publicarlo. Una vez publicado el documento se verá en la sección de Activos.",
                        "Si, publicar",
                        "publish"
                      );
                    }}
                  />
                ) : (
                  <></>
                )}
                <ButtonIconControla
                  roles={permissions.DOC_PLAN_ANUAL_SSO_VER}
                  icon={
                    <VisibilityOutlined
                      sx={{ color: "white" }}
                    />
                  }
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver"}
                  isNavegationOrigin={true}
                  directionUrl={"/documentation/annualplan/template?idAnnualPlan=" + row.id}
                  paramsIntUrl={{ readOnly: true }}
                />

                <ButtonIconControla
                  roles={permissions.DOC_PLAN_ANUAL_SSO_ARCHIVAR}
                  icon={<Archive sx={{ color: "white" }} />}
                  backgroundColor={"#F2994A"}
                  backgroundColorHover={"#FF881E"}
                  textTooltip={"Archivar"}
                  functionOnClick={() =>
                    handleOpenModalConfirm(
                      row.id,
                      "¿Estás seguro de archivar el documento?",
                      "",
                      "Si, archivar",
                      "archived"
                    )
                  }
                />
                {row.register_status == 1 ? (
                  <ButtonIconControla
                    roles={permissions.DOC_PLAN_ANUAL_SSO_ELIMINAR}
                    icon={<Delete sx={{ color: "white" }} />}
                    backgroundColor={"#EB5757"}
                    backgroundColorHover={"#FF4040"}
                    textTooltip={"Eliminar"}
                    functionOnClick={() => {
                      handleOpenModalConfirm(
                        row.id,
                        "¿Estás seguro de eliminar el documento?",
                        "",
                        "Si, Eliminar",
                        "delete_template"
                      );
                    }}
                  />
                ) : (
                  ""
                )}
              </Stack>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )
      )}
      {/* {rowsAnnualPlan.map((row) => 
                      row.register_status != 4 && row.register_status != 5 ?
                      
                      (
                        <TableRow
                          hover
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ color: purple }}
                            component="th"
                            scope="row"
                          >
                            {row.title}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            {row.register_status === 1 ? "Creado" : "Cerrado"}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 3, 4, 7, 8, 15]}
                                  icon={
                                    <BorderColorOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  // el editar de activos debe enviar a un modal
                                  functionOnClick={() => {
                                    handleOpenModalUploadFileEdit(row.id);
                                  }}
                                />
                              ) : (
                                ""
                              )}

                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Send sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Enviar a comité"}
                                  // functionOnClick={() => {
                                  //   handleOpenModalConfirm(
                                  //     row.id_pets,
                                  //     "¿Esta seguro de enviar a revisión?",
                                  //     'Una vez enviado no podra editar nuevamente el PETS:  "' +
                                  //     rowsActives.name +
                                  //     '"',
                                  //     "Si, enviar",
                                  //     "revision"
                                  //   );
                                  // }}
                                />
                              ) : row.register_status === 2 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={
                                    <FileUploadOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Publicar"}
                                  // functionOnClick={() => {
                                  //   handleOpenModalConfirm(
                                  //     rowsActives.id_pets,
                                  //     "¿Esta seguro de publicar?",
                                  //     'Una vez publicado se cerrara el PETS:  "' +
                                  //     rowsActives.name +
                                  //     '"',
                                  //     "Si, publicar",
                                  //     "public"
                                  //   );
                                  // }}
                                />
                              ) : (
                                <></>
                              )}

                              <ButtonIconControla
                                ////todos los roles
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={row.document_url}
                                directionTarget={"_blank"}
                              />

                              {row.register_status === 3 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Archive sx={{ color: "white" }} />}
                                  backgroundColor={"#F2994A"}
                                  backgroundColorHover={"#FF881E"}
                                  textTooltip={"Archivar"}
                                  // functionOnClick={() =>
                                  //   handleOpenModalConfirm(
                                  //     rowsActives.id_pets,
                                  //     "¿Esta seguro de archivar el PETS?",
                                  //     'PETS a archivar:  "' +
                                  //     rowsActives.name +
                                  //     '"',
                                  //     "Si, archivar",
                                  //     "archived"
                                  //   )
                                  // }
                                />
                              ) : (
                                ""
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))} */}
    </TableBody>

  )
}
export default ListBorrador;
