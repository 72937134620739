import { React, useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, IconButton, LinearProgress, Paper, Radio, RadioGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import SideNav from '../../../components/sidenav/sideNav';
import SubtitleText from '../../../components/text/subtitleText';
import TitleText from '../../../components/text/titleText';
import MyBaseline from '../../../api/MyBaseline';
import InputControlaVertical from '../../../components/textfields/inputControlaVertical';
import { Add, ArrowBackIosNew, ArrowForwardIos, BorderColorOutlined, Delete, FormatListNumbered } from '@mui/icons-material';
import ButtonControla from '../../../components/buttons/buttonController';
import MyTrainings from '../../../api/MyTrainings';
import DialogTitleControla from '../../../components/dialog/dialogTitleControla';
import LoadingControla from '../../../components/load/loadingControla';
import DialogConfirmControla from '../../../components/dialog/dialogConfirmControla';
import ButtonIconControla from '../../../components/buttons/buttonIconControla';
import SelectYesNotControla from '../../../components/selects/selectYesNotControla';
import { useAppContext } from '../../../context/AppContext';

const grey = "#9191B0";
const blue = "#034AFF";

const StyledBorderLinearProgress = styled(LinearProgress)({
    height: 10,
    borderRadius: 5,
});

const StyledPercentageText = styled(Typography)({
    color: "9191B0",
    fontSize: 12
});

function BaselineAnswersTwo() {
    const {permissions} = useAppContext();

    const params = new URLSearchParams(window.location.search);
    const id_trainings_exams = params.get('id_trainings_exams');
    const id_trainings = params.get('id_trainings');


    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    // Pantallas de carga y alertas FIN


    // Dialogos de confirmacion INICIO
    const [idAction, setIdAction] = useState('');

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState('');
    const [contentText, setContentText] = useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = useState('');
    const [buttonActionType, setButtonActionType] = useState('');


    const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
        setIdAction(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case 'deleteQuestions':
                handleDeleteQuestions()
                break;
            case 'deleteAnswers':
                handleDeleteAnswers()
                break;
        }
        setOpenModalConfirm(false);
    }

    // Dialogos de confirmacion FIN
    //Modals
    const [openModalCreate, setOpenModalCreate] = useState(false);

    const handleOpenModalCreate = () => {
        setOpenModalCreate(true);
    };
    const handleCloseModalCreate = () => {
        setOpenModalCreate(false);
    };

    const [openModalEdit, setOpenModalEdit] = useState(false);

    const handleOpenModalEdit = (idQuestion, question, value) => {
        setIdQuestion(idQuestion);
        setQuestion(question);
        setValue(value);
        setOpenModalEdit(true);
    };
    const handleCloseModalEdit = () => {
        setOpenModalEdit(false);
    };

    const [openModalEditAnswer, setOpenModalEditAnswer] = useState(false);

    const handleOpenModalEditAnswer = (idQuestion, answers, question) => {
        setIdQuestion(idQuestion);
        setRowAnswers(answers);
        setQuestion(question);
        setAnswer('');
        setOpenModalEditAnswer(true);
    };
    const handleCloseModalEditAnswer = () => {
        setOpenModalEditAnswer(false);
    };


    const [idQuestion, setIdQuestion] = useState(null);
    const [question, setQuestion] = useState(null);
    const [value, setValue] = useState(null);

    const [idAnswer, setIdAnswer] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [correct, setCorrect] = useState(0);
    const [totalValue, setTotalValue] = useState(0)

    const [rowAnswers, setRowAnswers] = useState([]);
    const handleGetAnswers = async () => {
        const response = await MyTrainings.getAnswers({ id_trainings_exams_questions: idQuestion });
        setRowAnswers(response);
    }

    const [rowQuestions, setRowQuestions] = useState([]);
    const handleGetQuestions = async () => {
        const response = await MyTrainings.getQuestions({ id_trainings_exams: id_trainings_exams });
        const totalValue = response.reduce((acumulador, item) => acumulador + item.value, 0);
        setTotalValue(totalValue ?? 0)
        setRowQuestions(response);
    }
    const [idStatus, setIdStatus] = useState("");
    const handleGetTraining = async () => {
        const response = await MyTrainings.getTrainings({ id_trainings: id_trainings });
        setIdStatus(response.data.data[0].id_trainings_statuses);
    }
    useEffect(() => {
        handleGetTraining();
        handleGetQuestions();
    }, []);

    const handleStoreQuestions = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyTrainings.storeQuestions({ id_trainings_exams: id_trainings_exams, question: question, value: value });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleGetQuestions();
            handleCloseModalCreate();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleUpdateQuestions = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyTrainings.updateQuestions({ id_trainings_exams_questions: idQuestion, question: question, value: value });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleGetQuestions();
            handleCloseModalEdit();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleDeleteQuestions = async () => {
        handleOpenLoading();
        const response = await MyTrainings.deleteQuestions({ id_trainings_exams_questions: idAction });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleGetQuestions();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleCleanAnswers = () => {
        setIdAnswer('');
        setAnswer('');
        setCorrect(0);
    }

    const handleOpenModalSelectAnswer = (idAnswer, answer, correct) => {
        setIdAnswer(idAnswer);
        setAnswer(answer);
        setCorrect(correct);
        console.log(correct);
    }


    const handleStoreOrUpdateAnswers = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        let response;
        if (idAnswer !== null && idAnswer !== undefined && idAnswer !== '') {
            response = await MyTrainings.updateAnswers({ id_trainings_exams_questions_answers: idAnswer, answer: answer, correct: correct });
        } else {
            response = await MyTrainings.storeAnswers({ id_trainings_exams_questions: idQuestion, answer: answer, correct: correct });
        }
        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleGetQuestions();
            handleCleanAnswers();
            handleGetAnswers();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleDeleteAnswers = async () => {
        handleOpenLoading();
        const response = await MyTrainings.deleteAnswers({ id_trainings_exams_questions_answers: idAction });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleGetQuestions();
            handleGetAnswers();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    return (
        <>
            <Box sx={{ display: "flex", height: '100%' }}>
                <SideNav returnBack={'/trainings/all/exams?id_trainings=' + id_trainings} />
                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />
                <Container>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={3} columnSpacing={3}>
                            <Grid item md={12}>
                                <SubtitleText
                                    text={"Capacitaciones"}
                                    color={grey}>
                                </SubtitleText>
                                <Box display='flex' >
                                    <Box flex={1}>
                                        <TitleText
                                            text={"Preguntas de examen"}>
                                        </TitleText>
                                    </Box>

                                    <Box flex={1} justifyContent='center'>
                                        <SubtitleText
                                            text={`Puntaje Total: ${totalValue}`}
                                            color={grey}>
                                        </SubtitleText>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {idStatus == 1 ?
                                    <ButtonControla
                                        roles={permissions.CAPACIT_TODAS_CAPACIT_EXAM_AGREGAR_PREGUNT}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar pregunta"}
                                        functionOnClick={() => handleOpenModalCreate()}
                                    />
                                    : ''}
                            </Grid>

                            <Grid container item spacing={2}>
                                {rowQuestions.map((row) => (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={row.id_trainings_exams_questions} >

                                        <Card sx={{
                                            maxWidth: "100%",
                                            borderRadius: "14px",
                                            height: 'auto',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between'
                                        }}
                                        >
                                            <CardContent
                                                sx={{
                                                    width: '100%'
                                                }}>
                                                <Grid container >
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height: "auto", mb: 1 }}>
                                                        <Typography color={blue} fontSize={17} fontWeight={600}
                                                            sx={{
                                                                userSelect: 'none'
                                                            }}>
                                                            {row.question + ' (' + row.value + ' puntos)'}
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height: "auto" }}>
                                                        <Typography color={blue} fontSize={16}
                                                            sx={{
                                                                userSelect: 'none', pb: .5
                                                            }}>
                                                            Grupo de respuestas:
                                                        </Typography>
                                                        {row.answers.map((answer) => (
                                                            <Typography color={blue} fontSize={15} key={answer.id_trainings_exams_questions_answers}
                                                                sx={{
                                                                    userSelect: 'none',
                                                                    backgroundColor: (answer.correct === 1 ? '#cff9cf' : ''),
                                                                    borderRadius: '8px',
                                                                    paddingLeft: '8px'
                                                                }}>
                                                                {answer.answer}
                                                            </Typography>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </CardContent>

                                            {idStatus == 1 ?
                                                <CardActions sx={{ padding: '16px' }}>
                                                    <Box>
                                                        <ButtonIconControla
                                                            roles={permissions.CAPACIT_TODAS_CAPACIT_EXAM_EDIT_RESP}
                                                            icon={
                                                                <FormatListNumbered
                                                                    sx={{ color: "white" }}
                                                                />
                                                            }
                                                            backgroundColor={"#305AD9"}
                                                            backgroundColorHover={"#0E4AFF"}
                                                            textTooltip={"Editar respuestas"}
                                                            functionOnClick={() => { handleOpenModalEditAnswer(row.id_trainings_exams_questions, row.answers, row.question) }}
                                                        />
                                                    </Box>
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="end"
                                                        alignItems="end"
                                                        spacing={1}
                                                        sx={{ width: '100%' }}
                                                    >
                                                        <Box>
                                                            <ButtonIconControla
                                                                roles={permissions.CAPACIT_TODAS_CAPACIT_EXAM_EDIT_PREGUNT}
                                                                icon={<BorderColorOutlined sx={{ color: 'white' }} />}
                                                                backgroundColor={"#2D9CDB"}
                                                                backgroundColorHover={"#33AEF4"}
                                                                textTooltip={"Editar pregunta"}
                                                                functionOnClick={() => { handleOpenModalEdit(row.id_trainings_exams_questions, row.question, row.value) }}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <ButtonIconControla
                                                                roles={permissions.CAPACIT_TODAS_CAPACIT_EXAM_ELIM_PREGUNT}
                                                                icon={
                                                                    <Delete
                                                                        sx={{ color: "white" }}
                                                                    />
                                                                }
                                                                backgroundColor={"#EB5757"}
                                                                backgroundColorHover={"#FF4040"}
                                                                textTooltip={"Eliminar pregunta"}
                                                                functionOnClick={() => { handleOpenModalConfirm(row.id_trainings_exams_questions, 'Eliminar pregunta', '¿Estás seguro de eliminar la pregunta con sus respuestas de este examen?', 'Sí, eliminar', 'deleteQuestions'); }}
                                                            />
                                                        </Box>

                                                    </Stack>
                                                </CardActions>
                                                : ''}
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid container justifyContent='flex-end'>
                        <Grid>
                            <ButtonControla
                                typeButton = "button"
                                textButton="Regresar"
                                url={'/trainings/all/exams?id_trainings=' + id_trainings}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Dialog
                    open={openModalCreate}
                    onClose={handleCloseModalCreate}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Agregar pregunta' functionOnClose={handleCloseModalCreate} />
                    <form onSubmit={handleStoreQuestions}>
                        <DialogContent >
                            <InputControlaVertical text={'Pregunta:'} inputType={'text'} inputValue={setQuestion} modalType={true} required />
                            <InputControlaVertical text={'Valor:'} inputType={'number'} inputValue={setValue} modalType={true} required />

                        </DialogContent>
                        <DialogActions>
                            <Stack direction={'row'} spacing={2}>
                                <Box>
                                    <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreate} />
                                </Box>
                                <Box>
                                    <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={'Crear pregunta'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                </Box>
                            </Stack>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={openModalEdit}
                    onClose={handleCloseModalEdit}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Editar pregunta' functionOnClose={handleCloseModalEdit} />
                    <form onSubmit={handleUpdateQuestions}>
                        <DialogContent >
                            <InputControlaVertical text={'Pregunta:'} inputType={'text'} inputValue={setQuestion} value={question} modalType={true} required />
                            <InputControlaVertical text={'Valor:'} inputType={'number'} inputValue={setValue} value={value} modalType={true} required />
                        </DialogContent>
                        <DialogActions>
                            <Stack direction={'row'} spacing={2}>
                                <Box>
                                    <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalEdit} />
                                </Box>
                                <Box>
                                    <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={'Actualizar pregunta'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                </Box>
                            </Stack>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={openModalEditAnswer}
                    onClose={handleCloseModalEditAnswer}
                    maxWidth="md"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText={question} functionOnClose={handleCloseModalEditAnswer} />

                    <DialogContent >
                        <form onSubmit={handleStoreOrUpdateAnswers}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <InputControlaVertical text={'Respuesta:'} inputType={'text'} inputValue={setAnswer} value={answer} modalType={true} required />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <SelectYesNotControla text={'Respuesta correcta:'} modalType={true} inputValue={setCorrect} value={correct} required />
                                </Grid>
                            </Grid>
                            <Stack direction={'row'} justifyContent="end" spacing={2} sx={{ mt: 2, mb: 2 }}>
                                <Box>
                                    <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Limpiar para crear'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={() => { handleCleanAnswers() }} />
                                </Box>
                                <Box>
                                    <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={(idAnswer !== null && idAnswer !== undefined && idAnswer !== '' ? 'Actualizar respuesta' : 'Crear respuesta')} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                </Box>
                            </Stack>
                        </form>

                        <Paper sx={{ overflow: "hidden", mt: 1 }}>
                            <TableContainer>
                                <Table
                                    stickyHeader
                                    sx={{ width: "100%", minWidth: "700px" }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ color: blue }}>
                                                Respuesta
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: blue }}>
                                                Correcta
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: blue }}>
                                                Acciones
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowAnswers.map((row, key) => (
                                            <TableRow
                                                hover
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                    backgroundColor: (idAnswer === row.id_trainings_exams_questions_answers ? 'aliceblue' : 'none')
                                                }}
                                                key={key}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                >
                                                    {row.answer}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                >
                                                    {(row.correct === 1 ? 'Sí' : 'No')}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="end"
                                                        alignItems="center"
                                                    >
                                                        <ButtonIconControla
                                                            roles={[1, 7, 8]}
                                                            icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                            backgroundColorHover={"#33AEF4"}
                                                            textTooltip={"Editar"}
                                                            functionOnClick={() => { handleOpenModalSelectAnswer(row.id_trainings_exams_questions_answers, row.answer, row.correct) }}
                                                        />
                                                        <ButtonIconControla
                                                            roles={[1, 7, 8]}
                                                            icon={
                                                                <Delete
                                                                    sx={{ color: "white" }}
                                                                />
                                                            }
                                                            backgroundColor={"#EB5757"}
                                                            backgroundColorHover={"#FF4040"}
                                                            textTooltip={"Eliminar"}
                                                            functionOnClick={() => { handleOpenModalConfirm(row.id_trainings_exams_questions_answers, 'Eliminar respuesta', '¿Estas seguro de eliminar esta respuesta de esta pregunta?', 'Sí, eliminar', 'deleteAnswers'); }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                    </DialogContent>
                    <DialogActions sx={{ paddingRight: '24px' }}>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cerrar'} backgroundColorHover={'#CBCBFF'} functionOnClick={handleCloseModalEditAnswer} />
                        </Stack>
                    </DialogActions>
                </Dialog>


            </Box >
        </>
    )
}

export default BaselineAnswersTwo