import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputBase,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  HistoryEduOutlined,
  PictureAsPdf,
} from "@mui/icons-material";
import Container from "@mui/material/Container";

import SideNav from "../../components/sidenav/sideNav";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import TitleText from "../../components/text/titleText";
import MySsoPolitics from "../../api/MySsoPolitics";
import MyUsers from "../../api/MyUsers";
import SearchUsersControla from "../../components/search/searchUsersControla";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
///
import MyConfigurationApi from "../../api/MyConfigurationApi";
import SearchButtonControlaTmp from "../../components/search/searchButtonControlaTmp";
import { EditorSsoPoliticsControlaTwo } from "../../components/editor/editorSsoPoliticsControlaTwo";
import { validationEditInputsByWorker } from "../../utils/validation_edit";
import { useNavigate } from "react-router-dom";

const colorTitle = "#9191B0";

function TemplateTwo() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const idRistTemplate = params.get("idSsoPolitics");

  //validar si es solo lectura 
  const readOnly = params.get("readOnly");

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  ///USER LOGGED
  const [idUserLogged, setIdUserLogged] = useState();
  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };

  //validation view worker

  const isWorker = validationEditInputsByWorker()
  ///

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    // setIdPETS(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        // deletePets();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
      console.log(response);
    }
  };

  // Abrir diálogo de cargar archivo
  const [openModalUploaFile, setOpenModalUploadFile] = useState(false);
  const handleOpenModalUploadFile = () => {
    setCode("");
    setDevelop("");
    setRevised("");
    setApproved("");
    setVersion("");
    setTitle("");
    setOpenModalUploadFile(true);
  };

  // Abrir diálogo de confirmación
  const [openModalSign, setOpenModalSign] = useState(false);
  const handleOpenModalSign = () => {
    setOpenModalSign(true);
  };

  const [version, setVersion] = useState("");
  const [title, setTitle] = useState("");
  const [documentText, setDocumentText] = useState(null);
  const [code, setCode] = useState("");

  const [develop, setDevelop] = useState("");
  const [dateDevelop, setDateDevelop] = useState("");
  const [revised, setRevised] = useState("");
  const [dateRevised, setDateRevised] = useState("");
  const [approved, setApproved] = useState("");
  const [dateApproved, setDateApproved] = useState("");

  const [dateManagerSign, setDateManagerSign] = useState("");
  /// manager //
  const [managerName, setManagerName] = useState("");
  const [managerId, setManagerId] = useState("");

  ///
  const [idDevelop, setIdDevelop] = useState("");
  const [idRevised, setIdRevised] = useState("");
  const [idApproved, setIdApproved] = useState("");
  const [idManager, setIdManager] = useState(0);

  const [developSign, setDevelopSign] = useState(0);
  const [revisedSign, setRevisedSign] = useState(0);
  const [approvedSign, setApprovedSign] = useState(0);

  const [managerSign, setManagerSign] = useState(0);
  const [firmUrl, setFirmUrl] = useState("");
  ///

  const templateText = (textdata) => {
    setDocumentText(textdata);
  };
  // jalar y mostrar datos del usuario preparado
  const [idUsersDevelop, setIdUsersDevelop] = useState("");
  const [openModalDevelop, setOpenModalDevelop] = useState(false);
  const handleOpenModalDevelop = () => {
    setOpenModalDevelop(true);
  };

  const handleGetUsersDevelop = async (idUser) => {
    //limpiar input
    setDateDevelop("");
    setDevelopSign(0);

    handleOpenLoading();
    setRegisterStatus(1);
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      const tmp_name = response.first_name + " " + response.last_name;
      const tmp_id = response.id_users;
      setDevelop(tmp_name);
      setIdUsersDevelop(tmp_id);
      console.log(develop);
      console.log(idUsersDevelop);
      // handleUpdateSsoPoliticsTemplate();'
      handleSaveUsersDevelop({ tmp_name: tmp_name, tmp_id: tmp_id });
      setOpenModalDevelop(false);
      handleOpenAlert("", "success");
    }
  };

  const handleSaveUsersDevelop = async ({ tmp_name, tmp_id }) => {
    handleOpenLoading();

    const response = await MySsoPolitics.updateSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idRistTemplate,
      develop: tmp_name,
      idUsersDevelop: tmp_id,
      developSign: "",
    });
    setOpenModalDevelop(false);
    handleEditSsoPoliticsTemplate();
    handleOpenAlert("", "success");
  };

  // jalar y mostrar datos del usuario revisado
  const [idUsersRevised, setIdUsersRevised] = useState("");
  const [openModalRevised, setOpenModalRevised] = useState(false);
  const handleOpenModalRevised = () => {
    setOpenModalRevised(true);
  };

  const handleGetUsersRevised = async (idUser) => {
    //limpiar input
    setDateRevised("");
    setRevisedSign(0);

    handleOpenLoading();
    setRegisterStatus(1);
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      const tmp_name = response.first_name + " " + response.last_name;
      const tmp_id = response.id_users;
      setRevised(tmp_name);
      console.log(tmp_id);
      setIdUsersRevised(tmp_id);
      // handleUpdateSsoPoliticsTemplate();
      handleSaveUsersRevised({ tmp_name: tmp_name, tmp_id: tmp_id });
      setOpenModalRevised(false);
      handleOpenAlert("", "success");
    }
  };

  const handleSaveUsersRevised = async ({ tmp_name, tmp_id }) => {
    handleOpenLoading();

    const response = await MySsoPolitics.updateSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idRistTemplate,
      revised: tmp_name,
      idUsersRevised: tmp_id,
      revisedSign: "",
    });
    setOpenModalDevelop(false);
    handleEditSsoPoliticsTemplate();
    handleOpenAlert("", "success");
  };

  // jalar y mostrar datos del usuario revisado
  const [idUsersApproved, setIdUsersApproved] = useState("");
  const [openModalApproved, setOpenModalApproved] = useState(false);
  const handleOpenModalApproved = () => {
    setOpenModalApproved(true);
  };

  const handleGetUsersApproved = async (idUser) => {
    //limpiar input
    setDateApproved("");
    setApprovedSign(0);

    handleOpenLoading();
    setRegisterStatus(1);
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      const tmp_name = response.first_name + " " + response.last_name;
      const tmp_id = response.id_users;

      setApproved(tmp_name);
      setIdUsersApproved(tmp_id);
      // handleUpdateSsoPoliticsTemplate();
      handleSaveUsersApproved({ tmp_name: tmp_name, tmp_id: tmp_id });
      setOpenModalApproved(false);
      handleOpenAlert("", "success");
    }
  };
  const handleSaveUsersApproved = async ({ tmp_name, tmp_id }) => {
    handleOpenLoading();

    const response = await MySsoPolitics.updateSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idRistTemplate,
      approved: tmp_name,
      idUsersApproved: tmp_id,
      approvedSign: "",
    });
    setOpenModalDevelop(false);
    handleEditSsoPoliticsTemplate();
    handleOpenAlert("", "success");
  };

  //   crear
  const handleCreateSsoPoliticsemplate = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MySsoPolitics.createSsoPoliticsTemplate({
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
      idUsersDevelop,
      idUsersRevised,
      idUsersApproved,
      dateDevelop,
      dateRevised,
      dateApproved,
      managerName: managerName,
      idManager: idManager,
    });

    //guardar firmas
    
    if(developSign == 1) {
      const resp = await MySsoPolitics.signDevelop({
        idSsoPoliticsTemplate: response.id,
      });
    }
    if(revisedSign == 1){
      const resp = await MySsoPolitics.signRevised({
        idSsoPoliticsTemplate: response.id,
        dateRevised: dateRevised,
      });
    }
    if(approvedSign == 1){
      const resp = await MySsoPolitics.signApproved({
        idSsoPoliticsTemplate: response.id,
        dateApproved: dateApproved,
      });
    }

    if (response.success !== false) {
      handleOpenAlert("El documento se ha creado con éxito", "success");
      navigate("/documentation/ssopolitics?idTabs=0");
    } else {
      handleOpenAlert("Ocurrió un error al crear el archivo", "error");
    }
    return response;
  };

  //Gerente
  const handleGetGeneralManager = async () => {
    const response = await MyUsers.getUsersByRole(10);
     
    if(response.length > 0) {
      setManagerName(response[0].first_name + " " + response[0].last_name);
      setIdManager(response[0].id_users);
      setFirmUrl(response[0].firm_url);
    }
  };

  // Editar temlplate
  // editar rist document
  //status
  const [registerStatus, setRegisterStatus] = useState(0);
  const handleEditSsoPoliticsTemplate = async () => {
    if (idRistTemplate != null) {
      const response = await MySsoPolitics.editSsoPoliticsTemplate({
        idSsoPoliticsTemplate: idRistTemplate,
      });
      if (response.success === false) {
        console.log("error");
      } else {
        setDevelop(response.develop);
        setCode(response.code);
        setApproved(response.approved);
        setDevelop(response.develop);
        setRevised(response.revised);
        //
        setManagerName(response.manager_name);
        //
        setVersion(response.version);
        setTitle(response.title);
        setDocumentText(response.document_text);
        setDateApproved(response.date_approved);
        setDateRevised(response.date_revised);
        setDateDevelop(response.date_develop);
        setDateManagerSign(response.date_manager);
        //////////////
        setIdUsersDevelop(response.id_users_develop);
        setIdUsersRevised(response.id_users_revised);
        setIdUsersApproved(response.id_users_approved);
  
        //
        setIdManager(response.id_users_manager);
        //
  
        setDevelopSign(response.develop_sign);
        setApprovedSign(response.approved_sign);
        setRevisedSign(response.revised_sign);
        //
        setManagerSign(response.manager_sign);
        setRegisterStatus(response.register_status);
      }
    }
    handleGetGeneralManager();
  };
  const handleUpdateSsoPoliticsTemplate = async () => {
    handleOpenLoading();
    const response = await MySsoPolitics.updateSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idRistTemplate,
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
      dateDevelop: dateDevelop,
      dateRevised: dateRevised,
      dateApproved: dateApproved,
      idUsersDevelop: idUsersDevelop,
      idUsersRevised: idUsersRevised,
      idUsersApproved: idUsersApproved,

      managerName: managerName,
      idManager: idManager,
      managerSign: managerSign,
      managerSignDate: dateManagerSign,
    });
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    handleEditSsoPoliticsTemplate();
    return response;
  };
  //////
  const handleSignDevelop = async () => {
    if (idRistTemplate == null) {
      setDevelopSign(1);
    } else {
      handleOpenLoading();
      const response = await MySsoPolitics.signDevelop({
        idSsoPoliticsTemplate: idRistTemplate,
      });
      handleEditSsoPoliticsTemplate();
      handleOpenAlert("Se firmó con éxito", "success");
      return response;
    }

  };

  const handleSignRevised = async () => {
    if (idRistTemplate == null) {
      setRevisedSign(1);
    } else {
      handleOpenLoading();
      const response = await MySsoPolitics.signRevised({
        idSsoPoliticsTemplate: idRistTemplate,
        dateRevised: dateRevised,
      });
      handleEditSsoPoliticsTemplate();
      handleOpenAlert("Se firmó con éxito", "success");
      return response;
    }
  };

  const handleSignApproved = async () => {
    if (idRistTemplate == null) {
      setApprovedSign(1);
    } else {
      handleOpenLoading();
      const response = await MySsoPolitics.signApproved({
        idSsoPoliticsTemplate: idRistTemplate,
        dateApproved: dateApproved,
      });
      handleEditSsoPoliticsTemplate();
      handleOpenAlert("Se firmó con éxito", "success");

      return response;
    }

  };

  ///

  const handleSignManager = async () => {
    handleOpenLoading();
    const response = await MySsoPolitics.signManager({
      idSsoPoliticsTemplate: idRistTemplate,
    });
    handleEditSsoPoliticsTemplate();
    handleOpenAlert("Se firmó con éxito", "success");
    return response;
  };

  /////

  //////// Manager Data

  const [companyName, setCompanyName] = useState("");

  const handleCompanyData = async () => {
    const response = await MyConfigurationApi.companyData();
    setCompanyName(response.name);
    console.log(response);
  };

  useEffect(() => {
    handleUserActive();
    handleEditSsoPoliticsTemplate();
    handleGetUsersActives();
    handleCompanyData();
    handleGetGeneralManager();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/ssopolitics" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <form onSubmit={handleCreateSsoPoliticsemplate}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Política SSO"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                // text={"Plantilla Política SSO"
                text={
                  registerStatus === 0
                    ? "Plantilla Política SSO"
                    : "Documento Política SSO"
                }
              >
                {registerStatus}
              </TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <InputControlaVertical
                text={"Código:"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                required
                disabled={isWorker}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Versión:"}
                inputType={"text"}
                inputValue={setVersion}
                value={version}
                required
                disabled={isWorker}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Título documento:"}
                inputType={"text"}
                inputValue={setTitle}
                value={title}
                required
                disabled={isWorker}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {/* <InputControlaVertical
                  text={"Elaborado:"}
                  inputType={"text"}
                  inputValue={setDevelop}
                  value={develop}
                  required
                /> */}
              <SearchButtonControlaTmp
                roles={[1, 7, 8]}
                titleLabel="Elaborado por: "
                inputValue={develop}
                functionClick={handleOpenModalDevelop}
                required
                disabled={isWorker}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <InputControlaVertical
                roles={[1, 7, 8]}
                text={"Fecha de Elaboración:"}
                inputType={"date"}
                inputValue={setDateDevelop}
                value={dateDevelop}
                required
                disabled={readOnly == "true" ? true : (idUsersDevelop == idUserLogged ? false : true)}
              />
            </Grid>
            {/* * */}
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {
                readOnly == "true" ?
                  (developSign == 0 ? "No firmado" : "Firmado") :
                  registerStatus === 0 ? (
                    ""
                  ) : developSign == 0 ? (
                    idUsersDevelop == idUserLogged ? (
                      <ButtonIconControla
                        icon={<HistoryEduOutlined sx={{ color: "white" }} />}
                        backgroundColor={"#F1C40F"}
                        backgroundColorHover={"#F4D03F"}
                        textTooltip={"Firmar"}
                        functionOnClick={() => handleSignDevelop()}
                      />
                    ) : (
                      "No firmado"
                    )
                  ) : (
                    "Firmado"
                  )}
            </Grid>
            {/* * */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {/* <InputControlaVertical
                  text={"Revisado:"}
                  inputType={"text"}
                  inputValue={setRevised}
                  value={revised}
                  required
                /> */}
              <SearchButtonControlaTmp
                roles={[1, 7, 8]}
                titleLabel="Revisado por: "
                inputValue={revised}
                functionClick={handleOpenModalRevised}
                disabled={isWorker}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <InputControlaVertical
                roles={[1, 7, 8]}
                text={"Fecha de Revisión:"}
                inputType={"date"}
                inputValue={setDateRevised}
                value={dateRevised}
                modalType={false}
                disabled={readOnly == "true" ? true : (idUsersRevised == idUserLogged ? false : true)}
              />
            </Grid>
            {/* * */}
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              {
                readOnly == "true" ? ( revisedSign== 0 ? "No firmado" : "Firmado") :
                  registerStatus === 0 ? (
                    ""
                  ) : revisedSign == 0 ? (
                    idUsersRevised == idUserLogged ? (
                      <ButtonIconControla
                        icon={<HistoryEduOutlined sx={{ color: "white" }} />}
                        backgroundColor={"#F1C40F"}
                        backgroundColorHover={"#F4D03F"}
                        textTooltip={"Firmar"}
                        functionOnClick={
                          dateRevised == ""
                            ? () => handleOpenModalConfirm()
                            : () => handleSignRevised()
                          // () => handleSignRevised()
                        }
                      />
                    ) : (
                      "No firmado"
                    )
                  ) : (
                    "Firmado"
                  )}
            </Grid>
            {/* * */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {/* <InputControlaVertical
                  text={"Aprobado:"}
                  inputType={"text"}
                  inputValue={setApproved}
                  value={approved}
                  required
                /> */}
              <SearchButtonControlaTmp
                roles={[1, 7, 8]}
                titleLabel="Aprobado por: "
                inputValue={approved}
                functionClick={handleOpenModalApproved}
                disabled={isWorker}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <InputControlaVertical
                roles={[1, 7, 8]}
                text={"Fecha de Aprobación:"}
                inputType={"date"}
                inputValue={setDateApproved}
                value={dateApproved}
                modalType={false}
                disabled={readOnly == "true" ? true : (idUsersApproved == idUserLogged ? false : true)}
              />
            </Grid>
            {/* * */}
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              {
                readOnly == "true" ? (approvedSign == 0 ? "No firmado" : "Firmado") :
                  registerStatus === 0 ? (
                    ""
                  ) : approvedSign == 0 ? (
                    idUsersApproved == idUserLogged ? (
                      <ButtonIconControla
                        icon={<HistoryEduOutlined sx={{ color: "white" }} />}
                        backgroundColor={"#F1C40F"}
                        backgroundColorHover={"#F4D03F"}
                        textTooltip={"Firmar"}
                        disabled={isWorker}
                        functionOnClick={
                          dateApproved == ""
                            ? () => handleOpenModalConfirm()
                            : () => handleSignApproved()
                          // () => handleSignRevised()
                        }
                      />
                    ) : (
                      "No firmado"
                    )
                  ) : (
                    "Firmado"
                  )}
            </Grid>
            {/* * */}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EditorSsoPoliticsControlaTwo
                dataEdit={documentText}
                handleDataEditor={templateText}
                companyName={companyName}
                disabled={isWorker}
              />
            </Grid>
            {/* GERENTE GENERAL */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputLabel
                sx={{
                  padding: "8px",
                  fontSize: "18px",
                  color: "#305AD9",
                  fontWeight: "bold",
                }}
              >
                Gerente General:
              </InputLabel>
              <InputBase
                type="text"
                value={managerName}
                required={false}
                disabled={isWorker}
                sx={{
                  pointerEvents: "none",
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  fontFamily: "Roboto",
                  width: "100%",
                  padding: "4px 10px",
                  border: "1px solid transparent",
                  backgroundColor: "#FFFFFF",
                  color: "#305AD9",
                  borderTopLeftRadius: "8px",
                  borderBottomLeftRadius: "8px",
                  "&.Mui-focused": {
                    border: "1px solid blue",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
              <InputControlaVertical
                roles={[1, 7, 8]}
                text={"Fecha de Firma:"}
                inputType={"date"}
                inputValue={setDateManagerSign}
                value={dateManagerSign}
                modalType={false}
                disabled={readOnly == "true" ? true : (idManager == idUserLogged ? false : true)}
              />
            </Grid>
            {/* * */}
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              {managerSign == 0 ? (
                idManager == idUserLogged ? (
                  <ButtonIconControla
                    icon={<HistoryEduOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#F1C40F"}
                    backgroundColorHover={"#F4D03F"}
                    textTooltip={"Firmar"}
                    functionOnClick={() => handleSignManager()}
                  />
                ) : (
                  "No firmado"
                )
              ) : (
                <img src={firmUrl} alt="Firma" width={"100px"}></img>
              )}
            </Grid>
            {/* * */}
          </Grid>

          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
            mt={2}
            mb={2}
            sx={{
              paddingTop: "2em",
            }}
          >
            <Box>
              <ButtonControla
                iconButton={<ArrowBack sx={{ color: "white" }} />}
                backgroundColor={"#C61C39"}
                backgroundColorHover={"#D61F3E"}
                textButton={"Regresar"}
                typeButton={"button"}
                isNavegationOrigin={true}
                url={"/documentation/ssopolitics"}
              />
            </Box>{" "}
            {idRistTemplate === null || idRistTemplate === undefined ? (
              <Box>
                <ButtonControla
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Guardar"}
                  typeButton={"submit"}
                />
              </Box>
            ) : registerStatus == 9 || registerStatus == 8 ? (
              ""
            ) : (
              <Box>
                <ButtonControla
                  // iconButton={<Add sx={{ color: "white" }} />}
                  roles={registerStatus === 4 ? [16] : [1, 7, 8]}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Actualizar"}
                  functionOnClick={() => handleUpdateSsoPoliticsTemplate()}
                />
              </Box>
            )}
            {registerStatus == 4 ? (
              <Box>
                <ButtonControla
                  //todos
                  roles={[1, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13]}
                  iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                  backgroundColor={"#F2994A"}
                  backgroundColorHover={"#FF881E"}
                  textButton={"Ver documento PDF"}
                  typeButton={"button"}
                  url={
                    process.env.REACT_APP_API_DJANGO +
                    "report/" +
                    idRistTemplate
                  }
                  target={"_blank"}
                />
              </Box>
            ) : (
              <></>
            )}
          </Stack>
        </form>
      </Container>

      {/* Buscar responsables */}
      {/* Elaborado por */}
      <Dialog
        open={openModalDevelop}
        onClose={() => {
          setOpenModalDevelop(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de elaborar la plantilla de Política SSO
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersDevelop}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalDevelop(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalRevised}
        onClose={() => {
          setOpenModalRevised(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Revisar la plantilla Política SSO
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersRevised}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalRevised(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Aprobado por */}
      <Dialog
        open={openModalApproved}
        onClose={() => {
          setOpenModalApproved(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Aprobar la plantilla de Políticas SSO
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersApproved}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalApproved(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalConfirm}
        onClose={() => setOpenModalConfirm(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Para firmar es necesario consignar fecha.
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              // backgroundColor: '#169073',
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalConfirm(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TemplateTwo;
