import { Grid, InputBase, InputLabel } from "@mui/material";
import React from "react";



function InputControlaHorizontal({ text, inputType, inputValue, modalType }) {

  // const [inputValue, setInputValue] = useState(null);

  return (
    <Grid container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >

      <Grid item xs={12} sm={12} md={5} lg={4} xl={4}
      >
        <InputLabel
          sx={{
            padding: '8px',
            fontSize: '20px',
            color: '#305AD9',
            fontWeight: 'bold'
          }}> {text} </InputLabel>
        </Grid>

        <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
        <InputBase type={inputType}
          onChange={({ target }) => inputValue(target.value)}
          sx={{
            width: '100%',
            padding: '8px 20px 8px 20px',
            backgroundColor: (modalType === true ? '#F5F5F5' : 'white'),
            color: '#305AD9',
            borderRadius: '8px'
          }}></InputBase>
      </Grid>
    </Grid >
  );
}

export default InputControlaHorizontal;
