import { React} from "react";

import SubtitleText from "../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import { Grid} from "@mui/material";
import TitleText from "../../components/text/titleText";
import CardControla from "../../components/cards/cardControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import BannerButtonControla from "../../components/cards/bannerButtonControla";
import { useAppContext } from "../../context/AppContext";

const colorTitle = "#9191B0";
function Index() {
  const name = MyConfigurationApi.companyData().name;
  const ruc = MyConfigurationApi.companyData().ruc;
  const { logoCompany } = useAppContext();

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/settings" />
      <Container>
        <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
              <SubtitleText text={"Configuración"} color={colorTitle} />
              <TitleText text={"Perfil de mi empresa"} />
            </Grid>
          </Grid>        
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <BannerButtonControla
                color1={"#3863FB"}
                color2={"#161A78"}
                title={name}
                subtitle={"RUC: " + ruc}
                image={logoCompany}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/company/generalinformation"}
                text={"Información general"}
                img={"/assets/images/banners/configuration/informacion-general.png"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/company/organization"}
                text={"Organizacion"}
                img={"/assets/images/banners/configuration/organization.png"}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/registers"}
                text={"Caracteristicas"}
                img={"/assets/images/errors/imgNotImage.jpg"}
              />
            </Grid> */}
            {/* <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/settings/registers"}
                text={"Certificaciones"}
                img={"/assets/images/errors/imgNotImage.jpg"}
              />
            </Grid> */}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Index;
