import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import {
    VisibilityOutlined,
    Unarchive
} from "@mui/icons-material";

import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";

function ListArchived(props) {
    const { permissions } = useAppContext();
    const navigate = useNavigate();

    const {
        rowsArchives,
        colorCell,
        idUserLogged,
        handleOpenModalConfirm
    } = props
    return (
        <TableBody>
            {
                rowsArchives.map((r) => (
                    <TableRow
                        hover
                        key={r.id_committee_regulations}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell sx={{ color: colorCell }}>{r.title}</TableCell>
                        <TableCell sx={{ color: colorCell }}>{r.model == 1 ? "Sistema" : "Archivo"}</TableCell>
                        <TableCell sx={{ color: colorCell }}>{r.id_status == 1 ? "Creado" : (r.id_status == 2 ? "Enviado a comité" : r.id_status == 3 ? "Publicado" : "Archivado")}</TableCell>
                        <TableCell align="right">
                            <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                            >
                                {
                                    r.model == 1 ? (
                                        <ButtonIconControla
                                            roles={permissions.COMITE_COMITE_RIC_ARCHIV_VER}
                                            icon={
                                                <VisibilityOutlined
                                                    sx={{ color: "white" }}
                                                />
                                            }
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            isNavegationOrigin={true}
                                            directionUrl={"/committee/presentCommittee/internalRegulationsCommittee/templateMenu/template"}
                                            paramsIntUrl={{idCommitteeRegulations: r.id_committee_regulations,
                                                readOnly: true}}
                                        />

                                    ) : " "
                                }
                                {
                                    r.model == 0 ? (
                                        <ButtonIconControla
                                            roles={permissions.COMITE_COMITE_RIC_ARCHIV_VER}
                                            icon={
                                                <VisibilityOutlined
                                                    sx={{ color: "white" }}
                                                />
                                            }
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            directionUrl={r.document}
                                            directionTarget={"_blank"}
                                        />

                                    ) : ""
                                }

                                <ButtonIconControla
                                    roles={r.produced == idUserLogged ? [] : permissions.COMITE_COMITE_RIC_ARCHIV_DESARCHIV}
                                    icon={<Unarchive sx={{ color: "white" }} />}
                                    backgroundColor={"#F2994A"}
                                    backgroundColorHover={"#FF881E"}
                                    textTooltip={"Desarchivar"}
                                    functionOnClick={() => {
                                        handleOpenModalConfirm(
                                            r.id_committee_regulations,
                                            "¿Estás seguro de desarchivar este reglamento?",
                                            '',
                                            "Si, desarchivar",
                                            "unarchived"
                                        )
                                    }}
                                />
                            </Stack>
                        </TableCell>
                    </TableRow>
                ))
            }
        </TableBody>
    )
}
export default ListArchived;