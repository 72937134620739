import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Tabs,
  Tab,
  Stack,
  InputLabel,
  InputBase,
  DialogActions,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  Delete,
  ArrowBackIosNew,
  ArrowForwardIos,
  Upload,
  Archive,
  FileUploadOutlined,
  Send,
  Unarchive,
  Publish,
  AssignmentTurnedIn,
  InfoOutlined,
} from "@mui/icons-material";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import SideNav from "../../components/sidenav/sideNav";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../components/text/subtitleText";
import BannerControla from "../../components/cards/bannerControla";
import ButtonControla from "../../components/buttons/buttonController";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import styled from "styled-components";
import MySsoPolitics from "../../api/MySsoPolitics";

const blue = "#034AFF";
const purple = "#8B8BD8";

function Fulfillments() {
  const params = new URLSearchParams(window.location.search);
  const idSsoPolitics = params.get("idSsoPolitics");

  // variables
  // cumplimientos
  const [fulfillments, setFulfillments] = useState([]);

  /////
  const [orgSigned, setOrgSigned] = useState("");

  const handleChangeOrgSigned = (event) => {
    setOrgSigned(event.target.value);
  };

  const [dated, setDated] = useState("");
  const handleChangeDated = (event) => {
    setDated(event.target.value);
  };

  const [concise, setConcise] = useState("");
  const handleChangeConcise = (event) => {
    setConcise(event.target.value);
  };

  const [coherent, setCoherent] = useState("");
  const handleChangeCoherent = (event) => {
    setCoherent(event.target.value);
  };

  const [memberProtection, setMemberProtection] = useState("");
  const handleChangeMemberProtection = (event) => {
    setMemberProtection(event.target.value);
  };

  const [normativityCompliance, setNormativityCompliance] = useState("");
  const handleChangeNormativityCompliance = (event) => {
    setNormativityCompliance(event.target.value);
  };

  const [consultCommitment, setConsultCommitment] = useState(null);
  const handleChangeConsultCommitment = (event) => {
    setConsultCommitment(event.target.value);
  };

  const [continuousImprovement, setContinuousImprovement] = useState(null);
  const handleChangeContinuousImprovement = (event) => {
    setContinuousImprovement(event.target.value);
  };

  const [clearRedaction, setClearRedaction] = useState(null);
  const handleChangeClearRedaction = (event) => {
    setClearRedaction(event.target.value);
  };

  const [recurringReview, setRecurringReview] = useState(null);
  const handleChangeRecurringReview = (event) => {
    setRecurringReview(event.target.value);
  };

  const [diffused, setDiffused] = useState(null);
  const handleChangeDiffused = (event) => {
    setDiffused(event.target.value);
  };

  const [provisioned, setProvisioned] = useState(null);
  const handleChangeProvisioned = (event) => {
    setProvisioned(event.target.value);
  };

  const [exposed, setExposed] = useState(null);
  const handleChangeExposed = (event) => {
    setExposed(event.target.value);
  }

  // funciones

  const handleGetFulfillments = async () => {
    const response = await MySsoPolitics.editSsoPoliticsTemplate({
      idSsoPoliticsTemplate: idSsoPolitics,
      firmada_organizacion: orgSigned,
      fechada: dated,
      concisa: concise,
      coherente: coherent,
      proteccion_miembros: memberProtection,
      cumplimiento_normatividad: normativityCompliance,
      compromiso_consulta_participacion_sso: consultCommitment,
      compromiso_mejora_continua: continuousImprovement,
      redactada_claridad: clearRedaction,
      consultada_documentada_revisada_periodicamente: recurringReview,
      difundida_organizacion: diffused,
      disposicion_partes_interesadas: provisioned,
    });
    setFulfillments(response);
    setOrgSigned(response.firmada_organizacion);
    setDated(response.fechada);
    setConcise(response.concisa);
    setCoherent(response.coherente);
    setMemberProtection(response.proteccion_miembros);
    setNormativityCompliance(response.cumplimiento_normatividad);
    setConsultCommitment(response.compromiso_consulta_participacion_sso);
    setContinuousImprovement(response.compromiso_mejora_continua);
    setClearRedaction(response.redactada_claridad);
    setRecurringReview(response.consultada_documentada_revisada_periodicamente);
    setDiffused(response.difundida_organizacion);
    setProvisioned(response.disposicion_partes_interesadas);
    setExposed(response.expuesta_escrito);
  };

  const handleSaveFulfillments = async (event) => {
    event.preventDefault();
    const response = await MySsoPolitics.postFulfillmentsSsoPolitics({
      idSsoPolitics: idSsoPolitics,
      orgSigned: orgSigned,
      dated: dated,
      concise: concise,
      coherent: coherent,
      memberProtection: memberProtection,
      normativityCompliance: normativityCompliance,
      consultCommitment: consultCommitment,
      continuousImprovement: continuousImprovement,
      clearRedaction: clearRedaction,
      recurringReview: recurringReview,
      diffused: diffused,
      provisioned: provisioned,
      exposed: exposed,
    });
    window.location.href = "/documentation/ssopolitics";
  };

  useEffect(() => {
    handleGetFulfillments();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/ssopolitics" />
      <Container>
        <Box>
          <form onSubmit={handleSaveFulfillments}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Cumplimientos"}
                  color={"#9191B0"}
                ></SubtitleText>
                <SubtitleText
                  text={
                    "A continuación te presentamos una lista para que verifiques si la política de tu organización cumple los requisitos legales:"
                  }
                  color={blue}
                ></SubtitleText>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2, paddingTop: 2 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: blue }} align="center">
                        Requisito de Cumplimiento
                      </TableCell>
                      <TableCell sx={{ color: blue }} align="center">
                        Cumple
                      </TableCell>
                      <TableCell sx={{ color: blue }} align="center">
                        No Cumple
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    ></TableRow>
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>
                            Estar firmada por la máxima autoridad de la
                            organización.
                          </Grid>
                          <Grid item>
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "La firma puede ser en original, digital o escaneada y la debe hacer el Gerente General o su equivalente, algunas organizaciones lo nombran Director, Presidente, CEO, etc."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>

                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={orgSigned == "true"}
                            value={true}
                            onChange={handleChangeOrgSigned}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={orgSigned == "false"}
                            name="radio-buttons"
                            onChange={handleChangeOrgSigned}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>Estar fechada.</Grid>
                          <Grid item>
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "Debe ser fecha cierta (día, mes y año) considerando también que la fecha de la firma de la Política debe ser posterior a la fecha en que se presentó al Comité o Supervisor SSO."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={dated == "true"}
                            value={true}
                            onChange={handleChangeDated}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={dated == "false"}
                            name="radio-buttons"
                            onChange={handleChangeDated}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item> Ser concisa.</Grid>
                          <Grid item>
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "Su contenido es específico, adecuado y apropiado para la organización."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={concise == "true"}
                            value={true}
                            onChange={handleChangeConcise}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={concise == "false"}
                            name="radio-buttons"
                            onChange={handleChangeConcise}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>Ser coherente.</Grid>
                          <Grid item>
                            {" "}
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "Recuerda que la Política debe estar alineada con la misión, la visión, los objetivos y los valores corporativos."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={coherent == "true"}
                            value={true}
                            onChange={handleChangeCoherent}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={coherent == "false"}
                            name="radio-buttons"
                            onChange={handleChangeCoherent}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>
                            Considerar el compromiso de protección de todos los
                            miembros de la organización.
                          </Grid>
                          <Grid item>
                            {" "}
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "El compromiso de proteger no es sólo a los trabjadores en planilla sino a todos los miembros de la organización, incluidos: tercerizados, contratistas, recibo por honorarios, practicantes e incluye prevención de lesiones, dolencias, enfermedades e incidentes relacionados."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={memberProtection == "true"}
                            value={true}
                            onChange={handleChangeMemberProtection}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={memberProtection == "false"}
                            name="radio-buttons"
                            onChange={handleChangeMemberProtection}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>
                            Considerar el cumplimiento de la normatividad.
                          </Grid>
                          <Grid item>
                            {" "}
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "Incluye requisitos legales y acuerdos voluntarios que la organizacióno suscriba."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={normativityCompliance == "true"}
                            value={true}
                            onChange={handleChangeNormativityCompliance}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={normativityCompliance == "false"}
                            name="radio-buttons"
                            onChange={handleChangeNormativityCompliance}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>
                            Considerar el compromiso de consulta y participación
                            en los elementos del Sistema de gestión de Seguridad
                            y Salud en el trabajo.
                          </Grid>
                          <Grid item>
                            {" "}
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "Tener en cuenta siempre la opinión de los trabajadores, sindicatos, Comités o Supervisores SST."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={consultCommitment == "true"}
                            value={true}
                            onChange={handleChangeConsultCommitment}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={consultCommitment == "false"}
                            name="radio-buttons"
                            onChange={handleChangeConsultCommitment}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>
                            Considerar el compromiso de mejora continua del
                            desempeño del Sistema de Gestión de la Seguridad y
                            Salud en el Trabajo.
                          </Grid>
                          <Grid item>
                            {" "}
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "La revisión de la política se debe efectuar al menos una vez al año, en la revisión se debe verificar si la política se adecúa a la situación actual de la organización. Permite plantear nuevos objetivos cuando los antiguos han sido alcanzados, introducir elementos nuevos o precisar los ya existentes. La revisión forma parte del proceso de mejora continua y por eso debe asumirse con el mayor detalle y un alto compromiso. Colocar versión 01 y así sucesivamente; al igual que el documento inicial, las siguientes versiones deben estar documentadas."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={continuousImprovement == "true"}
                            value={true}
                            onChange={handleChangeContinuousImprovement}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={continuousImprovement == "false"}
                            name="radio-buttons"
                            onChange={handleChangeContinuousImprovement}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>Estar redactada con claridad.</Grid>
                          <Grid item>
                            {" "}
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "La empresa debe contar con indicadores y metas cada vez más altas y exigentes."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={clearRedaction == "true"}
                            value={true}
                            onChange={handleChangeClearRedaction}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={clearRedaction == "false"}
                            name="radio-buttons"
                            onChange={handleChangeClearRedaction}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>
                            Ser consultada y estar documentada y revisada
                            periódicamente (anualmente).
                          </Grid>
                          <Grid item>
                            {" "}
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "La revisión de la política se debe efectuar al menos una vez al año, en la revisión se debe verificar si la política se adecúa a la situación actual de la organización. Permite plantear nuevos objetivos cuando los antiguos han sido alcanzados, introducir elementos nuevos o precisar los ya existentes. La revisión forma parte del proceso de mejora continua y por eso debe asumirse con el mayor detalle y un alto compromiso."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={recurringReview == "true"}
                            value={true}
                            onChange={handleChangeRecurringReview}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={recurringReview == "false"}
                            name="radio-buttons"
                            onChange={handleChangeRecurringReview}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>Ser difundida en la organización.</Grid>
                          <Grid item>
                            {" "}
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "Es fundamental que todas las personas vinculadas con la organización conozcan su política. Verificar que se ponga como parte del Reglamento Interno de Seguridad y Salud en el Trabajo. También es aconsejable que se incluya en folletos, como fondo de pantalla, en el anverso del carnet de los trabajadores y en todos aquellos espacios y medios que permitan garantizar una difusión y apropiación real del documento."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={diffused == "true"}
                            value={true}
                            onChange={handleChangeDiffused}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={diffused == "false"}
                            name="radio-buttons"
                            onChange={handleChangeDiffused}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>
                            Estar a disposición de las partes interesadas
                            externas.
                          </Grid>
                          <Grid item>
                            {" "}
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "Garantizar que esté lista para ser enviada a los terceros externos y/o publicada en el Sistema de Gestión para contratistas (evidencia de su disponibilidad)."
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={provisioned == "true"}
                            value={true}
                            onChange={handleChangeProvisioned}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={provisioned == "false"}
                            name="radio-buttons"
                            onChange={handleChangeProvisioned}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* */}
                    <TableRow>
                      <TableCell sx={{ color: purple }} align="left">
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <Grid item>
                            Ser expuesta por escrito y fácilmente accesible
                          </Grid>
                          <Grid item>
                            {" "}
                            <ButtonIconControla
                              icon={<InfoOutlined sx={{ color: "white" }} />}
                              backgroundColor={"#FFC300"}
                              backgroundColorHover={"#FACB32"}
                              textTooltip={
                                "La política debe estas publicada de manera visible tanto en la página web de la empresa, un sistema de gestión, en sus instalaciones (ubícala en un punto por el que ingresan todos a las instalaciones, si tienen varios ingresos podría haber una copia en cada uno de ellos). La publicación responde a la imagen corporativa de la empresa"
                              }
                              // functionOnClick={handleOpenModalTypeAgentsTable}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={exposed == "true"}
                            value={true}
                            onChange={handleChangeExposed}
                            name="radio-buttons"
                          ></Radio>
                        </FormControl>
                      </TableCell>
                      <TableCell align="center">
                        <FormControl>
                          <Radio
                            checked={exposed == "false"}
                            name="radio-buttons"
                            onChange={handleChangeExposed}
                            value={false}
                          ></Radio>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/* {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid container sx={{ mt: 4, paddingBottom: 10 }}>
              <Grid item xs={3} sm={3} md={10} lg={10} xl={10} />
              <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                <ButtonControla
                  textButton={"Guardar"}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1AA483"}
                  typeButton={"submit"}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={1} lg={1} xl={1}>
                <ButtonControla
                  textButton={"Regresar"}
                  backgroundColor={purple}
                  functionOnClick={() =>
                    (window.location.href = "/documentation/ssopolitics")
                  }
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
}
export default Fulfillments;
