import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Close,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
  InfoOutlined,
  PictureAsPdf,
  Print,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import CardUserControla from "../../../../components/cards/cardUserControla";
import MyRegistersInspections from "../../../../api/MyRegistersInspections";
import MyAreas from "../../../../api/MyAreas";
import SearchButtonIconControla from "../../../../components/search/searchButtonIconControla";
import InputControlaVerticalIcon from "../../../../components/textfields/inputControlaVerticalIcon";
import { useAppContext } from "../../../../context/AppContext";
import { validationEditInputsByWorker } from "../../../../utils/validation_edit";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const red = "#EB5757";

function Edit() {
  const { permissions } = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idRegisterInspection = params.get("idRegistersInspections");
  //validation view worker

  const isWorker = validationEditInputsByWorker()

  // BACKDROP
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;
  const [idRegisterInspectionWorkers, setidRegisterInspectionWorkers] =
    useState();

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = (idRegisterAccidentsWorkersValue) => {
    setOpenDelete(true);
    setidRegisterInspectionWorkers(idRegisterAccidentsWorkersValue);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  /////iduserid para responsables

  // id del responsable
  const [idRegisterResponsible, setIdRegisterResponsible] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setidRegisterInspectionWorkers(id);
    setIdAccidentsMeasures(id);
    setidRegisterInspectionResponsible(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    setIdRegisterResponsible(id);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteWorkers":
        handleDeleteAccidentsWorkers();
        break;
      case "deleteMeasures":
        handleDeleteCorrectivesActions();
        break;
      case "deleteResponsible":
        handleDeleteResponsible({ idUsers: idRegisterResponsible });
        break;
      case "deleteResponsibleAreas":
        handleDeleteResponsibleAreas(idRegisterResponsible);
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  // DIALOG

  //  abrir diálogo del buscador de responsable de medidas correctivas

  // agregar datos del trabajador DIALOG
  const [openModalUploadAddDates, setOpenModalUploadAddDates] = useState(false);
  const handleOpenModalUploadAddDates = () => {
    setWorkExperience(null);
    setArea(null);
    setShift(null);
    setWorkHoursBefore(null);
    setResponsibleCorrectiveActions("");
    handleGetUsersSearchsAddsWorkers();
    setOpenModalUploadAddDates(true);
  };

  const handleCloseModalUploadAddDates = () => {
    handleGetAccidentsWorkers();
    setOpenModalUploadAddDates(false);
  };

  // agregar medidas correctivas DIALOG
  const [
    openModalUploadCorrectiveActions,
    setOpenModalUploadCorrectiveActions,
  ] = useState(false);

  const handleOpenModalUploadCorrectiveActions = () => {
    setTimestampStart(null);
    setTimestampEnd(null);
    setDescription(null);
    setStatusCorrectivesActions(null);
    handleGetStatusCorrectivesActions();
    handleGetSearchsCorrectivesActionWorkers();
    setOpenModalUploadCorrectiveActions(true);
  };

  const handleCloseModalUploadCorrectiveActions = () => {
    setResponsibleCorrectiveActions("");
    handleGetCorrectivesActions();
    setOpenModalUploadCorrectiveActions(false);
  };

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadCResponsibleRegistration(true);
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    handleGetResponsibleWorkers();
    setOpenModalUploadCResponsibleRegistration(false);
  };

  // ACCIDENTE DE TRABAJO

  // Gravedad del accidente de trabajo
  const [severityAccidents, setSeverityAccidents] = useState(0);
  const [rowsSeverityWorkAccidents, setRowsSeverityWorkAccidents] = useState(
    []
  );

  const handleGetSeverityAccidents = async () => {
    const response = await MyRegistersAccidents.severityWorkAccident();
    if (response.success === false) {
    } else {
      setRowsSeverityWorkAccidents(response);
    }
    return response;
  };

  const [code, setCode] = useState("");
  const [affectedWorkers, setAffectedWorkers] = useState(0);
  const [causesDescription, setcausesDescription] = useState("");
  const [descriptionWork, setDescriptionWork] = useState("");
  const [injuredBodyPartDescription, setInjuredBodyPartDescription] =
    useState("");
  const [location, setLocation] = useState("");
  const [medicalLeaveDays, setMedicalLeaveDays] = useState(0);
  const [timestampAccident, setTimestampAccident] = useState("");
  const [timestampInvestigation, setTimestampInvestigation] = useState("");
  const [documentUrl1, setDocumentUrl1] = useState("");
  const [documentUrl2, setDocumentUrl2] = useState("");
  const [documentUrl3, setDocumentUrl3] = useState("");

  // variables inspecciones
  const [areaResponsible, setAreaResponsible] = useState("");
  const [conclusionsRecomendations, setConclusionsRecomendations] =
    useState("");
  const [date, setDate] = useState("");
  const [inspectionResponsible, setInspectionResponsible] = useState("");
  const [type, setType] = useState("");
  const [typeDetail, setTypeDetail] = useState("");
  const [objective, setObjective] = useState("");
  const [results, setResults] = useState("");
  const [status, setStatus] = useState("");
  //

  // severidad del accidente
  const [severityIncidents, setSeverityIncidents] = useState(0);
  const [rowsSeverityIncidents, setRowsSeverityIncidents] = useState([]);

  const handleGetSeverityInccidents = async () => {
    const response = await MyRegistersAccidents.severityIncidents();
    if (response.success === false) {
    } else {
      setRowsSeverityIncidents(response);
    }
    return response;
  };

  // get Usuarios a buscar al agregar trabajadores
  const [rowsUsersSearchsAddsWorkers, setRowsUsersSearchsAddsWorkers] =
    useState([]);

  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyRegistersAccidents.getUserSearchsAddsWorkers({
      idRegisterInspection,
    });
    setRowsUsersSearchsAddsWorkers(response);
  };
  // update register accidents
  const handleUpdateRegistersAccidents = async (event) => {
    setOpenBackdrop(!openBackdrop);

    event.preventDefault();
    const response = await MyRegistersAccidents.updateRegistersAccidents({
      idRegisterInspection,
      code: code,
      timestamp_accident: timestampAccident,
      timestamp_investigation: timestampInvestigation,
      location: location,
      id_registers_accidents_severities: severityAccidents,
      id_registers_accidents_severities_incident: severityIncidents,
      medical_leave_days: medicalLeaveDays,
      affected_workers: affectedWorkers,
      injured_body_part_desciption: injuredBodyPartDescription,
      description: descriptionWork,
      document_url_1: documentUrl1,
      document_url_2: documentUrl2,
      document_url_3: documentUrl3,
      causes_description: causesDescription,
    });

    if (response.success === false) {
      console.log("error al actualizar");
    } else {
      handleGetAccidentsWorkers();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se guardaron de forma correcta los datos del registro"
      );
    }
    return response;
  };

  const handleUpdateRegistersInspections = async (event) => {
    setOpenBackdrop(!openBackdrop);
    event.preventDefault();

    const response = await MyRegistersInspections.updateRegisterInspection({
      idRegister: idRegisterInspection,
      code: code,
      area: area,
      areaResponsible: areaResponsible,
      conclusionsRecomendations: conclusionsRecomendations,
      date: date,
      description: description,
      inspectionResponsible: inspectionResponsible,
      objective: objective,
      results: results,
      type: type,
      document_upload: true,
      document1: documentUrl1,
      typeDetail: typeDetail,
      register_status: 1,
    });

    if (response != null) {
      handleEditRegisterInspections();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se guardaron de forma correcta los datos del registro"
      );
    }
  };

  const handleUpdateRegistersCloseInspections = async (event) => {

    setOpenBackdrop(!openBackdrop);
    event.preventDefault();

    if (responsibles.length >= 1) {
      const found = responsibles.find((item) => item.firm === 0);
      if (found === undefined) {
        const response = await MyRegistersInspections.updateRegisterInspection({
          idRegister: idRegisterInspection,
          code: code,
          area: area,
          areaResponsible: areaResponsible,
          conclusionsRecomendations: conclusionsRecomendations,
          date: date,
          description: description,
          inspectionResponsible: inspectionResponsible,
          objective: objective,
          results: results,
          type: type,
          document_upload: documentUrl1.type && documentUrl1.name ? true : false,
          document1: documentUrl1,
          typeDetail: typeDetail,
          register_status: 2,
        });
        if (response != null) {
          handleEditRegisterInspections();
          handleCloseBackdrop();
          handleClickAlert(
            "success",
            "Se guardaron de forma correcta los datos del registro"
          );
        }
      } else {
        handleCloseBackdrop();
        handleClickAlert(
          "error",
          "Complete las secciones obligatorias (Trabajador responsable)"
        );
      }
    } else {
      handleCloseBackdrop();
      handleClickAlert(
        "error",
        "Complete las secciones obligatorias (Trabajador responsable)"
      );
    }
  };

  // edit register acidents
  const handleEditRegistersAccidents = async () => {
    const response = await MyRegistersAccidents.editRegistersAccidents({
      idRegisterInspection,
    });
    if (response.success === false) {
    } else {
      setRegisterStatus(
        response.id_registers_accidents_statuses === 1 ? true : false
      );
      setCode(response.code);
      setAffectedWorkers(response.affected_workers);
      setcausesDescription(response.causes_description);
      setInjuredBodyPartDescription(response.injured_body_part_desciption);
      setLocation(response.location);
      setMedicalLeaveDays(response.medical_leave_days);
      setTimestampAccident(
        response.timestamp_accident === null
          ? ""
          : response.timestamp_accident.substring(0, 16)
      );
      setTimestampInvestigation(
        response.timestamp_investigation === null
          ? ""
          : response.timestamp_investigation.substring(0, 10)
      );
      setDocumentUrl1(response.document_url_1);
      setDocumentUrl2(response.document_url_2);
      setDocumentUrl3(response.document_url_3);
      setDescriptionWork(response.description);
      setSeverityAccidents(response.id_registers_accidents_severities);
      setSeverityIncidents(response.id_registers_accidents_severities_incident);
    }
    return response;
  };

  const handleEditRegisterInspections = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersInspections.editRegisterInspection({
      idRegister: idRegisterInspection,
    });
    if (response != null) {
      setCode(response.code);
      setArea(response.area);
      setAreaResponsible(response.area_responsible);
      setInspectionResponsible(response.inspection_responsible);
      setConclusionsRecomendations(response.conclusiones_recomendations);
      setDate(response.date);
      setDescription(response.description);
      setDocumentUrl1(response.document_1); //document_url_1
      setObjective(response.objective);
      setResults(response.results);
      setType(response.type);
      setTypeDetail(response.type_detail);
      setConclusionsRecomendations(response.conclusions_recomendations);
      if (response.register_status === 1) {
        setRegisterStatus(false);
      } else {
        setRegisterStatus(true);
      }
      handleCloseBackdrop();
    } else {
      handleClickAlert("error", "Error, no se pudo obtener los datos");
      handleCloseBackdrop();
    }
  };

  // TRABAJADORES ACCIDENTADOS
  const [openModalUploadInvolvedWorkers, setOpenModalUploadInvolvedWorkers] =
    useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadInvolvedWorkers(true);
  };
  // crear trabajadores accidentados

  const yearActually = new Date().getFullYear();

  const [accidentsWorkers, setAccidentsWorkers] = useState([]);
  const [area, setArea] = useState("");
  const [shift, setShift] = useState("");

  const handleChangeShift = (event) => {
    setShift(event.target.value);
  };
  const [workExperience, setWorkExperience] = useState("");
  const [workHoursBefore, setWorkHoursBefore] = useState("");

  const handleStoreAccidentsWorkers = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.creatAccidentsWorkers({
      idRegisterInspection: idRegisterInspection,
      id_users: idResponsible,
      area: area,
      shift: shift,
      work_experience: workExperience,
      work_hours_before: workHoursBefore,
    });
    if (response.success === false) {
      console.log("error", response);
    } else {
      handleCloseModalUploadAddDates();
      handleGetAccidentsWorkers();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente los datos del trabajador"
      );
    }
    return response;
  };

  // mostrar trabajadores accidentados
  const handleGetAccidentsWorkers = async () => {
    const response = await MyRegistersAccidents.getAccidentsWorkers({
      idRegisterInspection,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setAccidentsWorkers(response);
    }
  };

  // eliminar trabajadores accidentados
  const handleDeleteAccidentsWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.deleteAccidentsWorkers({
      id_registers_accidents_workers: idRegisterInspectionWorkers,
    });
    if (response.success === true) {
      handleGetAccidentsWorkers();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se elimino correctamente el trabajador accidentado"
      );
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  /***************************  MEDIDAS CORRECTIVAS  *****************************/
  // buscar usuario por ID
  const [responsibleCorrectiveActions, setResponsibleCorrectiveActions] =
    useState(null);
  const [idResponsible, setIdResponsible] = useState(" ");

  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setResponsibleCorrectiveActions(
        response.first_name + " " + response.last_name
      );
      setOpenModalUpload(false);
      setOpenModalUploadInvolvedWorkers(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  const [idUserLogged, setIdUserLogged] = useState();

  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };

  // abrir modal de busqueda de usuarios de medidas correctivas
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const handleOpenModalResponsible = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUpload(true);
  };

  // mostrar estado de la medida correctiva
  const [rowsStatusCorrectivesActions, setRowsStatusCorrectivesActions] =
    useState([]);
  const handleGetStatusCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getStatusCorrectivesActions();
    if (response.success === false) {
    } else {
      setRowsStatusCorrectivesActions(response);
    }
    return response;
  };

  // estado de la medida correctiva
  const [statusCorrectivesActions, setStatusCorrectivesActions] =
    useState(null);

  // crear medidas correctivas
  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [description, setDescription] = useState("");

  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.createCorrectivesActions({
      idRegisterInspection: idRegisterInspection,
      id_registers_accidents_measures_statuses: statusCorrectivesActions,
      id_users: idResponsible,
      timestamp_start: timestampStart,
      timestamp_end: timestampEnd,
      description: description,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleGetCorrectivesActions();
      handleCloseModalUploadAddDates();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente la medida correctiva"
      );
    }
    return response;
  };

  // mostrar medidas correctivas
  const [correctivesActions, setCorrectivesActions] = useState([]);
  const handleGetCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getCorrectivesActionWorkers({
      idRegisterInspection,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setCorrectivesActions(response);
    }
  };

  // listar trabajdores para buscar en medidas correctivas

  const [
    rowsSearchsCorrectivesActionWorkers,
    setSearchsCorrectivesActionWorkers,
  ] = useState([]);

  const handleGetSearchsCorrectivesActionWorkers = async () => {
    const response =
      await MyRegistersAccidents.getSearchsCorrectivesActionWorkers({
        idRegisterInspection,
      });
    setSearchsCorrectivesActionWorkers(response);
  };

  // eliminar medidas correctives
  const [idAccidentsMeasures, setIdAccidentsMeasures] = useState(0);

  const handleDeleteCorrectivesActions = async () => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyRegistersAccidents.deleteCorrectivesActions({
      id_registers_accidents_measures: idAccidentsMeasures,
    });
    if (response.success === true) {
      handleGetCorrectivesActions();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert("success", "Se elimino  la medida correctiva");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // TRABAJADORES RESPONSABLES
  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyRegistersAccidents.getResponsibleWorkers({
      idRegisterInspection,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setResponsibleActions(response);
      setFirm(response.firm);
    }
  };

  //crear trabajadores responsables
  const [firm, setFirm] = useState();
  const handleStoreResponsibleWorkers = async (idUsers) => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.createResponsibleWorkers({
      idRegisterInspection: idRegisterInspection,
      id_users: idUsers,
      firm: firm,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleGetResponsibleWorkers();
      handleCloseModalUploadResponsibleRegistration();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert("success", "Se agregó correctamente ");
    }
    return response;
  };

  // eliminar trabajador responsable
  const [idRegisterInspectionResponsible, setidRegisterInspectionResponsible] =
    useState(0);

  const handleDeleteResponsibleWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.deleteResponsibleWorkers({
      id_registers_accidents_responsible: idRegisterInspectionResponsible,
    });
    if (response.success === true) {
      handleGetResponsibleWorkers();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert("success", "Se elimino  al trabajador responsable");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // Buscar trabajadores en medidas correctivas
  const [rowsSearchsResponsiblesWorkers, setSearchsResponsiblesWorkers] =
    useState([]);

  const handleGetSearchsResponsiblesWorkers = async () => {
    const response = await MyRegistersAccidents.getSearchsResponsiblesWorkers({
      idRegisterInspection,
    });
    setSearchsResponsiblesWorkers(response);
  };

  // firmar responsable
  const [openModalSignResponsible, setOpenModalSignResponsible] =
    useState(false);
  const [idAccidentsResponsible, setIdAccidentsResponsible] = useState(0);

  // firmar responsable
  const [idUserIdResponsible, setIdUserIdResponsible] = useState(0);

  const handleOpenModalSignResponsible = (
    idUserResponsible,
    idUserIdResponsible
  ) => {
    setIdAccidentsResponsible(idUserResponsible);
    setIdUserIdResponsible(idUserIdResponsible);
    setOpenModalSignResponsible(true);
  };

  // const handleSignResponsible = async (event) => {
  //   event.preventDefault();
  //   setOpenBackdrop(!openBackdrop);
  //   const response = await MyRegistersAccidents.signResponsible({
  //     id_accidents_responsible: idAccidentsResponsible,
  //   });
  //   if (response.success !== false) {
  //     handleCloseBackdrop();
  //     setOpenModalSignResponsible(false);
  //     handleGetResponsibleWorkers();
  //     handleClickAlert("success", "Se registro la firma");
  //   } else {
  //     alert("Error al obtener el usuario!");
  //   }
  // };

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(true);

  const handleGetRegisterStatus = async () => {
    const response = await MyRegistersAccidents.getRegisterStatus({
      idRegisterInspection: idRegisterInspection,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      handleEditRegistersAccidents();
    }
    return response;
  };

  // users
  // const [usersActives, setUsersActives] = useState([]);
  // const handleGetUsersActives = async () => {
  //   const response = await MyUsers.users();
  //   if (response.success === false) {
  //     alert("Error");
  //   } else {
  //     setUsersActives(response.users_actives);
  //   }
  // };
  const [openModalDatesUsers, setOpenModalDatesUers] = useState(false);

  const [userJsonDatesUsers, setuserJsonDatesUsers] = useState(false);
  const [documentDatesUsers, setDocumentDatesUsers] = useState(false);
  const [emailDatesUsers, setEmailDatesUsers] = useState(false);
  const [sexDatesUsers, setSexDatesUsers] = useState(false);
  const [phoneDatesUsers, setPhoneDatesUsers] = useState(false);
  const [ageDatesUsers, setAgeDatesUsers] = useState(false);
  const [currentTimeDatesUsers, setCurrentTimeDatesUsers] = useState(false);
  const [campusDatesUsers, setCampusDatesUsers] = useState(false);
  const [jobTypeDatesUsers, setJobTypeDatesUsers] = useState(false);

  const [areasData, setAreasData] = useState([]);
  const [inspectionData, setInspectionData] = useState([
    "Planeada",
    "No planeada",
    "Otra",
  ]);

  const handleOpenModalDataUsers = (
    userJson,
    document,
    email,
    phone,
    sex,
    age,
    currentTime,
    campus,
    jobType
  ) => {
    setuserJsonDatesUsers(userJson);
    setDocumentDatesUsers(document);
    setEmailDatesUsers(email);
    setPhoneDatesUsers(phone);
    setSexDatesUsers(sex);
    setAgeDatesUsers(age);
    setCurrentTimeDatesUsers(currentTime);
    setCampusDatesUsers(campus);
    setJobTypeDatesUsers(jobType);
    setOpenModalDatesUers(true);
  };

  //responsable del área inspeccionada

  const [openModalInspectionResponsible, setOpenModalInspectionResponsible] =
    useState(false);

  const handleOpenModalInspectionResponsible = () => {
    setOpenModalInspectionResponsible(true);
  };

  const [openModalAreaResponsible, setOpenModalAreaResponsible] =
    useState(false);

  const handleOpenModalAreaResponsible = () => {
    setOpenModalAreaResponsible(true);
  };
  //Responsables del registro y de la investigación

  const [responsibles, setResponsibles] = useState([]);
  const [idUserId, setIdUserId] = useState("");

  //obtiene responsables
  const handleGetResponsible = async () => {
    const response = await MyRegistersInspections.getResponsible({
      idRegister: idRegisterInspection,
    });

    setResponsibles(response);
  };
  //agrega responsables
  const handleStoreResponsible = async (idUsers) => {
    const response = await MyRegistersInspections.addResponsible({
      idUser: idUsers,
      idRegister: idRegisterInspection,
    });
    if (response != null) {
      handleGetResponsible();
      handleCloseModalUploadResponsibleRegistration();
    }
  };
  //elimina responsables
  const handleDeleteResponsible = async ({ idUsers }) => {
    const response = await MyRegistersInspections.deleteResponsible({
      idUser: idUsers,
      idRegister: idRegisterInspection,
    });
    handleGetResponsible();
    return response;
  };
  //firma responsables
  const handleSignResponsible = async (event) => {
    event.preventDefault();
    const response = await MyRegistersInspections.signResponsible({
      idUserId: idUserIdResponsible,
      idUser: idAccidentsResponsible,
    });
    handleGetResponsible();
    setOpenModalSignResponsible(false);
  };
  //responsables del registro y de la investigación
  const handleCloseRegister = async () => {
    const response = await MyRegistersInspections.closeRegisterInspections({
      idRegister: idRegisterInspection,
    });
    handleGetResponsible();
  };

  ///Handle get Areas

  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    console.log(response);
    setAreasData(response);
    return response;
  };

  // handle get users para el inspection responsible
  // Dialogos de confirmacion FIN
  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
    }
  };

  // jalar y mostrar datos del usuario preparado
  const [idUsersInspectionsResponsible, setIdUsersInspectionResponsible] =
    useState("");

  const handleGetUsersInspectionResponsible = async (idUser) => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setInspectionResponsible(response.first_name + " " + response.last_name);
      setIdUsersInspectionResponsible(response.id_users);
      setOpenModalInspectionResponsible(false);
      // handleOpenAlert("", "success");
    }
    handleCloseBackdrop();
  };

  const [idUsersAreaResponsible, setIdUsersAreaResponsible] = useState("");
  const handleGetUsersRevised = async (idUser) => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setAreaResponsible(response.first_name + " " + response.last_name);
      setIdUsersAreaResponsible(response.id_users);
      setOpenModalAreaResponsible(false);
      // handleOpenAlert("", "success");
    }
    handleCloseBackdrop();
  };
  ///////////////
  const [openModalUploadResponsibleAreas, setOpenModalUploadResponsibleAreas] =
    useState(false);
  const handleOpenModalUploadResponsibleAreas = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadResponsibleAreas(true);
  };

  const handleCloseModalUploadResponsibleAreas = () => {
    setOpenModalUploadResponsibleAreas(false);
  };

  const [responsiblesAreas, setResponsiblesAreas] = useState([]);

  const handleGetResponsibleAreas = async () => {
    const response = await MyRegistersInspections.getResponsibleAreas({
      idRegister: idRegisterInspection,
    });

    console.log(response);
    setResponsiblesAreas(response);
  };

  const handleStoreResponsibleAreas = async (idUsers) => {
    const response = await MyRegistersInspections.addResponsibleAreas({
      idUser: idUsers,
      idRegister: idRegisterInspection,
    });
    if (response != null) {
      handleGetResponsibleAreas();
      handleCloseModalUploadResponsibleAreas();
    }
  };

  const handleDeleteResponsibleAreas = async (idUsers) => {
    console.log(idUsers);
    const response = await MyRegistersInspections.deleteResponsibleAreas({
      idUser: idUsers,
    });
    handleGetResponsibleAreas();
    return response;
  };

  useEffect(() => {
    handleUserActive();
    // handleEditRegistersAccidents();
    handleEditRegisterInspections();

    handleGetSeverityAccidents();
    handleGetSeverityInccidents();

    handleGetAccidentsWorkers();
    // medidas correctivas
    handleGetCorrectivesActions();

    // trabajadores responsables
    handleGetResponsibleWorkers();

    handleGetResponsible();
    handleGetAreas();
    handleGetUsersActives();
    handleGetResponsibleAreas();

    // handleSelectUsersResponsible();
    // handleGetUsersActives();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      {/* <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      /> */}
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  "Inspecciones internas de seguridad y salud en el trabajo"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Nro registro"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                disabled={isWorker}
                required
              />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={4} xl={4} />
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegistersCloseInspections}>
            {/* <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Razón social o denominación social"}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <InputControlaVertical
                  text={"RUC"}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Domicilio (Dirección, distrito, etc)"}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <InputControlaVertical
                  text={"Tipo actividad económica"}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <InputControlaVertical
                  text={"N° de trabajadores en el centro fiscal"}
                />
              </Grid>
            </Grid> */}
            <Grid>
              <InputLabel
                sx={{
                  padding: "8px",
                  fontSize: "18px",
                  color: "#305AD9",
                  fontWeight: "bold",
                }}
              >
                Datos de la inspección
              </InputLabel>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <SelectControla
                  text={"Área inspeccionada"}
                  inputType={"text"}
                  inputValue={setArea}
                  value={area}  
                  modalType={false}
                  required
                  readOnly={registerStatus}
                  childrenRows={areasData.map((row) => (
                    <MenuItem key={row.id_areas} value={row.id_areas}>
                      {row.area}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inspección"}
                  inputType={"date"}
                  inputValue={setDate}
                  value={date}
                  required
                  disabled={isWorker}
                  readOnly={registerStatus}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonIconControla
                  titleLabel="Responsable del área inspeccionada "
                  inputValue={areaResponsible}
                  functionClick={handleOpenModalAreaResponsible}
                  textTooltip={
                    "Indicar nombre completo del personal que participó en la investigación interna"
                  }
                  required
                />
              </Grid> */}
              {/* responsables del area inspeccionada*/}
              <Grid container item>
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Stack direction="row" sx={{ marginBottom: 1 }}>
                    <Box>
                      <InputLabel
                        sx={{
                          padding: "8px",
                          fontSize: "18px",
                          color: "#305AD9",
                          fontWeight: "bold",
                        }}
                      >
                        Responsables del área inspeccionada
                      </InputLabel>

                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        sx={{ color: "#F2994A", ml: 1 }}
                      >
                        Este campo es obligatorio
                      </Typography>
                    </Box>
                    <Box>
                      <ButtonIconControla
                        icon={<InfoOutlined sx={{ color: "white" }} />}
                        backgroundColor={"#FFC300"}
                        backgroundColorHover={"#FACB32"}
                        disabled={isWorker}
                        textTooltip={
                          "Completar los nombres de los responsables del área o las áreas que han sido inspeccionadas en materia de seguridad y salud en el trabajo"
                        }
                        // functionOnClick={handleOpenModalTypeAgentsTable}
                      />
                    </Box>
                  </Stack>
                  <Box sx={
                    {
                      display: "flex",
                      alignItems: "center"
                    }
                  }>
                    {registerStatus === false ? (
                      <ButtonControla
                        roles = {permissions.DOC_REGIS_INSPEC_INT_AGREGAR_RESPONS}
                        iconButton={<Add sx={{ color: "white" }} />}
                        backgroundColor={"#169073"}
                        backgroundColorHover={"#1BAA88"}
                        textButton={"Agregar responsables"}
                        functionOnClick={handleOpenModalUploadResponsibleAreas}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Stack>
              </Grid>
              {/* responsables del area inspeccionada*/}

              <Grid container item >
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Nombre y Apellido
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Documento de identidad
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        ></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {responsiblesAreas.map((row) => (
                        <TableRow
                          hover
                          key={row.id_users}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.first_name + " " + row.last_name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.document}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                              {registerStatus === false ? (
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Estás seguro de eliminar los datos del trabajador responsable del área inspeccionada?",
                                      "Una vez eliminado no podrás recuperar los datos del trabajador responsable",
                                      "Si, eliminar",
                                      "deleteResponsibleAreas"
                                    );
                                  }}
                                  // functionOnClick={() =>
                                  //   handleDeleteResponsibleWorkers(
                                  //     row.id_registers_accidents_responsible
                                  //   )
                                  // }
                                />
                              ) : (
                                ""
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable de la inspección "
                  inputValue={inspectionResponsible}
                  functionClick={handleOpenModalInspectionResponsible}
                  required
                  disabledButton={isWorker}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <SelectControla
                  text={"Tipo de inspección"}
                  inputType={"text"}
                  inputValue={setType}
                  value={type}
                  required
                  disabled={isWorker}
                  readOnly={registerStatus}
                  childrenRows={inspectionData.map((row) => (
                    <MenuItem key={row} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Detalle del tipo de inspección"}
                  inputType={"text"}
                  inputValue={setTypeDetail}
                  value={typeDetail}
                  required
                  disabled={isWorker}
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Objetivo de la inspección interna"}
                  inputType={"textArea"}
                  inputValue={setObjective}
                  value={objective}
                  required
                  disabled={isWorker}
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVerticalIcon
                  text={"Resultado de la inspección interna"}
                  inputType={"textArea"}
                  inputValue={setResults}
                  value={results}
                  textTooltip={
                    "Indicar nombre completo del personal que participó en la inspección interna."
                  }
                  required
                  disabled={isWorker}
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={
                    "Descripción de la causa ante resultados desfavorables de la inspección"
                  }
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  required
                  disabled={isWorker}
                  readOnly={registerStatus}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Conclusiones  y recomendaciones"}
                  inputType={"textArea"}
                  inputValue={setConclusionsRecomendations}
                  value={conclusionsRecomendations}
                  required
                  disabled={isWorker}
                  readOnly={registerStatus}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={11} md={11}>
                <InputControlaVerticalIcon
                  text={"Adjuntar"}
                  inputType={"file"}
                  disabled={isWorker}
                  inputValue={setDocumentUrl1}
                  textTooltip={"Lista de verificación de ser el caso"}
                />
              </Grid>
              <Grid
                item
                xs={1}
                md={1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonIconControla
                  roles = {permissions.DOC_REGIS_INSPEC_INT_VER_DOCUMENTO}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl1}
                  disabled={isWorker}
                  directionTarget={"_blank"}
                  // functionOnClick={() => ""}
                />
              </Grid>
            </Grid>

            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === false ? (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={
                      handleOpenModalUploadResponsibleRegistration
                    }
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibles.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignResponsible(
                                    row.id,
                                    row.id_users
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            <img
                              src={row.firm_url}
                              alt='Firma no encontrada'
                              style={{ maxWidth: '100px', maxHeight: '100px' }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                            {registerStatus === false ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                                // functionOnClick={() =>
                                //   handleDeleteResponsibleWorkers(
                                //     row.id_registers_accidents_responsible
                                //   )
                                // }
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {/* tabla para mostrar accidents workers */}

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  // functionOnClick={() =>
                  //   (window.location.href =
                  //     "/documentation/registers/generals/inspections")
                  // }
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/inspections"}
                />
              </Grid>
              {registerStatus === false ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles = {permissions.DOC_REGIS_INSPEC_INT_ACTUALIZAR_REGIS}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                    functionOnClick={handleUpdateRegistersInspections}
                  />
                </Grid>
              ) : (
                ""
              )}
              {registerStatus === false ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles = {permissions.DOC_REGIS_INSPEC_INT_CERRAR_REGIS}
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    typeButton="submit"
                  />
                </Grid>
                
              ) : (
                ""
              )}
               {registerStatus === true ? (
              <Grid item xs={12} md={3}>
                <ButtonControla
                  roles={permissions.DOC_REGIS_INSPEC_INT_VER_PDF}
                  iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                  backgroundColor={"#F2994A"}
                  backgroundColorHover={"#FF881E"}
                  textButton={"Ver documento PDF"}
                  typeButton={"button"}
                  url={process.env.REACT_APP_API_DJANGO + 'report/'+idRegisterInspection}                 
                  target={"_blank"}
                />              
              </Grid>
                 ) : (
                  ""
                )}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog agregar trabajador involucrado */}
      <Dialog
        open={openModalUploadAddDates}
        onClose={handleCloseModalUploadAddDates}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar trabajador involucrado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreAccidentsWorkers}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Tiempo de experiencia en el puesto de trabajo"}
                  inputType={"text"}
                  inputValue={setWorkExperience}
                  value={workExperience}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Área"}
                  inputType={"text"}
                  inputValue={setArea}
                  value={area}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                {/* <InputControlaVertical
                  text={"Turno"}
                  inputType={"text"}
                  inputValue={setShift}
                  value={shift}
                  modalType={true}
                  required
                /> */}
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Turno
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={shift}
                  onChange={handleChangeShift}
                >
                  <MenuItem value={"Dia"}>Dia</MenuItem>
                  <MenuItem value={"Tarde"}>Tarde</MenuItem>
                  <MenuItem value={"Noche"}>Noche</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"N.º de horas trabajadas antes del accidente"}
                  inputType={"text"}
                  inputValue={setWorkHoursBefore}
                  value={workHoursBefore}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Trabajador involucrado: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsibleInvolvedWorkers}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadAddDates}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalUploadCorrectiveActions}
        onClose={handleCloseModalUploadCorrectiveActions}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setTimestampStart}
                  value={timestampStart}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimestampEnd}
                  value={timestampEnd}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Estado de medida correctiva:"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  required
                  value={statusCorrectivesActions}
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={rows.id_registers_accidents_measures_statuses}
                      value={rows.id_registers_accidents_measures_statuses}
                    >
                      {rows.statuses}
                    </MenuItem>
                  ))}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsible}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadCorrectiveActions}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          severity: severityAlert,
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
        open={openDelete}
        onClose={handleCloseDelete}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
          }}
        >
          Eliminar datos del trabajador accidentado
        </DialogTitle>
        <DialogContent>
          <Typography>
            Los datos del trabajador accidentado se eliminará de forma
            permanente
          </Typography>
          <Typography>¿Deseas continuar?.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button onClick={handleDeleteAccidentsWorkers}>Si, eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar usuarios de medidas correctivas */}
      <Dialog
        open={openModalUpload}
        onClose={() => {
          setOpenModalUpload(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsCorrectivesActionWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUpload(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables */}
      <Dialog
        open={openModalUploadInvolvedWorkers}
        onClose={() => {
          setOpenModalUploadInvolvedWorkers(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersSearchsAddsWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUploadInvolvedWorkers(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar responsable */}
      <Dialog
        open={openModalSignResponsible}
        onClose={() => {
          setOpenModalSignResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsible}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* modal users information */}
      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsible}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/*  */}
      <Dialog
        open={openModalUploadResponsibleAreas}
        onClose={handleCloseModalUploadResponsibleAreas}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Área Inspeccionada
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleAreas}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleAreas}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* */}
      <Dialog
        sx={{
          severity: severityAlert,
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
        open={openDelete}
        onClose={handleCloseDelete}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
          }}
        >
          Eliminar datos del trabajador accidentado
        </DialogTitle>
        <DialogContent>
          <Typography>
            Los datos del trabajador accidentado se eliminará de forma
            permanente
          </Typography>
          <Typography>¿Deseas continuar?.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button onClick={handleDeleteAccidentsWorkers}>Si, eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* users information */}
      <Dialog
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        open={openModalDatesUsers}
        onClose={() => setOpenModalDatesUers(false)}
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent>
          <CardUserControla
            users_json={userJsonDatesUsers}
            document={documentDatesUsers}
            email={emailDatesUsers}
            phone={phoneDatesUsers}
            sex={sexDatesUsers}
            age={ageDatesUsers}
            current_time={currentTimeDatesUsers}
            campus={campusDatesUsers}
            job_type={jobTypeDatesUsers}
          />{" "}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalDatesUers(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      {/*Dialog de agregar responsables de la inspeccion*/}
      <Dialog
        open={openModalInspectionResponsible}
        onClose={() => {
          setOpenModalInspectionResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Inspección
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersInspectionResponsible}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalInspectionResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalAreaResponsible}
        onClose={() => {
          setOpenModalAreaResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable del Área
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersRevised}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalAreaResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
