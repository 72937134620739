import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
    Container,
    Grid,
    MenuItem,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    InputLabel,
    Table,
    Paper,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Select
} from "@mui/material";

import {
    Add,
    Edit,
    DeleteForever,
    BorderColorOutlined,
    Delete,
    LoginOutlined,
    HistoryEduOutlined,
} from "@mui/icons-material";

import Checkbox from '@mui/material/Checkbox';

import SideNav from "../../../components/sidenav/sideNav";
import TitleText from "../../../components/text/titleText";
import SubtitleText from "../../../components/text/subtitleText";
import CompanyLogoControla from "../../../components/images/companyLogoControla";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import SelectControla from "../../../components/selects/selectControla";
import ButtonControla from "../../../components/buttons/buttonController";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import LoadingControla from "../../../components/load/loadingControla";
import SearchUsersAndAddOtherControla from "../../../components/search/searchUsersAndAddOtherControla";
import MyConfigurationApi from "../../../api/MyConfigurationApi";
import MyProceedingsBook from "../../../api/MyProceedingsBook";
import MyMeeting from "../../../api/MyMeetings";
import { useNavigate, useLocation } from 'react-router-dom';

import { styled } from "@mui/system";
import MyUsers from "../../../api/MyUsers";
const grey = "#9191B0";
const blue = "#034AFF";
const skyblue = "#2D9CDB";
const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em",
});

function EditProceedingSmallCompany() {
    const params = new URLSearchParams(window.location.search);
    const id_proceeding = params.get("id_proceeding");

    const navigate = useNavigate();
    const location = useLocation();
    const readOnly = location.state.readOnly;
    const [title, setTitle] = useState("");

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    //fin de alerts


    const [place, setPlace] = useState("");
    const [dateProceedings, setDateProceedings] = useState("");
    const [hourStart, setHourStart] = useState("");
    const [hourEnd, setHourEnd] = useState("");
    const [session, setSession] = useState("");
    const [modality, setModality] = useState("");
    const [responsible, setResponsible] = useState("");


    const [participants, setParticipants] = useState([]);
    const [guests, setGuests] = useState([]);

    const typeSession = [{ id_session: 1, nombre: "ordinario" }, { id_session: 2, nombre: "extraordinario" }];
    const typeModality = [{ id_modality: 1, nombre: "presencial" }, { id_modality: 2, nombre: "remoto" }];

    const [id_meeting, setIdMeeting] = useState("");

    const handleGetParticipantsByProceeding = async () => {

        const res = await MyProceedingsBook.getParticipantsByProceedingSmallCompany(id_proceeding);
        console.log(res)
        setParticipants(res.data);
    }

    const handleChangeAttendance = (event, id_proceedings, idMinutesParticipant) => {
        setParticipants(
            participants.map((p) => {
                if (p.id_minutes_participant === idMinutesParticipant) {
                    p.attended = event.target.checked === true ? 1 : 0;
                }
                return p;
            })
        );
    }

    const getMeetingById = async () => {
        handleOpenLoading();

        readOnly ? setTitle("Detalle de Acta") : setTitle("Editar Acta");

        const responseProceeding = await MyProceedingsBook.getProceedingById(id_proceeding);

        setIdMeeting(responseProceeding.data.id_committee_meeting);

        const resp = await MyUsers.editUsers({ idUsers: responseProceeding.data.responsible });

        setResponsible(resp.first_name + " " + resp.last_name);

        setDateProceedings(responseProceeding.data.date === null ? "" : responseProceeding.data.date);
        setHourStart(responseProceeding.data.start_time === null ? "" : responseProceeding.data.start_time.substr(0, 5));
        setHourEnd(responseProceeding.data.end_time === null ? "" : responseProceeding.data.end_time.substr(0, 5));
        setSession(responseProceeding.data.session);
        setModality(responseProceeding.data.modality);
        setPlace(responseProceeding.data.location);

        setOpenLoading(false);
    }

    const handleGetGuestByProceeding = async () => {
        const res = await MyProceedingsBook.getGuestByProceeding(id_proceeding);
        setGuests(res.data);
    }

    const getUser = () => {
        return MyConfigurationApi.userData();
    }

    const handleUpdateProceedings = async (e) => {
        e.preventDefault();
        handleOpenLoading();

        //actualizar datos
        const data = {
            location: place,
            date: dateProceedings,
            start_time: hourStart,
            end_time: hourEnd,
            session: session,
            modality: modality,
            id_users_modified: getUser().id_users,
        }
        //actualizar acta
        const r = await MyProceedingsBook.updateProceedings(data, id_proceeding);
        if (r.success === true) {
            handleOpenAlert("Se actualizó la acta con éxito", "success",);
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }

        //actualiza reunión si es acta programada
        const dataMeeting = {
            location: place,
            date: dateProceedings,
            start_time: hourStart,
            end_time: hourEnd,
            session: session,
            modality: modality,
            id_users_modified: getUser().id_users,
        }
        if (id_meeting !== null) {
            const res = await MyMeeting.updateMeeting(id_meeting, dataMeeting);

            if (r.success === true && res.success === true) {
                handleOpenAlert("Se actualizó la acta con éxito", "success");
            } else {
                handleOpenAlert("Ocurrió un error", "error");
            }
        }
    }

    //creación de invitados
    const [openModalCreateGuest, setOpenModalCreateGuest] = useState(false);
    const [users, setUsers] = useState([]);
    const [emailUsers, setEmailUsers] = useState("");
    const [nameUsers, setNameUsers] = useState("");

    const handleCloseModalCreateGuest = () => {
        setOpenModalCreateGuest(false);
    }

    const handleOpenModalCreateGuests = async () => {
        setOpenModalCreateGuest(true);

        //traer data de todo los usuarios del sistema
        const result = await MyUsers.users();
        setUsers(result.users_actives);
    }

    const onSearchValueChangeName = (event) => {
        setNameUsers(event.target.value);
    }
    const onSearchValueChangeEmail = (event) => {
        setEmailUsers(event.target.value);
    }

    const handleAddGuest = async (idUser) => {
        handleOpenLoading();

        const dataGuest = {
            name: nameUsers,
            email: emailUsers,
            id_user: null,
            id_minutes: id_proceeding,
        }
        if (idUser !== null) {
            const user = users.filter((u) => u.id_users === idUser);

            dataGuest.name = user[0].first_name + user[0].last_name;
            dataGuest.email = user[0].email;
            dataGuest.id_user = idUser;
        }
        const response = await MyProceedingsBook.postGuestForProceeding(dataGuest);
        if (response.success === true) {
            handleGetGuestByProceeding();
            handleOpenAlert("Se agregó al invitado con éxito", "success");

        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }

        handleCloseModalCreateGuest();

    }

    const handleDeleteGuest = async (idGuest) => {
        handleOpenLoading();

        const res = await MyProceedingsBook.deleteGuestByProceeding(idGuest);

        if (res.success === true) {
            handleOpenAlert("Se eliminó el invitado con éxito", "success",);
            handleGetGuestByProceeding();
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    //modal de temas
    const [openModalAddThemes, setOpenModalAddThemes] = useState(false);
    const handleOpenModalAddThemes = () => {
        setOpenModalAddThemes(true);
    }

    const handleCloseModalAddThemes = () => {
        setOpenModalAddThemes(false);
    }

    //temas
    const [listThemesSelected, setListThemesSelected] = useState([]);

    const getThemesByProceeding = async () => {
        const res = await MyProceedingsBook.getThemesByProceedingId(id_proceeding);
        setListThemesSelected(res.data);
    }
    const handleAddTheme = async () => {
        handleOpenLoading();

        // agregar tema al acta
        try {
            const dataTheme = {
                id_committee_theme: themeSelected,
                id_minutes: id_proceeding,
                name: titleTheme,
                id_users_created: getUser().id_users,
                id_users_modified: getUser().id_users
            }
            const res = await MyProceedingsBook.postThemesForProceeding(dataTheme);
            getThemesByProceeding();

            // cerrar modal de seleccionar tema
            handleCloseModalAddThemes();

            //limpiar el select, input de título
            setThemeSelected("");
            setTitleTheme("");

            handleOpenAlert("Se agregó el tema con éxito", "success");

        } catch (e) {
            handleOpenAlert("Ocurrió un error", "error");
        }

    }
    const handleDeleteThemeOption = async (id_theme_proceeding) => {
        handleOpenLoading();
        // eliminar tema del acta
        const response = await MyProceedingsBook.deleteThemeForProceeding(id_theme_proceeding);

        if (response.success === true) {
            getThemesByProceeding();
            handleOpenAlert("Se eliminó el tema con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    //catalogo de temas
    const [themeSelected, setThemeSelected] = useState("");
    const [themes, setThemes] = useState([]);

    const getThemes = async () => {
        const response = await MyMeeting.getThemesSmallCompany();
        setThemes(response.data);
    }
    const [titleTheme, setTitleTheme] = useState("");

    //modal de editar tema nuevo
    const [openModalEditTheme, setOpenModalEditTheme] = useState(false);
    const [idThemeEdit, setIdThemeEdit] = useState(null);

    const handleOpenModalEditTheme = () => {
        setOpenModalEditTheme(true);
    }
    const handleCloseModalEditTheme = () => {
        setOpenModalEditTheme(false);
    }

    const handleEditThemeOption = async (event) => {
        event.preventDefault();
        handleOpenLoading();

        const dataTheme = {
            name: titleTheme,
            id_users_modified: getUser().id_users
        }

        const response = await MyProceedingsBook.updateThemeForProceeding(idThemeEdit, dataTheme);

        if (response.success === true) {
            getThemesByProceeding();
            handleOpenAlert("Se actualizó el título correctamente", "success");
            handleCloseModalEditTheme();
            //limpiar
            setTitleTheme("");
        } else {
            handleOpenAlert("Ha ocurrido un error", "error");
        }
    }

    //
    const handleAddTemplateByTheme = async (idThemeSelected) => {

        //temas que existen para el acta
        // const response = await MyProceedingsBook.getThemesByProceedingId(id_proceeding);
        // const themeSelected = response.data.filter((t) => t.id_committee_minutes_theme === idThemeSelected);

        // if (themeSelected.length === 0) {
        //     console.log("1")
        //     //registrar tema nuevo
        //     const dataTheme = {
        //         id_committee_theme: themeSelected.id_committee_theme,
        //         id_minutes: id_proceeding,
        //         name: null,
        //         id_users_created: getUser().id_users,
        //         id_users_modified: getUser().id_users
        //     }
        //     const res = await MyProceedingsBook.postThemesForProceeding(dataTheme);
        //     navigate(`/proceedingBook/editProceeding/informationTheme?id_theme=${idThemeSelected}&id_proceeding=${id_proceeding}`);

        // } else {
        //mostrar plantilla
        const params = {
            readOnly: readOnly
        }
        navigate(`/proceedingBook/editProceeding/informationTheme?id_theme=${idThemeSelected}&id_proceeding=${id_proceeding}`, { state: params });
        // }
    }

    const handleSignParticipant = async (id_minutes_participant) => {
        handleOpenLoading();

        const data = {
            id_minutes_participant: id_minutes_participant,
            attended: 1,
            signature: 1
        }

        const resp = await MyProceedingsBook.updateAttendedMember(id_proceeding, data);
        if (resp.success == true) {
            handleGetParticipantsByProceeding();
            handleOpenAlert("Se firmó y marcó asistencia con éxito", "success");
        } else {
            handleOpenAlert("Ha ocurrido un error", "error");
        }
    }


    useEffect(() => {
        getMeetingById();
        handleGetGuestByProceeding();
        handleGetParticipantsByProceeding();
        getThemes();
        getThemesByProceeding();
    }, []);

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack="" />

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <Container sx={{ paddingBottom: 4 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <form onSubmit={handleUpdateProceedings}>
                        <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={2}
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SubtitleText
                                    text={"Libro de Actas"}
                                    color={grey}
                                ></SubtitleText>
                            </Grid>

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <TitleText
                                    text={title}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <CompanyLogoControla />
                            </Grid>

                            <Grid item xs={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Responsable del acta:"}
                                    inputType={"text"}
                                    inputValue={setResponsible}
                                    modalType={false}
                                    value={responsible}
                                    readOnly
                                    required
                                />

                            </Grid>
                            <Grid item xs={6} md={6} lg={6} xl={6}></Grid>

                            <Grid item xs={2} md={2} lg={2} xl={2}>
                                <InputControlaVertical
                                    text={"Fecha"}
                                    inputType={"date"}
                                    inputValue={setDateProceedings}
                                    modalType={false}
                                    value={dateProceedings}
                                    readOnly={readOnly}
                                    required
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <InputControlaVertical
                                    text={"Hora de Inicio"}
                                    inputType={"time"}
                                    inputValue={setHourStart}
                                    modalType={false}
                                    value={hourStart}
                                    readOnly={readOnly}
                                    required
                                />
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <InputControlaVertical
                                    text={"Hora de Fin"}
                                    inputType={"time"}
                                    inputValue={setHourEnd}
                                    modalType={false}
                                    value={hourEnd}
                                    readOnly={readOnly}
                                    required
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                <SelectControla
                                    text="Sesión:"
                                    inputValue={setSession}
                                    modalType={false}
                                    required
                                    value={session || ''}
                                    childrenRows={typeSession.map((rows) => (
                                        <MenuItem key={rows.id_session} value={rows.id_session}>
                                            {rows.nombre}
                                        </MenuItem>
                                    ))}
                                    readOnly={readOnly}
                                // disabled = {!isEdit}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                <SelectControla
                                    text="Modalidad:"
                                    inputValue={setModality}
                                    modalType={false}
                                    required
                                    value={modality || ''}
                                    childrenRows={typeModality.map((rows) => (
                                        <MenuItem key={rows.id_modality} value={rows.id_modality}>
                                            {rows.nombre}
                                        </MenuItem>
                                    ))}
                                    readOnly={readOnly}
                                // disabled = {!isEdit}
                                />
                            </Grid>
                            {
                                modality == "1" ? (
                                    <Grid item xs={8} md={8} lg={8} xl={8}>
                                        <InputControlaVertical
                                            text={"Lugar"}
                                            inputType={"text"}
                                            inputValue={setPlace}
                                            modalType={false}
                                            value={place}
                                            readOnly={readOnly}
                                            // disabled = {!isEdit}
                                            required
                                        />
                                    </Grid>
                                ) : ''
                            }


                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <StyledTypography
                                    sx={{
                                        padding: "8px",
                                        fontSize: "20px",
                                        color: "#305AD9",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Participantes
                                </StyledTypography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    Participante
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    Cargo
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    Asistencia
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    Firma
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {participants.map((p, index) => (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                >
                                                    <TableCell align="center">
                                                        {p.name + " " + p.last_name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {p.position_name}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Checkbox
                                                            key={index}
                                                            checked={p.attended === 0 ? false : true}
                                                            onChange={(event) => handleChangeAttendance(event, p.id_minutes, p.id_minutes_participant)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            color="success"
                                                            disabled={true}
                                                        />

                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            p.id_users == getUser().id_users ? (
                                                                p.signature == "1" ? (<img src={p.firm_url} alt="No se encuentra firma" width={"100px"}></img>) : (
                                                                    <ButtonIconControla
                                                                        icon={<HistoryEduOutlined sx={{ color: "white" }} />}
                                                                        backgroundColor={"#F1C40F"}
                                                                        backgroundColorHover={"#F4D03F"}
                                                                        textTooltip={"Firmar"}
                                                                        functionOnClick={() => handleSignParticipant(p.id_minutes_participant)}
                                                                    />

                                                                )



                                                            ) : (p.signature == null ? "No firmado" : (<img src={p.firm_url} alt="No se encuentra firma" width={"100px"}></img>))

                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <StyledTypography
                                    sx={{
                                        padding: "8px",
                                        fontSize: "20px",
                                        color: "#305AD9",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Invitados de la Reunión
                                </StyledTypography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {
                                    readOnly ? "" : (
                                        <ButtonControla
                                            textButton={"Agregar invitados"}
                                            iconButton={<Add />}
                                            backgroundColor={skyblue}
                                            backgroundColorHover={"#58C0FB"}
                                            functionOnClick={handleOpenModalCreateGuests}
                                        />

                                    )
                                }
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    Nombre
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    Email
                                                </TableCell>
                                                {
                                                    readOnly ? "" : (
                                                        <TableCell sx={{ color: blue }} align="center">
                                                            Acciones
                                                        </TableCell>
                                                    )
                                                }

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {guests.map((guest) => (
                                                <TableRow
                                                    hover
                                                >
                                                    <TableCell align="center">
                                                        {guest.name}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {guest.email}
                                                    </TableCell>
                                                    {
                                                        readOnly ? "" : (
                                                            <TableCell align="center">

                                                                <ButtonControla
                                                                    textButton={"Eliminar"}
                                                                    backgroundColor={"#EB5757"}
                                                                    backgroundColorHover={"#FF4040"}
                                                                    iconButton={<DeleteForever />}
                                                                    functionOnClick={() => { handleDeleteGuest(guest.id_committee_minutes_guest) }}
                                                                />
                                                            </TableCell>

                                                        )
                                                    }

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <StyledTypography
                                    sx={{
                                        padding: "8px",
                                        fontSize: "20px",
                                        color: "#305AD9",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Temas a tratar
                                </StyledTypography>
                            </Grid>


                            {readOnly ? "" : (
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                    <ButtonControla

                                        textButton={"Agregar tema"}
                                        iconButton={<Add />}
                                        backgroundColor={skyblue}
                                        backgroundColorHover={"#58C0FB"}
                                        functionOnClick={handleOpenModalAddThemes}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TableContainer component={Paper}>
                                    <Table
                                        stickyHeader
                                        sx={{ minWidth: 650 }}
                                        aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    Descripción
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    Acciones
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listThemesSelected.map((t, i) => (
                                                <TableRow
                                                    hover
                                                    key={i}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                    >
                                                        {t.name}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignContent="center"
                                                        >
                                                            {
                                                                readOnly ? "" : (
                                                                    t.id_committee_theme == 37 ? (
                                                                        <ButtonIconControla
                                                                        icon={<BorderColorOutlined sx={{ color: "white" }} />}
                                                                        backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"}
                                                                        textTooltip={"Editar"}
                                                                        functionOnClick={() => {
                                                                            handleOpenModalEditTheme();
                                                                            setIdThemeEdit(t.id_committee_minutes_theme);
                                                                            setTitleTheme(t.name);
                                                                        }}
                                                                    />
                                                                    ):""
                                                                )
                                                            }
                                                            <ButtonIconControla
                                                                icon={<LoginOutlined sx={{ color: "white" }} />}
                                                                backgroundColor={"#24996A"}
                                                                backgroundColorHover={"#1AA483"}
                                                                textTooltip={" documento"}
                                                                functionOnClick={() => {
                                                                    handleAddTemplateByTheme(t.id_committee_minutes_theme)
                                                                }}
                                                            />
                                                            {
                                                                readOnly ? " " : (
                                                                    <ButtonIconControla
                                                                        icon={<Delete sx={{ color: "white" }} />}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Eliminar"}
                                                                        functionOnClick={() => { handleDeleteThemeOption(t.id_committee_minutes_theme) }}
                                                                    />
                                                                )
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction={"row"} spacing={3} justifyContent={"center"}>
                                    <Box>
                                        <ButtonControla
                                            backgroundColor={"#CBCBFF"}
                                            textButton={"Regresar"}
                                            backgroundColorHover={"#CBCBFF"}
                                            typeButton={"button"}
                                            isNavegationOrigin={true}
                                            url={-1}
                                        />
                                    </Box>
                                    {
                                        readOnly ? "" : (
                                            <Box>
                                                <ButtonControla
                                                    iconButton={<Edit sx={{ color: "white" }} />}
                                                    backgroundColor={"#169073"}
                                                    backgroundColorHover={"#1BAA88"}
                                                    textButton={"Actualizar acta"}
                                                    typeButton={"submit"}
                                                />
                                            </Box>

                                        )
                                    }

                                </Stack>
                            </Grid>
                        </Grid>
                    </form>


                    {/* //modal asignar invitados*/}
                    <Dialog
                        open={openModalCreateGuest}
                        onClose={handleCloseModalCreateGuest}
                        maxWidth="sm"
                        fullWidth={true}
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                    >
                        <DialogTitleControla
                            titleText="Agregar Invitado"
                            functionOnClose={handleCloseModalCreateGuest}
                        />
                        <DialogContent>

                            <SearchUsersAndAddOtherControla
                                rowsUser={users}
                                showInputAlternative={true}
                                onSearchValueChangeName={onSearchValueChangeName}
                                onSearchValueChangeEmail={onSearchValueChangeEmail}
                                handleUsersActionButton={(id_users) => { handleAddGuest(id_users) }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Stack direction={"row"} spacing={2}>
                                <Box>
                                    <ButtonControla
                                        backgroundColor={"#CBCBFF"}
                                        textButton={"Cancelar"}
                                        backgroundColorHover={"#CBCBFF"}
                                        typeButton={"button"}
                                        functionOnClick={() => handleCloseModalCreateGuest()}
                                    />
                                </Box>
                            </Stack>
                        </DialogActions>
                    </Dialog>

                    {/* modal de agregar temas */}
                    <Dialog
                        open={openModalAddThemes}
                        onClose={handleCloseModalAddThemes}
                        maxWidth="sm"
                        fullWidth={true}
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                    >
                        <DialogTitleControla
                            titleText="Agregar Tema a la Agenda"
                            functionOnClose={handleCloseModalAddThemes}
                        />
                        <DialogContent>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}
                                container
                                columnSpacing={2}
                                alignItems="center"
                            >

                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <InputLabel
                                        sx={{
                                            marginTop: '20px',
                                            padding: '8px',
                                            fontSize: '18px',
                                            color: '#305AD9',
                                            fontWeight: 'bold'
                                        }}
                                        xs={4}
                                    >Temas</InputLabel>

                                </Grid>
                                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <Select
                                        size="small"
                                        value={themeSelected || ""}
                                        displayEmpty
                                        onChange={(event) => {
                                            setThemeSelected(event.target.value)
                                        }}
                                        sx={{
                                            marginTop: '20px',
                                            fontFamily: 'Roboto',
                                            width: "100%",
                                            backgroundColor: ('#FFFFFF'),
                                            height: "42px",
                                            color: '#305AD9',
                                        }}
                                    >
                                        {themes.map((t) => (
                                            <MenuItem
                                                key={t.id_committee_theme} value={t.id_committee_theme}>
                                                {t.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                                    sx={{ display: themeSelected !== 37 ? "none" : " " }}
                                >
                                    <InputControlaVertical
                                        text={"Título"}
                                        inputType={"text"}
                                        inputValue={setTitleTheme}
                                        modalType={true}
                                        value={titleTheme || ''}
                                        readOnly={false}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Stack direction={"row"} spacing={2}>
                                <Box>
                                    <ButtonControla
                                        backgroundColor={"#CBCBFF"}
                                        textButton={"Cancelar"}
                                        backgroundColorHover={"#CBCBFF"}
                                        typeButton={"button"}
                                        functionOnClick={() => handleCloseModalAddThemes()}
                                    />
                                </Box>
                                <Box>
                                    <ButtonControla
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar"}
                                        typeButton={"button"}
                                        functionOnClick={() => handleAddTheme()}
                                    />
                                </Box>

                            </Stack>
                        </DialogActions>
                    </Dialog>

                    {/* modal de editar titulo del tema */}
                    <Dialog
                        open={openModalEditTheme}
                        onClose={handleCloseModalEditTheme}
                        maxWidth="sm"
                        fullWidth={true}
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                    >
                        <DialogTitleControla
                            titleText="Editar Tema"
                            functionOnClose={handleCloseModalEditTheme}
                        />

                        <form onSubmit={handleEditThemeOption}>
                            <DialogContent>
                                <InputControlaVertical
                                    text={"Título"}
                                    inputType={"text"}
                                    inputValue={setTitleTheme}
                                    modalType={true}
                                    value={titleTheme || ''}
                                    required
                                />
                            </DialogContent>
                            <DialogActions>
                                <Stack direction={"row"} spacing={2}>
                                    <Box>
                                        <ButtonControla
                                            backgroundColor={"#CBCBFF"}
                                            textButton={"Cancelar"}
                                            backgroundColorHover={"#CBCBFF"}
                                            typeButton={"button"}
                                            functionOnClick={() => handleCloseModalEditTheme()}
                                        />
                                    </Box>
                                    <Box>
                                        <ButtonControla
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Actualizar"}
                                            typeButton={"submit"}
                                        />
                                    </Box>
                                </Stack>
                            </DialogActions>
                        </form>

                    </Dialog>



                </Box>
            </Container>
        </Box>

    )

}

export default EditProceedingSmallCompany;