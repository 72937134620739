import React, { Component, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const EditorSsoPoliticsControlaTwo = ({
  dataEdit,
  handleDataEditor = alert,
  disabled = false
}) => {
  const template = `
  <p>
    INGRESAR NOMBRE, empresa dedicada a INGRESAR OBJETO SOCIAL del
    sector INGRESAR SECTOR está comprometida en propiciar el
    mejoramiento de las condiciones de trabajo, salud y seguridad
    en todos los niveles de nuestra organización, trabajadores,
    contratistas y partes interesadas mediante el desarrollo del
    Sistema de Gestión de Seguridad en el Trabajo.
  </p>
  <p>
    Este sistema está orientado al desarrollo de un proceso lógico
    y por etapas basado en la mejora continua que incluye la
    Política, la planificación, la organización, la aplicación, la
    evaluación, la auditoría y acciones de mejora con el objetivo
    de anticipar, reconocer, evaluar y controlar los riesgos que
    pueden afectar la seguridad y salud en el trabajo en toda
    nuestra cadena de valor.
  </P>
  <p>
    INGRESAR NOMBRE asume la responsabilidad de proteger la salud
    y la seguridad de todos los trabajadores independientemente de
    su vinculación laboral en los diferentes ambientes de trabajo,
    por tanto, mantiene unas condiciones seguras y saludables en
    los lugares de trabajo.
  </p>
  <ul>
    <li>
    Desarrollar sus actividades protegiendo la integridad física
    de sus colaboradores, proveedores y la de terceros en las
    instalaciones pertenecientes a la empresa, mediante la
    prevención de lesiones, dolencias, enfermedades e incidentes
    relacionados con el trabajo, gestionando los peligros
    relacionados a las actividades de la empresa.
    </li>
    <li>
    Cumplir con la normativa legal vigente, regulaciones y
    compromisos voluntarios, suscritos por la organización en
    materia de seguridad y salud en el trabajo.
    </li>
    <li>
    Asegurar la consulta, participación, información y
    capacitación activa de nuestro personal en todos los aspectos
    del Sistema de Gestión de Seguridad y Salud en el Trabajo de
    nuestra empresa.
    </li>
    <li>
    Mejorar continuamente el desempeño del Sistema de Gestión de
    Seguridad y Salud en el trabajo e integrarlo a las demás
    actividades y otros sistemas de la empresa.
    </li>
  </ul>`;
  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        disabled={disabled}
        data={dataEdit === null ? template : dataEdit}
        onReady={(editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // You can store the "editor" and use when it is needed.
          // console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // console.log({ data });
        }}
        // onChange={(event, editor) => dataEditor(editor.getData())}

        onBlur={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
        onFocus={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
      />
    </div>
  );
};
