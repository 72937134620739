import * as React from "react";
import SubtitleText from "../../components/text/subtitleText";
import TitleText from "../../components/text/titleText";
import Container from "@mui/material/Container";
// import DocCard from "../../components/cards/docCard";
// import Link from "@mui/material/Link";
import SideNav from "../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import CardControla from "../../components/cards/cardControla";

const colorTitle = "#9191B0";
// const colorOneCardOne = "#e7e7e7";

function Index() {
  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F4F6" }}>
      <SideNav returnBack="/committee" />    

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid xs={12} md={12}>
              {/* <SubtitleText text={"Comité"} color={colorTitle}></SubtitleText> */}
              <TitleText text={"Comité General"} color={colorTitle}></TitleText>
            </Grid>
            <Grid xs={12} md={12}>
              {/* <DocBanner color1={colorOneBanner} color2={colorTwoBanner} image={"/assets/images/framecomite.png"}></DocBanner> */}
              <img
                src="/assets/images/committeeBanner.png"
                style={{ width: "100%" }}
                alt="Banner"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/committee/presentCommittee/currentCommittee/"}
                text={"Información del Comité"}
                img={"/assets/images/banners/committee/presentCommittee.png"}
              />
            </Grid>
            {/* <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                 url={"/committee/presentCommittee/"}
                text={"Histórico"}
                img={"/assets/images/banners/committee/historical.png"}
              />
            </Grid> */}
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                 url={"/committee/presentCommittee/"}
                text={"Reglamento Interno del comité"}
                img={"/assets/images/banners/committee/reglamentoInterno.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                 url={"/committee/presentCommittee/"}
                text={"Derechos y deberes de los miembros"}
                img={"/assets/images/banners/committee/derechoMiembros.png"}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Index;
