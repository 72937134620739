import React, { Component, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { templateGeneral } from "./templateGeneral";
import "./editorControlaStyle.css"

export const EditorInternalRegulationsCommitteeGeneral = ({ dataEdit, defaultTemplate, handleDataEditor = alert, ...rest })=>{
    const template = templateGeneral ;

    return (
        <div className="Container-editor">
            <CKEditor
                editor={ClassicEditor}
                {...rest}
                data={dataEdit === null||dataEdit === ""  ? template : dataEdit}
                onReady={(editor) => {
                    const data = editor.getData();
                    handleDataEditor(data);
                    // You can store the "editor" and use when it is needed.
                    // console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    handleDataEditor(data);
                }}
                // onChange={(event, editor) => dataEditor(editor.getData())}

                onBlur={(event, editor) => {
                    const data = editor.getData();
                    handleDataEditor(data);
                }}
                onFocus={(event, editor) => {
                    const data = editor.getData();
                    handleDataEditor(data);
                }}
            />
        </div>
    );
};