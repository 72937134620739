import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
  VisibilityOutlined,
  BorderColorOutlined,
  PictureAsPdf,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import CardUserControla from "../../../../components/cards/cardUserControla";
import MyAreas from "../../../../api/MyAreas";
import CardUserControlaAccident from "../../../../components/cards/cardUserControlaAccidents";
import { useAppContext } from "../../../../context/AppContext";
import MyTasksNew from "../../../../api/MyTasksNew";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";

function Edit() {
  const { permissions } = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idRegistersAccidents = params.get("idRegistersAccidents");

  // BACKDROP
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;
  const [idRegistersAccidentsWorkers, setIdRegistersAccidentsWorkers] =
    useState();

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = (idRegisterAccidentsWorkersValue) => {
    setOpenDelete(true);
    setIdRegistersAccidentsWorkers(idRegisterAccidentsWorkersValue);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdRegistersAccidentsWorkers(id);
    setIdAccidentsMeasures(id);
    setIdRegister(id);
    setIdRegistersAccidentsResponsible(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteWorkers":
        handleDeleteAccidentsWorkers();
        break;
      case "deleteMeasures":
        handleDeleteCorrectivesActions();
        break;
      case "deleteResponsible":
        handleDeleteResponsibleWorkers();
        break;
      case "deleteTypeMildAccident":
        handleDeleteTypeMildAccident();
        break;
      case "deleteTypeDisablingAccident":
        handleDeleteTypeDisablingAccident();
        break;
      case "deleteTypeMortalAccident":
        handleDeleteTypeMortalAccident();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  // DIALOG

  //  abrir diálogo del buscador de responsable de medidas correctivas

  // agregar datos del trabajador DIALOG
  const [openModalUploadAddDates, setOpenModalUploadAddDates] = useState(false);
  const handleOpenModalUploadAddDates = () => {
    setWorkExperience(null);
    setArea(null);
    setShift(null);
    setWorkHoursBefore(null);
    setResponsibleCorrectiveActions("");
    handleGetUsersSearchsAddsWorkers();
    setOpenModalUploadAddDates(true);
  };

  const handleCloseModalUploadAddDates = () => {
    handleGetAccidentsWorkers();
    setOpenModalUploadAddDates(false);
  };

  // agregar medidas correctivas DIALOG
  const [
    openModalUploadCorrectiveActions,
    setOpenModalUploadCorrectiveActions,
  ] = useState(false);

  const handleOpenModalUploadCorrectiveActions = () => {
    setTimestampStart(null);
    setTimestampEnd(null);
    setDescription(null);
    setStatusCorrectivesActions(null);
    handleGetStatusCorrectivesActions();
    handleGetSearchsCorrectivesActionWorkers();
    setOpenModalUploadCorrectiveActions(true);
  };

  const handleCloseModalUploadCorrectiveActions = () => {
    setResponsibleCorrectiveActions("");
    handleGetCorrectivesActions();
    setOpenModalUploadCorrectiveActions(false);
  };

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadCResponsibleRegistration(true);
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    handleGetResponsibleWorkers();
    setOpenModalUploadCResponsibleRegistration(false);
  };

  // ACCIDENTE DE TRABAJO

  // Gravedad del accidente de trabajo
  const [severityAccidents, setSeverityAccidents] = useState(0);
  const [rowsSeverityWorkAccidents, setRowsSeverityWorkAccidents] = useState(
    []
  );

  const handleGetSeverityAccidents = async () => {
    const response = await MyRegistersAccidents.severityWorkAccident();
    if (response.success === false) {
    } else {
      setRowsSeverityWorkAccidents(response);
    }
    return response;
  };

  const [code, setCode] = useState("");
  const [affectedWorkers, setAffectedWorkers] = useState("");
  const [causesDescription, setcausesDescription] = useState("");
  const [descriptionWork, setDescriptionWork] = useState("");
  const [injuredBodyPartDescription, setInjuredBodyPartDescription] =
    useState("");
  const [location, setLocation] = useState("");
  const [medicalLeaveDays, setMedicalLeaveDays] = useState("");
  const [timestampAccident, setTimestampAccident] = useState("");
  const [timestampInvestigation, setTimestampInvestigation] = useState("");
  const [documentUrl1, setDocumentUrl1] = useState(null);
  const [documentUrl2, setDocumentUrl2] = useState(null);
  const [documentUrl3, setDocumentUrl3] = useState(null);

  // severidad del accidente
  const [severityIncidents, setSeverityIncidents] = useState(0);
  const [rowsSeverityIncidents, setRowsSeverityIncidents] = useState([]);

  const handleGetSeverityInccidents = async () => {
    const response = await MyRegistersAccidents.severityIncidents();
    if (response.success === false) {
    } else {
      setRowsSeverityIncidents(response);
    }
    return response;
  };

  // get Usuarios a buscar al agregar trabajadores
  const [rowsUsersSearchsAddsWorkers, setRowsUsersSearchsAddsWorkers] =
    useState([]);

  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyRegistersAccidents.getUserSearchsAddsWorkers({
      idRegistersAccidents,
    });
    setRowsUsersSearchsAddsWorkers(response);
  };
  // update register accidents
  const handleUpdateRegistersAccidents = async (event) => {
    setOpenBackdrop(!openBackdrop);
    event.preventDefault();
    const response = await MyRegistersAccidents.updateRegistersAccidents({
      idRegistersAccidents,
      code: code,
      timestamp_accident: timestampAccident,
      timestamp_investigation: timestampInvestigation,
      location: location,
      id_registers_accidents_severities: severityAccidents,
      id_registers_accidents_severities_incident: severityIncidents,
      medical_leave_days: medicalLeaveDays,
      affected_workers: affectedWorkers,
      injured_body_part_desciption: injuredBodyPartDescription,
      description: descriptionWork,
      document_url_1: documentUrl1,
      document_url_2: documentUrl2,
      document_url_3: documentUrl3,
      causes_description: causesDescription,
    });

    if (response.success === false) {
      console.log("error al actualizar");
    } else {
      handleGetAccidentsWorkers();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se guardaron de forma correcta los datos del registro"
      );
      window.location.reload();
    }
    return response;
  };

  // edit register acidents
  const handleEditRegistersAccidents = async () => {
    const response = await MyRegistersAccidents.editRegistersAccidents({
      idRegistersAccidents,
    });

    if (response.success === false) {
      alert("Error");
    } else {
      setRegisterStatus(
        response.id_registers_accidents_statuses === 1 ? true : false
      );
      setCode(response.code);
      setAffectedWorkers(response.affected_workers);
      setcausesDescription(response.causes_description);
      setInjuredBodyPartDescription(response.injured_body_part_desciption);
      setLocation(response.location);
      setMedicalLeaveDays(response.medical_leave_days);
      setTimestampAccident(
        response.timestamp_accident === null
          ? ""
          : response.timestamp_accident.substring(0, 16)
      );
      setTimestampInvestigation(
        response.timestamp_investigation === null
          ? ""
          : response.timestamp_investigation.substring(0, 10)
      );
      setDocumentUrl1(response.document_url_1);
      setDocumentUrl2(response.document_url_2);
      setDocumentUrl3(response.document_url_3);
      setDescriptionWork(response.description);
      setSeverityAccidents(response.id_registers_accidents_severities);
      setSeverityIncidents(response.id_registers_accidents_severities_incident);
    }
    return response;
  };

  // TRABAJADORES ACCIDENTADOS
  const [openModalUploadInvolvedWorkers, setOpenModalUploadInvolvedWorkers] =
    useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadInvolvedWorkers(true);
  };
  // crear trabajadores accidentados

  const yearActually = new Date().getFullYear();

  const [accidentsWorkers, setAccidentsWorkers] = useState([]);
  const [area, setArea] = useState("");
  const [rowsAreaWorkerInvolved, setRowsAreaWorkerInvolved] = useState([]);
  //mostrar las áreas desde configuración
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setRowsAreaWorkerInvolved(response);
    return response;
  };

  const [shift, setShift] = useState("");

  const handleChangeShift = (event) => {
    setShift(event.target.value);
  };
  const [workExperience, setWorkExperience] = useState("");
  const [workHoursBefore, setWorkHoursBefore] = useState("");

  const handleStoreAccidentsWorkers = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.creatAccidentsWorkers({
      idRegistersAccidents: idRegistersAccidents,
      id_users: idResponsible,
      area: area,
      shift: shift,
      work_experience: workExperience,
      work_hours_before: workHoursBefore,
    });
    if (response.success === false) {
      console.log("error", response);
    } else {
      await handleGetAccidentsWorkers();
      handleCloseModalUploadAddDates();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente los datos del trabajador"
      );
    }
    return response;
  };

  // mostrar trabajadores accidentados
  const handleGetAccidentsWorkers = async () => {
    const response = await MyRegistersAccidents.getAccidentsWorkers({
      idRegistersAccidents,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setAccidentsWorkers(response);
    }
  };

  // eliminar trabajadores accidentados
  const handleDeleteAccidentsWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.deleteAccidentsWorkers({
      id_registers_accidents_workers: idRegistersAccidentsWorkers,
    });
    if (response.success === true) {
      await handleGetAccidentsWorkers();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se elimino correctamente el trabajador accidentado"
      );
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  /***************************  MEDIDAS CORRECTIVAS  *****************************/
  // buscar usuario por ID
  const [responsibleCorrectiveActions, setResponsibleCorrectiveActions] =
    useState(null);
  const [idResponsible, setIdResponsible] = useState(" ");

  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setResponsibleCorrectiveActions(
        response.first_name + " " + response.last_name
      );
      setOpenModalUpload(false);
      setOpenModalUploadInvolvedWorkers(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  const [idUserLogged, setIdUserLogged] = useState();

  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };

  // abrir modal de busqueda de usuarios de medidas correctivas
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const handleOpenModalResponsible = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUpload(true);
  };

  // mostrar estado de la medida correctiva
  const [rowsStatusCorrectivesActions, setRowsStatusCorrectivesActions] =
    useState([]);
  const handleGetStatusCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getStatusCorrectivesActions();
    if (response.success === false) {
    } else {
      setRowsStatusCorrectivesActions(response);
    }
    return response;
  };

  // estado de la medida correctiva
  const [statusCorrectivesActions, setStatusCorrectivesActions] =
    useState(null);

  // crear medidas correctivas
  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [description, setDescription] = useState("");

  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.createCorrectivesActions({
      idRegistersAccidents: idRegistersAccidents,
      id_registers_accidents_measures_statuses: statusCorrectivesActions,
      id_users: idResponsible,
      timestamp_start: timestampStart,
      timestamp_end: timestampEnd,
      description: description,
    });

    // agregar a tarea
  
    const data = {
      date_start: timestampStart,
      date_end: timestampEnd,
      description: description,
      id_status: 1,
      id_users: idResponsible,
      id_tasks_types: 2,
      title: "Tarea asignada en accidentes de trabajo",
      origin_url: `documentation/registers/generals/accidents/edit?idRegistersAccidents=${idRegistersAccidents}`
    }

    const res  = await MyTasksNew.postTasks(data);

    if (response.success === false) {
      console.log("error", response);
    } else {
      await handleGetCorrectivesActions();
      handleCloseModalUploadAddDates();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente la medida correctiva"
      );
    }
    return response;
  };

  // mostrar medidas correctivas
  const [correctivesActions, setCorrectivesActions] = useState([]);
  const handleGetCorrectivesActions = async () => {
    const response = await MyRegistersAccidents.getCorrectivesActionWorkers({
      idRegistersAccidents,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setCorrectivesActions(response);
    }
  };

  // listar trabajdores para buscar en medidas correctivas

  const [
    rowsSearchsCorrectivesActionWorkers,
    setSearchsCorrectivesActionWorkers,
  ] = useState([]);

  const handleGetSearchsCorrectivesActionWorkers = async () => {
    const resp = await MyUsers.users() ?? [];
    setSearchsCorrectivesActionWorkers(resp.users_actives); 
  };

  // eliminar medidas correctives
  const [idAccidentsMeasures, setIdAccidentsMeasures] = useState(0);

  const handleDeleteCorrectivesActions = async () => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyRegistersAccidents.deleteCorrectivesActions({
      id_registers_accidents_measures: idAccidentsMeasures,
    });
    if (response.success === true) {
      await handleGetCorrectivesActions();
      handleCloseBackdrop();
      handleCloseDelete();
      handleClickAlert("success", "Se elimino  la medida correctiva");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // TRABAJADORES RESPONSABLES
  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyRegistersAccidents.getResponsibleWorkers({
      idRegistersAccidents,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setResponsibleActions(response);
      setFirm(response.firm);
    }
  };

  //crear trabajadores responsables
  const [firm, setFirm] = useState();
  const handleStoreResponsibleWorkers = async (idUsers) => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.createResponsibleWorkers({
      idRegistersAccidents: idRegistersAccidents,
      id_users: idUsers,
      firm: firm,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      await handleGetResponsibleWorkers();
      handleCloseModalUploadResponsibleRegistration();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert("success", "Se agregó correctamente ");
    }
    return response;
  };

  // eliminar trabajador responsable
  const [idRegistersAccidentsResponsible, setIdRegistersAccidentsResponsible] =
    useState(0);

  const handleDeleteResponsibleWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.deleteResponsibleWorkers({
      id_registers_accidents_responsible: idRegistersAccidentsResponsible,
    });
    if (response.success === true) {
      await handleGetResponsibleWorkers();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert("success", "Se elimino  al trabajador responsable");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // Buscar trabajadores en medidas correctivas
  const [rowsSearchsResponsiblesWorkers, setSearchsResponsiblesWorkers] =
    useState([]);

  const handleGetSearchsResponsiblesWorkers = async () => {
    const response = await MyRegistersAccidents.getSearchsResponsiblesWorkers({
      idRegistersAccidents,
    });
    setSearchsResponsiblesWorkers(response);
  };

  // firmar responsable
  const [openModalSignResponsible, setOpenModalSignResponsible] =
    useState(false);
  const [idAccidentsResponsible, setIdAccidentsResponsible] = useState(0);

  const handleOpenModalSignResponsible = (idUserResposible) => {
    setIdAccidentsResponsible(idUserResposible);
    setOpenModalSignResponsible(true);
  };

  const handleSignResponsible = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyRegistersAccidents.signResponsible({
      id_accidents_responsible: idAccidentsResponsible,
    });
    if (response.success !== false) {
      handleCloseBackdrop();
      setOpenModalSignResponsible(false);
      handleGetResponsibleWorkers();
      handleClickAlert("success", "Se registro la firma");
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(true);

  const handleGetRegisterStatus = async () => {
    if (
      responsibleWorkers.length >= 1 &&
      correctivesActions.length >= 1 &&
      accidentsWorkers.length >= 1
    ) {
      const finded = responsibleWorkers.find((item) => item.firm === 0);

      if (finded === undefined) {
        const response = await MyRegistersAccidents.getRegisterStatus({
          idRegistersAccidents: idRegistersAccidents,
        });
        if (response.success === false) {
          alert("Error");
        } else {
          handleEditRegistersAccidents();
        }
        return response;
      } else {
        // setOpenModalSignResponsible(false);
        // handleGetResponsibleWorkers();
        handleClickAlert("error", "Registre firmas pendientes");
      }
    } else {
      // setOpenModalSignResponsible(false);
      // handleGetResponsibleWorkers();
      handleClickAlert("error", "Registre datos en las secciones: Trabajador involucrado, medidas correctivas y agregar responsable");
    }
  };

  const handleGeneratePDF = async () => {
    try {
      const response = await MyRegistersAccidents.generatePDF({idRegistersAccidents});
      const blob = await response.blob();

      // Crear un enlace temporal para descargar el archivo
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `register-${idRegistersAccidents}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch {
      console.error('Hubo un error con la generacion del pdf', 'error');
    }
  };


  const [openModalDatesUsers, setOpenModalDatesUers] = useState(false);

  const [userJsonDatesUsers, setuserJsonDatesUsers] = useState(false);
  const [documentDatesUsers, setDocumentDatesUsers] = useState(false);
  const [emailDatesUsers, setEmailDatesUsers] = useState(false);
  const [sexDatesUsers, setSexDatesUsers] = useState(false);
  const [phoneDatesUsers, setPhoneDatesUsers] = useState(false);
  const [ageDatesUsers, setAgeDatesUsers] = useState(false);
  const [currentTimeDatesUsers, setCurrentTimeDatesUsers] = useState(false);
  const [campusDatesUsers, setCampusDatesUsers] = useState(false);
  const [jobTypeDatesUsers, setJobTypeDatesUsers] = useState(false);

  const handleOpenModalDataUsers = (
    userJson,
    document,
    email,
    phone,
    sex,
    age,
    currentTime,
    campus,
    jobType
  ) => {
    setuserJsonDatesUsers(userJson);
    setDocumentDatesUsers(document);
    setEmailDatesUsers(email);
    setPhoneDatesUsers(phone);
    setSexDatesUsers(sex);
    setAgeDatesUsers(age);
    setCurrentTimeDatesUsers(currentTime);
    setCampusDatesUsers(campus);
    setJobTypeDatesUsers(jobType);
    setOpenModalDatesUers(true);
  };

  //------------- dialogo por tipo de gravedad del accidente-----------
  const [openModalTypeMild, setOpenModalTypeMild] = useState(false);
  const [openModalTypeDisabling, setOpenModalTypeDisabling] = useState(false);
  const [openModalTypeMortal, setOpenModalTypeMortal] = useState(false);
  const [typeActionButtonModal, setTypeActionButtonModal] = useState(null);
  const [typeModal, setTypeModal] = useState(null);
  const [idRegister, setIdRegister] = useState(null);

  const [rowsTypeMildAccident, setRowsTypeMildAccident] = useState([]);
  const [rowsTypeDisablingAccident, setRowsTypeDisablingAccident] = useState([]);
  const [rowsTypeMortalAccident, setRowsTypeMortalAccident] = useState([]);
  const [numberAffected, setNumberAffected] = useState(null);
  const [numberMedicalRest, setNumberMedicalRest] = useState(null);

  const handleOpenModalTypeMild = (typeModal, typeAcctionButton, data) => {
    setOpenModalTypeMild(true);

    setTypeModal(typeModal);
    setTypeActionButtonModal(typeAcctionButton);
    if (typeModal == "update") {
      setIdRegister(data.id_accident_type_minor) 
      setNumberAffected(data.affected_workers);
      setNumberMedicalRest(data.medical_rest_days);
    }else{
      setNumberAffected(null);
      setNumberMedicalRest(null);
    }
  }
  const handleCloseModalTypeMild = () =>{
    setOpenModalTypeMild(false);
  }

  const handleOpenModalTypeDisabling = (typeModal, typeAcctionButton, data)=>{
    setOpenModalTypeDisabling(true);

    setTypeModal(typeModal);
    setTypeActionButtonModal(typeAcctionButton);
    if(typeModal == "update"){
      setIdRegister(data.id_accident_type_disabling)
      setSeverityIncidents(data.id_registers_accidents_severities_incident)
      setNumberAffected(data.affected_workers)
      setNumberMedicalRest(data.medical_rest_days)

    }else{
      setSeverityIncidents(null)
      setNumberAffected(null)
      setNumberMedicalRest(null)
    }
  }
  const handleCloseModalTypeDisabling = ()=>{
    setOpenModalTypeDisabling(false);
  }

  const handleOpenModalTypeMortal = (typeModal, typeAcctionButton, data)=>{
    setOpenModalTypeMortal(true);

    setTypeModal(typeModal);
    setTypeActionButtonModal(typeAcctionButton);
    if(typeModal == "update"){
      setIdRegister(data.id_accident_type_mortal)
      setNumberAffected(data.affected_workers);
      setNumberMedicalRest(data.medical_rest_days);
    }else{
      setNumberAffected(null);
      setNumberMedicalRest(null);
    }
  }
  const handleCloseModalTypeMortal = ()=>{
    setOpenModalTypeMortal(false);
  }

  const handleActionButtonModal = (e) => {
    e.preventDefault();

    switch(typeActionButtonModal) {
      case "createTypeMild":
        handlePostTypeMildAccident();
        break;
      case "updateTypeMild":
        handleUpdateTypeMildAccident();
        break;
      case "createTypeDisabling":
        handlePostTypeDisablingAccident();
        break;
      case "updateTypeDisabling":
        handleUpdateTypeDisablingAccident();
        break;
      case "createTypeMortal":
        handlePostTypeMortalAccident();
        break;
      case "updateTypeMortal":
        handleUpdateTypeMortalAccident();
        break;
    }

  }

  const handleGetTypeMildAccident = async() => {
    setOpenBackdrop(true);

      const resp = await MyRegistersAccidents.getMildSeverityAccidents(idRegistersAccidents) ??[];
      if(resp.success == true){
        setRowsTypeMildAccident(resp.data);
      }else{
        handleClickAlert("error", "Problemas al traer la lista de accidentes leves");
      }
      
    handleCloseBackdrop();
  }
  const handlePostTypeMildAccident = async () => {
    setOpenBackdrop(true); 

    const data = {
      id_registers_accidents: idRegistersAccidents,
      medical_rest_days: numberMedicalRest,
      affected_workers: numberAffected,
    }
    try {
      const resp = await MyRegistersAccidents.postMildSeverityAccident(data);
      await handleGetTypeMildAccident();
      handleClickAlert("success", "Se registró el accidente leve con éxito");

    } catch (e) {
      handleClickAlert("error", "Ocurrió un problema al registrar");
    }
    handleCloseBackdrop();
    handleCloseModalTypeMild();
  }
  const handleUpdateTypeMildAccident = async () => {
    setOpenBackdrop(true);

    const data = {
      affected_workers: numberAffected,
      medical_rest_days: numberMedicalRest,
    }

    try{
      const resp = await MyRegistersAccidents.updateMildSeverityAccident(data, idRegister)

      await handleGetTypeMildAccident();
      handleClickAlert("success", "Se actualizó el registro con éxito");

    }catch(e){
      handleClickAlert("error", "Ocurrió un error al actualizar");
    }

    handleCloseBackdrop();
    handleCloseModalTypeMild();

  }
  const handleDeleteTypeMildAccident = async() => {
    setOpenBackdrop(true);
    try{
      const resp = await MyRegistersAccidents.deleteMildSeverityAccident(idRegister);
      await handleGetTypeMildAccident();
      handleClickAlert("success", "Se eliminó el registro con éxito");
    }catch(e){
      handleClickAlert("error", "Ocurrió un error al eliminar");
    }

    handleCloseBackdrop();
    handleCloseModalConfirm();
  }

  const handleGetTypeDisablingAccident = async() => {
  
      const resp = await MyRegistersAccidents.getDisablingSeverityAccident(idRegistersAccidents) ?? [];

      if(resp.success == true){
        setRowsTypeDisablingAccident(resp.data);
      }else{
        handleClickAlert("error", "Problemas al traer accidentes de tipo incapacitante");
      }
  }
  const handlePostTypeDisablingAccident = async() => {
    setOpenBackdrop(true);
    const data = {
      id_registers_accidents: idRegistersAccidents,
      id_registers_accidents_severities_incident: severityIncidents ,
      medical_rest_days: numberMedicalRest,
      affected_workers: numberAffected,
    }

    try{
      const resp = await MyRegistersAccidents.postDisablingSeverityAccident(data);
      await handleGetTypeDisablingAccident();
      handleClickAlert("success", "Se registró el accidente de tipo incapacitante con éxito");

    }catch(e){
      handleClickAlert("error", "Ocurrió un error al registrar");
    }

    handleCloseBackdrop();
    handleCloseModalTypeDisabling();
  }
  const handleUpdateTypeDisablingAccident = async() => {
    setOpenBackdrop(true);
    const data = {
      // id_registers_accidents: idRegistersAccidents,
      id_registers_accidents_severities_incident: severityIncidents ,
      medical_rest_days: numberMedicalRest,
      affected_workers: numberAffected,
    }
    try{
      const resp = await MyRegistersAccidents.updateDisablingSeverityAccident(data, idRegister);
      await handleGetTypeDisablingAccident();
      handleClickAlert("success", "Se actualizó el registro con éxito");
    }catch(e){
      handleClickAlert("error","Ocurrió un error al actualizar");
    }

    handleCloseModalTypeDisabling();
    handleCloseBackdrop();
  }
  const handleDeleteTypeDisablingAccident = async() => {
    setOpenBackdrop(true);

    try{
      const resp = await MyRegistersAccidents.deleteDisablingSeverityAccident(idRegister);

      await handleGetTypeDisablingAccident();
      handleClickAlert("success", "Se eliminó el registro con éxito");
    }catch(e){
      handleClickAlert("error", "Ocurrió un error al eliminar");
    }

    handleCloseModalConfirm();
    handleCloseBackdrop();
  }

  const handleGetTypeMortalAccident = async() => {
    
      const resp = await MyRegistersAccidents.getMortalSeveretyAccident(idRegistersAccidents)?? [];
      if(resp.success == true){
        setRowsTypeMortalAccident(resp.data);

      }else{
        handleClickAlert("error","Problemas al traer accidentes de tipo mortal");

      }
  }
  const handlePostTypeMortalAccident = async() => {
    setOpenBackdrop(true);
    const data = {
      id_registers_accidents: idRegistersAccidents,
      medical_rest_days: numberMedicalRest,
      affected_workers: numberAffected,
    }

    try{
      const resp = await MyRegistersAccidents.postMortalSeveretyAccident(data);
      await handleGetTypeMortalAccident();
      handleClickAlert("success", "Se registró el accidente de tipo mortal con éxito");

    }catch(e){
      handleClickAlert("error", "Ocurrió un error al registrar");
    }

    handleCloseModalTypeMortal();
    handleCloseBackdrop();
  }
  const handleUpdateTypeMortalAccident = async() => {
    setOpenBackdrop(true);
    const data = {
      medical_rest_days: numberMedicalRest,
      affected_workers: numberAffected,
    }

    try{
      const resp = await MyRegistersAccidents.updateMortalSeveretyAccident(data, idRegister);
      await handleGetTypeMortalAccident();
      handleClickAlert("success", "Se actualizó el registro con éxito");
    }catch(e){
      handleClickAlert("error", "Ocurrió un error al actualizar");
    }

    handleCloseModalTypeMortal();
    handleCloseBackdrop();
  }
  const handleDeleteTypeMortalAccident = async() => {
    setOpenBackdrop(true);
    try{
      const resp = await MyRegistersAccidents.deleteMortalSeveretyAccident(idRegister);
      await handleGetTypeMortalAccident();
      handleClickAlert("success", "Se eliminó el registro con éxito");
    }catch(e){
      handleClickAlert("error", "Ocurrió un error al eliminar");
    }

    handleCloseModalConfirm();
    handleCloseBackdrop();
  }


  useEffect(() => {
    handleUserActive();
    handleEditRegistersAccidents();

    handleGetSeverityAccidents();
    handleGetSeverityInccidents();
    handleGetAccidentsWorkers();

    // medidas correctivas
    handleGetCorrectivesActions();

    // trabajadores responsables
    handleGetResponsibleWorkers();

    // handleSelectUsersResponsible();
    // handleGetUsersActives();
    handleGetAreas();

    handleGetTypeMildAccident();
    handleGetTypeDisablingAccident();
    handleGetTypeMortalAccident();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      {/* <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      /> */}
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText text={"Accidentes de trabajo"}></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Nro registro"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                readOnly={!registerStatus}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegistersAccidents}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Datos del trabajador
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    roles={[1, 3, 4, 7, 8]}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModalUploadAddDates}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de Identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Edad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Área
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Turno
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tiempo de experiencia en el puesto de trabajo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N.º de horas trabajadas antes del accidente
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {accidentsWorkers.map((rowsAccidentsWorkers) => (
                      <TableRow
                        hover
                        key={rowsAccidentsWorkers.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rowsAccidentsWorkers.first_name +
                            " " +
                            rowsAccidentsWorkers.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowsAccidentsWorkers.document}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowsAccidentsWorkers.birthday ? 
                            yearActually - rowsAccidentsWorkers.birthday.substring(0, 4)
                            : 'Fecha de nacimiento no disponible'
                          }
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowsAccidentsWorkers.area}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowsAccidentsWorkers.shift}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowsAccidentsWorkers.work_experience}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rowsAccidentsWorkers.work_hours_before}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonIconControla
                              roles={[1, 3, 4, 7, 8, 12, 13]}
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              functionOnClick={() =>
                                handleOpenModalDataUsers(
                                  rowsAccidentsWorkers,
                                  true,
                                  true,
                                  true,
                                  true,
                                  true,
                                  true,
                                  true,
                                  true
                                )
                              }
                            />
                            {registerStatus === true ? (
                              <ButtonIconControla
                                roles={permissions.DOC_REGIS_AT_AGREGAR_DATOS}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rowsAccidentsWorkers.id_registers_accidents_workers,
                                    "¿Esta seguro de eliminar los datos del trabajador?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador",
                                    "Si, Eliminar",
                                    "deleteWorkers"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Investigación del accidente de trabajo"}
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  text={"Fecha y hora de ocurrencia del accidente"}
                  inputType={"datetime-local"}
                  inputValue={setTimestampAccident}
                  value={timestampAccident}
                  required
                  readOnly={!registerStatus}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  text={"Fecha de inicio de la investigación"}
                  inputType={"date"}
                  inputValue={setTimestampInvestigation}
                  value={timestampInvestigation}
                  required
                  readOnly={!registerStatus}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  text={"Lugar exacto donde ocurrió el accidente"}
                  inputType={"text"}
                  inputValue={setLocation}
                  value={location}
                  required
                  readOnly={!registerStatus}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
            </Grid>

            {/* Investigación del accidente de trabajo */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Investigación del accidente de trabajo"}
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>

              {/* tipo leve */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Gravedad del accidente del trabajo: Leve
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es opcional de acuerdo al accidente
                    </Typography>
                  </Box>
                  <Box>
                    {
                      registerStatus ? (
                        <ButtonControla
                          iconButton={<Add sx={{ color: "white" }} />}
                          backgroundColor={"#169073"}
                          backgroundColorHover={"#1BAA88"}
                          textButton={"Agregar datos"}
                          functionOnClick={() => handleOpenModalTypeMild("create", "createTypeMild", null)}
                        />
                      ) : ""
                    }
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 600 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >N° trabajadores afectados</TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >N° días de descanso médico</TableCell>
                        {
                          registerStatus ? (
                            <TableCell
                              sx={{
                                color: "#305ad9",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            ></TableCell>

                          ) : ""
                        }
                      </TableRow>       
                    </TableHead>
                    <TableBody>
                      {
                        rowsTypeMildAccident.map((row) => (
                          <TableRow
                            hover
                            key={row.id_accident_type_minor}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.affected_workers}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.medical_rest_days}
                            </TableCell>
                            {
                              registerStatus ? (
                                <TableCell align="right">
                                  <Stack
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <ButtonIconControla
                                      icon={
                                        <BorderColorOutlined
                                          sx={{ color: "white" }}
                                        />
                                      }
                                      backgroundColor={"#2D9CDB"}
                                      backgroundColorHover={"#33AEF4"}
                                      textTooltip={"Editar"}
                                      functionOnClick={() => handleOpenModalTypeMild("update", "updateTypeMild", row)}
                                    />

                                    <ButtonIconControla
                                      icon={<Delete sx={{ color: "white" }} />}
                                      backgroundColor={"#EB5757"}
                                      backgroundColorHover={"#FF4040"}
                                      textTooltip={"Eliminar"}
                                      functionOnClick={() => handleOpenModalConfirm(
                                        row.id_accident_type_minor,
                                        "Estás seguro de eliminar los datos del accidente leve?",
                                        "Una vez eliminado no se podrá recuperar la información.",
                                        "Si, eliminar",
                                        "deleteTypeMildAccident"
                                      )}
                                    />
                                  </Stack>
                                </TableCell>
                              ) : ""
                            }

                            
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>

              </Grid>

              {/* tipo incapacitante */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Gravedad del accidente del trabajo: Incapacitante
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es opcional de acuerdo al accidente
                    </Typography>
                  </Box>
                  <Box>
                    {
                      registerStatus ? (
                        <ButtonControla
                          iconButton={<Add sx={{ color: "white" }} />}
                          backgroundColor={"#169073"}
                          backgroundColorHover={"#1BAA88"}
                          textButton={"Agregar datos"}
                          functionOnClick={() => handleOpenModalTypeDisabling("create", "createTypeDisabling", null)}
                        />
                      ) : ""
                    }
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 600 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >N° trabajadores afectados</TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >Gravedad del accidente incapacitante</TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >N° días de descanso médico</TableCell>
                        {
                          registerStatus ? (
                            <TableCell
                              sx={{
                                color: "#305ad9",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            ></TableCell>

                          ) : ""
                        }                       
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        rowsTypeDisablingAccident.map((row) => (
                          <TableRow
                            hover
                            key={row.id_accident_type_disabling}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.affected_workers}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {rowsSeverityIncidents.filter((d) => d.id_registers_accidents_severities_incident == row.id_registers_accidents_severities_incident)[0]?.register_accident_severity_incident}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.medical_rest_days}
                            </TableCell>
                            {
                              registerStatus ? (
                                <TableCell align="right">
                                  <Stack
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <ButtonIconControla
                                      icon={
                                        <BorderColorOutlined
                                          sx={{ color: "white" }}
                                        />
                                      }
                                      backgroundColor={"#2D9CDB"}
                                      backgroundColorHover={"#33AEF4"}
                                      textTooltip={"Editar"}
                                      functionOnClick={() => handleOpenModalTypeDisabling(
                                        "update",
                                        "updateTypeDisabling",
                                        row
                                      )}
                                    />

                                    <ButtonIconControla
                                      icon={<Delete sx={{ color: "white" }} />}
                                      backgroundColor={"#EB5757"}
                                      backgroundColorHover={"#FF4040"}
                                      textTooltip={"Eliminar"}
                                      functionOnClick={() => handleOpenModalConfirm(
                                        row.id_accident_type_disabling,
                                        "¿Estás seguro de eliminar los datos del accidente incapacitante?",
                                        "Una vez eliminado no se podrá recuperar la información.",
                                        "Si, eliminar",
                                        "deleteTypeDisablingAccident"
                                      )}
                                    />

                                  </Stack>
                                </TableCell>
                              ) : ""
                            }                          
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              
              {/* tipo mortal */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Gravedad del accidente del trabajo: Mortal
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es opcional de acuerdo al accidente
                    </Typography>
                  </Box>
                  <Box>
                    {
                      registerStatus ? (
                        <ButtonControla
                          iconButton={<Add sx={{ color: "white" }} />}
                          backgroundColor={"#169073"}
                          backgroundColorHover={"#1BAA88"}
                          textButton={"Agregar datos"}
                          functionOnClick={() => handleOpenModalTypeMortal("create", "createTypeMortal", null)}
                        />
                      ) : ""
                    }
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 600 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >N° trabajadores afectados</TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >N° días de descanso médico</TableCell>
                        {
                          registerStatus ? (
                            <TableCell
                              sx={{
                                color: "#305ad9",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            ></TableCell>

                          ) : ""
                        }                       
                      </TableRow>

                    </TableHead>
                    <TableBody>
                      {
                        rowsTypeMortalAccident.map((row) => (
                          <TableRow
                            hover
                            key={row.id_accident_type_mortal}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.affected_workers}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.medical_rest_days}
                            </TableCell>
                            {
                              registerStatus ? (
                                <TableCell align="right">
                                  <Stack
                                    direction="row"
                                    justifyContent="end"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <ButtonIconControla
                                      icon={
                                        <BorderColorOutlined
                                          sx={{ color: "white" }}
                                        />
                                      }
                                      backgroundColor={"#2D9CDB"}
                                      backgroundColorHover={"#33AEF4"}
                                      textTooltip={"Editar"}
                                      functionOnClick={() => handleOpenModalTypeMortal("update", "updateTypeMortal", row)}
                                    />

                                    <ButtonIconControla
                                      icon={<Delete sx={{ color: "white" }} />}
                                      backgroundColor={"#EB5757"}
                                      backgroundColorHover={"#FF4040"}
                                      textTooltip={"Eliminar"}
                                      functionOnClick={() => handleOpenModalConfirm(
                                        row.id_accident_type_mortal,
                                        "¿Estás seguro de eliminar los datos del accidente mortal?",
                                        "Una vez eliminado no se podrá recuperar la información.",
                                        "Si, eliminar",
                                        "deleteTypeMortalAccident"
                                      )}
                                    />
                                  </Stack>
                                </TableCell>

                              ) : ""
                            }                        
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Describir parte del cuerpo lesionado(de ser el caso)"}
                  inputType={"textArea"}
                  inputValue={setInjuredBodyPartDescription}
                  value={injuredBodyPartDescription}
                  required
                  readOnly={!registerStatus}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción del accidente de trabajo"}
                  inputType={"textArea"}
                  inputValue={setDescriptionWork}
                  value={descriptionWork}
                  readOnly={!registerStatus}
                />
              </Grid>
            </Grid>

            {/* adjuntar */}

            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText text={"Adjuntar"} color={blue} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10} md={10}>
                <InputControlaVertical
                  roles={[1, 3, 4, 7, 8]}
                  text={
                    "Declaración del afectado sobre el accidente de trabajo"
                  }
                  inputType={"file"}
                  inputValue={setDocumentUrl1}
                  disabled={!registerStatus}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <ButtonIconControla
                  roles={[1, 3, 4, 7, 8, 12, 13]}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl1}
                  directionTarget={"_blank"}
                />
              </Grid>
              <Grid item xs={10} md={10}>
                <InputControlaVertical
                  roles={[1, 3, 4, 7, 8]}
                  text={"Declaración de testigos(de ser el caso)"}
                  inputType={"file"}
                  inputValue={setDocumentUrl2}
                  disabled={!registerStatus}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <ButtonIconControla
                  roles={[1, 3, 4, 7, 8, 12, 13]}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl2}
                  directionTarget={"_blank"}
                  // functionOnClick={() => ""}
                />
              </Grid>
              <Grid item xs={10} md={10}>
                <InputControlaVertical
                  roles={[1, 3, 4, 7, 8]}
                  text={
                    "Procedimientos, planos, registros, entre otros que ayuden a la investigación de ser el caso"
                  }
                  inputType={"file"}
                  inputValue={setDocumentUrl3}
                  disabled={!registerStatus}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <ButtonIconControla
                  roles={[1, 3, 4, 7, 8, 12, 13]}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl3}
                  directionTarget={"_blank"}
                  // functionOnClick={() => ""}
                />
              </Grid>
            </Grid>

            {/* descripcion de las causas del accidente de trabajo */}

            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Descripcion de las causas del accidente de trabajo"}
                  color={blue}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="h6" sx={{ color: "#305ad9" }}>
                Cada empresa o entidad pública o privada, puede adoptar el
                modelo de determinación de causas, que mejor se adapte a sus
                características y debe adjuntar al presente formato el
                desarrollo de la misma.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <InputControlaVertical
                inputType={"textArea"}
                inputValue={setcausesDescription}
                value={causesDescription}
                required
                readOnly={!registerStatus}
              />
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{ color: "#F2994A", ml: 1 }}
              >
                Este campo es obligatorio
              </Typography>
            </Grid>

            {/********************************  medidas correctivas  ********************************************** */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Medidas correctivas
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_AGREGAR_MEDIDAS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar medidas correctivas"}
                    functionOnClick={handleOpenModalUploadCorrectiveActions}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Descripción de la medida correctiva
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Responsable de la implementación
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Estado
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de inicio
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de fin
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {correctivesActions.map((rows) => (
                      <TableRow
                        hover
                        key={rows.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rows.description}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.first_name + " " + rows.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.id_registers_accidents_measures_statuses === 1
                            ? "En proceso"
                            : rows.id_registers_accidents_measures_statuses ===
                              2
                            ? "Finalizada"
                            : rows.id_registers_accidents_measures_statuses ===
                              3
                            ? "Vencida"
                            : "Cancelada"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.timestamp_start.substring(0, 10)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.timestamp_end === null
                            ? "No definido"
                            : rows.timestamp_end.substring(0, 10)}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus === true ? (
                              <ButtonIconControla
                                roles={[1, 3, 4, 7, 8]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rows.id_registers_accidents_measures,
                                    "¿Esta seguro de eliminar los datos de la medida correctiva?",
                                    "Una vez eliminado no se podra recuperar los datos de la medida correctiva",
                                    "Si, Eliminar",
                                    "deleteMeasures"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro y de la investigación
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_AGREGAR_RESPONS}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={
                      handleOpenModalUploadResponsibleRegistration
                    }
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm === 0 ? (
                            registerStatus && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignResponsible(
                                    row.id_registers_accidents_responsible
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            "Firmado"
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus === true ? (
                              <ButtonIconControla
                                roles={[1, 3, 4, 7, 8]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_accidents_responsible,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={ "/documentation/registers/generals/accidents"}
                />
              </Grid>
              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_ACTUALIZAR_REGIS}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                    // functionOnClick={handleUpdateRegistersAccidents}
                  />
                </Grid>
              ) : (
                ""
              )}
              {registerStatus == false && (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_CERRAR_REGIS}
                    iconButton={<PictureAsPdf fontSize="inherit" />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textButton={"Ver Documento PDF"}
                    functionOnClick={handleGeneratePDF}
                  />
                </Grid>
              )}

              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={permissions.DOC_REGIS_AT_CERRAR_REGIS}
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    functionOnClick={handleGetRegisterStatus}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog agregar trabajador involucrado */}
      <Dialog
        open={openModalUploadAddDates}
        onClose={handleCloseModalUploadAddDates}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar trabajador involucrado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreAccidentsWorkers}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Tiempo de experiencia en el puesto de trabajo"}
                  inputType={"text"}
                  inputValue={setWorkExperience}
                  value={workExperience}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <SelectControla
                  text="Área:"
                  inputValue={setArea}
                  required
                  value={area}
                  childrenRows={rowsAreaWorkerInvolved.map((rows) => (
                    <MenuItem key={rows.id_areas} value={rows.id_areas}>
                      {rows.area}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Turno
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={shift}
                  onChange={handleChangeShift}
                >
                  <MenuItem value={"Dia"}>Dia</MenuItem>
                  <MenuItem value={"Tarde"}>Tarde</MenuItem>
                  <MenuItem value={"Noche"}>Noche</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"N.º de horas trabajadas antes del accidente"}
                  inputType={"Number"}
                  inputValue={setWorkHoursBefore}
                  value={workHoursBefore}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Trabajador involucrado: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsibleInvolvedWorkers}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadAddDates}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalUploadCorrectiveActions}
        onClose={handleCloseModalUploadCorrectiveActions}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setTimestampStart}
                  value={timestampStart}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimestampEnd}
                  value={timestampEnd}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Estado de medida correctiva:"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  required
                  value={statusCorrectivesActions}
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={rows.id_registers_accidents_measures_statuses}
                      value={rows.id_registers_accidents_measures_statuses}
                    >
                      {rows.statuses}
                    </MenuItem>
                  ))}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable de la implementación: "
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalResponsible}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadCorrectiveActions}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la investigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          severity: severityAlert,
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
        open={openDelete}
        onClose={handleCloseDelete}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
          }}
        >
          Eliminar datos del trabajador accidentado
        </DialogTitle>
        <DialogContent>
          <Typography>
            Los datos del trabajador accidentado se eliminará de forma
            permanente
          </Typography>
          <Typography>¿Deseas continuar?.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button onClick={handleDeleteAccidentsWorkers}>Si, eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar usuarios de medidas correctivas */}
      <Dialog
        open={openModalUpload}
        onClose={() => {
          setOpenModalUpload(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsCorrectivesActionWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUpload(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables */}
      <Dialog
        open={openModalUploadInvolvedWorkers}
        onClose={() => {
          setOpenModalUploadInvolvedWorkers(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersSearchsAddsWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUploadInvolvedWorkers(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar responsable */}
      <Dialog
        open={openModalSignResponsible}
        onClose={() => {
          setOpenModalSignResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsible}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* modal users information */}
      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          severity: severityAlert,
          zIndex: (theme) => theme.zIndex.drawer + 0,
        }}
        open={openDelete}
        onClose={handleCloseDelete}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          sx={{
            color: "#1638F2",
            fontWeight: "bold",
          }}
        >
          Eliminar datos del trabajador accidentado
        </DialogTitle>
        <DialogContent>
          <Typography>
            Los datos del trabajador accidentado se eliminará de forma
            permanente
          </Typography>
          <Typography>¿Deseas continuar?.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancelar</Button>
          <Button onClick={handleDeleteAccidentsWorkers}>Si, eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* users information */}
      <Dialog
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        open={openModalDatesUsers}
        onClose={() => setOpenModalDatesUers(false)}
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent>
          <CardUserControlaAccident
            users_json={userJsonDatesUsers}
            document={documentDatesUsers}
            email={emailDatesUsers}
            phone={phoneDatesUsers}
            sex={sexDatesUsers}
            age={ageDatesUsers}
            current_time={currentTimeDatesUsers}
            campus={campusDatesUsers}
            job_type={jobTypeDatesUsers}
          />{" "}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalDatesUers(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>

      {/* dialogo de por  gravedad leve */}
      <Dialog
        open={openModalTypeMild}
        onClose={handleCloseModalTypeMild}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleControla
          titleText={typeModal == "update" ? "Actualizar datos del accidente leve" :"Agregar datos del accidente leve"}
          functionOnClose={handleCloseModalTypeMild}
        />
        <form onSubmit={handleActionButtonModal}>
          <DialogContent>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de trabajadores afectados"}
                  inputType={"number"}
                  inputValue={setNumberAffected}
                  value={numberAffected}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de días de descanso médico"}
                  inputType={"number"}
                  inputValue={setNumberMedicalRest}
                  value={numberMedicalRest}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalTypeMild}
              />
              <ButtonControla
                textButton={typeModal == "create" ?  "Crear" : "Actualizar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de por  gravedad incapacitante */}
      <Dialog
        open={openModalTypeDisabling}
        onClose={handleCloseModalTypeDisabling}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleControla
          titleText={typeModal== "create" ? "Agregar datos del accidente incapacitante" : "Actualizar datos del accidente incapacitante"}
          functionOnClose={handleCloseModalTypeDisabling}
        />
        <form onSubmit={handleActionButtonModal}>
          <DialogContent>
            <Grid container spacing={2} justifyContent={"center"}>

              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <SelectControla
                  text="Gravedad del accidente incapacitante:"
                  inputValue={setSeverityIncidents}
                  modalType={true}
                  value={severityIncidents}
                  childrenRows={rowsSeverityIncidents.map((row) => (
                    <MenuItem
                      key={row.id_registers_accidents_severities_incident}
                      value={row.id_registers_accidents_severities_incident}
                    >
                      {row.register_accident_severity_incident}
                    </MenuItem>
                  ))}
                  required
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de trabajadores afectados"}
                  inputType={"number"}
                  inputValue={setNumberAffected}
                  value={numberAffected}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de días de descanso médico"}
                  inputType={"number"}
                  inputValue={setNumberMedicalRest}
                  value={numberMedicalRest}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalTypeDisabling}
              />
              <ButtonControla
                textButton={typeModal == "create" ? "Crear" : "Actualizar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de por  gravedad mortal */}
      <Dialog
        open={openModalTypeMortal}
        onClose={handleCloseModalTypeMortal}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitleControla
          titleText={typeModal == "create" ?"Agregar datos del accidente mortal": "Actualizar datos del accidente mortal" }
          functionOnClose={handleCloseModalTypeMortal}
        />
        <form onSubmit={handleActionButtonModal}>
          <DialogContent>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de trabajadores afectados"}
                  inputType={"number"}
                  inputValue={setNumberAffected}
                  value={numberAffected}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={10} xl={10}>
                <InputControlaVertical
                  text={"N° de días de descanso médico"}
                  inputType={"number"}
                  inputValue={setNumberMedicalRest}
                  value={numberMedicalRest}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid> 
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalTypeMortal}
              />
              <ButtonControla
                textButton={typeModal== "create"? "Crear": "Actualizar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
