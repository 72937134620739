import { React, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import { Grid, Button, Tabs, Tab, InputLabel, Typography, Card, CardContent, CardMedia, InputBase, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select } from '@mui/material';
import { Search } from '@mui/icons-material';
import SubtitleText from '../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../components/sidenav/sideNav";
import DocBanner from '../../components/cards/docBanner';
import Stack from '@mui/material/Stack';
import MyEpps from '../../api/MyEpps'
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import ButtonMailboxControla from '../../components/buttons/buttonMailboxControla';
import { useNavigate } from 'react-router-dom';

const SearchTextField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        // marginRight: '1rem',
        width: 'auto'
        // display: 'block'
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
}));

function Index() {
    const navigate= useNavigate();

    const [openCreateEpps, setOpenCreateEpps] = useState(false);
    const [openEditEpps, setOpenEditEpps] = useState(false);
    // const [openCreateEppsCategories, setOpenCreateEppsCategories] = useState(false);

    const handleClickOpenCreateEpps = () => {
        setIdEppsEdit(null);
        setCategory(null);
        setName(null);
        setDescription(null);
        setPhotoUrl(null);
        setOpenCreateEpps(true);
    };

    const handleCloseCreateEpps = () => {
        setOpenCreateEpps(false);
    };

    const handleClickOpenEditEpps = (idEpps, idEppsCategoriesEdit, nameEdit, descriptionEdit, photoUrlEdit) => {
        setIdEppsEdit(idEpps);
        setCategory(idEppsCategoriesEdit);
        setCategoryView(idEppsCategoriesEdit);
        setName(nameEdit);
        setDescription(descriptionEdit);
        setPhotoUrl(null);
        setPhotoUrlView(photoUrlEdit);
        setOpenEditEpps(true);
    };

    const handleCloseEditEpps = () => {
        setOpenEditEpps(false);
    };

    // const handleClickOpenCreateEppsCategories = () => {
    //     setOpenCreateEppsCategories(true);
    // };

    // const handleCloseCreateEppsCategories = () => {
    //     setOpenCreateEppsCategories(false);
    // };

    const [idCategoriesSelected, setIdCategoriesSelected] = useState(0);

    const handleChange = (event, newIdCategoriesSelected) => {
        setIdCategoriesSelected(newIdCategoriesSelected);
        handleGetEpps(newIdCategoriesSelected);

    };

    const [rowsEppsCategories, setRowsEppsCategories] = useState([]);
    const [rowsEppsOriginal, setRowsEppsOriginal] = useState([]);
    const [rowsEpps, setRowsEpps] = useState([]);

    const handleSearchEpp = (searchEpp) => {
        setRowsEpps(rowsEppsOriginal);
        if (searchEpp !== '') {
            let expresion = new RegExp(`${searchEpp}.*`, "i");
            let eppsSearch = rowsEpps.filter(x => expresion.test(x.epp));
            setRowsEpps(eppsSearch);
        }
    };


    const handleGetEppsCategories = async () => {
        const response = await MyEpps.categories();

        if (response.success === false) {
        } else {
            setRowsEppsCategories(response);
        }
        return response;
    };

    const handleGetEpps = async (newIdCategoriesSelected) => {
        const response = await MyEpps.epps({ idEppsCategories: newIdCategoriesSelected });

        if (response.success === false) {
        } else {
            setRowsEpps(response);
            setRowsEppsOriginal(response);
        }
        return response;
    };


    useEffect(() => {
        handleGetEppsCategories();
        handleGetEpps();
    }, []);

    const [idEppsEdit, setIdEppsEdit] = useState(null);
    const [categoryView, setCategoryView] = useState(null);

    const [category, setCategory] = useState(null);
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [photoUrl, setPhotoUrl] = useState(null);
    const [photoUrlView, setPhotoUrlView] = useState(null);

    const handleChangeCategory = (event) => {
        setCategory(event.target.value);
    };

    const handleStoreEpps = async () => {
        const response = await MyEpps.createEpps({ idEppsCategories: category, name: name, description: description, photoUrl: photoUrl });

        if (response.success === false) {
        } else {
            handleGetEpps(idCategoriesSelected);
            handleCloseCreateEpps();
        }
        return response;
    };

    const handleUpdateEpps = async () => {
        const response = await MyEpps.updateEpps({ idEpps: idEppsEdit, idEppsCategories: category, name: name, description: description, photoUrl: photoUrl });

        if (response.success === false) {
        } else {
            handleGetEpps(idCategoriesSelected);
            handleCloseEditEpps();
        }
        return response;
    };

    const handleDeleteEpps = async () => {
        const response = await MyEpps.deleteEpps({ idEpps: idEppsEdit });

        if (response.success === false) {
        } else {
            handleGetEpps(idCategoriesSelected);
            handleCloseEditEpps();
        }
        return response;
    };



    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />

            <Container>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <ButtonMailboxControla
                            originUrl={"/admin/epps"}
                        />
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SubtitleText text={"Administrador"} color={'#9191B0'} ></SubtitleText>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
                            <DocBanner color2='#9d00ff' color1='#001156' text='EPPS' image={""} />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 400 }}
                            >

                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={idCategoriesSelected}
                                    onChange={handleChange}
                                    sx={{ borderRight: 1, borderColor: 'divider' }}
                                >
                                    <Tab label='Todos'
                                        key={0}
                                        value={0} />
                                    {rowsEppsCategories
                                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((rowsEppsCategories) => (
                                            <Tab label={rowsEppsCategories.category}
                                                key={rowsEppsCategories.id_epps_categories}
                                                value={rowsEppsCategories.id_epps_categories} />
                                        ))
                                    }
                                </Tabs>

                                <div
                                    style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
                                // key={rowsEpps.id_epps}
                                // hidden={idCategoriesSelected !== rowsEpps.id_epps_categories}
                                >
                                    <Grid container spacing={2} sx={{ width: '100%', margin: 'auto' }} >
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Grid container spacing={2} sx={{ width: '100%' }} >
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    {/* <Stack direction='row' justifyContent="space-between" alignItems="center"> */}
                                                    <SearchTextField onChange={({ target }) => handleSearchEpp(target.value)}
                                                    >
                                                        <SearchIconWrapper>
                                                            <Search />
                                                        </SearchIconWrapper>
                                                        <StyledInputBase
                                                            placeholder="Buscar ..."
                                                            inputProps={{ 'aria-label': 'search' }}
                                                        />
                                                    </SearchTextField>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                                    <Stack direction='row' justifyContent="end" >
                                                        <Button
                                                            onClick={() => handleClickOpenCreateEpps()}
                                                            variant="contained"
                                                            sx={{ marginRight: '1rem', width: 'auto' }}>
                                                            Crear EPP
                                                        </Button>

                                                        <Button
                                                            onClick={() => navigate('/admin/epps/categories')}
                                                            variant="contained"
                                                            sx={{ width: 'auto' }}>
                                                            Categorias
                                                        </Button>
                                                    </Stack>
                                                    {/* </Stack> */}
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        {rowsEpps
                                            .map((rowsEpps) => (

                                                <Grid item key={rowsEpps.id_epps} xs={12} sm={12} md={6} lg={4} xl={4} sx={{ p: 2 }}>
                                                    <Card sx={{
                                                        display: 'flex', justifyContent: 'space-between', height: 120,
                                                        "&:hover": {
                                                            cursor: 'pointer',
                                                            border: "1px solid #0F1255",
                                                            boxShadow: '0px 4px #0F1255',
                                                        },
                                                    }} onClick={() => handleClickOpenEditEpps(rowsEpps.id_epps, rowsEpps.id_epps_categories, rowsEpps.epp, rowsEpps.description, rowsEpps.photo_url)}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                                <Typography component="div" variant="h6">
                                                                    {rowsEpps.epp}
                                                                </Typography>
                                                                <Typography variant="subtitle2" color="text.secondary" component="div">
                                                                    {rowsEpps.description}
                                                                </Typography>
                                                            </CardContent>
                                                        </Box>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ maxWidth: 120, marginLeft: 0, maxHeight: 120, objectFit: 'contain' }}
                                                            image={rowsEpps.photo_url}
                                                            alt="Live from space album cover"
                                                        />
                                                    </Card>

                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </div>

                            </Box>
                        </Grid>
                    </Grid>

                    <Dialog open={openCreateEpps} onClose={handleCloseCreateEpps} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Crear Epp</DialogTitle>
                        <DialogContent>
                            <InputLabel
                                sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}> Categoria: </InputLabel>
                            <Select
                                defaultValue={idCategoriesSelected}
                                displayEmpty
                                onChange={handleChangeCategory}
                                sx={{ width: '100%', backgroundColor: '#F5F5F5', border: 'none' }}
                            >
                                <MenuItem key={0} value={0}>Seleccione</MenuItem>
                                {rowsEppsCategories
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((rowsEppsCategories) => (
                                        <MenuItem key={rowsEppsCategories.id_epps_categories} value={rowsEppsCategories.id_epps_categories}>{rowsEppsCategories.category}</MenuItem>

                                    ))
                                }
                            </Select>
                            <InputControlaVertical text={'Nombre:'} inputType={'text'} inputValue={setName} modalType={true} />
                            <InputControlaVertical text={'Descripcion (opcional):'} inputType={'textArea'} inputValue={setDescription} modalType={true} />

                            <InputControlaVertical text={'Foto:'} inputType={'file'} inputValue={setPhotoUrl} modalType={true} accept='image/*' />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCreateEpps}>Cancel</Button>
                            <Button onClick={handleStoreEpps}>Crear</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openEditEpps} onClose={handleCloseEditEpps} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Editar Epp</DialogTitle>
                        <DialogContent>
                            <InputLabel
                                sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}> Categoria: </InputLabel>
                            <Select
                                defaultValue={categoryView}
                                displayEmpty
                                onChange={handleChangeCategory}
                                sx={{ width: '100%', backgroundColor: '#F5F5F5', border: 'none' }}
                            >
                                <MenuItem key={0} value={0}>Seleccione</MenuItem>
                                {rowsEppsCategories
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((rowsEppsCategories) => (
                                        <MenuItem key={rowsEppsCategories.id_epps_categories} value={rowsEppsCategories.id_epps_categories}>{rowsEppsCategories.category}</MenuItem>

                                    ))
                                }
                            </Select>
                            <InputControlaVertical text={'Nombre:'} inputType={'text'} value={name} inputValue={setName} modalType={true} />
                            <InputControlaVertical text={'Descripcion (opcional):'} value={description} inputType={'textArea'} inputValue={setDescription} modalType={true} />

                            <InputControlaVertical text={'Nueva foto:'} inputType={'file'} inputValue={setPhotoUrl} modalType={true} accept='image/*' />

                            <InputLabel
                                sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}> Foto Acual: </InputLabel>

                            <Box sx={{ textAlign: 'center' }}>
                                <img src={photoUrlView} alt="Foto Epp" style={{ maxWidth: '300px', maxHeight: '300px' }} />
                            </Box>


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEditEpps}>Cancel</Button>
                            <Button onClick={handleDeleteEpps}>Eliminar</Button>
                            <Button onClick={handleUpdateEpps}>Actualizar</Button>
                        </DialogActions>
                    </Dialog>

                </Box>
            </Container >

        </Box >
    );

}

export default Index;

