import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import {
    Add,
    BorderColorOutlined,
    LoginOutlined,
} from "@mui/icons-material";
import LockIcon from '@mui/icons-material/Lock';
import { useAppContext } from "../../../../context/AppContext";

function ListActives(props) {
    const { permissions } = useAppContext();

    const {
        activeBook,
        documentType,
        colorCell,
        handleOpenModal
    } = props

    return (
        <TableBody
            sx={{ display: documentType === 0 ? "" : "none" }}
        >
            {activeBook.map((book) => (
                <TableRow>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {book.title}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {book.start_date}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignContent="center"
                        >
                            <ButtonIconControla
                                roles={permissions.COMITE_LIBRO_ACTA_EDIT}
                                icon={
                                    <BorderColorOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                functionOnClick={() => handleOpenModal(
                                    "Editar título del libro",
                                    "",
                                    "Sí, Actualizar",
                                    "editar",
                                    book.id_minute_book,
                                    book.title,
                                    true,
                                    false
                                )}
                            />
                            <ButtonIconControla
                                icon={
                                    <LoginOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#24996A"}
                                backgroundColorHover={"#1AA483"}
                                textTooltip={"Ver Actas"}
                                isNavegationOrigin={true}
                                directionUrl={"/proceedingBook/proceeding"}
                                paramsIntUrl={{
                                    id_book: book.id_minute_book,
                                    name_book: book.title,
                                    date_created: book.start_date,
                                    end_date: book.end_date,
                                    typeBook: "currentBook"
                                }}
                            />
                            <ButtonIconControla
                                roles={permissions.COMITE_LIBRO_ACTA_CERRAR}
                                icon={<LockIcon sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Cerrar Libro"}
                                functionOnClick={() => handleOpenModal(
                                    "Cerrar libro de actas",
                                    "¿Estás seguro de cerrar este libro de actas?",
                                    "Sí, Cerrar",
                                    "cerrar",
                                    book.id_minute_book,
                                    "",
                                    false,
                                    true
                                )}
                            />
                        </Stack>
                    </TableCell>

                </TableRow>

            ))}

        </TableBody>
    )

}
export default ListActives;