import MyCrypto from "./MyCrypto";
import MyLocalStorage from "./MyLocalStorage";

function tokenData() {
  const dataEncrypt = MyLocalStorage.getLocal("controla");
  let userTokenData = {};
  if (dataEncrypt) {
    const dataDecrypt = JSON.parse(MyCrypto.decrypt("controla", dataEncrypt));

    userTokenData = {
      userToken: dataDecrypt.token,
      userTokenRefresh: dataDecrypt.time_refresh,
      userTokenExpired: dataDecrypt.time_expiration,
    };
  }
  return userTokenData;
}

function userData() {
  const dataEncrypt = MyLocalStorage.getLocal("controla");
  let user = {};
  if (dataEncrypt) {
    const dataDecrypt = JSON.parse(MyCrypto.decrypt("controla", dataEncrypt));
    user = dataDecrypt.user;
  }
  return user;
}

function companyData() {
  const dataEncrypt = MyLocalStorage.getLocal("controla");
  let company = {};
  if (dataEncrypt) {
    const dataDecrypt = JSON.parse(MyCrypto.decrypt("controla", dataEncrypt));
    company = dataDecrypt.company;
  }
  return company;
}

function campusData() {
  const dataEncrypt = MyLocalStorage.getLocal("controla");
  let campus = {};
  if (dataEncrypt) {
    const dataDecrypt = JSON.parse(MyCrypto.decrypt("controla", dataEncrypt));
    campus = dataDecrypt.campus;
  }
  return campus;
}

function urlApi() {
  const api = process.env.REACT_APP_API_LARAVEL;
  return api;
}

function connectApi({ dataJson, method, url }) {
  const userToken = tokenData().userToken;
  const getData = async () => {
    let data = dataJson == null ? null : JSON.stringify(dataJson);
    try {
      const responseApi = await fetch(urlApi() + url, {
        method: method,
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken + "",
        },
      });
      const response = await responseApi.json();
      if (typeof response === "object") {
        return response;
      } else {
        window.location.href = "/internalError";
      }
    } catch (error) {
      console.log(error);
      // window.location.href = '/internalError';;
    }
  };
  return getData();
}

function connectApiPDF({ dataJson, method, url }) {
  const userToken = tokenData().userToken;
  const getData = async () => {
    let data = dataJson == null ? null : JSON.stringify(dataJson);
    try {
      const response = await fetch(urlApi() + url, {
        method: method,
        body: data,
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: "Bearer " + userToken + "",
        },
      });

      if (!response.ok) {
        throw new Error('Error al generar el PDF');
      }
    
      return response
    } catch (error) {
      console.log(error);
    }
  };
  return getData();
}

function connectLoginApi({ dataJson, method, url }) {
  const getData = async () => {
    const data = JSON.stringify(dataJson);
    try {
      const responseApi = await fetch(urlApi() + url, {
        method: method,
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await responseApi.json();
      return response;
    } catch (error) {
      return error;
    }
  };
  return getData();
}

//for fake api
function urlFakeApi() {
  const api = "http://localhost:4000/";
  return api;
}

function connectFakeApi({ dataJson, method, url }) {
  const getData = async () => {
    let data = dataJson == null ? null : JSON.stringify(dataJson);
    try {
      const responseApi = await fetch(urlFakeApi() + url, {
        method: method,
        body: data,
      });
      const response = await responseApi.json();
      if (typeof response === "object") {
        return response;
      } else {
        window.location.href = "/internalError";
      }
    } catch (error) {
      console.log(error);
      // window.location.href = '/internalError';;
    }
  };
  return getData();
}

function urlFormApi() {
  const api = process.env.REACT_APP_API_DJANGO + "api/register/";
  return api;
}

function connectFormApi({ dataJson, method, url }) {
  const userToken = tokenData().userToken;
  const getData = async () => {
    let data = dataJson == null ? null : JSON.stringify(dataJson);
    try {
      const responseApi = await fetch(urlFormApi() + url, {
        method: method,
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken + "",
        },
      });
      const response = await responseApi.json();
      if (typeof response === "object") {
        return response;
      } else {
        window.location.href = "/internalError";
      }
    } catch (error) {
      console.log(error);
      // window.location.href = '/internalError';;
    }
  };
  return getData();
}

function urlFormApiGeneral() {
  const api = process.env.REACT_APP_API_DJANGO + 'api/';
  return api;
}

function connectFormApiGeneral({ dataJson, method, url }) {
  const userToken = tokenData().userToken;
  const getData = async () => {
    let data = dataJson == null ? null : JSON.stringify(dataJson);
    try {
      const responseApi = await fetch(urlFormApiGeneral() + url, {
        method: method,
        body: data,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken + "",
        },
      });      

      const response = await responseApi.json();
      if (typeof response === "object") {
        return response;
      } else {
        window.location.href = "/internalError";
      }
    } catch (error) {
      console.log(error);
      // window.location.href = '/internalError';;
    }
  };
  return getData();
}

function connectFormApiFormData({ loadUserFile, method, url }) {
  const userToken = tokenData().userToken;
  let formData = new FormData();
  formData.append("users_file", loadUserFile);
  const getData = async () => {
    try {
      const responseApi = await fetch(urlFormApiGeneral() + url, {
        method: method,
        body: formData,
        headers: {
          Authorization: "Bearer " + userToken + "",
        },
      });      

      const response = await responseApi.json();
      if (typeof response === "object") {
        return response;
      } else {
        window.location.href = "/internalError";
      }
    } catch (error) {
      console.log(error);
      // window.location.href = '/internalError';;
    }
  };
  return getData();
}



const MyConfigurationApi = {
  urlApi,
  connectApi,
  connectLoginApi,
  tokenData,
  userData,
  companyData,
  campusData,
  urlFakeApi,
  connectFakeApi,
  connectFormApi,
  connectFormApiGeneral,
  connectFormApiFormData,
  connectApiPDF
};

export default MyConfigurationApi;
