import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Stack,
  } from "@mui/material";
  import {
    Add,
    ArrowBack,
    HistoryEduOutlined,
  } from "@mui/icons-material";

import SideNav from "../../../components/sidenav/sideNav";
import SubtitleText from "../../../components/text/subtitleText";
import TitleText from "../../../components/text/titleText";
import CompanyLogoControla from "../../../components/images/companyLogoControla";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import { EditorInternalRegulationsCommitteeGeneral } from "../../../components/editor/editorInternalRegulationsCommitteeGeneral";
import ButtonControla from "../../../components/buttons/buttonController";
import SearchButtonControla from "../../../components/search/searchButtonControla";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import SearchUsersControla from "../../../components/search/searchUsersControla";
import MyUsers from "../../../api/MyUsers";
import MyInternalRegulationsCommittee from "../../../api/MyInternalRegulationsCommittee";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingControla from "../../../components/load/loadingControla";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import MyConfigurationApi from "../../../api/MyConfigurationApi";
import { useAppContext } from "../../../context/AppContext";


const colorTitle = "#9191B0"
let rolesEditAll =[];

function TemplateRegulations() {
    const { permissions } = useAppContext();
    const navigate = useNavigate();

    const location = useLocation();
    const readOnly = location.state.readOnly;

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    //fin de alerts

    //editor
    const [documentText, setDocumentText] = useState("");

    const templateText = (textdata) => {
        setDocumentText(textdata);
    };

    //traer lista de usuarios para seleccionar 
    const [usersActives, setUsersActives] = useState([]);

    const handleGetUsersActives = async () => {
        const roles7 = await MyUsers.getUsersByRole(7);
        const roles12 = await MyUsers.getUsersByRole(12);
        const roles1 = await MyUsers.getUsersByRole(1);

        let user = roles7.concat(roles12, roles1);
        let set = new Set(user.map(JSON.stringify))
        let arrSinDuplicaciones = Array.from(set).map(JSON.parse);

        setUsersActives(arrSinDuplicaciones);

    };
    //usuario logeado
    const user = MyConfigurationApi.userData();
    const idUserLogged = user.id_users;

    //agregar usuarios responsable para  el reglamento 
    const [openModalAddResponsible, setOpenModalAddResponsible] = useState(false);
    const [titleModalAddResponsible, setTitleModalAddResponsible] = useState("");
    const [typeActionButton, setTypeActionButton] = useState("");

    const handleOpenModalAddResponsible = (title, typeActionButton) => {
        setOpenModalAddResponsible(true);
        setTitleModalAddResponsible(title);
        setTypeActionButton(typeActionButton);
    }
    const handleCloseModalAddResponsible = () => {
        setOpenModalAddResponsible(false);
    }

    const handleGetUserResponsible = async (idUser) => {
        handleOpenLoading();
        //id del usario seleccionado
        const response = await MyUsers.editUsers({ idUsers: idUser });

        if (response.success === false) {
            alert("Error");
        } else {
            switch (typeActionButton) {
                case "prepared":
                    setDevelop(response.first_name + " " + response.last_name);
                    setIdDevelop(response.id_users);
                    break;
                case "review":
                    setRevised(response.first_name + " " + response.last_name);
                    setIdRevised(response.id_users);
                    break;
                case "approval":
                    setApproved(response.first_name + " " + response.last_name);
                    setIdAproved(response.id_users);
                    break;
            }
        }
        handleCloseModalAddResponsible();
        setOpenLoading(false);
    }


    const [code, setCode] = useState("");
    const [version, setVersion] = useState("");
    const [titleDocument, setTitleDocument] = useState("");

    const [develop, setDevelop] = useState("");
    const [idDevelop, setIdDevelop] = useState(0);
    const [dateDevelop, setDateDevelop] = useState("");
    const [signatureDevelop, setSignatureDevelop] = useState(false);
    const [signatureUrlDevelop, setSignatureUrlDevelop] = useState("");


    const [revised, setRevised] = useState("");
    const [idRevised, setIdRevised] = useState(0);
    const [dateRevised, setDateRevised] = useState("");
    const [signatureRevised, setSignatureRevised] = useState(false);
    const [signatureUrlRevised, setSignatureUrlRevised] = useState("");

    const [approved, setApproved] = useState("");
    const [idAproved, setIdAproved] = useState(0);
    const [dateApproved, setDateApproved] = useState("");
    const [signatureApproved, setSignatureApproved] = useState(false);
    const [signatureUrlApproved, setSignatureUrlApproved] = useState("");



    const handlePostInternalRegulationsCommittee = async (e) => {
        handleOpenLoading();
        e.preventDefault();
        const data = {
            code: code,
            version: version,
            title: titleDocument,
            produced: idDevelop,
            reviewed: idRevised,
            approved: idAproved,
            date_elaboration: dateDevelop,
            date_review: dateRevised,
            date_approval: dateApproved,
            format: documentText,
            model: 1,
            document: null
        }

        const resp = await MyInternalRegulationsCommittee.createInternalRegulationsCommittee(data);
        if (resp.success == true) {
            navigate("/committee/presentCommittee/internalRegulationsCommittee/")
        } else {
            handleOpenAlert("Ocurrió un error al guardar", "error");
        }
    }

    const handleUpdatedInternalRegulationsCommittee = async (e) => {
        e.preventDefault();

        handleOpenLoading();
        const data = {
            code: code,
            version: version,
            title: titleDocument,
            produced: idDevelop,
            reviewed: idRevised,
            approved: idAproved,
            date_elaboration: dateDevelop,
            date_review: dateRevised,
            date_approval: dateApproved,
            format: documentText,
            model: 1,
            document: null
        }
        const resp = await MyInternalRegulationsCommittee.updatedInternalRegulationsCommittee(data, location.state.idCommitteeRegulations);

        if (resp.success == true) {
            handleGetInternalRegulationsCommitteeById();
            handleOpenAlert("El reglamento interno se actualizó con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error al actualizar el reglamento", "error");
        }

    }

    const handleGetInternalRegulationsCommitteeById = async () => {

        if (location.state.save == null) {
            handleOpenLoading();

            const resp = await MyInternalRegulationsCommittee.getInternalRegulationsCommitteeById(location.state.idCommitteeRegulations);

            handleGetNameUser(resp.data.produced).then((name) => { setDevelop(name) });
            handleGetNameUser(resp.data.reviewed).then((name) => { setRevised(name) });
            handleGetNameUser(resp.data.approved).then((name) => setApproved(name));

            setCode(resp.data.code);
            setVersion(resp.data.version);
            setTitleDocument(resp.data.title);


            setIdDevelop(resp.data.produced)
            setDateDevelop(resp.data.date_elaboration);
            setSignatureDevelop(resp.data.firm_elaboration);
            setSignatureUrlDevelop(resp.data.produced_firm_url);


            setIdRevised(resp.data.reviewed);
            setDateRevised(resp.data.date_review);
            setSignatureRevised(resp.data.firm_review);
            setSignatureUrlRevised(resp.data.reviewed_firm_url);


            setIdAproved(resp.data.approved);
            setDateApproved(resp.data.date_approval);
            setSignatureApproved(resp.data.firm_approval);
            setSignatureUrlApproved(resp.data.approved_firm_url);

            setDocumentText(resp.data.format);
            if (resp.success == true) {
                handleOpenAlert("Se listó el reglamento con éxito", "success");
            } else {
                handleOpenAlert("Ocurrió un error al lista el reglamento", "error");
            }
        }
    }

    const handleGetNameUser = async (idUser) => {
        // solo busca nombre si existe id del usuario aisgnado
        if (idUser != 0) {
            const resUsers = await MyUsers.editUsers({ idUsers: idUser });
            return resUsers.first_name + " " + resUsers.last_name;
        }

    }

    const handleAcctionForm = (e) => {
        e.preventDefault();
        switch (e.nativeEvent.submitter.innerText) {
            case "Actualizar":
                handleUpdatedInternalRegulationsCommittee(e);
                break;
            case "Guardar":
                handlePostInternalRegulationsCommittee(e);
                break;
        }
    }

    // fecha actual del sistema
    const current = new Date();
    const year = current.getFullYear();
    const month = (current.getMonth() + 1) > 9 ? current.getMonth() + 1 : `0${current.getMonth() + 1}`;
    const day = current.getDate();
    const date = `${year}-${month}-${day}`;

    const handleSignDevelop = async () => {
        handleOpenLoading();
        const data = {
            produced: idDevelop,
            reviewed: idRevised,
            approved: idAproved,
            firm_elaboration: 1,
            date_elaboration: date
        }
        const resp = await MyInternalRegulationsCommittee.updatedInternalRegulationsCommittee(data, location.state.idCommitteeRegulations);

        if (resp.success == true) {
            handleGetInternalRegulationsCommitteeById();
            handleOpenAlert("Se firmó con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleSingRevised = async () => {
        handleOpenLoading()
        const data = {
            produced: idDevelop,
            reviewed: idRevised,
            approved: idAproved,
            firm_review: 1,
            date_review: date,
        }

        const resp = await MyInternalRegulationsCommittee.updatedInternalRegulationsCommittee(data, location.state.idCommitteeRegulations)
        if (resp.success == true) {
            handleGetInternalRegulationsCommitteeById();
            handleOpenAlert("Se firmó con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleSignApproved = async () => {
        handleOpenLoading();
        const data = {
            produced: idDevelop,
            reviewed: idRevised,
            approved: idAproved,
            firm_approval: 1,
            date_approval: date
        }

        const resp = await MyInternalRegulationsCommittee.updatedInternalRegulationsCommittee(data, location.state.idCommitteeRegulations)
        if (resp.success == true) {
            handleGetInternalRegulationsCommitteeById();
            handleOpenAlert("Se firmó con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }

    }

    //roles de editar
    rolesEditAll = permissions.COMITE_COMITE_RIC_ACTIVOS_EDIT;
    const [editAll, setEditAll] = useState(false);


    //permisos para editar
    let isEditAll = false;
    const getPermmissionEdit = () => {

        const user = MyConfigurationApi.userData();
        const rolesUserLogged = user.roles;

        rolesEditAll?.forEach(rol => {
            if (rolesUserLogged.includes(rol)) {
                isEditAll = true;
            }
        })

        setEditAll(isEditAll);
    }

    useEffect(() => {
        handleGetUsersActives();
        handleGetInternalRegulationsCommitteeById();
        getPermmissionEdit();
    }, [rolesEditAll])
    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack="" />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <Container>
                <form onSubmit={handleAcctionForm}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <SubtitleText
                                text={"Reglamento Interno del Comité"}
                                color={colorTitle}
                            ></SubtitleText>
                            <TitleText
                                text={"Documento Reglamento de Constitución Y Funcionamiento del Comité de Seguridad y Salud Ocupacional"}
                            >
                            </TitleText>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <InputControlaVertical
                                text={"Código:"}
                                inputType={"text"}
                                inputValue={setCode}
                                value={code}
                                readOnly={readOnly ? true : !editAll}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                                text={"Versión:"}
                                inputType={"text"}
                                inputValue={setVersion}
                                value={version}
                                readOnly={readOnly ? true : !editAll}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} lg={5} xl={4}>
                            <InputControlaVertical
                                text={"Título del documento:"}
                                inputType={"text"}
                                inputValue={setTitleDocument}
                                value={titleDocument}
                                readOnly={readOnly ? true : !editAll}
                                required
                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <SearchButtonControla
                                titleLabel="Preparado por: "
                                inputValue={develop}
                                functionClick={() => {
                                    handleOpenModalAddResponsible(
                                        "Agregar responsable de elaborar reglamento",
                                        "prepared")
                                }}
                                disabledButton={readOnly ? true : (signatureDevelop == 1 ? true : !editAll)}
                            />

                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                                text={"Fecha de Elaboración:"}
                                inputType={"date"}
                                inputValue={setDateDevelop}
                                value={dateDevelop}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid container item xs={2} sm={2} md={2} lg={2} xl={2} >
                            {
                                location.state.save ? "" : (
                                    idDevelop == idUserLogged ? (
                                        readOnly ? (
                                            signatureDevelop == null ? (<Box sx={{ alignContent: 'flex-end' }}>No firmado</Box>) : (<img src={signatureUrlDevelop} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />)
                                        ) :
                                            (
                                                signatureDevelop != null ? (<img src={signatureUrlDevelop} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />) :
                                                    (
                                                        <Box sx={{ alignContent: 'flex-end' }}>
                                                            <ButtonIconControla
                                                                icon={<HistoryEduOutlined sx={{ color: "white" }} />}
                                                                backgroundColor={"#F1C40F"}
                                                                backgroundColorHover={"#F4D03F"}
                                                                textTooltip={"Firmar"}
                                                                functionOnClick={() => handleSignDevelop()}
                                                            />
                                                        </Box>
                                                    )
                                            )
                                    ) : signatureDevelop == null ? (<Box sx={{ alignContent: 'flex-end' }}>No firmado</Box>) : (<img src={signatureUrlDevelop} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />)
                                )
                            }
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SearchButtonControla
                                titleLabel="Revisado por: "
                                inputValue={revised}
                                functionClick={() => {
                                    handleOpenModalAddResponsible(
                                        "Agregar responsable de revisar el reglamento",
                                        "review")
                                }}
                                disabledButton={readOnly ? true : (signatureRevised == 1 ? true : !editAll)}
                            />

                        </Grid>
                        <Grid item xs={14} sm={4} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                                text={"Fecha de Revisión:"}
                                inputType={"date"}
                                inputValue={setDateRevised}
                                value={dateRevised}
                                modalType={false}
                                readOnly={true}
                            />

                        </Grid>
                        <Grid container item xs={2} sm={2} md={2} lg={2} xl={2} >
                            {
                                location.state.save ? "" : (
                                    idRevised == idUserLogged ? (
                                        readOnly ? (
                                            signatureRevised == null ? (<Box sx={{ alignContent: 'flex-end' }}>No firmado</Box>) : (<img src={signatureUrlRevised} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />)
                                        ) : (
                                            signatureRevised != null ? (<img src={signatureUrlRevised} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />) :
                                                (
                                                    <Box sx={{ alignContent: 'flex-end' }}>
                                                        <ButtonIconControla
                                                            icon={<HistoryEduOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#F1C40F"}
                                                            backgroundColorHover={"#F4D03F"}
                                                            textTooltip={"Firmar"}
                                                            functionOnClick={() => { handleSingRevised() }}
                                                        />
                                                    </Box>

                                                )
                                        )
                                    ) : signatureRevised == null ? (<Box sx={{ alignContent: 'flex-end' }}>No firmado</Box>) : (<img src={signatureUrlRevised} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />)
                                )
                            }
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SearchButtonControla
                                titleLabel="Aprobado por: "
                                inputValue={approved}
                                functionClick={() => {
                                    handleOpenModalAddResponsible(
                                        "Agregar responsable de aprobar el reglamento",
                                        "approval")
                                }}
                                disabledButton={readOnly || signatureApproved == 1 ? true : !editAll}
                            />

                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <InputControlaVertical
                                text={"Fecha de Aprobación:"}
                                inputType={"date"}
                                inputValue={setDateApproved}
                                value={dateApproved}
                                modalType={false}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid container item xs={2} sm={2} md={2} lg={2} xl={2} >
                            {
                                location.state.save ? "" : (
                                    idAproved == idUserLogged ? (
                                        readOnly ? (
                                            signatureApproved == null ? (<Box sx={{ alignContent: 'flex-end' }}>No firmado</Box>) : (<img src={signatureUrlApproved} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />)
                                        ) : (
                                            signatureApproved != null ? (<img src={signatureUrlApproved} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />) :
                                                (
                                                    <Box sx={{ alignContent: 'flex-end' }}>
                                                        <ButtonIconControla
                                                            icon={<HistoryEduOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#F1C40F"}
                                                            backgroundColorHover={"#F4D03F"}
                                                            textTooltip={"Firmar"}
                                                            functionOnClick={() => { handleSignApproved() }}
                                                        />
                                                    </Box>
                                                )
                                        )
                                    ) : signatureApproved == null ? (<Box sx={{ alignContent: 'flex-end' }}>No firmado</Box>) : (<img src={signatureUrlApproved} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />)
                                )
                            }
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <EditorInternalRegulationsCommitteeGeneral
                                dataEdit={documentText}
                                handleDataEditor={templateText}
                                disabled={readOnly ? true : !editAll}
                            />
                        </Grid>

                    </Grid>

                    <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent={"center"}
                        mt={2}
                        mb={2}
                        sx={{
                            paddingTop: "2em",
                        }}
                    >
                        <Box>
                            <ButtonControla
                                iconButton={<ArrowBack sx={{ color: "white" }} />}
                                backgroundColor={"#C61C39"}
                                backgroundColorHover={"#D61F3E"}
                                textButton={"Regresar"}
                                typeButton={"button"}
                                isNavegationOrigin={true}
                                url={"/committee/presentCommittee/internalRegulationsCommittee/"}
                            />
                        </Box>

                        <Box>
                            {
                                location.state.save ? (
                                    <ButtonControla
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Guardar"}
                                        typeButton={"submit"}
                                    />
                                ) : ""
                            }
                        </Box>

                        <Box>

                            {
                                !location.state.save && !readOnly ? (
                                    <ButtonControla
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Actualizar"}
                                        typeButton={"submit"}
                                    />
                                ) : " "
                            }
                        </Box>

                    </Stack>
                </form>

            </Container>

            {/* modal de agregar responsable para elaborar */}
            <Dialog
                open={openModalAddResponsible}
                onClose={handleCloseModalAddResponsible}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText={titleModalAddResponsible}
                    functionOnClose={handleCloseModalAddResponsible}
                />
                <DialogContent>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SearchUsersControla
                            rowsUser={usersActives}
                            handleUsersActionButton={handleGetUserResponsible}
                        />
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Stack direction={"row"} spacing={2} justifyContent={"flex-start"}>
                        <ButtonControla
                            backgroundColor={"#CBCBFF"}
                            textButton={"Cancelar"}
                            functionOnClick={handleCloseModalAddResponsible}
                        />
                    </Stack>
                </DialogActions>
            </Dialog>
        </Box>
    );

}
export default TemplateRegulations;