import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Close, Add, ArrowBack } from "@mui/icons-material";
import Container from "@mui/material/Container";

import SideNav from "../../components/sidenav/sideNav";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import { EditorControla } from "../../components/editor/editorControla";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import TitleText from "../../components/text/titleText";
import MyAnnualPlan from "../../api/MyAnnualPlan";
import SearchButtonControla from "../../components/search/searchButtonControla";
import MyUsers from "../../api/MyUsers";
import SearchUsersControla from "../../components/search/searchUsersControla";
import { EditorAnnualPlanControla } from "../../components/editor/editorAnnualPlanControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import { validationEditInputsByWorker } from "../../utils/validation_edit";
import { useLocation, useNavigate} from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

const colorTitle = "#9191B0";

function Template() {
  const location = useLocation();
  const {permissions} = useAppContext();
  const navigate = useNavigate(); 

  const rolesEdit = permissions.DOC_PLAN_ANUAL_SSO_EDIT;

  const readOnly = location.state.readOnly;

  const params = new URLSearchParams(window.location.search);
  const idAnnualPlan = params.get("idAnnualPlan");
  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const isWorker = validationEditInputsByWorker();

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    // setIdPETS(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        // deletePets();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
    }
  };

  // Abrir diálogo de cargar archivo
  const [openModalUploaFile, setOpenModalUploadFile] = useState(false);
  const handleOpenModalUploadFile = () => {
    setCode(null);
    setDevelop(null);
    setRevised(null);
    setApproved(null);
    setVersion(null);
    setTitle(null);
    setOpenModalUploadFile(true);
  };

  const [version, setVersion] = useState(null);
  const [title, setTitle] = useState(null);
  const [documentText, setDocumentText] = useState(null);
  const [code, setCode] = useState(null);

  const [develop, setDevelop] = useState("");

  const [dateDevelop, setDateDevelop] = useState("");
  const [revised, setRevised] = useState("");

  const [dateRevised, setDateRevised] = useState("");
  const [approved, setApproved] = useState("");

  const [dateApproved, setDateApproved] = useState("");

  const templateText = (textdata) => {
    setDocumentText(textdata);
  };
  // jalar y mostrar datos del usuario preparado
  const [idUsersDevelop, setIdUsersDevelop] = useState("");
  const [openModalDevelop, setOpenModalDevelop] = useState(false);
  const handleOpenModalDevelop = () => {
    setOpenModalDevelop(true);
  };

  const handleGetUsersDevelop = async (idUser) => {
    handleOpenLoading();
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setDevelop(response.first_name + " " + response.last_name);
      setIdUsersDevelop(response.id_users);
      setOpenModalDevelop(false);
      handleOpenAlert("", "success");
    }
  };

  // jalar y mostrar datos del usuario revisado
  const [idUsersRevised, setIdUsersRevised] = useState("");
  const [openModalRevised, setOpenModalRevised] = useState(false);
  const handleOpenModalRevised = () => {
    setOpenModalRevised(true);
  };

  const handleGetUsersRevised = async (idUser) => {
    handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setRevised(response.first_name + " " + response.last_name);
      setIdUsersRevised(response.id_users);
      setOpenModalRevised(false);
      handleOpenAlert("", "success");
    }
  };

  // jalar y mostrar datos del usuario revisado
  const [idUsersApproved, setIdUsersApproved] = useState("");
  const [openModalApproved, setOpenModalApproved] = useState(false);
  const handleOpenModalApproved = () => {
    setOpenModalApproved(true);
  };

  const handleGetUsersApproved = async (idUser) => {
    handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setApproved(response.first_name + " " + response.last_name);
      setIdUsersApproved(response.id_users);
      setOpenModalApproved(false);
      handleOpenAlert("", "success");
    }
  };

  //   crear
  const handleCreateAnnualPlanTemplate = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyAnnualPlan.createAnnualPlanTemplate({
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
      idUsersDevelop,
      idUsersRevised,
      idUsersApproved,
      dateDevelop,
      dateRevised,
      dateApproved,
    });
    if (response.success !== false) {
      handleOpenAlert("El documento se ha creado con éxito", "success");
      navigate("/documentation/AnnualPlan?idTabs=0");
    } else {
      handleOpenAlert("Ocurrió un error al crear el archivo", "error");
    }
    return response;
  };

  // Editar temlplate
  // editar annual plan document

  const handleEditAnnualPlanTemplate = async () => {
    handleOpenLoading();

    if (idAnnualPlan != null) {
      const response = await MyAnnualPlan.editAnnualPlanTemplate({
        idAnnualPlanTemplate: idAnnualPlan,
      });
      setDevelop(response.develop);

      setIdUsersDevelop(response.id_users_develop);

      setCode(response.code);
      setApproved(response.approved);

      setIdUsersApproved(response.id_users_approved);

      setDevelop(response.develop);
      setRevised(response.revised);

      setIdUsersRevised(response.id_users_revised);

      setVersion(response.version);
      setTitle(response.title);
      setDocumentText(response.document_text);
      setDateApproved(response.date_approved);
      setDateRevised(response.date_revised);
      setDateDevelop(response.date_develop);
      setRegisterStatus(response.register_status);
    }

    setOpenLoading(false);
  };
  const handleUpdateAnnualPlanTemplate = async () => {
    handleOpenLoading();
    const response = await MyAnnualPlan.updateAnnualPlanTemplate({
      idAnnualPlanTemplate: idAnnualPlan,
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
      dateDevelop: dateDevelop,
      dateApproved: dateApproved,
      dateRevised: dateRevised
    });
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    return response;
  };

  ////Status register

  const [registerStatus, setRegisterStatus] = useState(0);

  const userData = MyConfigurationApi.userData();
  const idUserLogged = userData.id_users;
  const rolesUserLogged = userData.roles;
  const [isEditTotal, setIsEditTotal]= useState(false);


  const handleValidateIsEdit = () =>{
    rolesEdit?.map((rol)=>{
      if(rolesUserLogged.includes(rol)){
        setIsEditTotal(true)
      }
    })
  }
 

  useEffect(() => {
    handleEditAnnualPlanTemplate();
    handleGetUsersActives();
    handleValidateIsEdit();
  }, [rolesEdit]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/AnnualPlan" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <form onSubmit={handleCreateAnnualPlanTemplate}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Plan anual"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                // text={"Plantilla Plan Anual"}
                text={
                  registerStatus === 0
                    ? "Plantilla Plan Anual"
                    : "Documento Plan Anual"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <InputControlaVertical
                text={"Código:"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                required
                readOnly={readOnly? true : (isEditTotal ? false : true)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Versión:"}
                inputType={"text"}
                inputValue={setVersion}
                value={version}
                required
                readOnly={readOnly? true : (isEditTotal ? false : true)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5} xl={5}></Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Título documento:"}
                inputType={"text"}
                inputValue={setTitle}
                value={title}
                required
                readOnly={readOnly? true : (isEditTotal ? false : true)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {
                readOnly ? (
                  <InputControlaVertical
                    text={"Elaborado por:"}
                    inputType={"text"}
                    inputValue={setDevelop}
                    value={develop}
                    readOnly
                  />
                ) : (
                  <SearchButtonControla
                    titleLabel="Elaborado por: "
                    inputValue={develop}
                    functionClick={handleOpenModalDevelop}
                    disabledButton={!isEditTotal}
                  />
                )
              }


            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputControlaVertical
                text={"Fecha de Elaboración:"}
                inputType={"date"}
                inputValue={setDateDevelop}
                value={dateDevelop}
                required
                disabled={idUsersDevelop == idUserLogged ? false : true}
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {
                readOnly ? (
                  <InputControlaVertical
                    text={"Revisado por:"}
                    inputType={"text"}
                    inputValue={setRevised}
                    value={revised}
                    readOnly
                  />

                ) : (
                  <SearchButtonControla
                    titleLabel="Revisado por: "
                    inputValue={revised}
                    functionClick={handleOpenModalRevised}
                    disabledButton={!isEditTotal}
                  />
                )
              }


            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputControlaVertical
                text={"Fecha de Revisión:"}
                inputType={"date"}
                inputValue={setDateRevised}
                value={dateRevised}
                modalType={false}
                disabled={idUsersRevised == idUserLogged ? false : true}
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {
                readOnly ? (
                  <InputControlaVertical
                    text={"Aprobado por:"}
                    inputType={"text"}
                    inputValue={setApproved}
                    value={approved}
                    readOnly
                  />

                ) : (
                  <SearchButtonControla
                    titleLabel="Aprobado por: "
                    inputValue={approved}
                    functionClick={handleOpenModalApproved}
                    disabledButton={!isEditTotal}
                  />
                )
              }


            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputControlaVertical
                text={"Fecha de Aprobación:"}
                inputType={"date"}
                inputValue={setDateApproved}
                value={dateApproved}
                modalType={false}
                disabled={idUsersApproved == idUserLogged ? false : true}
                readOnly={readOnly}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EditorAnnualPlanControla
                dataEdit={documentText}
                handleDataEditor={templateText}
                disabled={readOnly? true : (isEditTotal ? false : true)}
                readOnly
              />
            </Grid>
          </Grid>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
            mt={2}
            mb={2}
          >
            <Box>
              <ButtonControla
                iconButton={<ArrowBack sx={{ color: "white" }} />}
                backgroundColor={"#C61C39"}
                backgroundColorHover={"#D61F3E"}
                textButton={"Regresar"}
                typeButton={"button"}
                isNavegationOrigin={true}
                url={"/documentation/AnnualPlan"}
              />
            </Box>{" "}
            {idAnnualPlan === null || idAnnualPlan === undefined ? (
              <Box>
                <ButtonControla
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Guardar"}
                  typeButton={"submit"}
                />
              </Box>
            ) : (
              readOnly ? "" : (
                <Box>
                  <ButtonControla
                    // iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar"}
                    functionOnClick={() => handleUpdateAnnualPlanTemplate()}
                    disabled={isWorker}
                  />
                </Box>

              )

            )}
          </Stack>
        </form>
      </Container>

      {/* Buscar responsables */}
      {/* Elaborado por */}
      <Dialog
        open={openModalDevelop}
        onClose={() => {
          setOpenModalDevelop(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de elaborar la plantilla Plan Anual
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersDevelop}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalDevelop(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalRevised}
        onClose={() => {
          setOpenModalRevised(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Revisar la plantilla Plan Anual
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersRevised}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalRevised(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalApproved}
        onClose={() => {
          setOpenModalApproved(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Aprobar la plantilla Plan Anual
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersApproved}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalApproved(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Template;
