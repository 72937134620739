import { React, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ButtonControla from "../components/buttons/buttonController.jsx";
import ButtonIconControla from "../components/buttons/buttonIconControla.jsx";
import DialogTitleControla from "../components/dialog/dialogTitleControla.jsx";
import DialogConfirmControla from "../components/dialog/dialogConfirmControla.jsx";
import SelectControla from "../components/selects/selectControla.jsx";
import DialogInformativeControla from "../components/dialog/dialogInformativeControla.jsx";
import Paper from "@mui/material/Paper";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Card,
    CardContent,
    CardMedia,
    Grid,
    InputLabel,
    Typography,
    Stack,
    MenuItem,
    Backdrop,
    Snackbar,
    Alert,
} from "@mui/material";
import {
    Add,
    Delete
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { useNavigate } from 'react-router-dom';

import MyMemberships from "../api/MyMemberships.jsx";

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em",
});

function ModulesBySectionMembership (){
    const navigate = useNavigate();
    //Message Alert
    const [stateAlert, setStateAlert] = useState({
        openAlert: false,
        vertical: "top",
        horizontal: "center",
        severityAlert: "success",
        messageAlert: "",
      });
    
    const { severityAlert, messageAlert, vertical, horizontal, openAlert } = stateAlert;

    const handleOpenAlert = (severityAlert, messageAlert)=>{
        setStateAlert({
            openAlert: true,
            vertical: "top",
            horizontal: "right",
            severityAlert: severityAlert,
            messageAlert: messageAlert,
          });
    }

    const handleCloseAlert = () =>{
        setStateAlert({ ...stateAlert, openAlert: false });
    }
    //
    const params = new URLSearchParams(window.location.search);
    let id_configMemberships = params.get("id_config_memberships");
    let title =  params.get("title");
    let membership =  params.get("membresia");
    let idSection = params.get("id_section")

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    }

    const [openModalConfirm, setOpenModalConfirm] = useState(false);

    const [titleText, setTitleText]= useState("");
    const [contentText, setContentText]= useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [idConfigModule , setIdConfigModule] = useState("");

    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, idConfigModule) => {
        setOpenModalConfirm(true);

        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setIdConfigModule(idConfigModule);
    }

    const handleCloseModalConfirm = ()=>{
        setOpenModalConfirm(false);
    }
    // 
    const [openModalInformative, setOpenModalInformative] = useState(false);
    const [messageModal, setMessageModal] = useState("");

    const handleCloseModalInformative = ()=>{
        setOpenModalInformative(false);
    }


    const [moduleSelected,setModuleSelected] = useState("");
    const [modules, setModules] = useState([]);
    const [statusSelected, setStatusSelected] = useState("");
   
   

    const status = [{"id_status": 1, "name": "desactivado"},{"id_status": 2, "name": "activado"}];
    const getModules = async()=>{
        const res = await MyMemberships.getModuleGalleryBySection(idSection);
        setModules(res.data);
    }

    const [modulesBySection, setModulesBySection] = useState([]);

    const getModulesBySection = async()=>{
        setOpenBackdrop(true);
        const res  = await MyMemberships.getModulesBySection(id_configMemberships);
        setModulesBySection(res.data);
        handleCloseBackdrop();
    }

    const [openModalModules, setOpenModalModules]= useState(false);
    const handleOpenModalModules = () =>{
        setOpenModalModules(true);
        
        //limpiar
        setModuleSelected(null);
        setStatusSelected(null);

    }
    const handleCloseModalModules = () =>{
        setOpenModalModules(false);
    }

    const handlePostModuleBySection = async (event) => {
        event.preventDefault();
        setOpenBackdrop(true);

        //verificar si el modulo existe
        const res = await MyMemberships.getModulesBySection(id_configMemberships);
        const moduleExists = res.data.filter((m) => m.id_configuration_modules === moduleSelected);

        if (moduleExists.length !== 0) {
            setOpenModalInformative(true);
            setMessageModal("El módulo ya existe en la sección");
        } else {
            const data = {
                "id_config_memberships": id_configMemberships,
                "id_configuration_modules": moduleSelected,
                "active": statusSelected,
                "parent_id": null
            }

            const r = await MyMemberships.postModuleBySection(data);
            if (r.success === true) {
                getModulesBySection();
                handleOpenAlert("success", "Se agregó módulo con éxito");
            } else {
                handleOpenAlert("error", "Ocurrió un error");
            }

        }

        handleCloseBackdrop();
        handleCloseModalModules();

    }

    const handleDeleteModuleBySection = async () => {
        setOpenBackdrop(true);
       
        const resp = await MyMemberships.deleteModuleBySection(idConfigModule);
        if(resp.success === true){
            getModulesBySection();
            handleOpenAlert("success", "Se eliminó el módulo con éxito");
        }else {
            handleOpenAlert("error", "Ocurrió un error");
        }

        handleCloseModalConfirm();
        handleCloseBackdrop();
    }

    const handleSearchModules = async(module)=>{
        const res = await MyMemberships.getModulesGalleryByParent(module.id_configuration_modules);

        if (res.data.length === 0) {
            setOpenModalInformative(true);
            setMessageModal("No existe galería de submódulos en el sistema");
        } else {
            const params = {
                id_section:idSection,
                id_config_membership: id_configMemberships,
                id_config_section: module.id_config_section,
                id_config_module:  module.id_configuration_modules,
                name: module.title
            }
            navigate("/submodulesByModule",{state: params});
        }

    }

    useEffect(()=>{
        getModules();
        getModulesBySection();
    }, []);

    

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleDeleteModuleBySection}
            />
            {/* componente Alert */}
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openAlert}
                onClose={handleCloseAlert}
                key={vertical + horizontal}
                autoHideDuration={3000}
            >
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                handleCloseAlert();
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={severityAlert}
                    size="small"
                >
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Container>

                <StyledTypography
                    sx={{
                        padding: "8px",
                        fontSize: "20px",
                        color: "#305AD9",
                        fontWeight: "bold",
                    }}
                >
                    Membresía - {membership}
                </StyledTypography>

                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>

                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        sx={{ marginTop: 3, marginBottom: 1 }}
                    >
                        <Box>
                            <InputLabel
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Sección - {title}
                            </InputLabel>
                        </Box>
                        <Box>
                            <ButtonControla
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agregar módulo a la sección"}
                                functionOnClick={() => { handleOpenModalModules() }}
                            />
                        </Box>
                    </Stack>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Paper sx={{ overflow: "hidden" }}>
                            {
                                modulesBySection.length === 0 ? (
                                    <StyledTypography
                                        sx={{
                                            padding: "8px",
                                            fontSize: "18px",
                                            color: "#305AD9",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        No contiene módulos.
                                    </StyledTypography>

                                ) : ""
                            }
                            <Grid
                                container
                                spacing={2}
                                sx={{ width: "100%", margin: "auto", display: "flex", flexDirection: 'row', flexWrap: 'wrap',columnGap:'80px',rowGap:"2px" }}
                            >
                                {modulesBySection.map((module, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}
                                        key={index} sx={{ p: 2 }}>
                                        <Card
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                height: 100,
                                                width: 350,

                                                "&:hover": {
                                                    cursor: "pointer",
                                                    border: "1px solid #0F1255",
                                                    boxShadow: "0px 4px #0F1255",
                                                },
                                            }}
                                            onClick={() => {
                                                handleSearchModules(module) 
                                            }}
                                        >
                                            <Box
                                                sx={{ display: "flex", flexDirection: "column" }}
                                            >
                                                <CardContent sx={{ flex: "1 0 auto", display: "flex", alignItems: 'center', textAlign: "center" }}>
                                                    <Typography sx={{ fontSize: "14px" }}>
                                                        {module.title}
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                            <CardMedia
                                                component="img"
                                                sx={{
                                                    maxWidth: 100,
                                                    marginLeft: 0,
                                                    maxHeight: 100,
                                                    objectFit: "contain",
                                                }}
                                                image={module.image}
                                                alt="Live from space album cover"
                                            />
                                            <Box
                                                sx={{ display: "flex", flexDirection: "row", gap: "3px", width: "auto" }}
                                            >
                                                <CardContent sx={{ flex: "1 0 auto", display: "flex", flexDirection: "row", gap: "3px", alignItems: 'center' }}>
                                                    <ButtonIconControla
                                                        icon={<Delete sx={{ color: "white" }} />}
                                                        backgroundColor={"#EB5757"}
                                                        backgroundColorHover={"#FF4040"}
                                                        textTooltip={"Eliminar"}
                                                        functionOnClick={(e) => {
                                                            e.stopPropagation();

                                                            handleOpenModalConfirm(
                                                                "¿Estás seguro de eliminar este módulo?",
                                                                "Una vez eliminado no se podrá recuperar información",
                                                                "Sí, eliminar",
                                                                module.id_config_section
                                                            )
                                                        }}
                                                    />

                                                </CardContent>
                                            </Box>

                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            </Container>

             {/* modal agregar modulos */}
             <Dialog
                open={openModalModules}
                onClose={handleCloseModalModules}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitleControla
                    titleText="Agregar módulos a sección"
                    functionOnClose={handleCloseModalModules}
                />
                
                <form onSubmit={handlePostModuleBySection}>
                    <DialogContent>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <SelectControla
                                text="Módulos:"
                                inputValue={setModuleSelected}
                                modalType={true}
                                required
                                value={moduleSelected || ''}
                                childrenRows={modules.map((rows) => (
                                    <MenuItem key={rows.id_configuration_modules} value={rows.id_configuration_modules}>
                                        {rows.title}
                                    </MenuItem>
                                ))}
                            />

                            <SelectControla
                                text="Estado:"
                                inputValue={setStatusSelected}
                                modalType={true}
                                required
                                value={statusSelected || ''}
                                childrenRows={status.map((rows) => (
                                    <MenuItem key={rows.id_status} value={rows.id_status}>
                                        {rows.name}
                                    </MenuItem>
                                ))}
                            />
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={handleCloseModalModules}
                                />
                            </Box>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Guardar"}
                                    typeButton={"submit"}
                                />
                            </Box>
                        </Stack>

                    </DialogActions>

                </form>
            </Dialog>

            {/* modal de informaciòn */}
            <Dialog
            open={openModalInformative}
            onClose={handleCloseModalInformative}
            maxWidth="sm"
            fullWidth={true}
            >
                <DialogTitleControla
                titleText={"Informe"}
                functionOnClose={handleCloseModalInformative}
                />
                <DialogContent sx={{ paddingTop: "0" }}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            color: "#1638F2",
                        }}
                    >
                        {"No existe galeria de submódulos en el sistema"}
                    </Typography>

                </DialogContent>
            </Dialog>

            <DialogInformativeControla
              open={openModalInformative}
              onClose={handleCloseModalInformative}
              message={messageModal}
            />


             {/* mostrar loading  */}
             <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 9999,
                }}
                open={openBackdrop}
            >
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>
    );

}

export default ModulesBySectionMembership;