import { Box, Backdrop, CircularProgress, Container, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import { BorderColorOutlined,  Delete } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import Pagination from "./pagination";
import { useAppContext } from "../../../../context/AppContext";

import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import MyTrainings from "../../../../api/MyTrainings";
import LoadingControla from "../../../../components/load/loadingControla";
import { useState } from "react";

export const TableTrainings = (props) => {
	const { permissions } = useAppContext();
	const { handleGetTrainings, rowTrainings, currentPage, loading } = props
	const { page, size } = useParams();
	const blue = "#034AFF";

	//loading
	const [openAlert, setOpenAlert] = useState(false);
	const [openLoading, setOpenLoading] = useState(false);
	const [messageAlert, setMessageAlert] = useState("");
	const [typeAlert, setTypeAlert] = useState("success");

	const handleOpenLoading = () => {
		setOpenAlert(false);
		setOpenLoading(true);
		setMessageAlert("");
		setTypeAlert("success");
	};

	const handleOpenAlert = (alertMessage, alertType) => {
		setOpenAlert(true);
		setOpenLoading(false);
		setMessageAlert(alertMessage);
		setTypeAlert(alertType);
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
	};


	//modal de confirmación
	const [openModalConfirm, setOpenModalConfirm] = useState(false);
	const [titleText, setTitleText] = useState("");
	const [contentText, setContentText] = useState("");
	const [buttonTextConfirm, setButtonTextConfirm] = useState("");
	const [buttonActionType, setButtonActionType] = useState("");
	const [idTraining, setIdTraining] = useState("");

	const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, buttonActionType, idTraining) => {
		setOpenModalConfirm(true);

		setTitleText(titleText);
		setContentText(contentText);
		setButtonTextConfirm(buttonTextConfirm);
		setButtonActionType(buttonActionType);
		setIdTraining(idTraining);
	}

	const handleCloseModalConfirm = () => {
		setOpenModalConfirm(false);
	}

	const handleActionConfirm = () => {
		switch (buttonActionType) {
			case "delete":
				handleDeleteTraining();
				break;

		}

	}

	//
	const handleDeleteTraining = async () => {
		handleOpenLoading()

		const resp = await MyTrainings.deleteTrainings(idTraining);

		if (resp.success == true) {
			handleGetTrainings(currentPage);
			handleOpenAlert("Se eliminó la capacitación con éxito", "success")
		} else {
			handleOpenAlert("Ocurrió un error al eliminar", "error");
		}

		handleCloseModalConfirm();
	}


	return (
		<Box>
			<DialogConfirmControla
				openModalConfirm={openModalConfirm}
				handleCloseModalConfirm={handleCloseModalConfirm}
				titleText={titleText}
				contentText={contentText}
				buttonTextConfirm={buttonTextConfirm}
				handleActionConfirm={handleActionConfirm}
			/>
			<LoadingControla
				openLoading={openLoading}
				openAlert={openAlert}
				handleCloseAlert={handleCloseAlert}
				messageAlert={messageAlert}
				typeAlert={typeAlert}
			/>



			<Box sx={{ display: "flex", justifyContent: "center" }}>
				{loading && (<CircularProgress />)}
			</Box>
			{
				!loading && (
					rowTrainings.data.length > 0 ? (
						<Grid container sx={{ mt: 2 }}>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Paper sx={{ overflow: "hidden" }}>
									<TableContainer>
										<Table
											stickyHeader
											sx={{ width: "100%", minWidth: "700px" }}
											aria-label="simple table"
										>
											<TableHead>
												<TableRow sx={{ display: rowTrainings.data.length == 0 ? 'none' : '' }}>
													{/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
													<TableCell align="center" sx={{ color: blue }}>
														Tema
													</TableCell>
													<TableCell align="center" sx={{ color: blue }}>
														Nombre
													</TableCell>
													<TableCell align="center" sx={{ color: blue }}>
														Estado
													</TableCell>
													<TableCell align="center" sx={{ color: blue }}>
														Fecha de inicio
													</TableCell>
													<TableCell align="center" sx={{ color: blue }}>
														Fecha de fin
													</TableCell>
													<TableCell align="center" sx={{ color: blue }}>
														Acciones
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{rowTrainings.data.map((row, key) => (
													<TableRow
														hover
														sx={{
															"&:last-child td, &:last-child th": { border: 0 },
														}}
														key={key}
													>
														<TableCell
															component="th"
															scope="row"
														>
															{row.subject}
														</TableCell>
														<TableCell
															component="th"
															scope="row"
														>
															{row.name}
														</TableCell>
														<TableCell
															component="th"
															scope="row"
														>
															{row.status}
														</TableCell>
														<TableCell
															component="th"
															scope="row"
														>
															{(row.timestamp_start != null) ? row.timestamp_start.substr(0, 19) : ''}
														</TableCell>
														<TableCell
															component="th"
															scope="row"
														>
															{(row.timestamp_end != null) ? row.timestamp_end.substr(0, 19) : ''}
														</TableCell>
														<TableCell align="right">
															<Stack
																direction="row"
																justifyContent="end"
																alignItems="center"
															>
																<ButtonIconControla
																	roles={permissions.CAPACIT_TODAS_CAPACIT_EDIT}
																	icon={
																		<BorderColorOutlined
																			sx={{ color: "white" }}
																		/>
																	}
																	backgroundColor={"#2D9CDB"}
																	backgroundColorHover={"#33AEF4"}
																	textTooltip={"Editar"}
																	isNavegationOrigin={true}
																	directionUrl={"/trainings/all/edit?id_trainings=" + row.id_trainings}
																/>
																{
																	row.id_trainings_statuses == 4 ? (
																		<ButtonIconControla
																			roles={permissions.CAPACIT_TODAS_CAPACIT_ELIM}
																			icon={<Delete sx={{ color: "white" }} />}
																			backgroundColor={"#EB5757"}
																			backgroundColorHover={"#FF4040"}
																			textTooltip={"Eliminar"}
																			functionOnClick={() => {
																				handleOpenModalConfirm(
																					"Estás seguro de eliminar esta capacitación",
																					"Una vez eliminado no se podrá recuperar la información",
																					"Si, eliminar",
																					"delete",
																					row.id_trainings
																				)
																			}}
																		/>


																	) : ""
																}

															</Stack>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</Grid>
						</Grid>
					) : (
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							No se encontro capacitaciones
						</Box>
					)
				)
			}

			<Pagination
				handlePageChange={handleGetTrainings}
				currentPage={currentPage}
				lastPage={rowTrainings.last_page}
			/>

			
		</Box>
	)
}