import React from 'react';
import { router } from './routes/routes';
import { AppProvider } from './context/AppContext';
import { RouterProvider } from 'react-router-dom';
import AppRoutes from './routes/dinamyc';

const App = () => {
  return (
    <AppProvider>
      <AppRoutes />
      {/*<RouterProvider router={router} */}
    </AppProvider>
  );
};

export default App;
