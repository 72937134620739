import React, { useEffect, useState } from "react";
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, Tabs, Tab, CircularProgress, Stack } from "@mui/material";
import { useAppContext } from "../../context/AppContext";
import { useLocation } from "react-router-dom";


const blue = "#034AFF";

const TableControla = ({ tabs, children, loading }) => {

  const location= useLocation();

  const [selectedTab, setSelectedTab] = useState(0);
  const { loadingPermissions } = useAppContext();

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(()=>{
    const params = new URLSearchParams(window.location.search);
    const newIdTabs = parseInt(params.get('idTabs') || 0);
    setSelectedTab(newIdTabs);
  },[location])


  return (
    <Paper sx={{ overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table
          stickyHeader
          sx={{ width: "100%", minWidth: "700px" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {tabs && tabs.map((tab, index) => {
                if (index == selectedTab) {
                  return tab.columnNames.map((name, index) => (
                    <TableCell key={index} sx={{ color: "blue" }}>
                      {name}
                    </TableCell>
                  ))
                }
              })}
              {
                tabs.some((e) => 'label' in e) ? (
                  <TableCell colSpan={4}>
                    <Stack
                      direction="row"
                      justifyContent="end"
                      alignItems="center"
                    >
                      <Tabs
                        value={selectedTab}
                        onChange={handleChangeTab}
                        aria-label="Tipos de documento"
                      >
                        {tabs && tabs.map((tab, index) => {
                          if (tab.label) {
                            return <Tab key={index} label={tab.label} />
                          }
                        })}
                      </Tabs>
                    </Stack>
                  </TableCell>
                ) : ""
              }
            </TableRow>
          </TableHead>

          {loading || loadingPermissions ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            (children.length != undefined ? children[selectedTab] : children) && (React.cloneElement(children.length != undefined ? children[selectedTab] : children, { documentType: selectedTab }))
          )}

        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableControla;
