import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Stack,
  InputLabel,
  InputBase,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBackIosNew,
  ArrowForwardIos,
  Upload
} from "@mui/icons-material";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import MyPets from "../../../../api/MyPets";

import CloseIcon from "@mui/icons-material/Close";
import BannerControla from "../../../../components/cards/bannerControla";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../../../components/text/subtitleText";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import MyNotificacionNew from "../../../../api/MyNotificacionNew";
import { useAppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import ListModels from "./components/list-models";
import ListActives from "./components/list-actives";
import ListArchived from "./components/list-archived";
import TableControla from "../../../../components/tables/table-controla";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";



const blue = "#034AFF";
const purple = "#8B8BD8";

function Index() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");
  const [dataNotification, setDataNotification] = useState({ message: null,
    document_url: null,
    id_users_created: null,
    id_users_modified: null,
    id_users_sender: null,
    id_users_receiving: null });
  
  const getUser = () =>{
    return MyConfigurationApi.userData();
  }

  const [statusFirmPrepare , setStatusFirmPrepare] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType,
    dataNotification,
    firmPrepare
  ) => {
    setIdPETS(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    setDataNotification(dataNotification);
    setStatusFirmPrepare(firmPrepare);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        deletePets();
        break;
      case "revision":
        updatestatusesPets(2);
        break;
      case "public":
        updatestatusesPets(3);
        break;
      case "archived":
        updatearchivedPets();
        break;
      case "unarchived":
        updateunarchivedPets();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  // abrir dialogo agregar modelo pets
  const [openModalUploadAddModelPets, setOpenModalUploadAddModelPets] =
    useState(false);
  const handleOpenModalUploadAddModelPets = () => {
    setCode(null);
    setVersion(null);
    setTitulo(null);
    setDocumentUrl(null);
    setOpenModalUploadAddModelPets(true);
  };

  const handleCloseModalUploadAddModelPets = () => {
    setOpenModalUploadAddModelPets(false);
  };

  // Abrir diálogo de cargar archivo pets
  const [openModalUploaFilePets, setOpenModalUploadFilePets] = useState(false);
  const handleOpenModalUploadFilePets = () => {
    setCode(null);
    setVersion(null);
    setTitulo(null);
    setDocumentUrl(null);
    setOpenModalUploadFilePets(true);
  };

  const handleCloseModalUploadFilePets = () => {
    setOpenModalUploadFilePets(false);
  };

  const [code, setCode] = useState(null);
  const [version, setVersion] = useState(null);
  const [titulo, setTitulo] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  // id_pets_statuses, code, version, name, document_url, mode
  // estados de los PETS
  const [rowsActives, setRowsActives] = useState([]);
  const [rowsArchived, setRowsArchived] = useState([]); //archived
  const [rowsModels, setRowsModels] = useState([]);

  const currentTime = new Date();
  const [year, setYear] = useState(currentTime.getFullYear());

  const [documentType, setDocumentType] = useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    handleGetPets(year + 1);
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    handleGetPets(year - 1);
  };

  // mostrar PETS y filtrar por año
  const handleGetPets = async (inputYear = undefined) => {
    handleOpenLoading()
    if (inputYear != undefined) {
      handleOpenLoading();
    }
    const response = await MyPets.pets({
      year: inputYear == undefined ? year : inputYear,
    });

    if (response.success === false) {
      handleOpenAlert("Ocurrio un error al listar los PETS", "error");
    } else {
      setRowsActives(response.pet_no_archived);
      setRowsArchived(response.pet_archived);
      setRowsModels(response.pet_model);
      if (inputYear != undefined) {
        setDocumentType(1);
        handleOpenAlert(
          "Listando PETS activos del año " + inputYear,
          "success"
        );
      }
      setOpenLoading(false);
    }
    return response;
  };
  //   crear pets Model
  const handlePostCreateModel = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPets.createPets({
      code: code,
      version: version,
      name: titulo,
      document_url: documentUrl,
      model: 1,
    });

    if (response.success !== false) {
      handleOpenAlert(response.msg, "success");
      handleGetPets();
      handleCloseModalUploadAddModelPets();
    } else {
      handleOpenAlert("Ocurrió un error al crear el modelo", "error");
    }
    return response;
  };

  //   crear pets archivo
  const handlePostCreateArchive = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyPets.createPets({
      code: code,
      version: version,
      name: titulo,
      document_url: documentUrl,
      model: 0,
    });
    if (response.success !== false) {
      navigate("/documentation/documents/procedures/pets/?idTabs=1");
      await handleGetPets();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Ocurrió un error al crear el archivo", "error");
    }
    handleCloseModalUploadFilePets();
    return response;
  };

  // editar archivo pets
  const [idPETS, setIdPETS] = useState(0);

  const [openModalEditFile, setOpenModalEditFile] = useState(false);
  const handleCloseModalEditFile = () => setOpenModalEditFile(false);

  const handleOpenModalEditFile = (idPets, name, code, documentUrl) => {
    setIdPETS(idPets);
    setTitulo(name);
    setCode(code);
    setDocumentUrl(documentUrl);
    setOpenModalEditFile(true);
  };

  const handlePostEditPets = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPets.editPets({
      id_pets: idPETS,
      name: titulo,
      code: code,
      document_url: documentUrl,
    });
    if (response.success !== false) {
      handleOpenAlert(response.msg, "success");
      handleCloseModalEditFile();
      handleGetPets();
    } else {
      handleOpenAlert("Hubo un error al actualizar", "success");
    }
    return response;
  };

  // eliminar PETS
  const deletePets = async () => {
    handleOpenLoading();
    const response = await MyPets.deletePets({ id_pets: idPETS });
    if (response.success !== false) {
      await handleGetPets();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Ocurrió un error al eliminar el archivo", "error");
    }
    return response;
  };

  const updatestatusesPets = async (idPetsStatuses) => {
    if (idPetsStatuses == 2 && statusFirmPrepare == null) {
      handleOpenAlert("No se puede enviar a revisión, falta firma del que elabora PETS", "error");
    } else {
      handleOpenLoading();
      const response = await MyPets.updatestatusesPets({
        id_pets: idPETS,
        id_pets_statuses: idPetsStatuses,
      });


      if (response.success === false) {
        handleOpenAlert(response.msg, "error");
      } else {
        await handleGetPets();
        //llamada post de notificacion
        // postNotification();

        handleOpenAlert("Documento enviado a revisión con éxito", "success");
      }
      return response;

    }


  };

  // const postNotification = async () =>{
  //   dataNotification.id_users_receiving.forEach(async element => {
  //     const newData = {...dataNotification, "id_users_receiving": element};
  //     console.log(newData);
  //     const response  = await MyNotificacionNew.sendNotificacion(newData);
  //   });

  // };

  const updatearchivedPets = async () => {
    handleOpenLoading();
    const response = await MyPets.updatearchivedPets({ id_pets: idPETS });
    if (response.success === false) {
      alert("Error");
    } else {
      handleOpenAlert("Documento archivado", "success");
      await handleGetPets();
    }
    return response;
  };

  const updateunarchivedPets = async () => {
    handleOpenLoading();
    const response = await MyPets.updateunarchivedPets({ id_pets: idPETS });
    if (response.success === false) {
      alert("Error");
    } else {
      handleOpenAlert("Documento desarchivado", "success");
      await handleGetPets();
    }
    return response;
  };

  useEffect(() => {
    handleGetPets();
  }, []);

 
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <Box>
          <ButtonMailboxControla
           originUrl={"/documentation/documents/procedures/pets"}
          />
          <Grid container>
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Procedimientos"}
                color={"#9191B0"}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/petsBanner.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="PETS"
              />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
              <Stack direction={"row"} spacing={2}>
                <Box>
                  <ButtonControla
                    roles={permissions.DOC_HG_PROC_PETS_MOD_AGREGAR_MODELO}
                    iconButton={<Add sx={{ color: "white" }} />}
                    textButton={"Agregar modelo PETS"}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#1976d2"}
                    functionOnClick={handleOpenModalUploadAddModelPets}
                  />
                </Box>
                <Box>
                  <ButtonControla
                    roles={ permissions.DOC_HG_PROC_PETS_MOD_SUBIR_ARCHIVO}
                    iconButton={<Upload sx={{ color: "white" }} />}
                    backgroundColor={"#1cb591"}
                    backgroundColorHover={"#169073"}
                    textButton={"Subir archivo PETS"}
                    functionOnClick={handleOpenModalUploadFilePets}
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={3} xl={3} sx={{ mt: 2 }}>
              {" "}
            </Grid>

            {/* filtrar por año */}
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={2} md={3} lg={3} xl={3}>
                  <InputLabel
                    sx={{
                      padding: "8px 0px 8px 0px",
                      fontSize: "14px",
                      color: "#305AD9",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Periodo:
                  </InputLabel>
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                  <InputBase
                    type="number"
                    value={year}
                    onChange={handleGetPets}
                    readOnly
                    sx={{
                      width: "100%",
                      padding: "4px 10px 4px 10px",
                      backgroundColor: "white",
                      color: "#305AD9",
                      fontSize: "14px",
                      borderRadius: "8px",
                    }}
                  ></InputBase>
                </Grid>
                <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                    }}
                    onClick={handleDecreaseYear}
                  >
                    <ArrowBackIosNew />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                    }}
                    onClick={handleIncreaseYear}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

              <TableControla
                loading={openLoading}
                tabs={[
                  {
                    label: "Modelos",
                    columnNames: ["Nombre del documento"]
                  },
                  {
                    label: "Activos",
                    columnNames: ["Nombre del documento", "Tipo", "Estado"]
                  },
                  {
                    label: "Archivados",
                    columnNames: ["Nombre del documento", "Tipo", "Estado"]
                  }
                ]}
              >
                <ListModels
                  rowsModels={rowsModels}
                  colorCell={purple}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />

                <ListActives
                  rowsActives={rowsActives}
                  colorCell={purple}
                  getUser={getUser}
                  handleOpenModalEditFile={handleOpenModalEditFile}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />

                <ListArchived
                  rowsArchived={rowsArchived}
                  colorCell={purple}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />

              </TableControla>

            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Dialog subir archivo pets PETS */}
      <Dialog
        open={openModalUploaFilePets}
        onClose={handleCloseModalUploadFilePets}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Subir archivo PETS
          </Typography>
        </DialogTitle>

        <form onSubmit={handlePostCreateArchive}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Código"}
                  inputType={"text"}
                  inputValue={setCode}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Versión"}
                  inputType={"text"}
                  inputValue={setVersion}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitulo}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadFilePets}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar modelo PETS */}
      <Dialog
        open={openModalUploadAddModelPets}
        onClose={handleCloseModalUploadAddModelPets}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar nuevo modelo PETS
          </Typography>
        </DialogTitle>

        <form onSubmit={handlePostCreateModel}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Código"}
                  inputType={"text"}
                  inputValue={setCode}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Versión"}
                  inputType={"text"}
                  inputValue={setVersion}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitulo}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadAddModelPets}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog para editar archivo pets*/}
      <Dialog
        open={openModalEditFile}
        onClose={handleCloseModalEditFile}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Actualizar documento PETS"
          functionOnClose={handleCloseModalEditFile}
        />
        <form onSubmit={handlePostEditPets}>
          <DialogContent>
            <InputControlaVertical
              text={"Código:"}
              inputType={"text"}
              value={code}
              inputValue={setCode}
              modalType={true}
            />
            <InputControlaVertical
              text={"Nombre del PETS:"}
              inputType={"text"}
              value={titulo}
              inputValue={setTitulo}
              modalType={true}
            />
            <InputControlaVertical
              text={"Subir Archivo"}
              inputType={"file"}
              inputValue={setDocumentUrl}
              modalType={true}
            />
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <Box>
                <ButtonControla
                  backgroundColor={"#CBCBFF"}
                  textButton={"Cancelar"}
                  backgroundColorHover={"#CBCBFF"}
                  typeButton={"button"}
                  functionOnClick={handleCloseModalEditFile}
                />
              </Box>
              <Box>
                <ButtonControla
                  iconButton={<Add />}
                  backgroundColor={"#169073"}
                  textButton={"Actualizar PETS"}
                  backgroundColorHover={"#1BAA88"}
                  typeButton={"submit"}
                />
              </Box>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Index;
