import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  BorderColorOutlined,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
  InfoOutlined,
  PictureAsPdf,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import MyDangerousIncident from "../../../../api/MyDangerousIncident";
import LoadingControla from "../../../../components/load/loadingControla";
import MyAreas from "../../../../api/MyAreas";
import CardUserControla from "../../../../components/cards/cardUserControla";
import { useAppContext } from "../../../../context/AppContext";
import MyTasksNew from "../../../../api/MyTasksNew";

const colorTitle = "#9191B0";
const blue = "#034AFF";

function Edit() {
  const { permissions } = useAppContext();
  const rolesButtonAddData = permissions.DOC_REGIS_INCID_PELIGRO_AGREGAR_DATOS;
  const rolesButtonViewDocument = permissions.DOC_REGIS_INCID_PELIGRO_VER_DOCUMENT;
  const rolesButtonAddCorrectiveAcction = permissions.DOC_REGIS_INCID_PELIGRO_AGREGAR_MEDIDAS;
  const rolesButtonAddResponsible = permissions.DOC_REGIS_INCID_PELIGRO_AGREGAR_RESPONS;
  const rolesButtonUpdateRegis = permissions.DOC_REGIS_INCID_PELIGRO_ACTUALIZAR_REGIS;
  const rolesButtonCloseRegis = permissions.DOC_REGIS_INCID_PELIGRO_CERRAR_REGIS;
  const rolesButtonViewPdf = permissions.DOC_REGIS_INCID_PELIGRO_VER_PDF;

  const params = new URLSearchParams(window.location.search);
  const idRegistersDangerousIncidents = params.get(
    "idRegistersDangerousIncidents"
  );

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdWorkerInvolved(id);
    setIdMeasures(id);
    setIdResponsibleWorker(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteWorkers":
        handleDeleteAccidentsWorkers();
        break;
      case "deleteMeasures":
        handleDeleteCorrectivesActions();
        break;
      case "deleteResponsible":
        handleDeleteResponsibleWorker();
        // updatestatusesPets();
        break;
      case "close":
        handleGetRegisterStatus();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  // INCIDENTES PELIGROSOS

  // variables del form general
  const [code, setCode] = useState("");
  const [typeIncidentDangerous, setTypeIncidentDangerous] = useState("");
  const [affectedWorkers, setAffectedWorkers] = useState("");
  const [affectedResidents, setAffectedResidents] = useState("");
  const [detailTypeAttention, setDetailTypeAttention] = useState("");
  const [timestampDangerousIncident, setTimestampDangerousIncident] =
    useState("");
  const [timestampInvestigation, setTimestampInvestigation] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [documentUrl1, setDocumentUrl1] = useState("");
  const [documentUrl2, setDocumentUrl2] = useState("");
  const [documentUrl3, setDocumentUrl3] = useState("");
  const [causesDescription, setcausesDescription] = useState("");

  // select add incident o incident peligrosorowsTypesRegisters
  const [rowsTypeIncidentDangerous, setRowsTypeIncidentDangerous] = useState(
    []
  );
  const hadleGetRegistersDangerousIncident = async () => {
    const response =
      await MyDangerousIncident.getRegistersDangerousIncidentType();
    if (response.success === false) {
    } else {
      setRowsTypeIncidentDangerous(response);
    }
    return response;
  };

  // update register de incidentes peligrosos
  const handleUpdateRegistersDangerousIncident = async () => {
    // event.preventDefault();
    handleOpenLoading();
    const response = await MyDangerousIncident.updateRegistersDangerousIncident(
      {
        idRegistersDangerousIncidents,
        code: code,
        registers_dangerous_incident_type: typeIncidentDangerous,
        affected_workers: affectedWorkers,
        affected_residents: affectedResidents,
        detail_type_attention: detailTypeAttention,
        timestamp_accident: timestampDangerousIncident,
        timestamp_investigation: timestampInvestigation,
        location: location,
        description: description,
        document_url_1: documentUrl1,
        document_url_2: documentUrl2,
        document_url_3: documentUrl3,
        causes_description: causesDescription,
        register_status: 1,
      }
    );
    if (response.success === false) {
      handleOpenAlert("Ocurrió un error al crear el modelo", "error");
    } else {
      handleEditRegistersDangerousIncident();
      handleOpenAlert("Se actualizaron los datos", "success");
    }
    return response;
  };

  // edit register incidentes peligrosos
  const handleEditRegistersDangerousIncident = async () => {
    const response = await MyDangerousIncident.editRegistersDangerousIncident({
      idRegistersDangerousIncidents,
    });
    if (response.success === false) {
    } else {
      setRegisterStatus(response.register_status === 1 ? true : false);
      setCode(response.code);
      setTypeIncidentDangerous(response.registers_dangerous_incident_type);
      setAffectedWorkers(response.affected_workers);
      setAffectedResidents(response.affected_residents);
      setcausesDescription(response.causes_description);
      setDescription(response.description);
      setLocation(response.location_dangerous_incident);
      setDetailTypeAttention(response.detail_type_attention);
      setTimestampDangerousIncident(
        response.timestamp_dangerous_incident === null ||
          response.timestamp_dangerous_incident === undefined
          ? ""
          : response.timestamp_dangerous_incident.substring(0, 16)
      );
      setTimestampInvestigation(
        response.timestamp_investigation === null ||
          response.timestamp_investigation === undefined
          ? ""
          : response.timestamp_investigation.substring(0, 10)
      );
      setDocumentUrl1(response.document_url_1);
      setDocumentUrl2(response.document_url_2);
      setDocumentUrl3(response.document_url_3);
    }
    return response;
  };

  // USUARIOS ACTIVOS
  const [rowsUsersSearchs, setRowsUsersSearchs] = useState([]);
  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyUsers.users();
    setRowsUsersSearchs(response.users_actives);
  };

  // buscar usuario por ID y setear sus datos
  const [nameResponsibleInput, setResponsibleInput] = useState(null);
  const [responsibleDocumentInput, setResponsibleDocumentInput] =
    useState(null);
  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setResponsibleInput(response.first_name + " " + response.last_name);
      setResponsibleDocumentInput(response.document);
      setOpenModalResponsible(false);
      handleOpenAlert(response.msg, "success");
    } else {
    }
  };

  // identificar usuario logeado
  const [idUserLogged, setIdUserLogged] = useState();
  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };

  //*************************  TRABAJADOR INVOLUCRADO *******************************************
  const [workersInvolved, setWorkersInvolved] = useState([]);

  const [dayExperienceWorkerInvolved, setDayExperienceWorkerInvolved] =
    useState(0);
  const [monthExperienceWorkerInvolved, setMonthExperienceWorkerInvolved] =
    useState(0);
  const [yearExperienceWorkerInvolved, setYearExperienceWorkerInvolved] =
    useState(0);
  const [areaWorkerInvolved, setAreaWorkerInvolved] = useState(0);
  const [rowsAreaWorkerInvolved, setRowsAreaWorkerInvolved] = useState([]);
  //mostrar las áreas desde configuración
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setRowsAreaWorkerInvolved(response);
    return response;
  };

  const [shiftWorkerInvolved, setShiftWorkerInvolved] = useState("");
  const handleChangeShift = (event) => {
    setShiftWorkerInvolved(event.target.value);
  };
  const [workHoursBeforeWorkerInvolved, setWorkHoursBeforeWorkerInvolved] =
    useState("");
  const [idResponsible, setIdResponsible] = useState(" ");


  // agregar datos del trabajador DIALOG
  const [openModalWorkerInvolved, setOpenModalWorkerInvolved] = useState(false);
  const handleOpenModalWorkerInvolved = () => {
    setDayExperienceWorkerInvolved(null);
    setMonthExperienceWorkerInvolved(null);
    setYearExperienceWorkerInvolved(null);
    setAreaWorkerInvolved(null);
    setShiftWorkerInvolved(null);
    setWorkHoursBeforeWorkerInvolved(null);
    setResponsibleInput("");
    setOpenModalWorkerInvolved(true);
  };

  // crear trabajador involucrado
  const handleCreateWorkersInvolved = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyDangerousIncident.createWorkersInvolved({
      idRegistersDangerousIncident: idRegistersDangerousIncidents,
      id_users: idResponsible,
      nameResponsibleInput: nameResponsibleInput,
      responsibleDocumentInput: responsibleDocumentInput,
      day_experience: dayExperienceWorkerInvolved,
      month_experience: monthExperienceWorkerInvolved,
      year_experience: yearExperienceWorkerInvolved,
      area: areaWorkerInvolved,
      shift: shiftWorkerInvolved,
      work_hours_before: workHoursBeforeWorkerInvolved,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleGetWorkersInvolved();
      setOpenModalWorkerInvolved(false);
      handleOpenAlert("Se creó de forma correcta", "success");
    }
    return response;
  };

  // mostrar trabajadores involucrado
  const handleGetWorkersInvolved = async () => {
    const response = await MyDangerousIncident.getWorkersInvolved({
      idRegistersDangerousIncident: idRegistersDangerousIncidents,
    });
    if (response.success !== false) {
      setWorkersInvolved(response);
    } else {
    }
  };

  // eliminar trabajadores involucrado
  const [idWorkerInvolved, setIdWorkerInvolved] = useState();

  const handleDeleteAccidentsWorkers = async () => {
    handleOpenLoading();
    const response = await MyDangerousIncident.deleteWorkerInvolved({
      idWorkerInvolved: idWorkerInvolved,
    });
    if (response.success === true) {
      handleGetWorkersInvolved();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  // dialog para buscar el responsable de trabajador involucrado
  const [openModalResponsible, setOpenModalResponsible] = useState(false);
  const handleOpenModalResponsible = () => {
    setResponsibleInput("");
    setOpenModalResponsible(true);
  };

  // *********************+  MEDIDAS CORRECTIVAS ***********************************************

  // agregar medidas correctivas DIALOG
  const [openModalCorrectiveActions, setOpenModalCorrectiveActions] =
    useState(false);

  const [timestampStart, setTimestampStart] = useState("");
  const [timestampEnd, setTimestampEnd] = useState("");
  const [descriptionMeasure, setDescriptionMeasure] = useState("");

  const handleOpenModalCorrectiveActions = async() => {
    setTimestampStart(null);
    setTimestampEnd(null);
    setDescriptionMeasure(null);
    setStatusCorrectivesActions(null);
    await handleGetStatusCorrectivesActions();
    setResponsibleInput("");
    setOpenModalCorrectiveActions(true);
  };

  // mostrar estado de la medida correctiva
  const [statusCorrectivesActions, setStatusCorrectivesActions] =
    useState(null);

  const [rowsStatusCorrectivesActions, setRowsStatusCorrectivesActions] =
    useState([]);
  const handleGetStatusCorrectivesActions = async () => {
    const response = await MyDangerousIncident.getCorrectiveActionsSatus();
    console.log(response);

    if (response.success === false) {
    } else {
      setRowsStatusCorrectivesActions(response);
    }
    return response;
  };

  // crear medidas correctivas
  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();

    handleOpenLoading();
    const response = await MyDangerousIncident.createCorrectiveActions({
      idRegistersDangerousIncident: idRegistersDangerousIncidents,
      id_dangerous_incident_corrective_actions_status: statusCorrectivesActions,
      id_users: idResponsible,
      name_user_responsible: nameResponsibleInput,
      document_user_responsible: responsibleDocumentInput,
      timestamp_start: timestampStart,
      timestamp_end: timestampEnd == null ? "" :timestampEnd ,
      description: descriptionMeasure,
    });

    //agregar a tarea

    const data = {
      date_start: timestampStart,
      date_end: timestampEnd,
      description: descriptionMeasure,
      id_status: 1,
      id_users: idResponsible,
      id_tasks_types: 2,
      title: "Tarea asignada en registro de incidentes peligrosos e incidentes",
      origin_url: `documentation/registers/generals/incidentsdangerousincidents/edit?idRegistersDangerousIncidents=${idRegistersDangerousIncidents}`
    }
    const resp = await MyTasksNew.postTasks(data);

    handleGetCorrectivesActions();
    setOpenModalWorkerInvolved(false);
    setOpenModalCorrectiveActions(false);
    handleOpenAlert(
      "Se agregó de forma correcta la medida correctiva",
      "success"
    );

    return response;
  };

  // mostrar medidas correctivas
  const [correctivesActions, setCorrectivesActions] = useState([]);
  const handleGetCorrectivesActions = async () => {
    const response = await MyDangerousIncident.getCorrectiveActions({
      idRegistersDangerousIncident: idRegistersDangerousIncidents,
    });
    setCorrectivesActions(response);
  };

  // eliminar medidas correctives
  const [idMeassure, setIdMeasures] = useState(0);

  const handleDeleteCorrectivesActions = async () => {
    handleOpenLoading();

    const response = await MyDangerousIncident.deleteCorrectivesActions({
      idMeassure: idMeassure,
    });
    await handleGetCorrectivesActions();
    handleOpenAlert(
      "Se eliminó de forma correcta la medida correctiva",
      "success"
    );

    return response;
  };

  // editar estado de la medida correctiva DIALOG
  const [openModalEditCorrectiveActions, setOpenModalEditCorrectiveActions] =
    useState(false);

  const [idCorrectiveActions, setIdCorrectiveActions] = useState();

  const handleOpenEditModalCorrectiveActions = async(idCorrectiveActions) => {
    await handleGetStatusCorrectivesActions();
    setIdCorrectiveActions(idCorrectiveActions);
    setStatusCorrectivesActions(idCorrectiveActions.id_dangerous_incident_corrective_actions_status);
    handleEditCorrectiveActions(idCorrectiveActions);
    setOpenModalEditCorrectiveActions(true);
  };

  const handleEditCorrectiveActions = async (idCorrectiveActions) => {
    const response = await MyDangerousIncident.editCorrectiveActions({ idCorrectiveActions: idCorrectiveActions });
    setStatusCorrectivesActions(response.id_dangerous_incident_corrective_actions_status === undefined ? '' : response.id_dangerous_incident_corrective_actions_status);
    return response;
  };

  const handleUpdateCorrectiveActions = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyDangerousIncident.UpdateCorrectiveActions({
      idCorrectiveActions: idCorrectiveActions,
      id_dangerous_incident_corrective_actions_status: statusCorrectivesActions,
    });
    handleGetCorrectivesActions();
    setOpenModalEditCorrectiveActions(false);
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    return response;
  };


  //*************************  RESPONSABLES  *******************************************
  const [openModalResponsibleWorker, setOpenModalResponsibleWorker] =
    useState(false);

  const handleOpenModalResponsibleWorker = () => {
    setOpenModalResponsibleWorker(true);
  };

  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyDangerousIncident.getResponsibleWorkers({
      idRegistersDangerousIncident: idRegistersDangerousIncidents,
    });
    setResponsibleActions(response);
  };

  //crear trabajadores responsables
  const handleStoreResponsibleWorkers = async (idUsers) => {
    handleOpenLoading();
    const response = await MyDangerousIncident.createResponsibleWorkers({
      idRegistersDangerousIncident: idRegistersDangerousIncidents,
      id_users: idUsers,
    });
    handleGetResponsibleWorkers();
    setOpenModalResponsibleWorker(false);
    handleOpenAlert("Se agregó de forma correcta el responsable", "success");
    return response;
  };

  // eliminar trabajador responsable
  const [idResponsibleWorker, setIdResponsibleWorker] = useState(0);
  const handleDeleteResponsibleWorker = async () => {
    const response = await MyDangerousIncident.deleteResponsibleWorker({
      idResponsible: idResponsibleWorker,
    });
    handleGetResponsibleWorkers();
    return response;
  };

  // firmar responsable
  const [openModalSignResponsibleWorker, setOpenModalSignResponsibleWorker] =
    useState(false);
  const [idResponsibleSign, setIdResponsibleSign] = useState(0);
  const [idResponsibleSignUsers, setIdResponsibleSignUsers] = useState(0);

  const handleOpenModalSignResponsibleWorker = (idResponsible, id_users) => {
    setIdResponsibleSign(idResponsible);
    setIdResponsibleSignUsers(id_users);
    setOpenModalSignResponsibleWorker(true);
  };

  const handleSignResponsibleWorker = async (event) => {
    event.preventDefault();
    const response = await MyDangerousIncident.updateResponsibleWorkers({
      idResponsible: idResponsibleSign,
      id_users: idResponsibleSignUsers,
    });
    setOpenModalSignResponsibleWorker(false);
    handleGetResponsibleWorkers();
  };

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(1);
  const handleGetRegisterStatus = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    if (
      responsibleWorkers.length >= 1 &&
      correctivesActions.length >= 1
    ) {
      const finded = responsibleWorkers.find((item) => item.firm === 0);
      if (finded === undefined) {
        handleOpenLoading();
        const response =
          await MyDangerousIncident.updateRegistersDangerousIncident({
            idRegistersDangerousIncidents,
            code: code,
            registers_dangerous_incident_type: typeIncidentDangerous,
            affected_workers: affectedWorkers,
            affected_residents: affectedResidents,
            detail_type_attention: detailTypeAttention,
            timestamp_accident: timestampDangerousIncident,
            timestamp_investigation: timestampInvestigation,
            location: location,
            description: description,
            document_url_1: documentUrl1,
            document_url_2: documentUrl2,
            document_url_3: documentUrl3,
            causes_description: causesDescription,
            register_status: 2,
          });
        handleOpenAlert("El registro se cerró de forma correcta", "success");
        handleEditRegistersDangerousIncident();
        return response;
      } else {
        handleOpenAlert("Firmas pendientes", "error");
      }
    } else {
      handleOpenAlert(
        "Complete las secciones obligatorias(Medidas correctivas y trabajador responsable)",
        "error"
      );
    }
  };

  // infromación del usuario
  const [openModalDatesUsers, setOpenModalDatesUers] = useState(false);

  const [userJsonDatesUsers, setuserJsonDatesUsers] = useState(false);
  const [documentDatesUsers, setDocumentDatesUsers] = useState(false);
  const [emailDatesUsers, setEmailDatesUsers] = useState(false);
  const [sexDatesUsers, setSexDatesUsers] = useState(false);
  const [phoneDatesUsers, setPhoneDatesUsers] = useState(false);
  const [ageDatesUsers, setAgeDatesUsers] = useState(false);
  const [currentTimeDatesUsers, setCurrentTimeDatesUsers] = useState(false);
  const [campusDatesUsers, setCampusDatesUsers] = useState(false);
  const [jobTypeDatesUsers, setJobTypeDatesUsers] = useState(false);

  const handleOpenModalDataUsers = (
    userJson,
    document,
    email,
    phone,
    sex,
    age,
    currentTime,
    campus,
    jobType
  ) => {
    setuserJsonDatesUsers(userJson);
    setDocumentDatesUsers(document);
    setEmailDatesUsers(email);
    setPhoneDatesUsers(phone);
    setSexDatesUsers(sex);
    setAgeDatesUsers(age);
    setCurrentTimeDatesUsers(currentTime);
    setCampusDatesUsers(campus);
    setJobTypeDatesUsers(jobType);
    setOpenModalDatesUers(true);
  };


  useEffect(() => {
    hadleGetRegistersDangerousIncident();
    handleEditRegistersDangerousIncident();
    handleGetWorkersInvolved();
    handleUserActive();
    // medidas correctivas
    handleGetCorrectivesActions();
    handleGetUsersSearchsAddsWorkers();

    // trabajadores responsables
    handleGetResponsibleWorkers();

    // areas
    handleGetAreas();

    handleGetStatusCorrectivesActions();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={"Registro de incidentes peligrosos e incidentes"}
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"N° de registro:"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleGetRegisterStatus}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Datos del trabajador
                </InputLabel>
              </Box>
              <Box sx={{ marginRight: 90 }}>
                <ButtonIconControla
                  roles={[1, 3, 4, 5, 6, 7, 8, 10, 12, 13]}
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#FC5A70"}
                  backgroundColorHover={"#DC5063"}
                  textTooltip={
                    "Completar sólo en caso que el incidente afecte al trabajador "
                  }
                />
              </Box>

              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    roles={rolesButtonAddData}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModalWorkerInvolved}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de Identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Área
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Turno
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tiempo de experiencia en el puesto de trabajo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N° de horas trabajadas antes del suceso{" "}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                  // sx={{ display: documentType !== 1 ? "none" : "" }}
                  >
                    {workersInvolved.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name_user_responsible}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document_user_responsible}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.area}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.shift}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.day_experience +
                            " dia, " +
                            row.month_experience +
                            " mes, " +
                            row.year_experience +
                            " año"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.work_hours_before}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonIconControla
                              roles={[1, 3, 4, 5, 6, 7, 8, 10, 12, 13]}
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              functionOnClick={() =>
                                handleOpenModalDataUsers(
                                  row,
                                  true,
                                  true,
                                  true,
                                  true,
                                  true,
                                  true,
                                  true,
                                  true
                                )
                              }
                            />
                            {registerStatus === true ? (
                              <ButtonIconControla
                                roles={[1, 7, 8]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador",
                                    "Si, Eliminar",
                                    "deleteWorkers"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"INVESTIGACION DEL INCIDENTE PELIGROSO O INCIDENTE"}
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Seleccione si es incidente peligroso o incidente:"
                  inputValue={setTypeIncidentDangerous}
                  modalType={false}
                  required
                  value={typeIncidentDangerous}
                  childrenRows={rowsTypeIncidentDangerous.map((row) => (
                    <MenuItem
                      key={row.id}
                      value={row.id}
                    >
                      {row.registers_dangerous_incident_type}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"N° de trabajadores potencialmente afectados"}
                  inputType={"number"}
                  inputValue={setAffectedWorkers}
                  value={affectedWorkers}
                  required
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"N° de pobladores potencialmente afectados"}
                  inputType={"number"}
                  inputValue={setAffectedResidents}
                  value={affectedResidents}
                  required
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={
                    "Detallar tipo de atención en primeros auxilios (de ser el caso)"
                  }
                  inputType={"textArea"}
                  inputValue={setDetailTypeAttention}
                  value={detailTypeAttention}
                  required
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={
                    "Fecha y hora en que ocurrió el incidente peligroso o incidente"
                  }
                  inputType={"datetime-local"}
                  inputValue={setTimestampDangerousIncident}
                  value={timestampDangerousIncident}
                  required
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A" }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio de la investigación"}
                  inputType={"date"}
                  inputValue={setTimestampInvestigation}
                  value={timestampInvestigation}
                  required
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A" }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Lugar exacto donde ocurrió el hecho"}
                  inputType={"text"}
                  inputValue={setLocation}
                  value={location}
                  required
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
            </Grid>

            {/* Investigación del accidente de trabajo */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Investigación del incidente peligroso o incidente"}
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={
                    "Describa sólo los hechos, no escriba información subjetiva que no pueda ser comprobada"
                  }
                  inputType={"textArea"}
                  inputValue={setDescription}
                  value={description}
                />
              </Grid>
            </Grid>

            {/* adjuntar */}

            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText text={"Adjuntar"} color={blue} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10} md={10}>
                <InputControlaVertical
                  text={"Declaración del afectado, de ser el caso"}
                  inputType={"file"}
                  inputValue={setDocumentUrl1}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <ButtonIconControla
                  roles={rolesButtonViewDocument}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl1}
                  directionTarget={"_blank"}
                />
              </Grid>
              <Grid item xs={10} md={10}>
                <InputControlaVertical
                  text={"Declaración de testigos(de ser el caso)"}
                  inputType={"file"}
                  inputValue={setDocumentUrl2}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <ButtonIconControla
                  roles={rolesButtonViewDocument}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl2}
                  directionTarget={"_blank"}
                // functionOnClick={() => ""}
                />
              </Grid>
              <Grid item xs={10} md={10}>
                <InputControlaVertical
                  text={
                    "Procedimientos, planos, registros, entre otros que ayuden a la investigación de ser el caso."
                  }
                  inputType={"file"}
                  inputValue={setDocumentUrl3}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <ButtonIconControla
                  roles={rolesButtonViewDocument}
                  icon={<VisibilityOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Ver documento"}
                  directionUrl={documentUrl3}
                  directionTarget={"_blank"}
                // functionOnClick={() => ""}
                />
              </Grid>
            </Grid>

            {/* descripcion de las causas del accidente de trabajo */}

            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={8} md={8}>
                <SubtitleText
                  text={
                    "Descripción de las causas que originaron el incidente peligroso o incidente"
                  }
                  color={blue}
                />
              </Grid>

              <Grid item xs={4} md={4}>
                <ButtonIconControla
                  roles={[1, 3, 4, 5, 6, 7, 8, 10, 12, 13]}
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#F1C40F"}
                  backgroundColorHover={"#F4D03F"}
                  textTooltip={
                    "Cada empresa, entidad pública o privada puede adoptar el modelo de determinación de las causas que mejor se adapte a sus características."
                  }
                />
              </Grid>
            </Grid>

            {/* <Grid item xs={12} md={12}>
              <Typography variant="h6" sx={{ color: "#305ad9" }}>
                Cada empresa, entidad pública o privada puede adoptar el modelo
                de determinación de las causas que mejor se adapte a sus
                características. .
              </Typography>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <InputControlaVertical
                // text={""}
                inputType={"textArea"}
                inputValue={setcausesDescription}
                value={causesDescription}
                required
              />
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{ color: "#F2994A", ml: 1 }}
              >
                Este campo es obligatorio
              </Typography>
            </Grid>

            {/********************************  medidas correctivas  ********************************************** */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Medidas correctivas
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    roles={rolesButtonAddCorrectiveAcction}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar medidas correctivas"}
                    functionOnClick={handleOpenModalCorrectiveActions}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Descripción de la medida correctiva
                        <ButtonIconControla
                          roles={[1, 3, 4, 5, 6, 7, 8, 10, 12, 13]}
                          icon={<InfoOutlined sx={{ color: "white" }} />}
                          backgroundColor={"#F1C40F"}
                          backgroundColorHover={"#F4D03F"}
                          textTooltip={
                            "Descripción de la medida correctiva a implementarse para eliminar la causa y prevenir la consecuencia"
                          }
                        />
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Responsable
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de inicio
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de fin
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Estado{" "}
                        <ButtonIconControla
                          roles={[1, 3, 4, 5, 6, 7, 8, 10, 12, 13]}
                          icon={<InfoOutlined sx={{ color: "white" }} />}
                          backgroundColor={"#F1C40F"}
                          backgroundColorHover={"#F4D03F"}
                          textTooltip={
                            "Completar el estado de implementación de la medida correctiva"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {correctivesActions.map((rows) => (
                      <TableRow
                        hover
                        key={rows.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rows.description === undefined
                            ? ""
                            : rows.description}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.name_user_responsible === undefined
                            ? ""
                            : rows.name_user_responsible}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {rows.timestamp_start === undefined ||
                            rows.timestamp_start === null
                            ? " "
                            : rows.timestamp_start.substring(0, 10)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.timestamp_end === undefined ||
                            rows.timestamp_end === null
                            ? "No definido"
                            : rows.timestamp_end.substring(0, 10)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.id_dangerous_incident_corrective_actions_status === 125 ? "En proceso" : ''}
                          {rows.id_dangerous_incident_corrective_actions_status === 126 ? "Finalizado" : ''}
                          {rows.id_dangerous_incident_corrective_actions_status === 127 ? "Vencida" : ''}
                          {rows.id_dangerous_incident_corrective_actions_status === 128 ? "Cancelada" : ''}

                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus === true ?
                              <ButtonIconControla
                                roles={[1, 7, 8]}
                                icon={
                                  <BorderColorOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                functionOnClick={() => { handleOpenEditModalCorrectiveActions(rows.id) }}
                              /> : ''
                            }
                            {registerStatus === true ? (
                              <ButtonIconControla
                                roles={[1, 7, 8]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rows.id,
                                    "¿Esta seguro de eliminar los datos de la medida correctiva?",
                                    "Una vez eliminado no se podra recuperar los datos de la medida correctiva",
                                    "Si, Eliminar",
                                    "deleteMeasures"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    roles={rolesButtonAddResponsible}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={handleOpenModalResponsibleWorker}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Cargo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.position_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                roles={[1, 7, 8]}
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignResponsibleWorker(
                                    row.id,
                                    row.id_users
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            <img
                              src={row.firm_url}
                              alt='Firma no encontrada'
                              style={{ maxWidth: '100px', maxHeight: '100px' }}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus === true ? (
                              <ButtonIconControla
                                roles={[1, 7, 8]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable " +
                                    row.first_name +
                                    " " +
                                    row.last_name,
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >

              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/incidentsdangerousincidents"}
                />
              </Grid>
              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={rolesButtonUpdateRegis}
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    // typeButton="submit"
                    functionOnClick={handleUpdateRegistersDangerousIncident}
                  />
                </Grid>
              ) : (
                ""
              )}
              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    roles={rolesButtonCloseRegis}
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    typeButton="submit"
                    tooltipText="Es recomendable cerrar el registro sólo cuando hayan vencido las fechas de fin de las medidas correctivas y hayas actualizado su estado"
                  />
                </Grid>
              ) : (
                ""
              )}
              {registerStatus === false ? (
                <Grid item xs={12} md={3}>
                  <ButtonControla
                    roles={rolesButtonViewPdf}
                    iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textButton={"Ver documento PDF"}
                    typeButton={"button"}
                    url={process.env.REACT_APP_API_DJANGO + 'report/' + idRegistersDangerousIncidents}
                    target={"_blank"}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog agregar trabajador involucrado */}
      <Dialog
        open={openModalWorkerInvolved}
        onClose={() => setOpenModalWorkerInvolved(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar trabajador involucrado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateWorkersInvolved}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography
                  style={{
                    display: "flex",
                    fontSize: "20px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Tiempo de experiencia en el puesto de trabajo
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"Dia"}
                  inputType={"number"}
                  inputValue={setDayExperienceWorkerInvolved}
                  value={dayExperienceWorkerInvolved}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"Mes"}
                  inputType={"number"}
                  inputValue={setMonthExperienceWorkerInvolved}
                  value={monthExperienceWorkerInvolved}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"Año"}
                  inputType={"number"}
                  inputValue={setYearExperienceWorkerInvolved}
                  value={yearExperienceWorkerInvolved}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <SelectControla
                  text="Área:"
                  inputValue={setAreaWorkerInvolved}
                  modalType={true}
                  required
                  value={areaWorkerInvolved}
                  childrenRows={rowsAreaWorkerInvolved.map((rows) => (
                    <MenuItem key={rows.id_areas} value={rows.id_areas}>
                      {rows.area}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Turno
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={shiftWorkerInvolved}
                  onChange={handleChangeShift}
                >
                  <MenuItem value={"Dia"}>Dia</MenuItem>
                  <MenuItem value={"Tarde"}>Tarde</MenuItem>
                  <MenuItem value={"Noche"}>Noche</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"N.º de horas trabajadas antes del accidente"}
                  inputType={"text"}
                  inputValue={setWorkHoursBeforeWorkerInvolved}
                  value={workHoursBeforeWorkerInvolved}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Trabajador involucrado: "
                  inputValue={nameResponsibleInput}
                  functionClick={handleOpenModalResponsible}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalWorkerInvolved(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalCorrectiveActions}
        onClose={() => setOpenModalCorrectiveActions(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de inicio"}
                  inputType={"date"}
                  inputValue={setTimestampStart}
                  value={timestampStart}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimestampEnd}
                  value={timestampEnd}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescriptionMeasure}
                  value={descriptionMeasure}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <SelectControla
                  text="Estado de medida correctiva:"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  required
                  value={statusCorrectivesActions}
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={rows.id}
                      value={rows.id}
                    >
                      {rows.status}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <ButtonIconControla
                  roles={[1, 3, 4, 5, 6, 7, 8, 10, 12, 13]}
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#F1C40F"}
                  backgroundColorHover={"#F4D03F"}
                  textTooltip={
                    "Recuerda que al vencer la fecha de ejecución (fecha de fin) considerada debes actualizar el estado de la medida correctiva."
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  inputValue={nameResponsibleInput}
                  functionClick={handleOpenModalResponsible}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalCorrectiveActions(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Editar estado de la medida correctiva */}
      <Dialog
        open={openModalEditCorrectiveActions}
        onClose={() => setOpenModalEditCorrectiveActions(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Editar estado de la medida correctiva
          </Typography>
        </DialogTitle>
        <form onSubmit={handleUpdateCorrectiveActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <SelectControla
                  text="Estado de medida correctiva:"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  required
                  value={statusCorrectivesActions}
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={rows.id}
                      value={rows.id}
                    >
                      {rows.status}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <ButtonIconControla
                  roles={[1, 3, 4, 5, 6, 7, 8, 10, 12, 13]}
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#F1C40F"}
                  backgroundColorHover={"#F4D03F"}
                  textTooltip={
                    "Recuerda que al vencer la fecha de ejecución (fecha de fin) considerada debes actualizar el estado de la medida correctiva."
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalEditCorrectiveActions(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalResponsibleWorker}
        onClose={() => setOpenModalResponsibleWorker(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsUsersSearchs}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalResponsibleWorker(false)}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables */}
      <Dialog
        open={openModalResponsible}
        onClose={() => {
          setOpenModalResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersSearchs}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar responsable */}
      <Dialog
        open={openModalSignResponsibleWorker}
        onClose={() => {
          setOpenModalSignResponsibleWorker(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsibleWorker}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsibleWorker(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* users information */}
      <Dialog
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        open={openModalDatesUsers}
        onClose={() => setOpenModalDatesUers(false)}
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent>
          <CardUserControla
            users_json={userJsonDatesUsers}
            document={documentDatesUsers}
            email={emailDatesUsers}
            phone={phoneDatesUsers}
            sex={sexDatesUsers}
            age={ageDatesUsers}
            current_time={currentTimeDatesUsers}
            campus={campusDatesUsers}
            job_type={jobTypeDatesUsers}
          />{" "}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalDatesUers(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Edit;
