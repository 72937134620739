import { Grid, InputBase, InputLabel, TextareaAutosize } from "@mui/material";
import { React, useState } from "react";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import { validationRoles } from "../../utils/validation_rol";

import ValidationEditByRoles from "../../utils/validation_edit_roles";


function InputControlaVertical(props) {
  const { display,text, inputType, inputValue, modalType = false, value = undefined, required = false, roles = [] ,...other } = props;
  
  //prueba de roles para habilitar o deshabilitar
  const isEdit = roles.length == 0 ? true : ValidationEditByRoles(roles);

  const [textAreaBorder, setTextAreaBorder] = useState('1px solid transparent')

  // const showButton = validationRoles(roles)
  const showButton = true;

  return showButton && (
    <Grid style={{display: `${display}`}} container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <InputLabel
          sx={{
            padding: '8px',
            fontSize: '18px',
            color: '#305AD9',
            fontWeight: 'bold'
          }}> {text} </InputLabel>
        {inputType === 'textArea' ?
          <TextareaAutosize
            onFocus={() => { setTextAreaBorder('1px solid blue') }}
            onBlur={() => { setTextAreaBorder('1px solid transparent') }}
            className='inputControlaVertical'
            onChange={({ target }) => inputValue(target.value)}
            minRows={3}
            required={required}
            readOnly = {!isEdit}
            style={{
              fontSize: '1rem',
              fontFamily: 'Roboto',
              width: '100%',
              padding: '4px 10px',
              border: textAreaBorder,
              outline: '0px transparent',
              backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
              color: '#305AD9',
              borderRadius: '8px',
            }}
            value={(value === undefined || value === null ? undefined : value)}
            {...other}
          />
          :
          inputType === 'file' ?
            <InputBase type={inputType}
              className='inputControlaVertical'
              onChange={({ target }) => inputValue(target.files[0])}
              required={required}
              inputProps={
                { ...other }
              }
              sx={{
                fontFamily: 'Roboto',
                width: '100%',
                padding: '4px 10px',
                border: '1px solid transparent',
                backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
                color: '#305AD9',
                borderRadius: '8px',
                "&.Mui-focused": {
                  border: "1px solid blue",
                }
              }} />
            :
            <InputBase type={inputType}
              className='inputControlaVertical'
              value={(value === undefined || value === null ? undefined : value)}
              onChange={({ target }) => inputValue(target.value)}
              required={required}
              readOnly = {!isEdit}
              {...other}
              sx={{
                fontFamily: 'Roboto',
                width: '100%',
                padding: '4px 10px',
                border: '1px solid transparent',
                backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
                color: '#305AD9',
                borderRadius: '8px',
                "&.Mui-focused": {
                  border: "1px solid blue",
                }
              }} />
        }

      </Grid>
    </Grid >
    
  )
}

export default InputControlaVertical;
