import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Stack,
  } from "@mui/material";

  import {Add} from "@mui/icons-material";

import SubtitleText from "../../../components/text/subtitleText";
import SideNav from "../../../components/sidenav/sideNav";
import BannerControla from "../../../components/cards/bannerControla";
import ButtonControla from "../../../components/buttons/buttonController";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MyConfigurationApi from "../../../api/MyConfigurationApi";
import MyTasks from "../../../api/MyTasks";
import TableControla from "../../../components/tables/table-controla";
import LoadingControla from "../../../components/load/loadingControla";
import ListOcurrences from "./components/list -occurrences";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const purple = "#8B8BD8";

function TaskOccurrences() {
    const location = useLocation();
    const paramsInter = location.state;
    const readOnly = location.state.readOnly;
    console.log(readOnly)

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //fin de alerts

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");

    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, comment) => {
        setOpenModalConfirm(true);

        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setIdComment(comment.id_tasks_comments);
    }
    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }


    const [openModal, setOpenModal] = useState(false);
    const [buttonActionType, setButtonActionType] = useState("");
    const [idComment, setIdComment] = useState("");

    const handleOpenModal = (buttonActionType, comment) => {
        setOpenModal(true);
        setButtonActionType(buttonActionType);
        if (buttonActionType === "editar") {
            setDescriptionComment(comment.description);
            setDateComment(comment.created_date);
            setIdComment(comment.id_tasks_comments);
        } else {
            setDescriptionComment("");
            setDateComment("");
        }

    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleActionModal = (e) => {
        switch (buttonActionType) {
            case "crear":
                handlePostCommentByIdTask(e);
                break;
            case "editar":
                handleUpdateCommentByIdTaks(e);
                break;
        }
    }
    // 

    const [dateComment, setDateComment] = useState("");
    const [descriptionComment, setDescriptionComment] = useState("");

    const [comments, setComments] = useState([]);

    const handlePostCommentByIdTask = async (e) => {
        e.preventDefault();
        handleOpenLoading();

        const data = {
            "created_date": dateComment,
            "description": descriptionComment,
            "id_tasks": paramsInter.id_tasks,
        }
        const resp = await MyTasks.postCommentByIdTask(data);
        if (resp.success === true) {
            getHandleCommentBytasks();
            handleOpenAlert("Se creó comentario con éxito", "success");

        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }

        handleCloseModal();
    }

    const handleUpdateCommentByIdTaks = async (e) => {
        e.preventDefault();
        handleOpenLoading();

        const data = {
            "description": descriptionComment
        }
        const resp = await MyTasks.UpdateCommentByIdTask(idComment, data);
        if (resp.success === true) {
            getHandleCommentBytasks();
            handleOpenAlert("Se actualizó el comentario con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }

        handleCloseModal();
    }

    const handleDeleteCommentByIdTask = async () => {

        handleOpenLoading();

        const resp = await MyTasks.deleteCommentByIdTask(idComment);
        if (resp.success === true) {
            getHandleCommentBytasks();
            handleOpenAlert("Se eliminó el comentario con éxito", "success");

        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }

        handleCloseModalConfirm();
    }

    const getHandleCommentBytasks = async () => {
        handleOpenLoading();

        const resp = await MyTasks.getCommentByIdTask(paramsInter.id_tasks);
        setComments(resp.data);

        setOpenLoading(false);
    }

    const user = MyConfigurationApi.userData();
    const idUserLogged = user.id_users;

    useEffect(() => {
        getHandleCommentBytasks();
    }, []);

    return (
        <Box sx={{ display: "flex" }}>
            <SideNav returnBack="" />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleDeleteCommentByIdTask}
            />

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SubtitleText
                                text={"Actividades"}
                                color={colorTitle}
                            ></SubtitleText>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <BannerControla
                                image={
                                    "/assets/images/banners/configuration/improvactionsbaselinebanner.png"
                                }
                                text={"Ocurrencia de tarea"}
                            />
                        </Grid>

                    </Grid>
                    {
                        readOnly ? "" : (
                            <Grid container
                                spacing={2}
                                sx={{ mt: 2 }}
                                justifyContent="space-between"
                                direction="row"
                                alignItems="center">
                                <Grid item>
                                    <ButtonControla
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar comentario"}
                                        functionOnClick={() => { handleOpenModal("crear", null) }}
                                    />
                                </Grid>
                            </Grid>

                        )
                    }

                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <TableControla
                                loading={openLoading}
                                tabs={[
                                    {
                                        columnNames: ["Orden", "Comentario", "Fecha", "Usuario", "Acciones"]
                                    }
                                ]}
                            >

                                <ListOcurrences
                                    comments={comments}
                                    colorCell={purple}
                                    idUserLogged={idUserLogged}
                                    handleOpenModal={handleOpenModal}
                                    handleOpenModalConfirm={handleOpenModalConfirm}
                                />

                            </TableControla>

                        </Grid>
                    </Grid>

                </Box>
            </Container>

            {/* modal de agregar comentario */}

            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText={"Agregar comentario"}
                    functionOnClose={handleCloseModal}
                />

                <form onSubmit={handleActionModal}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <InputControlaVertical
                            text={"Fecha"}
                            inputType={"date"}
                            inputValue={setDateComment}
                            modalType={true}
                            value={dateComment || ''}
                            required
                        />
                        <InputControlaVertical
                            text={"Descripción"}
                            inputType={"textArea"}
                            inputValue={setDescriptionComment}
                            modalType={true}
                            value={descriptionComment || ''}
                            required
                        />

                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#CBCBFF"}
                                    textButton={"Cancelar"}
                                    backgroundColorHover={"#CBCBFF"}
                                    typeButton={"button"}
                                    functionOnClick={() => handleCloseModal}
                                />
                            </Box>
                            <Box>
                                <ButtonControla
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={"Agregar"}
                                    typeButton={"submit"}
                                />
                            </Box>

                        </Stack>
                    </DialogActions>

                </form>

            </Dialog>

        </Box>
    );

}
export default TaskOccurrences;