import MyConfigurationApi from "./MyConfigurationApi";

function getAccidentsOcupational() {
  const url = "registers/mype/accidents/occupational/incident/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

//registros de mype
async function createAccidentsOccupational({ code }) {
    const url = "registers/mype/accidents/occupational/incident/store";
    const data = { code: code };
    return MyConfigurationApi.connectApi({
      dataJson: data,
      method: "POST",
      url: url,
    });
  }
  
  function editAccidentsOccupational({idRegistersAccOccInc }) {
    const url = "registers/mype/accidents/occupational/incident/edit/" + idRegistersAccOccInc;
    return MyConfigurationApi.connectApi({  dataJson: null,  method: "GET",  url: url, });
  }
  
  async function updateAccidentsOccupational({ 
    idRegistersAccOccInc, 
    code, 
    id_registers_accidents_occupationals_incidents_types,
    timestamp_accident,
    timestamp_investigation,
    location,
    id_registers_accidents_occupationals_incidents_severities,
    id_registers_accidents_occupationals_incidents_incident,
    medical_leave_days,
    affected_workers,
    injured_body_part_desciption,
    description,
    causes_description
   }) {
    
    const url = "registers/mype/accidents/occupational/incident/update/" + idRegistersAccOccInc;
    const data = {
      code: code,
      id_registers_accidents_occupationals_incidents_types:id_registers_accidents_occupationals_incidents_types,
      timestamp_accident:timestamp_accident,
      timestamp_investigation:timestamp_investigation,
      location:location,
      id_registers_accidents_occupationals_incidents_severities:id_registers_accidents_occupationals_incidents_severities,
      id_registers_accidents_occupationals_incidents_incident:id_registers_accidents_occupationals_incidents_incident,
      medical_leave_days:medical_leave_days,
      affected_workers:affected_workers,
      injured_body_part_desciption:injured_body_part_desciption,
      description:description,
      causes_description:causes_description
     
    };

    return MyConfigurationApi.connectApi({  dataJson: data,   method: "PUT",  url: url, });
  }

    // listar Listar tipos de registro
    function typeRegistersAccOccInc() {
      const url = "registers/mype/accidents/occupational/incident/types/get";
      return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
      });
    }
  
  
  // Listar gravedad
  function severityWorkAccident() {
    const url = "registers/mype/accidents/occupational/incident/severities/get";
    return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET", url: url, });
  }

  // listar gravedad de incidentes
  function severityIncidents() {
    const url = "registers/mype/accidents/occupational/incident/severities/incidents/get";
    return MyConfigurationApi.connectApi({
      dataJson: null,
      method: "GET",
      url: url,
    });
  }


  // MEDIDAS CORRECTIVAS
// Usuarios a buscar users de accidents occ
function getAccOccuInciMeasuresUsers({ idRegistersAccOccInc }) {
  const url = "registers/mype/accidents/occupational/incident/measures/users/get/" + idRegistersAccOccInc;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "GET",  url: url, });
}

//get de estatus de medidas correctivas
function getMeasuresStatuses() {
  const url = "registers/mype/accidents/occupational/incident/measures/statuses/get";
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET", url: url, });
}

// listar medidas correctivas en la tabla principal
function getMeasures({ idRegistersAccOccInc }) {
  const url = "registers/mype/accidents/occupational/incident/measures/get/" + idRegistersAccOccInc;
  return MyConfigurationApi.connectApi({dataJson: null, method: "GET", url: url, });
}

// crear medidas correctivas
async function createCorrectivesActions({ 
  idRegistersAccOccInc,  
  id_registers_accidents_occupationals_incidents_measures_statuses, 
  id_users, 
  timestamp_start, 
  timestamp_end,
  description,
  // id_users_created,
  // id_users_modified

}) 
  {
  const url = "registers/mype/accidents/occupational/incident/measures/store/" + idRegistersAccOccInc;
  const data = {
    id_registers_accidents_occupationals_incidents_measures_statuses: id_registers_accidents_occupationals_incidents_measures_statuses, 
    id_users: id_users, 
    timestamp_start: timestamp_start,  
    timestamp_end: timestamp_end,  
    description: description, 
  };
  return MyConfigurationApi.connectApi({dataJson: data,    method: "POST",    url: url,  });
}

//eliminar medidas correctivas
async function deleteCorrectivesActions({ id_measures }) {
  const url ="registers/mype/accidents/occupational/incident/measures/delete/" + id_measures ;
  return MyConfigurationApi.connectApi({dataJson: null,  method: "DELETE",  url: url, });
}


// TRABAJADOR  RESPONSABLE 

// RESPOSABLES
// Buscar trabajadores en responsables:
function getSearchsResponsiblesWorkers({ idRegistersAccOccInc }) {
  const url = "registers/mype/accidents/occupational/incident/responsible/users/get/" + idRegistersAccOccInc;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "GET",  url: url, });
}

// //get de trabajadores responsables
function getResponsibleWorkers({ idRegistersAccOccInc }) {
  const url = "registers/mype/accidents/occupational/incident/responsible/get/"+idRegistersAccOccInc;
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET", url: url, });
}

// // crear trabajadores responsables
async function createResponsibleWorkers({ idRegistersAccOccInc,  id_users}) {
  const url =    "registers/mype/accidents/occupational/incident/responsible/store/" + idRegistersAccOccInc;
  const data = {
    id_users: id_users, 
  };
  return MyConfigurationApi.connectApi({    dataJson: data,    method: "POST",    url: url,  });
}
// // eliminar trabajadores responsable
async function deleteResponsibleWorkers({ id_registers_responsible }) {
  const url ="registers/mype/accidents/occupational/incident/responsible/delete/" + id_registers_responsible;
  return MyConfigurationApi.connectApi({dataJson: null,  method: "DELETE",  url: url, });
}

// ACCIDENTES OCUPACIONALES INCIDENTES
// Buscar trabajadores en responsables:
function getSearchsResponsiblesWorkersAgentesType({ idRegistersAccOccInc }) {
  const url = "registers/mype/accidents/occupational/incident/agents/types/users/get/" + idRegistersAccOccInc;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "GET",  url: url, });
}

// Listar agentes tipos de enfermedades ocupacionales
function getAgentsTypes() {
  const url = "registers/mype/accidents/occupational/incident/agents/types/get";
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET",  url: url, });
}

//get de enfermedades ocupacionales
function getRelsDAgentsTypes({ idRegistersAccOccInc }) {
  const url = "registers/mype/accidents/occupational/incident/rels/agents/types/get/"+idRegistersAccOccInc;
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET", url: url, });
}

// crear enfermedades ocupacionales
async function createAgentsTypes({ 
  idRegistersAccOccInc,  
  id_registers_accidents_occupationals_incidents_agents, 
  first_semester_count,
  second_semester_count,
  disease_name,
  area,
  causes,
  measures,
  id_users,
  start_date,
  end_date 
}) {
  const url ="registers/mype/accidents/occupational/incident/rels/agents/types/store/" + idRegistersAccOccInc;
  const data = {
    id_registers_accidents_occupationals_incidents_agents:id_registers_accidents_occupationals_incidents_agents, 
  first_semester_count:first_semester_count,
  second_semester_count:second_semester_count,
  disease_name:disease_name,
  area:area,
  causes:causes,
  measures:measures,
  id_users:id_users,
  start_date:start_date,
  end_date:end_date
  };
  console.log(data);
  return MyConfigurationApi.connectApi({dataJson: data,    method: "POST",    url: url,  });
}


// eliminar enfermedades ocupacionales
// // eliminar trabajadores responsable
async function deleteAccOccIncAgentsTypes({ id_agent_type }) {
  const url ="registers/mype/accidents/occupational/incident/rels/agents/types/delete/" + id_agent_type;
  return MyConfigurationApi.connectApi({dataJson: null,  method: "DELETE",  url: url, });
}

// ACCIDENTES OCUPACIONALES INCIDENTES TRABAJADORES 
// workers accidents
// trabajadores accidentes 
function getSearchsAccOccIncWorkersUsers({ idRegistersAccOccInc }) {
  const url = "registers/mype/accidents/occupational/incident/workers/users/get/" + idRegistersAccOccInc;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

//get de trabajadores accidentados
function getAccOccIncWorkers({ idRegistersAccOccInc }) {
  const url = "registers/mype/accidents/occupational/incident/workers/get/"+idRegistersAccOccInc;
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET", url: url, });
}

// // crear trabajadores responsables
async function creatAccOccIncsWorkers({ idRegistersAccOccInc,  id_users,  area,  shift,  work_experience,  work_hours_before,}) {
  const url =    "registers/mype/accidents/occupational/incident/workers/store/" + idRegistersAccOccInc;
  const data = {id_users: id_users, area: area, shift: shift,  work_experience: work_experience,  work_hours_before: work_hours_before, };
  return MyConfigurationApi.connectApi({    dataJson: data,    method: "POST",    url: url,  });
}

// // eliminar trabajadores responsable
async function deleteAccOccIncsWorkers({ id_registers_workers }) {
  const url ="registers/mype/accidents/occupational/incident/workers/delete/" + id_registers_workers;
  return MyConfigurationApi.connectApi({dataJson: null,  method: "DELETE",  url: url, });
}

//cerrar registro de accidentes ocupacionales
function accidentsOcupationalEnd({idRegistersAccOccInc}) {
  const url = "registers/mype/accidents/occupational/incident/update/statuses/end/"+idRegistersAccOccInc;
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "PUT", url: url, });
}

// firmar responsable por
function signResponsible({idResponsibleSign}) {
  const url = 'registers/mype/accidents/occupational/incident/responsible/update/firm/' + idResponsibleSign;
  const data = {  
  };
  return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

//get de tipos de agentes
function getAgentsTypesTable() {
  const url = "registers/general/occupational/diseases/categories/agents/types/get";
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET", url: url, });
}

function generatePDF({ idRegistersOcupationalDiseases }) {
    const url = 'registers/general/occupational/diseases/get/pdf/' + idRegistersOcupationalDiseases;
    return MyConfigurationApi.connectApiPDF({ dataJson: null, method: 'GET', url: url });
}

function mypeGeneratePDF({ idRegistersAccOccInc }) {
    const url = 'registers/mype/accidents/occupational/incident/pdf/' + idRegistersAccOccInc;
    return MyConfigurationApi.connectApiPDF({ dataJson: null, method: 'GET', url: url });
}

//tipos de accidentes
function getDisablingAccident({id_registers_accidents_occupationals_incidents}){
  const url = 'mype/accident-type-disabling/accident/' + id_registers_accidents_occupationals_incidents ;
  return MyConfigurationApi.connectApi({ 
    dataJson: null,
    method: 'GET', 
    url: url 
  });
}

function postDisablingAccident({id_registers_accidents_occupationals_incidents,id_registers_accidents_severities_incident,medical_rest_days,affected_workers}){
  const url = 'mype/accident-type-disabling' ;
  const data = {
    id_register_mype: id_registers_accidents_occupationals_incidents,
    id_registers_accidents_severities_incident: id_registers_accidents_severities_incident,
    medical_rest_days: medical_rest_days,
    affected_workers: affected_workers
  }

  return MyConfigurationApi.connectApi({ 
    dataJson: data,
    method: 'POST', 
    url: url 
  });
}

function updateDisablingAccident({id_registers_accidents_occupationals_incidents,id_registers_accidents_severities_incident,medical_rest_days,affected_workers, id_disabling_accident}){
  const url = 'mype/accident-type-disabling/' + id_disabling_accident;
  const data = {
    id_register_mype: id_registers_accidents_occupationals_incidents,
    id_registers_accidents_severities_incident: id_registers_accidents_severities_incident,
    medical_rest_days: medical_rest_days,
    affected_workers:affected_workers 
  }

  return MyConfigurationApi.connectApi({ 
    dataJson: data,
    method: 'PUT', 
    url: url 
  });
}

function deleteDisablingAccident({id_disabling_accident}){
  const url = 'mype/accident-type-disabling/' + id_disabling_accident;
  return MyConfigurationApi.connectApi({ 
    dataJson: null,
    method: 'DELETE', 
    url: url 
  });
}


function getMinorAccident({id_registers_accidents_occupationals_incidents}){
  const url = 'mype/accident-type-minor/accident/' +id_registers_accidents_occupationals_incidents ;
  return MyConfigurationApi.connectApi({ 
    dataJson: null,
    method: 'GET', 
    url: url 
  });
}

function postMinorAccident({id_registers_accidents_occupationals_incidents, medical_rest_days, affected_workers}){
  const url = 'mype/accident-type-minor' ;
  const data = {
    id_register_mype: id_registers_accidents_occupationals_incidents,
    medical_rest_days: medical_rest_days,
    affected_workers: affected_workers,
  }

  return MyConfigurationApi.connectApi({ 
    dataJson: data,
    method: 'POST', 
    url: url 
  });
}

function updateMinorAccident({id_minor_accident,id_registers_accidents_occupationals_incidents, medical_rest_days, affected_workers}){
  const url = 'mype/accident-type-minor/' + id_minor_accident ;
  const data = {
    id_register_mype: id_registers_accidents_occupationals_incidents,
    medical_rest_days: medical_rest_days,
    affected_workers: affected_workers,
  }

  return MyConfigurationApi.connectApi({ 
    dataJson: data,
    method: 'PUT', 
    url: url 
  });
}

function deleteMinorAccident({id_minor_accident}){
  const url = 'mype/accident-type-minor/' + id_minor_accident ;
  return MyConfigurationApi.connectApi({ 
    dataJson: null,
    method: 'DELETE', 
    url: url 
  });
}


function getMortalAccident({id_registers_accidents_occupationals_incidents}){
  const url = 'mype/accident-type-mortal/accident/' + id_registers_accidents_occupationals_incidents ;
  return MyConfigurationApi.connectApi({ 
    dataJson: null,
    method: 'GET', 
    url: url 
  });
}

function postMortalAccident({id_registers_accidents_occupationals_incidents, medical_rest_days, affected_workers}){
  const url = 'mype/accident-type-mortal' ;
  const data = {
    id_register_mype: id_registers_accidents_occupationals_incidents,
    medical_rest_days: medical_rest_days,
    affected_workers: affected_workers,
  }

  console.log(data);
  return MyConfigurationApi.connectApi({ 
    dataJson: data,
    method: 'POST', 
    url: url 
  });
}

function updateMortalAccident({id_mortal_accident, id_registers_accidents_occupationals_incidents, medical_rest_days, affected_workers}){
  const url = 'mype/accident-type-mortal/'  + id_mortal_accident;
  const data = {
    id_register_mype: id_registers_accidents_occupationals_incidents,
    medical_rest_days: medical_rest_days,
    affected_workers: affected_workers,
  }

  console.log(data);
  return MyConfigurationApi.connectApi({ 
    dataJson: data,
    method: 'PUT', 
    url: url 
  });

}

function deleteMortalAccident({id_mortal_accident}){
  const url = 'mype/accident-type-mortal/' + id_mortal_accident ;
  return MyConfigurationApi.connectApi({ 
    dataJson: null,
    method: 'DELETE', 
    url: url 
  });
}


const MyAccidentsOccupationalsIncidents = {
    getAccidentsOcupational,
    createAccidentsOccupational,
    editAccidentsOccupational,
    updateAccidentsOccupational,

    // lista de gravedad

    typeRegistersAccOccInc,
    severityWorkAccident,
    severityIncidents,

    //mEDIDAS CORRECTIVAS 
    getAccOccuInciMeasuresUsers,
    getMeasures,
    createCorrectivesActions,
    getMeasuresStatuses,
    deleteCorrectivesActions,

    // TRABAJDOR RESPONSABLE
    getSearchsResponsiblesWorkers,
    getResponsibleWorkers,
    createResponsibleWorkers,
    deleteResponsibleWorkers,

    // enfermedades ocupacionales
    getAgentsTypes,
    getRelsDAgentsTypes,
    createAgentsTypes,
    getSearchsResponsiblesWorkersAgentesType,
    deleteAccOccIncAgentsTypes,

    //trabajadores enfermos
    getSearchsAccOccIncWorkersUsers,
    getAccOccIncWorkers,
    creatAccOccIncsWorkers,
    deleteAccOccIncsWorkers,

    // cerrar sesion
    accidentsOcupationalEnd,

    // firmar 
    signResponsible,

    // tipos de agentes tabla 
    getAgentsTypesTable,
    generatePDF,
    mypeGeneratePDF,

    //tipos de accidentes
    postDisablingAccident,
    getDisablingAccident,
    updateDisablingAccident,
    deleteDisablingAccident,
    getMinorAccident,
    postMinorAccident,
    updateMinorAccident,
    deleteMinorAccident,
    getMortalAccident,
    postMortalAccident,
    updateMortalAccident,
    deleteMortalAccident





    
  };
  export default MyAccidentsOccupationalsIncidents;