import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import {
    BorderColorOutlined,
    Delete
} from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";

import { useAppContext } from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";

function ListScheduled(props) {
    const {permissions} = useAppContext();
    const navigate = useNavigate();

    const {
        currentMeetings,
        colorCell,
        handleOpenModalConfirm
    } = props
    return (
        <TableBody
            // sx={{ display: typeStatusMeeting === 0 ? "" : "none" }}
        >
            {currentMeetings.map((meeting) => (
                <TableRow
                    hover
                    key={meeting.id_committee_meeting}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.id_committee_meeting}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.description}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.date}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.session === "1" ? "ordinario" : "extraordinario"}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {meeting.modality === "1" ? "presencial" : "remoto"}
                    </TableCell>

                    {/* botones de la tabla */}
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignContent="center"
                        >
                            <ButtonIconControla
                                // roles={permissions.COMITE_AGENDAR_REU_PROGRAMADA_VER}
                                icon={
                                    <VisibilityOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                functionOnClick={() =>{
                                    const params ={
                                        readOnly: true
                                    }
                                    navigate("/proceedingBook/scheduleMeetings/editScheduledMeeting?id_meeting=" + meeting.id_committee_meeting, {state:params})                                                        
                                }}

                            />
                            <ButtonIconControla
                                // roles={permissions.COMITE_AGENDAR_REU_PROGRAMADA_EDITAR}
                                icon={
                                    <BorderColorOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                functionOnClick={() => {
                                    const params = {
                                        readOnly: false
                                    }
                                    navigate("/proceedingBook/scheduleMeetings/editScheduledMeeting?id_meeting=" + meeting.id_committee_meeting, {state:params})                                                         
                                }}
                            />
                            <ButtonIconControla
                                // roles={permissions.COMITE_AGENDAR_REU_PROGRAMADA_PUBLICAR}
                                icon={
                                    <FileUploadOutlinedIcon
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1AA483"}
                                textTooltip={"Notificar"}
                                functionOnClick={() => {
                                    handleOpenModalConfirm(
                                        meeting.id_committee_meeting,
                                        "¿Estás seguro de notificar la reunión?",
                                        "Una vez notificada no podrás editar la reunión",
                                        "Sí, notificar",
                                        "publish"
                                    )
                                }}
                            />
                            <ButtonIconControla
                                // roles={permissions.COMITE_AGENDAR_REU_PROGRAMADA_ELIMINAR}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => handleOpenModalConfirm(
                                    meeting.id_committee_meeting,
                                    "¿Estás seguro de eliminar esta reunión?",
                                    "Una vez eliminado no podrás recuperar la reunión",
                                    "Si, eliminar",
                                    "delete"
                                )}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}

export default ListScheduled;