import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import MyUsers from "../../../../api/MyUsers";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import LoadingControla from "../../../../components/load/loadingControla";
import MyStatisticalData from "../../../../api/MyRegisterStatisticalData";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";

const colorTitle = "#9191B0";


function Edit() {
  const params = new URLSearchParams(window.location.search);
  const idRegistersStatisticalData = params.get(
    "idRegistersStatisticalData"
  );


  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdResponsibleWorker(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteResponsible":
        handleDeleteResponsibleWorker();
        break;
    }
    setOpenModalConfirm(false);
  };
  // Dialogos de confirmacion FIN

  
  // update register
  const handleUpdateRegistersStatisticalData = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const convertHoursWorked = JSON.stringify(hoursWorked);
  
    const response = await MyStatisticalData.updateRegistersStatisticalData(
      {
        idRegistersStatisticalData,
        code,
        startDate,
        endDate,
        hoursWorked: convertHoursWorked
      }
    );
    handleOpenAlert("Se actualizaron los datos con éxito", "success");

    return response;
  };

  // edit register
  const handleEditRegistersStatisticalData = async () => {
    const response = await MyStatisticalData.editRegistersStatisticalData({
      idRegistersStatisticalData,
    });

    setRegisterStatus(response.register_status === 1 ? true : false);
    setCode(response.code);
    setStartDate(response.startDate);
    setEndDate(response.endDate);
    setHoursWorked(response.hoursWorked);

    return response;
  };

  const [listUsers, setListUsers] = useState([]);

  const handleGetUsers = async() => {
    const response = await MyUsers.users();
    setListUsers(response.users_actives);
  }

  // USUARIOS ACTIVOS
  const [rowsUsersSearchs, setRowsUsersSearchs] = useState([]);
  const handleGetUsersSearchsAddsWorkers = async () => {
    let users = listUsers;

    //traer lista de responsables del registro
    const resp = await MyStatisticalData.getResponsibleWorkers({
      idRegistersStatisticalData: idRegistersStatisticalData,
    }) ?? [];

    // filtrar usuarios sin que se repita
    resp.forEach(user => {
      users = users.filter( u => u.id_users !== user.id_users);
    });

    setRowsUsersSearchs(users);
  };



  // identificar usuario logeado
  const [idUserLogged, setIdUserLogged] = useState();
  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };


  //*************************  RESPONSABLES  *******************************************
  const [openModalResponsibleWorker, setOpenModalResponsibleWorker] = useState(false);

  const handleOpenModalResponsibleWorker = async() => {
    handleOpenLoading();
    await  handleGetUsersSearchsAddsWorkers();
    setOpenModalResponsibleWorker(true);
    setOpenLoading(false);
  };


  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyStatisticalData.getResponsibleWorkers({
      idRegistersStatisticalData: idRegistersStatisticalData,
    });
    if (response.success === false) {
    } else {
      setResponsibleActions(response);
    }
  };

  //crear trabajadores responsables
  const handleStoreResponsibleWorkers = async (idUsers) => {
    handleOpenLoading();
    const response = await MyStatisticalData.createResponsibleWorkers({
      idRegistersStatisticalData: idRegistersStatisticalData,
      id_users: idUsers
    });

    if (response.success === false) {
    } else {
      handleGetResponsibleWorkers();
      setOpenModalResponsibleWorker(false);
      handleOpenAlert("Se agregó de forma correcta los datos", "success");
    }
    return response;
  };


  // eliminar trabajador responsable
  const [idResponsibleWorker, setIdResponsibleWorker] = useState(0);
  const handleDeleteResponsibleWorker = async () => {
    const response = await MyStatisticalData.deleteResponsibleWorker({
      idResponsible: idResponsibleWorker,
    });
    handleGetResponsibleWorkers();
    return response;
  };

  // // firmar responsable
  const [openModalSignResponsibleWorker, setOpenModalSignResponsibleWorker] = useState(false);
  const [idResponsibleSign, setIdResponsibleSign] = useState(0);
  const [idResponsibleSignUsers, setIdResponsibleSignUsers] = useState(0);
  const handleOpenModalSignResponsibleWorker = (idResponsible, id_users) => {
    setIdResponsibleSign(idResponsible);
    setIdResponsibleSignUsers(id_users);
    setOpenModalSignResponsibleWorker(true);
  };

  const handleSignResponsibleWorker = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyStatisticalData.updateResponsibleWorkers({
      idResponsible: idResponsibleSign,
      id_users: idResponsibleSignUsers
    });
    setOpenModalSignResponsibleWorker(false);
    handleGetResponsibleWorkers();
    handleOpenAlert("Se registró la firma con éxito", "success");

  };

  // // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(1);
  const handleGetRegisterStatus = async () => {
    handleOpenLoading();
    const response = await MyStatisticalData.updateRegistersStatusClose({
      idRegistersStatisticalData: idRegistersStatisticalData,
    });
    handleOpenAlert("El registro se cerró de forma correcta", "success");
    handleEditRegistersStatisticalData();
    return response;
  };

  //traer datos de registros estadisticos 12 meses
  // REGISTRO DE datos estadisticos
  const [code, setCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate ]= useState("");

//12 meses de inputs
  const [janHoursWorked, setJanHoursWorked] = useState(null);
  const [febHoursWorked, setFebHoursWorked] = useState(null);
  const [marHoursWorked, setMarHoursWorked] = useState(null);
  const [aprHoursWorked, setAprHoursWorked] = useState(null);
  const [mayHoursWorked, setMayHoursWorked] = useState(null);
  const [junHoursWorked, setJunHoursWorked] = useState(null);
  const [julHoursWorked, setJulHoursWorked] = useState(null);
  const [augHoursWorked, setAugHoursWorked] = useState(null);
  const [sepHoursWorked, setSepHoursWorked] = useState(null);
  const [octHoursWorked, setOctHoursWorked] = useState(null);
  const [novHoursWorked, setNovHoursWorked] = useState(null);
  const [decHoursWorked, setDecHoursWorked] = useState(null);


  const [rowsStatisticalData, setRowsStatisticalData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [hoursWorked, setHoursWorked] = useState({});

  const handleGetDataForStatisticsRecords = async () => {
    handleOpenLoading();

    if (code !== "") {
      if (startDate == "" && endDate == "") {
        const hoursWorkedFirst = {
          "01": 0,
          "02": 0,
          "03": 0,
          "04": 0,
          "05": 0,
          "06": 0,
          "07": 0,
          "08": 0,
          "09": 0,
          "10": 0,
          "11": 0,
          "12": 0
        }
        try {
          const resp = await MyStatisticalData.getDataForStatisticsRecords({ horas_trabajadas: hoursWorkedFirst });
          setRowsStatisticalData(resp);
          handleOpenAlert("", "success");
        } catch (e) {
          handleOpenAlert("Ocurrió un error al generar datos estadísticos");
        }


      } else {
        const hoursWorkedSave = JSON.parse(hoursWorked)

        try {
          const resp = await MyStatisticalData.getDataForStatisticsRecords({ horas_trabajadas: hoursWorkedSave, fecha_inicio: startDate, fecha_fin: endDate });
          setRowsStatisticalData(resp);
          handleOpenAlert("Se cargó datos estadísticos del rango de fecha indicada con éxito", "success");
        } catch (e) {
          handleOpenAlert("Ocurrió un error al generar datos estadísticos");
        }
      }

    }



  }

  const handlePostDataForStatisticsRecords = async (e) => {
    e.preventDefault();

    const hoursWorkedPost = {};

    for (let i = 1; i <= dateRange.length; i++) {
      let key = i < 10 ? `0${i}` : `${i}`;
      hoursWorkedPost[key] = dateRange[i - 1].value;
    }

    setHoursWorked(hoursWorkedPost);

    handleOpenLoading();

    try {
      const resp = await MyStatisticalData.getDataForStatisticsRecords({ horas_trabajadas: hoursWorkedPost, fecha_inicio: startDate, fecha_fin: endDate });
      setRowsStatisticalData(resp);
      handleOpenAlert("Datos estadísticos generados con éxito");

    } catch (e) {
      handleOpenAlert("Problemas al generar datos estadísticos", "error");
    }

    //limpiar
      setJanHoursWorked(null);
      setFebHoursWorked(null);
      setMarHoursWorked(null);
      setAprHoursWorked(null);
      setMayHoursWorked(null);
      setJunHoursWorked(null);
      setJulHoursWorked(null);
      setAugHoursWorked(null);
      setSepHoursWorked(null);
      setOctHoursWorked(null);
      setNovHoursWorked(null);
      setDecHoursWorked(null);

    setOpenLoading(false);
    handleCloseModalGenerateStaticalData();
  }

  const [openModalGenerateStaticalData, setOpenModalGenerateStaticalData] = useState(false);



  const handleOpenModalGenerateStaticalData = () => {
    handleShowInputsByNumberMonths();

    if (startDate !== "" && endDate !== "" && (endDate > startDate)) {
      setOpenModalGenerateStaticalData(true)
    } else if(endDate < startDate){
      handleOpenAlert("Fecha fin debe ser mayor a fecha inicial", "error");
    }
    else {
      handleOpenAlert("Ingrese el rango de fechas para generar datos estadísticos", "error");
    }

  }

  const handleCloseModalGenerateStaticalData = () => {
    setOpenModalGenerateStaticalData(false)
  }

 
  const months = [
    {
      id:1,
      inputValue:setJanHoursWorked,
      value: janHoursWorked,
      mes: "Enero",
    },
    {
      id:2,
      inputValue: setFebHoursWorked,
      value: febHoursWorked,
      mes: "Febrero",
    },
    {
      id:3,
      inputValue: setMarHoursWorked,
      value: marHoursWorked,
      mes: "Marzo",
    },
    {
      id:4,
      inputValue: setAprHoursWorked,
      value: aprHoursWorked,
      mes: "Abril",
    },
    {
      id:5,
      inputValue: setMayHoursWorked,
      value: mayHoursWorked,
      mes: "Mayo",
    },
    {
      id:6,
      inputValue: setJunHoursWorked,
      value: junHoursWorked,
      mes: "Junio",
    },
    {
      id:7,
      inputValue: setJulHoursWorked,
      value: julHoursWorked,
      mes: "Julio",
    },
    {
      id:8,
      inputValue: setAugHoursWorked,
      value: augHoursWorked,
      mes: "Agosto",
    },
    {
      id:9,
      inputValue: setSepHoursWorked,
      value: sepHoursWorked,
      mes: "Setiembre",
    },
    {
      id:10,
      inputValue: setOctHoursWorked,
      value: octHoursWorked,
      mes: "Octubre",
    },
    {
      id:11,
      inputValue: setNovHoursWorked,
      value: novHoursWorked,
      mes: "Noviembre",
    },
    {
      id:12,
      inputValue: setDecHoursWorked,
      value: decHoursWorked,
      mes: "Diciembre",
    }]

  const handleShowInputsByNumberMonths = () => {

    const yearStart = startDate.substring(0, 4);
    const yearEnd = endDate.substring(0, 4);
    const initialMonth = startDate.substring(5, 7);
    const finalMonth = endDate.substring(5, 7);

    //para un mismo año 
    if (yearStart == yearEnd) {

      const monthsFilter = months.filter((month) => month.id >= initialMonth && month.id <= finalMonth);
      setDateRange(monthsFilter);

    } else {
      const monthsFilter1 = months.filter((month) => month.id >= initialMonth && month.id <= 12)
      const monthsFilter2 = months.filter((month) => month.id >= 1 && month.id <= finalMonth)

      setDateRange(monthsFilter1.concat(monthsFilter2));
    }

  }


  useEffect(() => {
    handleEditRegistersStatisticalData();

    // RESPONSABLES
    handleGetResponsibleWorkers();

    handleGetUsers();
    handleUserActive();
  }, []);

  useEffect(()=>{
    handleGetDataForStatisticsRecords();
  },[code])

  useEffect(()=>{
    handleShowInputsByNumberMonths();
  },[janHoursWorked,
    febHoursWorked,
    marHoursWorked,
    aprHoursWorked,
    mayHoursWorked,
    junHoursWorked,
    julHoursWorked,
    augHoursWorked,
    sepHoursWorked,
    octHoursWorked,
    novHoursWorked,
    decHoursWorked
  ])

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  "Registros de estadísticas de seguridad y salud en el trabajo"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegistersStatisticalData}>
            {/* tabla para mostrar accidents workers */}
            <Grid container spacing={2} sx={{ marginTop: "4px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Código:"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"Mes inicial:"}
                  inputType={"date"}
                  inputValue={setStartDate}
                  value={startDate}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"Mes final:"}
                  inputType={"date"}
                  inputValue={setEndDate}
                  value={endDate}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} sx={{ marginTop: '40px' }}>

                <ButtonControla
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Generar datos estadísticos"}
                  functionOnClick={handleOpenModalGenerateStaticalData}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper sx={{ overflow: "hidden" }}>
                  <TableContainer>
                    <Table
                      stickyHeader
                      sx={{ width: "100%", minWidth: "100%" }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow sx={{ border: 1 }}>
                          <TableCell rowSpan={2} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#40679E" }}>Mes</TableCell>
                          <TableCell rowSpan={2} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#40679E" }}>N° Accidente Mortal</TableCell>
                          <TableCell rowSpan={2} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#40679E" }} width="100px">Área/Sede</TableCell>
                          <TableCell rowSpan={2} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#40679E" }}>Accid. de Trabajo Leve</TableCell>
                          <TableCell rowSpan={2} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#40679E" }}>Área/Sede</TableCell>
                          <TableCell colSpan={7} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#1B3C73" }}>Solo Para Accidentes Incapacitantes</TableCell>
                          <TableCell colSpan={5} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#526D82" }}>Enfermedad Ocupacional</TableCell>
                          <TableCell rowSpan={2} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#3E497A" }}>N° Incidentes Peligrosos</TableCell>
                          <TableCell rowSpan={2} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#3E497A" }}>Área/Sede</TableCell>
                          <TableCell rowSpan={2} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#3E497A" }}>N° Incidentes</TableCell>
                          <TableCell rowSpan={2} align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#3E497A" }}>Área/Sede</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#365486"}}>N° Accid. Trab. Incap.</TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#365486"}}>Área/Sede</TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#365486"}}>Total Horas hombres trabajadas</TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#365486"}}>Índice de frecuencia</TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#365486"}}>N° días perdidos </TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#365486"}}>Índice de gravedad</TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white" , fontWeight: 'bold', background: "#365486"}}>Índice de accidentabilidad</TableCell>

                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white"  , fontWeight: 'bold', background: "#74959A"}}>N° Enf. Ocup.</TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white"  , fontWeight: 'bold', background: "#74959A"}}>Área/Sede</TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white"  , fontWeight: 'bold', background: "#74959A"}}>N° Trabajadores expuestos al agente</TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white"  , fontWeight: 'bold', background: "#74959A"}}>Tasa de Incidencia</TableCell>
                          <TableCell align="center" sx={{ border: 1, borderColor: '#7882A4', color: "white"  , fontWeight: 'bold', background: "#74959A"}}>N° Trabaj. Con Cáncer Profesional</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {
                          rowsStatisticalData.map((row, i) => (
                            <TableRow
                              hover
                              key={i}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <TableCell align="center" component="th" scope="row" sx={{ color: "blue" }}>{row.mes}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_accidente_mortal}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_mortal}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{ }</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_leve }</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_accidente_incapacitante}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_incapacitante}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.total_horas_hombres_trabajadas}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.indice_frecuencia}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.dias_perdidos}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.indice_gravedad}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.indice_accidentabilidad}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_enfermedad_ocupacional}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_enfermedad_ocupacional}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_trabajadores_expuestos}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.tasa_incidencia}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_trabajadores_cancer}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_incidentes_peligrosos}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_incidente_peligroso}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.nro_incidentes}</TableCell>
                              <TableCell align="center" component="th" scope="row" sx={{ color: "#8B8BD8" }}>{row.area_sede_incidente}</TableCell>
                            </TableRow>

                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro y de la investigación
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={handleOpenModalResponsibleWorker}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Cargo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.position_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignResponsibleWorker(
                                    row.id,
                                    row.id_users
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            "Firmado"
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable " +
                                    row.first_name +
                                    " " +
                                    row.last_name,
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  isNavegationOrigin={true}
                  url={"/documentation/registers/generals/statisticaldata/dataStatisticalRecords"}
                />
              </Grid>
              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                  />
                </Grid>
              ) : (
                ""
              )}
              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    functionOnClick={handleGetRegisterStatus}
                  />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </form>
        </Box>
      </Container>


      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalResponsibleWorker}
        onClose={() => setOpenModalResponsibleWorker(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsUsersSearchs}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalResponsibleWorker(false)}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar responsable */}
      <Dialog
        open={openModalSignResponsibleWorker}
        onClose={() => {
          setOpenModalSignResponsibleWorker(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsibleWorker}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsibleWorker(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de generar datos estadisticos */}
      <Dialog
        open={openModalGenerateStaticalData}
        onClose={handleCloseModalGenerateStaticalData}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText={"Agregar total de horas hombre trabajadas para los meses seleccionados"}
          functionOnClose={handleCloseModalGenerateStaticalData}
        />

        <form onSubmit={handlePostDataForStatisticsRecords}>
          <DialogContent>
            <Grid container spacing={2} sx={{ marginTop: "4px" }}>
              {
                dateRange.map((month) => (
                  <Grid item xs={6} sm={4} md={3} lg={3} xl={3}>
                    <InputControlaVertical
                      text={month.mes}
                      inputType={"number"}
                      inputValue= {(event) => month.inputValue(event)}
                      value={month.value} 
                      modalType={true}
                    />
                  </Grid>
                ))
              }

            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalGenerateStaticalData}
              />
              <ButtonControla
                textButton={"Generar"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Edit;
