import React, { useState } from "react";
import { useAppContext } from "../../context/AppContext";
import { useEffect } from "react";

export const useModulesFree = () => {
  const { sectionsModules } = useAppContext();
  const [isBaselineFree, setIsBaselineFree] = useState(false);

  const getModulesFree = () => {
    // code : SIDENAV_BASELINE => LINEA BASE
    const sectionData = sectionsModules.find(
      (item) => item.code === "SIDENAV_BASELINE"
    );

    // code : BASELINE_FREE => linea base free
    const baselineFree = sectionData.modules.find(
      (item) => item.code === "BASELINE_FREE"
    );

    if (baselineFree) {
      setIsBaselineFree(true);
    }
  };

  useEffect(() => {
    getModulesFree();
  }, []);

  return {
    isBaselineFree,
  };
};
