import { React, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import SideNav from "../../components/sidenav/sideNav";
import Container from '@mui/material/Container';
import { 
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    Typography,
 } from '@mui/material';
import BannerControla from '../../components/cards/bannerControla';
import ButtonControla from "../../components/buttons/buttonController";
import {
    Add
} from "@mui/icons-material";

import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import SelectControla from "../../components/selects/selectControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import DialogInformativeControla from "../../components/dialog/dialogInformativeControla";
import LoadingControla from "../../components/load/loadingControla";
import MyMeeting from "../../api/MyMeetings";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import MyProceedingsBook from "../../api/MyProceedingsBook";
import { useNavigate } from 'react-router-dom';
import { useAppContext } from "../../context/AppContext";
import ListScheduled from "./components/list-scheduled";
import ListNotified from "./components/list-notified";
import ListArchived from "./components/list-archived";
import TableControla from "../../components/tables/table-controla";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";


const blue = "#034AFF";
const purple = "#8B8BD8";

function ScheduleMeetings () {
    const {permissions} = useAppContext();
    const navigate = useNavigate();
 
      // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

    //estados de activar o desactivar modal, modal de confirmación 
    const [openModalCreateMeeting, setOpenModalCreateMeeting] = useState(false);
    const [openModalInformative, setOpenModalInformative] = useState(false);
    const [messageModalInformative, setMessageModalInformative] = useState("");

    const handleCloseModalInformative = ()=>{
        setOpenModalInformative(false);
    }

    //abrir modal
    const handleOpenModalMeeting = () => {
        setDateMeeting(null);
        setDescriptionMeeting(null);
        setHourStart(null);
        setHourEnd(null);
        setSession(null);
        setModality(null);
        setPlace(null);

        setOpenModalCreateMeeting(true);
    }
    //cerrar el modal
    const handleCloseModalMeeting = () => {
        setOpenModalCreateMeeting(false);
    }

    
    // *modal de confirmación *
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");
    const [idMeeting, setIdMeeting] = useState(null);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);

    const handleOpenModalConfirm = (idMeet, titleText , contentText, buttonTextConfirm, buttonActionType) => {
        setIdMeeting(idMeet);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    }

    const handleActionConfirm = () =>{
        switch(buttonActionType){
            case "delete":
                handleDeleteMeeting();
                break;
            case "publish":
                handlePublishMeeting();
                break;
            case "cancel":
                handleMeetingArchived();
                break;
        };
        handleCloseModalConfirm();
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    //array que muestra en el select 
    const typeSession = [{ id_session: 1,nombre:"ordinaria"},{ id_session:2, nombre:"extraordinaria"}];
    const typeModality = [{id_modality:1, nombre: "presencial"}, {id_modality: 2, nombre: "remoto"}];

    //datos del modal
    const [modality, setModality] = useState("");
    const [session, setSession] = useState("");
    const [hourStart, setHourStart] = useState("");
    const [hourEnd, setHourEnd] = useState("");
    const [dateMeeting, setDateMeeting] =  useState("");
    const [descriptionMeeting, setDescriptionMeeting] = useState("");
    const [place, setPlace] = useState("");
    
    //control de los picker
    const [readOnlyStatus, setReadOnlyStatus] = useState(false);
    const [idStatus, setIdStatus] = useState(false);

    //obtener lista de array de reuniones
    const [arrayMeeting, setArrayMeeting] = useState([]);
    
    //crear reunión - post
    const hanledCreatePostMeeting = async (event) => {
        event.preventDefault();

        handleOpenLoading();
        handleCloseModalMeeting();

        //guardar datos de la meet
        const dataMeeting= {
            description: descriptionMeeting,
            date : dateMeeting,
            hourStart: hourStart,
            hourEnd: hourEnd,
            session: session,
            modality: modality,
            place : place,
            userCreated : getUseCreated().id_users
        }

        //llamada a la api 
        const response  = await MyMeeting.createMeeting(dataMeeting);

        //generar participantes
        const res = await MyMeeting.postParcitipants({
            id_committee_meeting: response.data.id_committee_meeting
        });

        if(response.success === true && res.success == true){
            getListMeeting();
            handleOpenAlert("Se guardó exitosamente la reunión","success");
        } else{
            //notificar que no se guardó
            handleOpenAlert("Ocurrió un error","error" );
        }
       
        return response;
    }

    //listar las reuniones
    const getListMeeting = async () =>{
        handleOpenLoading();

        const response  = await MyMeeting.getMeeting();
        if(response.success === true){
            setArrayMeeting(response.data);
            
        } else{
            console.log("error");
        }

        setOpenLoading(false);
    }

    //eliminar una reunión id_meeting
    const handleDeleteMeeting = async () =>{
        handleOpenLoading();
        
        const response = await MyMeeting.deleteMeeting(idMeeting);

        if(response.success === true){
            getListMeeting();
            handleOpenAlert("Se eliminó correctamente","success");
        } else{
            handleOpenAlert("Ha ocurrido un error","error");
        }
    }

    const handlePublishMeeting = async () => {
        handleOpenLoading();

        const data = {
            id_status_meeting : 2,
        }
        const response = await MyMeeting.updateStatus(idMeeting,data);

        if(response.success === true){
            handleOpenAlert("Se notificó correctamente a comité", "success");
            await getListMeeting();
            
        } else {
            handleOpenAlert("Ocurrió un error","error");
        }

    }

    const getUseCreated = () =>{
       return  MyConfigurationApi.userData();
    }

    const [currentMeetings, setCurrentMeetings] = useState([]);
    const [notifiedMeetings, setNotifiedMeetings] = useState([]);
    const [archivedMeetings, setArchivedMeetings] = useState([]);
    const[meetNotProceeding, setMeetNotProceeding] = useState([]);
    
    const getMeetingByStatus = async() => {
       
        updateMeetingStatusPastDate();

        //estado 1=creado , 2=notificado , 3=archivado
        setCurrentMeetings(arrayMeeting.filter((meet)=> meet.status === 1));
        setNotifiedMeetings(arrayMeeting.filter((meet)=> meet.status === 2));
        setArchivedMeetings(arrayMeeting.filter((meet)=> meet.status === 3));

        const meet = arrayMeeting.filter((meet)=> meet.status === 2);
        const response  = await MyProceedingsBook.listProceedings();
        meet.forEach((m, index)=>{
            response.data.forEach((pro)=>{
                if(pro.id_committee_meeting === m.id_committee_meeting){
                    delete(meet[index]);
                }
            });
        });

        setMeetNotProceeding(meet.filter((m)=> m !== undefined));
    }

    
    const [typeStatusMeeting, setTypeStatusMeeting] = useState(0);

    const handleChangeStatusMeeting = (event, newValue) => {
        setTypeStatusMeeting(newValue);
    }
   
    const handleMeetingArchived = async () => {
        handleOpenLoading();

        const data = {
            id_status_meeting : 3,
        }
        const response = await MyMeeting.updateStatus(idMeeting,data);
 
        if(response.success === true){
            handleOpenAlert("Se anuló la reunión correctamente", "success");
            getListMeeting();
        } else{

            handleOpenAlert("Ocurrió un error","error");
        }
    }

    const handleSearchActivedBook = async (idMeeting) => {
       handleOpenLoading();
        //buscar libro actual activo
        const response = await MyProceedingsBook.listProceedingsBook();
        const activedBook = response.data.filter((book) => book.active === 1);

        if (activedBook.length === 0) {
            setOpenLoading(false);
            setOpenModalInformative(true);
            setMessageModalInformative("No existe un libro activo, por favor crear un nuevo libro en la sección de libro de actas");

        } else {
            const respon = await MyProceedingsBook.listProceedings();
            const existProceedingsByMeeting = respon.data.filter((p) => p.id_committee_meeting === idMeeting);

            if (existProceedingsByMeeting.length === 0) {

                // traer actas existentes del libro activo
                const res = await MyProceedingsBook.getMinutesByIdBook(activedBook[0].id_minute_book);

                //verificar si actas estan cerradas para poder crear
                if (res.data.some((proceeding) => proceeding.status == 1)) {
                    setOpenLoading(false);
                    setOpenModalInformative(true);
                    setMessageModalInformative("No se puede generar nueva acta, ingrese a módulo de 'LIBRO DE ACTA' para cerrar el acta pendiente.");
                } else {
                    //crear la acta por primera vez
                    const idProceedingsMeeting = {
                        id_committe_meeting: idMeeting,
                        id_minute_book: activedBook[0].id_minute_book
                    }
                    const resp = await MyProceedingsBook.createProceedings(idProceedingsMeeting);

                    //registrar participantes del acta
                    const data_idProceeding  = {
                        id_minutes: resp.data.id_minutes
                    }
                    await MyProceedingsBook.postParticipantsProceedings(data_idProceeding);

                    navigate("/editProceedingsMeeting?id_proceeding="+ resp.data.id_minutes);
                }

            } else {
                navigate("/editProceedingsMeeting?id_proceeding=" + existProceedingsByMeeting[0].id_minutes);
            }
        }
    }

    const updateMeetingStatusPastDate = async () => {
        const response = await MyMeeting.getMeeting();
        const notifiedMeeting = response.data.filter((meet) => meet.status === 2);

        //fecha hoy
        const date = new Date();
        const today = date.getDate();
        const month = date.getMonth() + 1;
        const currentDate = `${date.getFullYear()}-${month.toString().length === 1 ? "0" + month.toString() : month}-${today.toString().length === 1 ? "0" + today : today}`;


        notifiedMeeting.forEach(async(meet) => {
            if (Date.parse(meet.date) < Date.parse(currentDate)) {
                const data = {
                    id_status_meeting: 3,
                }
                const response = await MyMeeting.updateStatus(meet.id_committee_meeting, data);

            }
        });

    }


    useEffect( ()=> {
        getListMeeting();
    }, []);

    useEffect(()=>{
        getMeetingByStatus();
    },[arrayMeeting]);


    return (
        <Box sx={{ display: "flex" }}>
            <SideNav returnBack="/committee" />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

            <Container>
                <Box sx={{ flexGrow: 1 }}>

                    <Grid container>
                        <Grid item xs={12} md={12} >
                            <ButtonMailboxControla
                                originUrl={"/committee/scheduleMeetings"}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image="/assets/images/banners/committee/bannerMeeting.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Reuniones Programadas"
                            />
                        </Grid>
                    </Grid>

                    <Grid container
                        spacing={2}
                        sx={{ mt: 2 }}
                        justifyContent="space-between"
                        direction="row"
                        alignItems="center">
                        <Grid item>
                            <ButtonControla
                                roles={permissions.COMITE_AGENDAR_REU_PROGRAMADA_AGENDAR}
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agendar reunión"}
                                functionOnClick={handleOpenModalMeeting}
                            />
                        </Grid>
                    </Grid>

                    {/* grid para la tabla  */}
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <TableControla
                                loading={openLoading}
                                tabs={[
                                    {
                                        label: "Programadas",
                                        columnNames: ["Código", "Descripción", "Fecha de la reunión", "Tipo de reunión", "Modalidad"]
                                    },
                                    {
                                        label: "Notificadas",
                                        columnNames: ["Código", "Descripción", "Fecha de la reunión", "Tipo de reunión", "Modalidad"]
                                    },
                                    {
                                        label: "Archivadas",
                                        columnNames: ["Código", "Descripción", "Fecha de la reunión", "Tipo de reunión", "Modalidad"]
                                    },
                                ]}

                            >
                                <ListScheduled
                                    currentMeetings={currentMeetings}
                                    colorCell={purple}
                                    handleOpenModalConfirm={handleOpenModalConfirm}
                                />
                                <ListNotified
                                    notifiedMeetings={notifiedMeetings}
                                    colorCell={purple}
                                    meetNotProceeding={meetNotProceeding}
                                    handleSearchActivedBook={handleSearchActivedBook}
                                    handleOpenModalConfirm={handleOpenModalConfirm}
                                />
                                <ListArchived
                                    archivedMeetings={archivedMeetings}
                                    colorCell={purple}
                                />

                            </TableControla>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            {/* modal de agregar nueva reuniòn */}
            <Dialog
                open={openModalCreateMeeting}
                onClose={handleCloseModalMeeting}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>
                    <Typography
                        id="modal-modal-title"
                        sx={{
                            color: "#1638F2",
                            fontWeight: "bold",
                            fontSize: "22px",
                        }}
                    >
                        Agendar nueva reunión
                    </Typography>
                </DialogTitle>

                <form onSubmit={hanledCreatePostMeeting}>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <InputControlaVertical
                            text={"Fecha de Reunión"}
                            inputType={"date"}
                            inputValue={setDateMeeting}
                            modalType={true}
                            value={dateMeeting || ''}
                            required
                        />
                        <InputControlaVertical
                            text={"Descripción"}
                            inputType={"text"}
                            inputValue={setDescriptionMeeting}
                            modalType={true}
                            value={descriptionMeeting || ''}
                            required
                        />

                        <Grid container spacing={2}
                            justifyContent="space-between"
                            direction="row"
                            alignItems="center">
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    roles={[1, 7, 8, 9, 15]}
                                    text={"Hora de Inicio"}
                                    inputType={"time"}
                                    inputValue={setHourStart}
                                    modalType={true}
                                    value={hourStart || ''}
                                    readOnly={idStatus == 2 ? false : readOnlyStatus}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    roles={[1, 7, 8, 9, 15]}
                                    text={"Hora de Fin"}
                                    inputType={"time"}
                                    inputValue={setHourEnd}
                                    modalType={true}
                                    value={hourEnd || ''}
                                    readOnly={idStatus == 2 ? false : readOnlyStatus}
                                    required
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}
                            justifyContent="space-between"
                            direction="row"
                            alignItems="center">
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <SelectControla
                                    text="Sesión:"
                                    inputValue={setSession}
                                    modalType={true}
                                    required
                                    value={session || ''}
                                    childrenRows={typeSession.map((rows) => (
                                        <MenuItem key={rows.id_session} value={rows.id_session}>
                                            {rows.nombre}
                                        </MenuItem>
                                    ))}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <SelectControla
                                    text="Modalidad:"
                                    inputValue={setModality}
                                    modalType={true}
                                    required
                                    value={modality || ''}
                                    childrenRows={typeModality.map((rows) => (
                                        <MenuItem key={rows.id_modality} value={rows.id_modality}>
                                            {rows.nombre}
                                        </MenuItem>
                                    ))}
                                />
                            </Grid>

                        </Grid>

                        <InputControlaVertical
                            display={modality === 1 ? 'block' : 'none'}
                            roles={[1, 7, 8, 9, 15]}
                            text={"Lugar"}
                            inputType={"text"}
                            inputValue={setPlace}
                            modalType={true}
                            value={place || ''}
                            readOnly={idStatus == 2 ? false : readOnlyStatus}
                            required={modality === 1 ? true : false}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: "none",
                                color: "#6969D6",
                                backgroundColor: "#CBCBFF",
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                            onClick={handleCloseModalMeeting}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            sx={{
                                textTransform: "none",
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                        >
                            Guardar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* modal de informaciòn */}
            <DialogInformativeControla
                open={openModalInformative}
                onClose={handleCloseModalInformative}
                message={messageModalInformative}
            />

        </Box>
    );
}

export default ScheduleMeetings;
