import { React, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Grid, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { BorderColorOutlined, Delete } from '@mui/icons-material';
import SubtitleText from '../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../components/sidenav/sideNav";
import DocBanner from '../../components/cards/docBanner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import MyBaseline from '../../api/MyBaseline';
import { useNavigate } from 'react-router-dom';

const colorTitle = "#9191B0";

function Lineaments() {
    const navigate = useNavigate();

    const [rowsLineaments, setRowsLineaments] = useState([]);

    const handleGetLineaments = async () => {
        const response = await MyBaseline.lineaments();

        if (response.success === false) {
            // setAlertMessage(response.message);
            // setOpenLoadView(false);
        } else {
            setRowsLineaments(response);
        }
        return response;
    };

    useEffect(() => {
        handleGetLineaments();
    }, []);

    const [idLineaments, setIdLineaments] = useState(null);
    const [lineaments, setLineaments] = useState(null);

    const [openCreateLineaments, setOpenCreateLineaments] = useState(false);

    const handleClickOpenCreateLineaments = () => {
        setOpenCreateLineaments(true);
    };

    const handleCloseCreateLineaments = () => {
        setOpenCreateLineaments(false);
    };

    const [openEditLineaments, setOpenEditLineaments] = useState(false);

    const handleClickOpenEditLineaments = (idLineaments, lineaments) => {
        setIdLineaments(idLineaments);
        setLineaments(lineaments);
        setOpenEditLineaments(true);
    };

    const handleCloseEditLineaments = () => {
        setOpenEditLineaments(false);
    };

    const [openDeleteLineaments, setOpenDeleteLineaments] = useState(false);

    const handleClickOpenDeleteLineaments = (idLineaments) => {
        setIdLineaments(idLineaments);
        setOpenDeleteLineaments(true);
    };

    const handleCloseDeleteLineaments = () => {
        setOpenDeleteLineaments(false);
    };


    const handleStoreLineaments = async () => {
        const response = await MyBaseline.storeLineaments({ lineaments: lineaments });

        if (response.success === false) {
        } else {
            handleGetLineaments();
            handleCloseCreateLineaments();
        }
        return response;
    };

    const handleUpdateLineaments = async () => {
        const response = await MyBaseline.updateLineaments({ idLineaments: idLineaments, lineaments: lineaments });

        if (response.success === false) {
        } else {
            handleGetLineaments();
            handleCloseEditLineaments();
        }
        return response;
    };

    const handleDeleteLineaments = async () => {
        const response = await MyBaseline.deleteLineaments({ idLineaments: idLineaments });

        if (response.success === false) {
        } else {
            handleGetLineaments();
            handleCloseDeleteLineaments();

        }
        return response;
    };



    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />

            <Container>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <SubtitleText text={"Procedimientos"} color={colorTitle} ></SubtitleText>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <DocBanner color2='#BC6FEC' color1='#E6BEFF' text='Linea Base Indicadores' image={""} />
                        </Grid>
                    </Grid>

                    <Stack direction="row" spacing={1} sx={{ mt: 2 }} >
                        <Button
                            variant="contained"
                            onClick={() => handleClickOpenCreateLineaments()}
                        >Agregar Lineamientos
                        </Button>
                        <Button
                            variant="contained"
                            onClick={()=>navigate('/admin/baseline/indicators')}
                        >Indicadores
                        </Button>
                    </Stack>


                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 400 }}>
                                    <Table stickyHeader sx={{ width: '100%', minWidth: '700px' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell>Tipos de lineamientos</TableCell> */}
                                                <TableCell>Lineamientos</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody   >
                                            {rowsLineaments
                                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((rowsLineaments) => (
                                                    <TableRow hover
                                                        key={rowsLineaments.id_baseline_lineaments}
                                                    >
                                                        {/* <TableCell align="center"><Checkbox /></TableCell> */}

                                                        {/* <TableCell>
                                                            {rowsLineaments.baseline_lineament_type}
                                                        </TableCell> */}
                                                        <TableCell>
                                                            {rowsLineaments.baseline_lineament}
                                                        </TableCell>
                                                        <TableCell>
                                                            {rowsLineaments.actions}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <ButtonIconControla icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={'#169073'} textTooltip='Editar' functionOnClick={() => handleClickOpenEditLineaments(rowsLineaments.id_baseline_lineaments, rowsLineaments.baseline_lineament)} />
                                                                <ButtonIconControla icon={<Delete sx={{ color: 'white' }} />} backgroundColor={'#EB5757'} textTooltip='Eliminar' functionOnClick={() => handleClickOpenDeleteLineaments(rowsLineaments.id_baseline_lineaments)} />
                                                            </Stack>
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Dialog open={openCreateLineaments} onClose={handleCloseCreateLineaments} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Crear lineamiento</DialogTitle>
                        <DialogContent>
                            <InputControlaVertical text={'Lineamiento:'} inputType={'text'} inputValue={setLineaments} modalType={true} />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCreateLineaments}>Cancelar</Button>
                            <Button onClick={handleStoreLineaments}>Crear</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openEditLineaments} onClose={handleCloseEditLineaments} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Editar lineamiento</DialogTitle>
                        <DialogContent>
                            <InputControlaVertical text={'Lineamiento:'} inputType={'text'} value={lineaments} inputValue={setLineaments} modalType={true} />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEditLineaments}>Cancelar</Button>
                            <Button onClick={handleUpdateLineaments}>Actualizar</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openDeleteLineaments} onClose={handleCloseDeleteLineaments} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Eliminar lineamiento</DialogTitle>
                        <DialogContent>
                            <Typography >
                                Si eliminas este lineamiento tambien eliminaras todos los indicadores que le pertenezcan.
                            </Typography>
                            <Typography >
                                ¿Deseas continuar?.
                            </Typography>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDeleteLineaments}>Cancelar</Button>
                            <Button onClick={handleDeleteLineaments}>Si, eliminar</Button>
                        </DialogActions>
                    </Dialog>

                </Box>
            </Container >


        </Box >




    );

}

export default Lineaments;

