import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  InputLabel,
  InputBase,
  Grid,
  IconButton,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Container from "@mui/material/Container";
import {
  Add,
  DriveFolderUpload,
  ArrowBackIosNew,
  ArrowForwardIos
} from "@mui/icons-material";
import SideNav from "../../../../components/sidenav/sideNav";
import SubtitleText from "../../../../components/text/subtitleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import BannerControla from "../../../../components/cards/bannerControla";
import MyAts from "../../../../api/MyAts";
import ButtonControla from "../../../../components/buttons/buttonController";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import TableControla from "../../../../components/tables/table-controla";
import ListModels from "./components/list-models";
import ListActives from "./components/list-actives";
import ListArchived from "./components/list-archived";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import MyNotificacionNew from "../../../../api/MyNotificacionNew";
import { useAppContext } from "../../../../context/AppContext";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";


const grey = "#9191B0";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 800,
  overflow: "visible",
  border: "1px solid #000",
  backgroundColor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

function Index() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Pantallas de carga y alertas FIN

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");
  const [dataNotification, setDataNotification] = useState({
    message: null,
    document_url: null,
    id_users_created: null,
    id_users_modified: null,
    id_users_sender: null,
    id_users_receiving: null
  });


  //traer dato del usuario
  const getUser = () => {
    return MyConfigurationApi.userData();
  }

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType,
    dataNotification
  ) => {
    setIdAts(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
    setDataNotification(dataNotification);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        handleDeleteAts();
        break;
      case "sendWorkers":
        handleUpdateStatusesAts(2);
        break;
      case "public":
        handleUpdateStatusesAts(3);
        break;
      case "archived":
        handleUpdateArchiveAts(1);
        break;
      case "unarchived":
        handleUpdateArchiveAts(0);
        break;
      case "sendWorkersByTemplate":
        handleUpdateStatusAtsSendWorkers(2);
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  const handleGetAts = async (inputYear = undefined) => {
    handleOpenLoading();
    if (inputYear != undefined) {
      handleOpenLoading();
    }

    const response = await MyAts.ats({
      year: inputYear == undefined ? year : inputYear,
    });

    if (response.success === false) {
      handleOpenAlert("Ocurrio un error al listar los ATS", "error");
    } else {
      const ats_model = response.ats_model;
      const ats_no_archived = response.ats_no_archived;
      const ats_archived = response.ats_archived;
      setRowsModels(ats_model);
      setRowsActives(ats_no_archived);
      setRowsArchives(ats_archived);

      handleOpenAlert("Listando ATS activos del año ", "success");

      if (inputYear != undefined) {
        setDocumentType(1);
        handleOpenAlert("Listando ATS activos del año " + inputYear, "success");
      }
    }
    return response;
  };

  const handlePostCreateAts = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyAts.createAts({
      name,
      code,
      model: 0,
      document_url: documentUrl,
    });
    if (response.success !== false) {
      console.log("tabs")
      navigate("/ats?idTabs=1");
      await handleGetAts();
      handleCloseModalUpload();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Ocurrio un error al crear el ATS", "error");
    }
    return response;
  };

  const handlePostCreateModelAts = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyAts.createAts({ name, code, model: 1 });
    if (response.success !== false) {
      navigate("/ats/model/edit?id_ats=" + response.id_ats);
    } else {
      handleOpenAlert("Ocurrio un error al crear el modelo ATS", "error");
    }
    return response;
  };

  const handleDeleteAts = async () => {
    handleOpenLoading();
    const response = await MyAts.deleteAts({ id_ats: idAts });
    if (response.success !== false) {
      await handleGetAts();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Ocurrio un error al crear el ATS", "error");
    }
    return response;
  };

  //datos del usuario logeado
  const user = MyConfigurationApi.userData();
  const idUserLogged = user.id_users;

  useEffect(() => {
    handleGetAts();
  }, []);

  const [rowsModels, setRowsModels] = useState([]);
  const [rowsActives, setRowsActives] = useState([]);
  const [rowsArchives, setRowsArchives] = useState([]);
  const currentTime = new Date();
  const [year, setYear] = useState(currentTime.getFullYear());

  const [documentType, setDocumentType] = useState(
    idTabs !== undefined && idTabs !== null && idTabs !== ""
      ? parseInt(idTabs)
      : 0
  );
  const [idAts, setIdAts] = useState(null);

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    handleGetAts(year + 1);
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    handleGetAts(year - 1);
  };

  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [area, setArea] = useState(null);
  const [place, setPlace] = useState(null);
  const [hourStart, setHourStart] = useState(null);
  const [hourFinish, setHourFinish] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const handleOpenModalCreate = () => setOpenModalCreate(true);
  const handleCloseModalCreate = () => setOpenModalCreate(false);

  const [openModalUpload, setOpenModalUpload] = useState(false);
  const handleOpenModalUpload = () => {
    setName(null);
    setCode(null);
    setArea(null);
    setPlace(null);
    setHourStart(null);
    setHourFinish(null);
    setDocumentUrl(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => setOpenModalUpload(false);

  const [openModalEditUpload, setOpenModalEditUpload] = useState(false);
  const handleOpenModalEditUpload = (idAts, name, code) => {
    setIdAts(idAts);
    setName(name);
    setCode(code);
    setOpenModalEditUpload(true);
  };

  const handleCloseModalEditUpload = () => setOpenModalEditUpload(false);

  const handleUpdateAts = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyAts.updateAts({
      id_ats: idAts,
      name,
      code,
      document_url: documentUrl,
    });

    if (response.success !== false) {
      handleGetAts();
      handleCloseModalEditUpload();
      handleOpenAlert(response.msg, "success");
      setDocumentType(1);
    } else {
      handleOpenAlert("Hubo un error al actualizar", "success");
    }
    return response;
  };

  const handleUpdateStatusesAts = async (idAtsStatuses) => {
    handleOpenLoading();
    const response = await MyAts.updateStatusesAts({
      id_ats: idAts,
      id_ats_statuses: idAtsStatuses,
    });

    if (response.success !== false) {
      handleGetAts();
      handleCloseModalEditUpload();

      // enviar notificación
      postNotification();

      //muestra alerta de exito
      handleOpenAlert(response.msg, "success");



    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  const postNotification = async () => {
    const response = await MyNotificacionNew.sendNotificacion(dataNotification);
  };

  const handleUpdateArchiveAts = async (archived) => {
    handleOpenLoading();
    const response = await MyAts.updateArchiveAts({
      id_ats: idAts,
      archived: archived,
    });

    if (response.success !== false) {
      handleGetAts();
      handleCloseModalEditUpload();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  const handleUpdateStatusAtsSendWorkers = async (idAtsStatus) => {
    handleOpenLoading();

    const resp = await MyAts.updateStatusesAts({
      id_ats: idAts,
      id_ats_statuses: idAtsStatus,
    });

    if (resp.success == true) {
      handleGetAts();
      handleOpenAlert("El ats se envío a trabajadores con éxito", "success");

    } else {
      handleOpenAlert("Ocurrió un error", "error");
    }
    handleCloseModalConfirm();
  }

  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <SideNav returnBack="" />

        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />

        <DialogConfirmControla
          openModalConfirm={openModalConfirm}
          handleCloseModalConfirm={handleCloseModalConfirm}
          titleText={titleText}
          contentText={contentText}
          buttonTextConfirm={buttonTextConfirm}
          handleActionConfirm={handleActionConfirm}
        />

        <Container>
          <Box>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={5}>
                <ButtonMailboxControla
                  originUrl={"/ats"}
                />
              </Grid>


              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Procedimientos"}
                  color={grey}
                ></SubtitleText>
                <BannerControla
                  image="/assets/images/banners/documents/atsBanner.png"
                  color2="#2F67BC"
                  color1="#8FBCFF"
                  text="ATS"
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
                <Stack direction={"row"} spacing={2}>
                  <Box>
                    <ButtonControla
                      roles={permissions.DOC_HG_PROC_ATS_MOD_AGREGAR_MODELO}
                      iconButton={<Add sx={{ color: "white" }} />}
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#33AEF4"}
                      textButton={"Agregar modelo ATS"}
                      functionOnClick={handleOpenModalCreate}
                    />
                  </Box>
                  <Box>
                    <ButtonControla
                      roles={permissions.DOC_HG_PROC_ATS_MOD_SUBIR_ARCHIVO}
                      iconButton={<DriveFolderUpload sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Subir archivo ATS"}
                      functionOnClick={handleOpenModalUpload}
                    />
                  </Box>
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={3}
                xl={3}
                sx={{ mt: 2 }}
              ></Grid>

              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={2} md={3} lg={3} xl={3}>
                    <InputLabel
                      sx={{
                        padding: "8px 0px 8px 0px",
                        fontSize: "14px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Periodo:
                    </InputLabel>
                  </Grid>
                  <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                    <InputBase
                      type="number"
                      value={year}
                      onChange={handleGetAts}
                      readOnly
                      sx={{
                        width: "100%",
                        padding: "4px 10px 4px 10px",
                        backgroundColor: "white",
                        color: "#305AD9",
                        fontSize: "14px",
                        borderRadius: "8px",
                      }}
                    ></InputBase>
                  </Grid>
                  <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        color: "#305AD9",
                      }}
                      onClick={handleDecreaseYear}
                    >
                      <ArrowBackIosNew />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        color: "#305AD9",
                      }}
                      onClick={handleIncreaseYear}
                    >
                      <ArrowForwardIos />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                <TableControla
                  loading={openLoading}
                  // tabIndex={idTabs}
                  tabs={[
                    {
                      label: "Modelos",
                      columnNames: ["Nombre del documento"]
                    },
                    {
                      label: "Activados",
                      columnNames: ["Nombre del documento"]
                    },
                    {
                      label: "Archivados",
                      columnNames: ["Nombre del documento"]
                    }
                  ]}
                >

                  <ListModels
                    rowsModels={rowsModels}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                  />

                  <ListActives
                    rowsActives={rowsActives}
                    idUserLogged={idUserLogged}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                    handleOpenModalEditUpload={handleOpenModalEditUpload}
                  />

                  <ListArchived
                    rowsArchives={rowsArchives}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                  />

                </TableControla>

              </Grid>
            </Grid>

            <Dialog
              open={openModalCreate}
              onClose={handleCloseModalCreate}
              maxWidth="sm"
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Crear nuevo modelo ATS"
                functionOnClose={handleCloseModalCreate}
              />
              <form onSubmit={handlePostCreateModelAts}>
                <DialogContent>
                  <InputControlaVertical
                    text={"Código:"}
                    inputType={"text"}
                    inputValue={setCode}
                    modalType={true}
                    required
                  />
                  <InputControlaVertical
                    text={"Nombre del modelo ATS:"}
                    inputType={"text"}
                    inputValue={setName}
                    modalType={true}
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Stack direction={"row"} spacing={2}>
                    <Box>
                      <ButtonControla
                        backgroundColor={"#CBCBFF"}
                        textButton={"Cancelar"}
                        backgroundColorHover={"#CBCBFF"}
                        typeButton={"button"}
                        functionOnClick={handleCloseModalCreate}
                      />
                    </Box>
                    <Box>
                      <ButtonControla
                        iconButton={<Add />}
                        backgroundColor={"#169073"}
                        textButton={"Crear modelo de ATS"}
                        backgroundColorHover={"#1BAA88"}
                        typeButton={"submit"}
                      />
                    </Box>
                  </Stack>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              open={openModalUpload}
              onClose={handleCloseModalUpload}
              maxWidth="sm"
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Cargar documento ATS"
                functionOnClose={handleCloseModalUpload}
              />
              <form onSubmit={handlePostCreateAts}>
                <DialogContent>
                  <InputControlaVertical
                    text={"Código:"}
                    inputType={"text"}
                    inputValue={setCode}
                    modalType={true}
                    required
                  />
                  <InputControlaVertical
                    text={"Nombre del ATS:"}
                    inputType={"text"}
                    inputValue={setName}
                    modalType={true}
                    required
                  />
                  <InputControlaVertical
                    text={"Subir Archivo"}
                    inputType={"file"}
                    inputValue={setDocumentUrl}
                    modalType={true}
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Stack direction={"row"} spacing={2}>
                    <Box>
                      <ButtonControla
                        backgroundColor={"#CBCBFF"}
                        textButton={"Cancelar"}
                        backgroundColorHover={"#CBCBFF"}
                        typeButton={"button"}
                        functionOnClick={handleCloseModalUpload}
                      />
                    </Box>
                    <Box>
                      <ButtonControla
                        iconButton={<Add />}
                        backgroundColor={"#169073"}
                        textButton={"Cargar ATS"}
                        backgroundColorHover={"#1BAA88"}
                        typeButton={"submit"}
                      />
                    </Box>
                  </Stack>
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              open={openModalEditUpload}
              onClose={handleCloseModalEditUpload}
              maxWidth="sm"
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla
                titleText="Actuaizar documentol ATS"
                functionOnClose={handleCloseModalEditUpload}
              />
              <form onSubmit={handleUpdateAts}>
                <DialogContent>
                  <InputControlaVertical
                    text={"Código:"}
                    inputType={"text"}
                    value={code}
                    inputValue={setCode}
                    modalType={true}
                    required
                  />
                  <InputControlaVertical
                    text={"Nombre del ATS:"}
                    inputType={"text"}
                    value={name}
                    inputValue={setName}
                    modalType={true}
                    required
                  />
                  <InputControlaVertical
                    text={"Subir Archivo"}
                    inputType={"file"}
                    inputValue={setDocumentUrl}
                    modalType={true}
                  />
                </DialogContent>
                <DialogActions>
                  <Stack direction={"row"} spacing={2}>
                    <Box>
                      <ButtonControla
                        backgroundColor={"#CBCBFF"}
                        textButton={"Cancelar"}
                        backgroundColorHover={"#CBCBFF"}
                        typeButton={"button"}
                        functionOnClick={handleCloseModalEditUpload}
                      />
                    </Box>
                    <Box>
                      <ButtonControla
                        iconButton={<Add />}
                        backgroundColor={"#169073"}
                        textButton={"Actualizar ATS"}
                        backgroundColorHover={"#1BAA88"}
                        typeButton={"submit"}
                      />
                    </Box>
                  </Stack>
                </DialogActions>
              </form>
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Index;
