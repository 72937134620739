import React, { Component, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export const EditorControla = ({ dataEdit, defaultTemplate, handleDataEditor = alert, ...rest }) => {
  
  const template = defaultTemplate == null ? '' : defaultTemplate;

  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        {...rest}
        data={dataEdit === null ? template : dataEdit}
        onReady={(editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // You can store the "editor" and use when it is needed.
          // console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // console.log({ data });
        }}
        // onChange={(event, editor) => dataEditor(editor.getData())}

        onBlur={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
        onFocus={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
      />
    </div>
  );
};
