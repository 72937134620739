import * as React from 'react';

import SubtitleText from '../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import '../../index.css';
import CardControla from "../../components/cards/cardControla";

import BannerControla from '../../components/cards/bannerControla';

const colorTitle = "#9191B0";

function Programs() {
  return (

    <Box sx={{ display: "flex" }}>
      <SideNav />

      <Container>

        <Box sx={{ flexGrow: 1 }}>

          <Grid container>
                            {/* NO ELIMINAR - TEMPORALMENTE COMENTADO */}

            {/* <Grid item xs={12} md={12}>
              <SubtitleText text={"Documentación"} color={colorTitle}></SubtitleText>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <BannerControla image='/assets/images/programs.png' color2='#2F67BC' color1='#8FBCFF' text='Programas' />

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              {/* <Link href={"/documentation/programs/sso"} underline="none">
                <DocCard  size={"medium"} color1={colorOneCardOne} color2={colorTwoCardOne} text={"Programas anual de SSO y otros"} img={imageProgramsSSO}></DocCard>
              </Link> */}
              <CardControla
                url={"/documentation/programs/sso"}
               text={"Programas anual de SSO y otros"}
                img={"/assets/images/programs_general.png"}
              /> 
            </Grid>
            <Grid item xs={3} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/programs/training"}
                text={"Programas anual de capacitaciones"}
                img={"/assets/images/programs_trainings.png"}
              />
            </Grid>     
          </Grid>

        </Box>
      </Container>

    </Box>

  );

}

export default Programs;