import React, { useEffect, useState } from 'react';
import SubtitleText from '../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid, Stack, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Add, Upload } from '@mui/icons-material';
import BannerControla from '../components/cards/bannerControla';
import ButtonControla from '../components/buttons/buttonController';
import TableControla from '../components/tables/table-controla';
import LoadingControla from '../components/load/loadingControla';
import ListModels from './components/list-models';
import ListActives from './components/list-actives';
import ListArchived from './components/list-archived';
import DialogTitleControla from '../components/dialog/dialogTitleControla';
import InputControlaVertical from '../components/textfields/inputControlaVertical';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';
import MyStandards from '../api/MyStandards';
import DialogConfirmControla from '../components/dialog/dialogConfirmControla';
import ButtonMailboxControla from '../components/buttons/buttonMailboxControla';
import MyConfigurationApi from '../api/MyConfigurationApi';


const colorTitle = "#9191B0";
const purple = "#8B8BD8";

function Index() {
    const navigate = useNavigate();
    const { permissions } = useAppContext();

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //fin de alerts

    const getUser = () => {
        return MyConfigurationApi.userData();
    }
    

    //dialogo de confirmación
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");
    const [idStandard, setIdStandard] = useState("");

    const [statusFirmPrepare , setStatusFirmPrepare] = useState("");

    const handleOpenModalConfirm = (titleText, contentText, buttonTextConfirm, buttonActionType, idStandard, firmPrepare) => {
        setOpenModalConfirm(true);

        setIdStandard(idStandard);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);

        setStatusFirmPrepare(firmPrepare);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {

        switch (buttonActionType) {
            case "deleteModel":
                handleDeleteStandardById(1);
                break;
            case "deleteActiveStandard":
                handleDeleteStandardById(2);
                break;
            case "sendReview":
                handleUpdatedStatusStandard(2);
                break;
            case "publish":
                handleUpdatedStatusStandard(3);
                break;
            case "archived":
                handleUpdatedStatusStandard(4);
                break;
            case "unarchived":
                handleUpdatedStatusStandard(5);
                break;
        }
    }
    //

    const [rowsModels, setRowsModels] = useState([]);
    const [rowsStandards, setRowsStandards] = useState([]);
    const [rowsArchived, setRowsArchived] = useState([]);

    const [code, setCode] = useState("");
    const [version, setVersion] = useState("");
    const [title, setTitle] = useState("");
    const [urlDocument, setUrlDocument] = useState(null);

    const [openModalCreateTemplate, setOpenModalCreateTemplate] = useState(false);

    const handleOpenModalCreateTemplate = () => {
        setOpenModalCreateTemplate(true)
    }

    const handleCloseModalCreateTemplate = () => {
        setOpenModalCreateTemplate(false)
    }

    // dialogo de subir archivo
    const [openModalUpload, setOpenModalUpload] = useState(false);
    const handleOpenModalUpload = (typeAction, data) => {
        setOpenModalUpload(true)
        setButtonActionType(typeAction);

        if (data !== null) {
            setIdStandard(data.id_standards);
            setCode(data.code);
            setVersion(data.version);
            setTitle(data.document_title);
            setUrlDocument(data.document_url);
        }

        if (typeAction == "create") {
            //limpiar 
            setCode(null);
            setVersion(null);
            setTitle(null);
            setUrlDocument(null)
        }
    }

    const handleCloseModalUpload = () => {
        setOpenModalUpload(false);
    }

    const getStandards = async () => {
        handleOpenLoading();

        const resp = await MyStandards.getStandards() ?? [];
        setRowsModels(resp.data.filter((r) => r.model == 1 && r.active == null));
        setRowsStandards(resp.data.filter((r) => r.active == 1 && r.id_standards_statuses !== 4));
        setRowsArchived(resp.data.filter((r) => r.id_standards_statuses == 4));

        setOpenLoading(false);
    }


    const handlePostCreateStandar = async (e) => {
        e.preventDefault();
        handleOpenLoading();

        const data = {
            code: code,
            version: version,
            document_title: title,
            archive: false,
            model: true,
            document_url: null,
            objective: null,
            scope: null,
            legal_references_and_other_standards: null,
            standard_specifications: null,
            responsible_persons: null,
            records_controls_and_documentation: null,
            review_and_continuous_improvement: null,
            id_users_prepare: null,
            date_firm_prepare: null,
            firm_prepare: null,
            id_users_review_1: null,
            date_firm_review_1: null,
            firm_review_1: null,
            id_users_review_2: null,
            date_firm_review_2: null,
            firm_review_2: null,
            id_users_approve: null,
            date_firm_approve: null,
            firm_approve: null
        }

        const resp = await MyStandards.postStandard(data);

        if (resp.success == true) {
            navigate(`/documentation/documents/standards/model?id_standard=${resp.data.id_standards}`);
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleActionModalUpload = (e) => {
        e.preventDefault();

        switch (buttonActionType) {
            case "create":
                handlePostUpload(e);
                break;
            case "update":
                handleUpdateUpload();
                break;
        }
    }

    const handlePostUpload = async (e) => {
        e.preventDefault();

        handleOpenLoading();

        const data = {
            code: code,
            version: version,
            active: true,
            archive: true,
            document_title: title,
            document_url: urlDocument
        }

        const resp = await MyStandards.postStandard(data);

        if (resp.success == true) {
            navigate("/documentation/documents/standards?idTabs=1");
            await getStandards();
            handleOpenAlert("Se registró el archivo con éxito", "success");
        } else {
            handleOpenAlert("Ocurrió un error al subir archivo", "error");
        }

        handleCloseModalUpload();
    }


    const handleUpdateUpload = async () => {
        handleOpenLoading();
        const data = {
            code: code,
            version: version,
            document_title: title,
            document_url: urlDocument
        }

        const resp = await MyStandards.updateStandardById(data, idStandard);
        if (resp.success) {
            await getStandards();
            handleOpenAlert("Se actualizó documento con éxito", "success");

        } else {
            handleOpenAlert("Ocurrió un error al actualizar", "error");

        }
        handleCloseModalUpload();

    }

    const handleDeleteStandardById = async (type) => {
        handleOpenLoading();

        const msg = type == 1 ? "modelo" : "estándar";


        const resp = await MyStandards.deleteStandardById(idStandard);

        if (resp.success == true) {
            await getStandards();
            handleOpenAlert(`Se eliminó el ${msg} con éxito`, "success");

        } else {
            handleOpenAlert("Ocurrió un error al eliminar", "error");
        }
        handleCloseModalConfirm();
    }

    const handleUpdatedStatusStandard = async (idStatu) => {

        if (statusFirmPrepare == null) {
            handleOpenAlert("No se puede enviar a revisión, falta firma del que elabora ESTÁNDAR", "error");
        } else {
            handleOpenLoading();
            const data = {
                id_standards_statuses: idStatu == 5 ? 3 : idStatu
            }

            const resp = await MyStandards.updateStatusStandard(data, idStandard);

            if (resp.success == true) {
                await getStandards();
                handleOpenAlert(idStatu == 2 ? ("Se envío a revisión con éxito") : (idStatu == 3 ? "Se publicó el documento con éxito" : (idStatu == 4 ? "Se archivó el documento con éxito" : "Se desarchivó el documento con éxito")), "success");
            } else {
                handleOpenAlert("Ocurrió un error", "error");
            }
        }
        handleCloseModalConfirm();
    }

    useEffect(() => {
        getStandards();
    }, []);


    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />

            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />

            <Container>

                <Grid container>
                    <ButtonMailboxControla
                     originUrl={"/documentation/documents/standards"}
                    />

                    <Grid item xs={12} md={12}>
                        <SubtitleText text={"Documentación"} color={colorTitle}></SubtitleText>
                        <BannerControla image='/assets/images/banners/documents/standarBanner.png' color2='#2F67BC' color1='#8FBCFF' text='Estándares' />
                    </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <ButtonControla
                                    roles={permissions.DOC_HG_STANDAR_MOD_AGREGAR_MOD}
                                    iconButton={<Add sx={{ color: "white" }} />}
                                    textButton={"Agregar modelo ESTÁNDAR"}
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#1976d2"}
                                    functionOnClick={handleOpenModalCreateTemplate}
                                />

                            </Box>
                            <Box>
                                <ButtonControla
                                    roles={permissions.DOC_HG_STANDAR_MOD_SUBIR_ARCHI}
                                    iconButton={<Upload sx={{ color: "white" }} />}
                                    backgroundColor={"#1cb591"}
                                    backgroundColorHover={"#169073"}
                                    textButton={"Subir archivo ESTÁNDAR"}
                                    functionOnClick={() => handleOpenModalUpload("create", null)}
                                />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TableControla
                            loading={openLoading}
                            tabs={[
                                {
                                    label: "Modelos",
                                    columnNames: ["Nombre del documento"]
                                },
                                {
                                    label: "Activos",
                                    columnNames: ["Nombre del documento", "Tipo", "Estado"]
                                },
                                {
                                    label: "Archivados",
                                    columnNames: ["Nombre del documento", "Tipo", "Estado"]
                                }
                            ]}
                        >
                            <ListModels
                                rowsModels={rowsModels}
                                colorCell={purple}
                                handleOpenModalConfirm={handleOpenModalConfirm}
                            />
                            <ListActives
                                rowsStandards={rowsStandards}
                                colorCell={purple}
                                getUser={getUser}
                                handleOpenModalConfirm={handleOpenModalConfirm}
                                handleOpenModalUpload={handleOpenModalUpload}
                            />
                            <ListArchived
                                rowsArchived={rowsArchived}
                                handleOpenModalConfirm={handleOpenModalConfirm}
                                colorCell={purple}
                            />
                        </TableControla>

                    </Grid>
                </Grid>
            </Container>

            {/* dialogo de creación de modelo */}
            <Dialog
                open={openModalCreateTemplate}
                onClose={handleCloseModalCreateTemplate}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText={"Agregar nuevo modelo ESTANDAR"}
                    functionOnClose={handleCloseModalCreateTemplate}
                />
                <form onSubmit={handlePostCreateStandar}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Código"}
                                    inputType={"text"}
                                    inputValue={setCode}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Versión"}
                                    inputType={"text"}
                                    inputValue={setVersion}
                                    modalType={true}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Título documento"}
                                    inputType={"text"}
                                    inputValue={setTitle}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalCreateTemplate}
                            />

                            <ButtonControla
                                textButton={"Crear"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

            {/* dialogo subir  o actualizar archivo */}
            <Dialog
                open={openModalUpload}
                onClose={handleCloseModalUpload}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText={buttonActionType == "create" ? "Subir archivo Estándar" : "Actualizar archivo Estándar"}
                    functionOnClose={handleCloseModalUpload}
                />
                <form onSubmit={handleActionModalUpload}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Código"}
                                    inputType={"text"}
                                    inputValue={setCode}
                                    value={code}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputControlaVertical
                                    text={"Versión"}
                                    inputType={"text"}
                                    inputValue={setVersion}
                                    value={version}
                                    modalType={true}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Título documento"}
                                    inputType={"text"}
                                    inputValue={setTitle}
                                    value={title}
                                    modalType={true}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Subir archivo"}
                                    inputType={"file"}
                                    inputValue={setUrlDocument}
                                    modalType={true}
                                    required={buttonActionType == "create" ? true : false}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={"row"} spacing={2}>
                            <ButtonControla
                                textButton={"Cancelar"}
                                backgroundColor={"#CBCBFF"}
                                functionOnClick={handleCloseModalUpload}
                            />
                            <ButtonControla
                                textButton={buttonActionType == "create" ? "Guardar" : "Actualizar"}
                                backgroundColor={"#389532"}
                                typeButton={"submit"}
                            />
                        </Stack>
                    </DialogActions>
                </form>
            </Dialog>

            {/* dialogo actualizar archivo */}
        </Box >
    );
}

export default Index;

