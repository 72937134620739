import * as React from "react";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import Container from "@mui/material/Container";
import SideNav from "../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import { CircularProgress, Grid } from "@mui/material";
import CardControla from "../components/cards/cardControla";
import { useAppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import MyConfigurationApi from "../api/MyConfigurationApi";
import BannerButtonControla from "../components/cards/bannerButtonControla";

const colorTitle = "#9191B0";

function DynamicModules({
  id_section,
  title,
  imageBanner = "/assets/images/committeeBanner.png",
}) {
  const { configurationModules, permissions } = useAppContext();
  const history = useNavigate();

  const handleNavigation = (idConfigSection, route, title, imageBanner) => {
    history(route, {
      state: {
        idConfigSection: idConfigSection,
        title: title,
        imageBanner: imageBanner,
      },
    });
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F4F6" }}>
      <SideNav returnBack="" />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid xs={12} md={12}>
              <SubtitleText text={title} color={colorTitle}></SubtitleText>
              <TitleText text={title} color={colorTitle}></TitleText>
            </Grid>
            <Grid xs={12} md={12}>
              {id_section == 3 ? (
                <BannerButtonControla
                  color1={"#090979"}
                  color2={"#00d4ff"}
                  title={"Recuerda llevar al día tus capacitaciones "}
                  textButton={"Comenzar ahora"}
                  image={imageBanner}
                />
              ) : (
                <BannerButtonControla
                  color1={"#090979"}
                  color2={"#00d4ff"}
                  image={imageBanner}
                />
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {Array.isArray(configurationModules) &&
            configurationModules.length > 0 ? (
              configurationModules.map((section) => {
                console.log(section);

                if (section.id_section === id_section) {
                  return section.modules.map((module) => {
                    console.log("roles", module);
                    const userData = MyConfigurationApi.userData();
                    const usersRoles = userData.roles;
                    let showGrid = false;
                    let roles = module.roles;

                    roles =
                      roles.length === 0
                        ? [
                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                            16,
                          ]
                        : roles;
                    roles.map((rol) => {
                      if (usersRoles.includes(rol)) {
                        showGrid = true;
                      }
                    });

                    return (
                      showGrid && (
                        <Grid
                          key={module.id_configuration_modules}
                          item
                          xs={12}
                          md={3}
                          sx={{ mt: 3 }}
                        >
                          <CardControla
                            roles={module.roles}
                            text={module.title}
                            img={module.image}
                            onClick={() =>
                              handleNavigation(
                                module.id_config_section,
                                module.href,
                                module.title,
                                module.image_banner
                              )
                            }
                          />
                        </Grid>
                      )
                    );
                  });
                }
                return null;
              })
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default DynamicModules;
