import { React } from "react";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import SideNav from "../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import { CircularProgress, Grid } from "@mui/material";
import CardControla from "../components/cards/cardControla";
import BannerButtonControla from "../components/cards/bannerButtonControla";
import { useAppContext } from "../context/AppContext";

const colorTitle = "#9191B0";
const colorOneCardOne = "#e7e7e7";

function Mailbox() {
  const { configurationModules, permissions } = useAppContext();

  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F4F6" }}>
      <SideNav />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Buzón de participación"}
                color={colorTitle}
              ></SubtitleText>
            </Grid>
            <Grid item md={12}>
              <TitleText text={"Buzón"} color={colorTitle}></TitleText>
            </Grid>
            <Grid item xs={12} md={12}>
              <BannerButtonControla
                color1={"#ABD82B"}
                color2={"#309B0B"}
                title={"Revisa en pocos minutos la participación de tu equipo "}
                textButton={"Comenzar ahora"}
                image={"/assets/images/doccreationimage.png"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>        
            <Grid
              item
              xs={12}
              md={3.5}
              sx={{ mt: 3 }}
              style={{ cursor: "pointer" }}
            >
              <Link
                onClick={() => (window.location.href = "/mailbox/congratulations")}
                underline="none"
              >
                <CardControla
                  size={"medium"}
                  color1={colorOneCardOne}
                  color2={colorOneCardOne}
                  text={"Felicitaciones"}
                  img={"/assets/images/banners/mailbox/bannerRecibidos.png"}
                  alt="banner"
                ></CardControla>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={3.5}
              sx={{ mt: 3 }}
              style={{ cursor: "pointer" }}
            >
              <Link href={"/mailbox/reports"} underline="none">
                <CardControla
                  size={"medium"}
                  color1={colorOneCardOne}
                  color2={colorOneCardOne}
                  text={"Reportes "}
                  img={"/assets/images/banners/mailbox/bannerEnviados.png"}
                  alt="banner"
                ></CardControla>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={3.5}
              sx={{ mt: 3 }}
              style={{ cursor: "pointer" }}
            >
              <Link href={"/mailbox/consults"} underline="none">
                <CardControla
                  size={"medium"}
                  color1={colorOneCardOne}
                  color2={colorOneCardOne}
                  text={"Consultas y comentarios "}
                  img={"/assets/images/banners/mailbox/bannerRecibidos.png"}
                  alt="banner"
                ></CardControla>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={3.5}
              sx={{ mt: 3 }}
              style={{ cursor: "pointer" }}
            >
              <Link href={"/mailbox/notifications"} underline="none">
                <CardControla
                  size={"medium"}
                  color1={colorOneCardOne}
                  color2={colorOneCardOne}
                  text={"Notificaciones "}
                  img={"/assets/images/banners/mailbox/bannerRecibidos.png"}
                  alt="banner"
                ></CardControla>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Mailbox;
