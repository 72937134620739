import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import { Unarchive } from "@mui/icons-material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";

function ListArchived(props) {
    const { permissions } = useAppContext();

    const {
        rowsAnnualP,
        colorCell,
        handleOpenModalConfirm
    } = props
    return (
        <TableBody>
            {rowsAnnualP.map((row) =>
                row.register_status == 5 ? (
                    <TableRow
                        hover
                        key={row.id}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                    >
                        <TableCell sx={{ color: colorCell }}>
                            {row.title}
                        </TableCell>
                        <TableCell sx={{ color: colorCell }}>
                            {row.document_url != null &&
                                row.document_url != undefined
                                ? "Archivo"
                                : "Sistema"}
                        </TableCell>
                        <TableCell sx={{ color: colorCell }}>
                            {row.status}
                        </TableCell>
                        <TableCell align="right">
                            <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                            >
                                {row.hasOwnProperty("document_url") ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_PLAN_ANUAL_SSO_ARCHIVADOS_VER}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        directionUrl={row.document_url}
                                        directionTarget={"_blank"}
                                    />
                                ) : row.id !== 1 ? (
                                    <ButtonIconControla
                                        roles={permissions.DOC_PLAN_ANUAL_SSO_ARCHIVADOS_VER}
                                        icon={
                                            <VisibilityOutlined
                                                sx={{ color: "white" }}
                                            />
                                        }
                                        backgroundColor={"#305AD9"}
                                        backgroundColorHover={"#0E4AFF"}
                                        textTooltip={"Ver"}
                                        isNavegationOrigin={true}
                                        directionUrl={"/documentation/annualplan/template?idAnnualPlan=" + row.id}
                                        paramsIntUrl={{ readOnly: true }}
                                    />
                                ) : (
                                    ""
                                )}
                                <ButtonIconControla
                                    roles={permissions.DOC_PLAN_ANUAL_SSO_ARCHIVADOS_DESARCHIVAR}
                                    icon={<Unarchive sx={{ color: "white" }} />}
                                    backgroundColor={"#F2994A"}
                                    backgroundColorHover={"#FF881E"}
                                    textTooltip={"Desarchivar"}
                                    functionOnClick={() =>
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de desarchivar el documento?",
                                            "",
                                            "Si, desarchivar",
                                            "unarchived"
                                        )
                                    }
                                />
                            </Stack>
                        </TableCell>
                    </TableRow>
                ) : (
                    ""
                )
            )}
        </TableBody>

    )

}
export default ListArchived;