import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import { Grid } from "@mui/material";
import BannerButtonControla from "../../components/cards/bannerButtonControla";
import CardControla from "../../components/cards/cardControla";
import SideNav from "../../components/sidenav/sideNav";
import TitleText from "../../components/text/titleText";
import BannerControla from "../../components/cards/bannerControla";

const colorTitle = "#9191B0";
// Recuerda agendar la reunión mensual de tu Comité

function Index() {
  return (
    <Box sx={{ display: "flex" }}>
      <SideNav returnBack="/" />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TitleText text={"Tareas"} color={colorTitle}></TitleText>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/* <BannerControla color1={'#3863FB'} color2={'#161A78'} text={'Mantén en orden la organización de tu empresa'} /> */}
              <BannerControla
                image={"/assets/images/banners/configuration/tareasbanner.png"}
                text={"Tareas"}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/activities/task/mytasks"}
                text={"Mis Tareas"}
                img={"/assets/images/banners/configuration/empresa.png"}
                subText={"Trabajador"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/activities/task/taksWorkers"}
                text={"Tareas"}
                img={"/assets/images/banners/configuration/empresa.png"}
                subText={"Jefe"}
              />
            </Grid>


            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                url={"/activities/task/baseline/improvementactions"}
                text={"Acciones de mejora"}
                img={"/assets/images/banners/configuration/empresa.png"}
                subText={"Línea base"}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                // url={"/activities/task"}
                text={"Tareas"}
                img={"/assets/images/banners/configuration/empresa.png"}
                subText={"Línea base"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                // url={"/activities/task"}
                text={"Medidas correctivas"}
                img={"/assets/images/banners/configuration/empresa.png"}
                subText={"Registros"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                // url={"/activities/task"}
                text={"Recomendaciones"}
                img={"/assets/images/banners/configuration/empresa.png"}
                subText={"Actas"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <CardControla
                // url={"/activities/task"}
                text={"Tareas"}
                img={"/assets/images/banners/configuration/empresa.png"}
                subText={"Herramientas de gestión de riesgos"}
              />
            </Grid> */}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Index;
