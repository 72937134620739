import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import {
    LoginOutlined
} from "@mui/icons-material";

function ListArchived(props) {

    const {
        archivedBooks,
        documentType,
        colorCell
    } = props

    return (
        <TableBody
            sx={{ display: documentType === 1 ? "" : "none" }}
        >
            {archivedBooks.map((book) => (
                <TableRow>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {book.title}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {book.start_date}
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: colorCell }}
                        align="center"
                    >
                        {book.end_date}
                    </TableCell>
                    <TableCell align="right">
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignContent="center"
                        >

                            <ButtonIconControla
                                icon={
                                    <LoginOutlined
                                        sx={{ color: "white" }}
                                    />
                                }
                                backgroundColor={"#24996A"}
                                backgroundColorHover={"#1AA483"}
                                textTooltip={"Ver Actas"}
                                isNavegationOrigin={true}
                                directionUrl={"/committee/proceedingsBook/addProceedingsBook/proceeedingsMeeting"}
                                paramsIntUrl={{
                                    id_book: book.id_minute_book,
                                    name_book: book.title,
                                    date_created: book.start_date,
                                    end_date: book.end_date,
                                    typeBook: "archivedBook"
                                }}
                            />
                        </Stack>
                    </TableCell>

                </TableRow>

            ))}

        </TableBody>

    )

}
export default ListArchived;