import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from "@mui/material";
import { React, useState, useEffect } from "react";
import SideNav from "../components/sidenav/sideNav";
import BannerControla from "../components/cards/bannerControla";
import { Add} from "@mui/icons-material";
import ButtonControla from "../components/buttons/buttonController";
import DialogTitleControla from "../components/dialog/dialogTitleControla";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import MyAreas from "../api/MyAreas";
import DialogConfirmControla from "../components/dialog/dialogConfirmControla";
import { useAppContext } from "../context/AppContext";
import TableControla from "../components/tables/table-controla";
import LoadingControla from "../components/load/loadingControla";
import ListAreas from "./components/list-areas";
import ButtonMailboxControla from "../components/buttons/buttonMailboxControla";

//colors
const purple = "#8B8BD8";
const blue = "#034AFF";

function Index() {
  const { permissions } = useAppContext();


  //Variables
  const [rowAreas, setRowAreas] = useState([]);

  const [idArea, setIdArea] = useState("");
  const [areaName, setAreaName] = useState("");

  //Modals
  const [openModalCreate, setOpenModalCreate] = useState(false);

  const handleOpenModalCreate = () => {
    setOpenModalCreate(true);
  };
  const handleCloseModalCreate = () => {
    setOpenModalCreate(false);
  };

  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenModalEdit = (idArea, areaName) => {
    setIdArea(idArea);
    setAreaName(areaName);
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdArea(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        handleDeleteAreas(idArea);
        break;
    }
    setOpenModalConfirm(false);
  };

  //CRUD functions
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setRowAreas(response);
    return response;
  };

  const handleCreateAreas = async () => {
    handleOpenLoading();
    const response = await MyAreas.createAreas({ area: areaName });

    if (response.success == true) {
      handleGetAreas();
      handleGetAreasRefrenced();
      handleOpenAlert("Área agregada con éxito", "success");
    } else {
      handleOpenAlert("Ocurrió un error", "error");
    }

    handleCloseModalCreate();

    return response;
  };

  const handleUpdateAreas = async () => {
    handleOpenLoading();
    const response = await MyAreas.updateAreas({
      idArea: idArea,
      area: areaName,
    });

    if (response.success == true) {
      handleGetAreas();
      handleGetAreasRefrenced();
      handleOpenAlert("Área editada con éxito", "success");

    } else {
      handleOpenAlert("Ocurrió un error", "error");
    }

    handleCloseModalEdit();

    return response;
  };

  const handleDeleteAreas = async (idArea) => {
    handleOpenLoading();
    const response = await MyAreas.deleteAreas({ idArea: idArea });

    if (response.success == true) {
      handleGetAreas();
      handleGetAreasRefrenced();
      handleOpenAlert("Área eliminada con éxito", "success")

    } else {
      handleOpenAlert("Ocurrió un error", "error");
    }

    return response;
  };


  const [rowsAreaReferenced, setRowsAreaReferenced] = useState([]);
  //mostrar las áreas desde configuración
  const handleGetAreasRefrenced = async () => {
    handleOpenLoading();

    const response = await MyAreas.getAreasReferenced();
    setRowsAreaReferenced(response);

    setOpenLoading(false);
    return response;
  };

  //UseEffect
  useEffect(() => {
    handleGetAreas();
    handleGetAreasRefrenced();
  }, []);
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/settings" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <ButtonMailboxControla
              originUrl={"/settings/areas"}
              />
            </Grid>

            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BannerControla
                image=""
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Areas"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ mt: 2 }}
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <ButtonControla
                roles={permissions.CONF_AREA_AGREGAR}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar Área"}
                functionOnClick={() => handleOpenModalCreate()}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TableControla
                loading={openLoading}
                tabs={[
                  {
                    columnNames: ["Nombre", "Acciones"]
                  }
                ]}
              >

                <ListAreas
                  rowsAreaReferenced={rowsAreaReferenced}
                  colorCell={purple}
                  handleOpenModalEdit={handleOpenModalEdit}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                />

              </TableControla>

            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalCreate}
        onClose={() => handleCloseModalCreate()}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Agregar nueva Área"
          functionOnClose={() => handleCloseModalCreate()}
        />
        {/* <form onSubmit={() => handleCreateAreas()}> */}
        <DialogContent sx={{ paddingTop: "0" }}>
          <InputControlaVertical
            text={"Nombre"}
            inputType={"text"}
            inputValue={setAreaName}
            modalType={true}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="success"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleCloseModalCreate()}
          >
            Cancelar
          </Button>
          <Button
            //   type="submit"
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              // backgroundColor: '#169073',
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleCreateAreas()}
          >
            Crear
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      <Dialog
        open={openModalEdit}
        onClose={() => handleCloseModalEdit()}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Editar Área"
          functionOnClose={() => handleCloseModalEdit()}
        />
        {/* <form onSubmit={() => handleCreateAreas()}> */}
        <DialogContent sx={{ paddingTop: "0" }}>
          <InputControlaVertical
            text={"Nombre"}
            inputType={"text"}
            inputValue={setAreaName}
            value={areaName}
            modalType={true}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="success"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleCloseModalEdit()}
          >
            Cancelar
          </Button>
          <Button
            //   type="submit"
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              // backgroundColor: '#169073',
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleUpdateAreas()}
          >
            Editar
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

    </Box>
  );
}

export default Index;
