import { React, useEffect, useState } from "react";
// import parse from 'html-react-parser';
import Box from "@mui/material/Box";
import {
    Alert,
    Container,
    Grid,
    Backdrop,
    MenuItem,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    Snackbar,
    InputLabel,
    Table,
    Paper,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Select
} from "@mui/material";
import {
    Add,
    Edit,
    DeleteForever,
  } from "@mui/icons-material";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import SideNav from "../../../../../components/sidenav/sideNav";
import SubtitleText from "../../../../../components/text/subtitleText";
import TitleText from "../../../../../components/text/titleText";
import CompanyLogoControla from "../../../../../components/images/companyLogoControla";
import InputControlaVertical from "../../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../../components/buttons/buttonController";
import { EditorControla } from "../../../../../components/editor/editorControla";
import HelpIcon from '@mui/icons-material/Help';
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";
import DialogTitleControla from "../../../../../components/dialog/dialogTitleControla";
import SearchUsersControla   from "../../../../../components/search/searchUsersControla";
import DialogConfirmControla from "../../../../../components/dialog/dialogConfirmControla";
import SelectControla from "../../../../../components/selects/selectControla";
import InputControlaHorizontal from "../../../../../components/textfields/inputControlaHorizontal";
import MyMeeting from "../../../../../api/MyMeetings";
import MyUsers from "../../../../../api/MyUsers";
import MyProceedingsBook from "../../../../../api/MyProceedingsBook";

const grey = "#9191B0";
const skyblue = "#2D9CDB";
const blue = "#034AFF";

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em",
  });

function ViewInformationTheme (){
    //obtener el id de la reunión por el enlace 
    const params = new URLSearchParams(window.location.search);
    const id_theme_proceeding = params.get("id_theme");
    const id_proceeding  = params.get("id_proceeding");

    const [titleTheme, setTitleTheme] = useState("");
    const [information, setInformation]= useState(``);
    const [contentTemplate, setContentTemplate] = useState(``);
    const [recommendations, setRecommendations] = useState([]);

    const [approval, setApproval] = useState(0);
    const [comment, setComment] = useState("");

    const typeApproval = [{ id_approval: 1, name: "Por consenso" }, { id_approval: 2, name: "Por votación" }];

    const getThemeByProceeding= async()=>{
        const r = await MyProceedingsBook.getThemesByProceedingId(id_proceeding);
        const theme = r.data.filter((theme)=> theme.id_committee_minutes_theme === Number(id_theme_proceeding));
        setTitleTheme(theme[0].name);
        setContentTemplate(theme[0].report);
        setInformation(theme[0].description);
        setComment(theme[0].comment);
        setApproval(theme[0].approval);
    }
    const getRecommendationsByTheme = async() => {
        const res  = await MyProceedingsBook.getRecommendationsByTheme(id_theme_proceeding);
        setRecommendations(res.data)
    }
    useEffect(()=>{
        getThemeByProceeding();
        getRecommendationsByTheme();

    },[]);

    return(
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack = ""/> 
            
            <Container  sx={{ paddingBottom: 4 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SubtitleText
                            text={"Tema a tratar en reunión"}
                             color={grey}
                            ></SubtitleText>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TitleText
                                text={"Detalles del tema"}
                            />
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <InputControlaVertical
                                text={"Tema"}
                                inputType={"text"}
                                inputValue={setTitleTheme}
                                modalType={false}
                                value={titleTheme || ''}
                                readOnly={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputLabel
                                sx={{
                                    padding: '8px',
                                    fontSize: '18px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>Informe</InputLabel>
                            <div
                                style={{
                                    fontSize: '1rem',
                                    fontFamily: 'Roboto',
                                    width: '100%',
                                    padding: '4px 10px',
                                    border: '1px solid transparent',
                                    outline: '0px transparent',
                                    backgroundColor: '#FFFFFF',
                                    color: '#305AD9',
                                    borderRadius: '8px',
                                }}
                            >
                                {contentTemplate}
                                {/* {contentTemplate === null ? "" : parse(contentTemplate)} */}
                            </div>
                        </Grid>
                        
                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputControlaVertical
                                text={"Comentarios"}
                                inputType={"textArea"}
                                inputValue={setComment}
                                value={comment}
                                modalType={false}
                                required
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Recomendaciones
                            </StyledTypography>
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Descripción
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Responsable
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Fecha de inicio
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Fecha de Fin
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Estado
                                            </TableCell>
                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recommendations.map((r, index)=>(
                                            <TableRow>
                                                <TableCell sx={{ color: blue }} align="center">
                                                   {r.description}
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                   {r.first_name + " "+ r.last_name}
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    {r.start_date}
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    {r.end_date}
                                                </TableCell>
                                                <TableCell sx={{ color: blue }} align="center">
                                                    {r.status === 1 ? "En proceso" : ""}
                                                </TableCell>
                                            
                                            </TableRow>
                                        ))}                                   
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                            <SelectControla
                            text="Aprobación del acuerdo:"
                            inputValue={setApproval}
                            modalType={false}
                            required
                            readOnly={true}
                            value={approval || ''}
                            childrenRows={typeApproval.map((rows) => (
                                <MenuItem key={rows.id_approval} value={rows.id_approval}>
                                    {rows.name}
                                </MenuItem>
                            ))}
                            />
                        </Grid>

                    </Grid>
                </Box>

            </Container>          
        </Box>
    );

}

export default ViewInformationTheme;
