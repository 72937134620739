import { React, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, Grid, MenuItem, Modal, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add, DeleteForever, BorderColorOutlined, Search, Print, Edit as EditIcon, HistoryEduOutlined, Delete, DownloadForOffline, PictureAsPdf } from '@mui/icons-material';
import SideNav from '../../../components/sidenav/sideNav';
import SubtitleText from '../../../components/text/subtitleText';
import TitleText from '../../../components/text/titleText';
import MyAts from '../../../api/MyAts';
import MyUsers from '../../../api/MyUsers';
import InputControlaVertical from '../../../components/textfields/inputControlaVertical';
import CompanyLogoControla from '../../../components/images/companyLogoControla';
import ButtonControla from '../../../components/buttons/buttonController';
import SearchUsersControla from '../../../components/search/searchUsersControla';
import DialogTitleControla from '../../../components/dialog/dialogTitleControla';
import LoadingControla from '../../../components/load/loadingControla';
import MyConfigurationApi from '../../../api/MyConfigurationApi';
import ButtonIconControla from '../../../components/buttons/buttonIconControla';
import SearchButtonControla from '../../../components/search/searchButtonControla';
import SearchEppsControla from '../../../components/search/searchEppsControla';
import DialogConfirmControla from '../../../components/dialog/dialogConfirmControla';
import MyTrainings from '../../../api/MyTrainings';
import MyCampus from '../../../api/MyCampus';
import SelectControla from '../../../components/selects/selectControla';
import { Link } from 'react-router-dom';
import SearchUsersControlaCustom from './components/search-user-controla-custom';
import MyAreas from '../../../api/MyAreas';
import { useAppContext } from '../../../context/AppContext';

const grey = "#9191B0";
const blue = "#034AFF";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";


const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
})

function Workers() {

    const {permissions} = useAppContext();

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    // Pantallas de carga y alertas FIN


    // Dialogos de confirmacion INICIO
    const [idAction, setIdAction] = useState('');

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState('');
    const [contentText, setContentText] = useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = useState('');
    const [buttonActionType, setButtonActionType] = useState('');
    const [endTime, setEndTime] = useState(null);


    const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
        setIdAction(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case 'deleteWorkers':
                handleDeleteWorkers()
                break;
        }
        setOpenModalConfirm(false);
    }

    // Dialogos de confirmacion FIN

    const params = new URLSearchParams(window.location.search);
    const id_trainings = params.get('id_trainings');

    //Modals
    const [openModalCreate, setOpenModalCreate] = useState(false);

    const handleOpenModalCreate = () => {
        setOpenModalCreate(true);
    };
    const handleCloseModalCreate = () => {
        setOpenModalCreate(false);
    };

    const [rowWorkers, setRowWorkers] = useState([]);
    const handleGetWorkers = async () => {
        const response = await MyTrainings.getWorkers({ id_trainings: id_trainings });
        setRowWorkers(response);
    }

    const [rowNotWorkers, setRowNotWorkers] = useState([]);
    const handleUsersNoResponsible = async () => {
        const response = await MyTrainings.getNotWorkers({ id_trainings: id_trainings });

        if (response.success !== false) {
            setRowNotWorkers(response);
        } else {
            alert('Error');

        }
        return response;
    };
    const [idStatus, setIdStatus] = useState("");
    const handleGetTraining = async () => {
        const response = await MyTrainings.getTrainings({ id_trainings: id_trainings });
        setIdStatus(response.data.data[0].id_trainings_statuses);
        setEndTime(response.data.data[0].timestamp_end);
    }

    const [areas, setAreas] = useState([]);
    const getAreas = async () => {
        const response = await MyAreas.getAreas()
        setAreas(response)
    }

    useEffect(() => {
        handleGetTraining();
        handleGetWorkers();
        handleUsersNoResponsible();
        getAreas()
    }, []);

    const handleStoreWorkers = async (id_users) => {
        handleOpenLoading();
        const response = await MyTrainings.storeWorkers({ id_trainings, id_users });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleGetWorkers();
            handleCloseModalCreate();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleAddUserForArea = async (id_areas) => {
        handleOpenLoading();
        const response = await MyTrainings.storeWorkersForArea({ id_trainings, id_areas });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleGetWorkers();
            handleCloseModalCreate();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleDeleteWorkers = async () => {
        handleOpenLoading();
        const response = await MyTrainings.deleteWorkers({ id_trainings_workers: idAction });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleGetWorkers();
            handleCloseModalConfirm();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };


     const getStatus = (row, value, threshold) => {
        const endTimeValue = new Date(endTime);
        const timeValue = new Date();

        if (timeValue > endTimeValue || threshold == 1) {
            return row[value] === threshold ? `${value === 'assistances' ? 'Asistio' : 'Firmo'}` : `${value === 'assistances' ? 'No asistio' : 'No Firmo'}`;
        } else {
            return "Pendiente";
        }
    };

    const WorkedAssistance = (row) => {
        return getStatus(row.row, 'assistances', row.row.assistances);
    };

    const WorkedSignature = (row) => {
        return getStatus(row.row, 'firm', row.row.firm);
    };

    return (
        <>
            <Box sx={{ display: "flex", height: "100%" }}>

                <SideNav returnBack='/trainings/all' />

                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />

                <Container sx={{ paddingBottom: 4 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item md={10}>
                                <SubtitleText text={"Capacitaciones"} color={grey}></SubtitleText>
                                <TitleText text={"Trabajadores"} />
                            </Grid>
                            {/* <Grid item md={2}>

                            </Grid> */}
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                                <CompanyLogoControla />
                            </Grid>
                        </Grid>
                        <Stack
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems="center"
                            sx={{ mt: .5, mb: 2 }}
                        >
                            <Tabs value={1} aria-label="Tipos" >
                                <Link to={"/trainings/all/edit?id_trainings=" + id_trainings}><Tab label="Detalles" /></Link>
                                <Link to={"/trainings/all/workers?id_trainings=" + id_trainings}><Tab label="Trabajadores"  /></Link>
                                <Link to={"/trainings/all/materials?id_trainings=" + id_trainings}><Tab label="Materiales"  /></Link>
                                <Link to={"/trainings/all/exams?id_trainings=" + id_trainings}><Tab label="Examenes" /></Link>
                                <Link to={"/trainings/all/evidences?id_trainings=" + id_trainings}><Tab label="Evidencias" /></Link>
                            </Tabs>
                        </Stack>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {idStatus == 1 || idStatus == 2 ?
                                    <ButtonControla
                                        roles={permissions.CAPACIT_TODAS_CAPACIT_TRABAJ_AGREGA}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar trabajador"}
                                        functionOnClick={() => handleOpenModalCreate()}
                                    />
                                    : ''}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper sx={{ overflow: "hidden" }}>
                                    <TableContainer>
                                        <Table
                                            stickyHeader
                                            sx={{ width: "100%", minWidth: "700px" }}
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Nombre y apellido
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Documento de identidad
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Asistencia
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Firma
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Nota
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Acciones
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowWorkers.map((row, key) => (
                                                    <TableRow
                                                        hover
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}
                                                        key={key}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {row.first_name + ' ' + row.last_name}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {row.document}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            <WorkedAssistance row={row}/>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            <WorkedSignature row={row}/>
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {(row.qualification == null ? 'No dio examen' : row.qualification)}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                            >
                                                                {idStatus == 1 || idStatus == 2 ?
                                                                    <ButtonIconControla
                                                                        roles={permissions.CAPACIT_TODAS_CAPACIT_TRABAJ_ELIM}
                                                                        icon={
                                                                            <Delete
                                                                                sx={{ color: "white" }}
                                                                            />
                                                                        }
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        textTooltip={"Editar"}
                                                                        functionOnClick={() => { handleOpenModalConfirm(row.id_trainings_workers, 'Eliminar trabajador', '¿Estás seguro de eliminar el trabajador de esta capacitación?', 'Sí, eliminar', 'deleteWorkers'); }}
                                                                    />
                                                                    : ''}
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                <Dialog
                    open={openModalCreate}
                    onClose={handleCloseModalCreate}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Agregar trabajador' functionOnClose={handleCloseModalCreate} />
                    <DialogContent >
                        <SearchUsersControlaCustom 
                            areas={areas} 
                            rowsUser={rowNotWorkers} 
                            handleUsersActionButton={handleStoreWorkers}
                            handleAddUserForArea={handleAddUserForArea}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={'row'} spacing={2}>
                            <Box>
                                <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreate} />
                            </Box>
                        </Stack>
                    </DialogActions>
                </Dialog>

            </Box >
        </>
    )
}

export default Workers