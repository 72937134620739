import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRegistersAudit() {
  const url = "register_general_audits";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function getRegisterStatus({ idRegister }) {
  const url = idRegister + "/status/end";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "PUT",
    url: url,
  });
}

function editRegisterAudit({ idRegister }) {
  const url = "register_general_audits/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function updateRegisterAudit({
  idRegister,
  code,
  nonConformitiesNumber,
  nonConformityDescription,
  nonConformityCauses,
  documentUrl1,
  documentUrl2,
}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (documentUrl1.length !== 0 && documentUrl1 !== "{}") {
    console.log("cambia 1");
    documentUrl1 = await uploadFileCompanies(
      documentUrl1,
      "documentation/registers/generals/auditoring/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }

  if (documentUrl2.length !== 0 && documentUrl2 !== "{}") {
    console.log("cambia 2");
    documentUrl2 = await uploadFileCompanies(
      documentUrl2,
      "documentation/registers/generals/auditoring" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }
  if (documentUrl1 === null) {
    documentUrl1 = documentUrl1;
  } else {
    if (
      typeof documentUrl1 === "string" &&
      documentUrl1.substring(0, 38) === "https://firebasestorage.googleapis.com"
    ) {
      documentUrl1 = documentUrl1;
    } else {
      documentUrl1 = await uploadFileCompanies(
        documentUrl1,
        "documentation/registers/generals/auditoring" +
          year +
          "/" +
          month +
          "/" +
          day +
          "/"
      );
    }
  }

  if (
    documentUrl2 !== null &&
    documentUrl2 !== undefined &&
    documentUrl2.length !== 0
  ) {
    if (
      typeof documentUrl2 === "string" &&
      documentUrl2.substring(0, 38) === "https://firebasestorage.googleapis.com"
    ) {
      documentUrl2 = documentUrl2;
    } else {
      documentUrl2 = await uploadFileCompanies(
        documentUrl2,
        "documentation/registers/generals/auditoring" +
          year +
          "/" +
          month +
          "/" +
          day +
          "/"
      );
    }
  }

  const url = "register_general_audits/" + idRegister;

  const data = {
    code: code,
    non_conformities_number: nonConformitiesNumber,
    non_conformity_description: nonConformityDescription,
    non_conformity_causes: nonConformityCauses,
    document_url_1: documentUrl1,
    document_url_2: documentUrl2,
  };

  console.log(data);

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
function createRegisterAudit({ code }) {
  const url = "register_general_audits";
  const data = { code: code, status: "Creado" };

  console.log(url);
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function getAddAuditioners({ idRegister }) {
  const url = "sec/register_general_audits_add_auditioners/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

//agrega responsables en la tabla
function addResponsibleAddAudit({ idUser, idRegister }) {
  const url = idRegister + "/responsible";
  const data = {
    id_users: idUser,
  };
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function getResponsibleAudit({ idRegister }) {
  const url = idRegister + "/responsible";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

//Elimina responsables de la tabla
function deleteResponsibleAudit({ idRegister, idUser }) {
  const url = "responsible/detail/" + idUser;
  const data = {
    id_users: idUser,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "DELETE",
    url: url,
  });
}

//firma responsables
function signResponsibleAudit({ idUserId, idUser }) {
  const url = "responsible/detail/" + idUser;
  const data = {
    id_users: idUserId,
    firm: 1,
    firm_date: null,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
///Auditores y registros
function getAuditorsAudit({ idRegister }) {
  const url = "sec/register_general_audits_add_auditioners/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function addAuditorsAudit({ idRegister, auditName, registerNumber }) {
  const url = "sec/register_general_audits_add_auditioners/" + idRegister;
  const data = {
    name: auditName,
    register_number: registerNumber,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function deleteAuditorsAudit({ idSubregister }) {
  const url =
    "sec/register_general_audits_add_auditioners/detail/" + idSubregister;
  console.log(url);
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}
//Auditores y registros

//Auditores y registros responsables
function getAuditorsResponsibles({ idRegister }) {
  const url =
    "sec/register_general_audits_add_auditioners_responsibles/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function addAuditorsResponsibles({
  idRegister,
  date,
  auditProcesses,
  responsibleName,
}) {
  const url =
    "sec/register_general_audits_add_auditioners_responsibles/" + idRegister;
  const data = {
    date: date,
    audit_processes: auditProcesses,
    responsible_name: responsibleName,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function deleteAuditorsResponsibles({ idSubregister }) {
  const url =
    "sec/register_general_audits_add_auditioners_responsibles/detail" +
    idSubregister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

// MEDIDAS CORRECTIVAS

// Crear medidas Correctivas

function createCorrectiveActions({
  idRegistersAudits,
  idAuditsCorrectiveActionsStatus,
  idUsers,
  nameUserResponsible,
  documentUserResponsible,
  timeStampStart,
  timeStampEnd,
  description,
}) {
  const data = {
    id_registers_audits_measures_statuses: idAuditsCorrectiveActionsStatus,
    id_users: idUsers,
    timestamp_start: timeStampStart,
    timestamp_end: timeStampEnd,
    description: description,
  };

  console.log(data);
  const url =
    "sec/register_general_audits_corrective_measures/" + idRegistersAudits;
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function getCorrectiveActions({ idRegistersAudits }) {
  const url =
    "sec/register_general_audits_corrective_measures/" + idRegistersAudits;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function deleteCorrectiveActions({ idRegistersAudits }) {
  const url =
    "sec/register_general_audits_corrective_measures/detail/" +
    idRegistersAudits;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

function getAuditoringAudit({ idRegistersAudits }) {
  const url = "sec/register_general_audits_audit/" + idRegistersAudits;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function createAuditoringAudit({
  idRegistersAudits,
  idUsers,
  auditoringProcess,
  auditoringDate,
}) {
  const url = "sec/register_general_audits_audit/" + idRegistersAudits;
  const data = {
    id_users: idUsers,
    auditoring_process: auditoringProcess,
    auditoring_date: auditoringDate,
    
  };
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function deleteAuditoringAudit({ idAuditoringAudit }) {
  const url = "sec/register_general_audits_audit/detail/" + idAuditoringAudit;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}
//Auditores y registros responsables
const MyRegistersAudits = {
  getRegistersAudit,
  editRegisterAudit,
  updateRegisterAudit,
  createRegisterAudit,
  deleteResponsibleAudit,
  getAddAuditioners,
  addResponsibleAddAudit,
  getResponsibleAudit,
  getAuditorsAudit,
  addAuditorsAudit,
  deleteAuditorsAudit,
  getAuditorsResponsibles,
  addAuditorsResponsibles,
  deleteAuditorsResponsibles,
  signResponsibleAudit,
  getRegisterStatus,
  createCorrectiveActions,
  getCorrectiveActions,
  deleteCorrectiveActions,

  getAuditoringAudit,
  createAuditoringAudit,
  deleteAuditoringAudit,
};

export default MyRegistersAudits;
