import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../../../components/buttons/buttonIconControla";

import {
    BorderColorOutlined,
    VisibilityOutlined,
} from "@mui/icons-material";
import { useAppContext } from "../../../../../context/AppContext";

function ListEvaluations(props) {
    const { permissions } = useAppContext();

    const {
        evaluations
    } = props;
    return (
        <TableBody>
            {
                evaluations.map((e) => (
                    <TableRow
                        key={e.id}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "4px"
                        }}
                    >
                        <TableCell component="th" scope="row">
                            {e.nro_register}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {e.register_status == 1 ? "creado" : "Finalizado"}
                        </TableCell>

                        <TableCell align="right">
                            <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                            >
                                {
                                    e.register_status == 1 ? (
                                        <ButtonIconControla
                                            roles={permissions.DOC_REGISMYPES_EVALUAC_SSO_EDIT}
                                            icon={
                                                <BorderColorOutlined
                                                    sx={{ color: "white" }}
                                                />
                                            }
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textTooltip={"Editar"}
                                            isNavegationOrigin={true}
                                            directionUrl={`/documentation/registers/mypes/evaluationSSO/edit?id_evaluation=${e.id}`}
                                        />

                                    ) : (
                                        <ButtonIconControla
                                            roles={permissions.DOC_REGISMYPES_EVALUAC_SSO_VER}
                                            icon={
                                                <VisibilityOutlined sx={{ color: "white" }} />
                                            }
                                            backgroundColor={"#305AD9"}
                                            backgroundColorHover={"#0E4AFF"}
                                            textTooltip={"Ver"}
                                            isNavegationOrigin={true}
                                            directionUrl={`/documentation/registers/mypes/evaluationSSO/edit?id_evaluation=${e.id}`}
                                        />
                                    )
                                }
                            </Stack>
                        </TableCell>
                    </TableRow>
                ))
            }
        </TableBody>
    );
}
export default ListEvaluations;