import * as React from 'react';

import SubtitleText from '../components/text/subtitleText';
import Container from '@mui/material/Container';
import DocCard from "../components/cards/docCard";
import Link from '@mui/material/Link';
import SideNav from "../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { CircularProgress, Grid } from '@mui/material';
import DocBanner from '../components/cards/docBanner';
import { useAppContext } from '../context/AppContext';
import CardControla from '../components/cards/cardControla';

const colorTitle = "#9191B0";

function Index() {
    const { configurationModules } = useAppContext();
    return (

        <Box sx={{ display: "flex" }}>
            <SideNav />

            <Container>

                <Box sx={{ flexGrow: 1 }}>

                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <SubtitleText text={"Administrador"} color={colorTitle}></SubtitleText>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <DocBanner text='Herramientas de administrador' color1={'#65A3FF'} color2={'#0F1255'} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sx={{ mt: 3 }}>
                            <Link href={"/admin/epps"} underline="none">
                                <DocCard size={"medium"} color1={'#0fd98c'} color2={'#8FBCFF'} text={"Epps"} img={""}></DocCard>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ mt: 3 }}>
                            <Link href={"/admin/baseline/indicators"} underline="none">
                                <DocCard size={"medium"} color1={'#FFB185'} color2={'#FF5A26'} text={"Linea Base"} img={""}></DocCard>
                            </Link>
                        </Grid>
                    </Grid>

                </Box>
            </Container>

        </Box>

    );

}

export default Index;

