import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem
} from "@mui/material";
import { React, useState, useEffect } from "react";
import SideNav from "../components/sidenav/sideNav";
import BannerControla from "../components/cards/bannerControla";
import { Add} from "@mui/icons-material";
import ButtonControla from "../components/buttons/buttonController";
import DialogTitleControla from "../components/dialog/dialogTitleControla";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import MyAreas from "../api/MyAreas";
import MyOccupationalPositions from "../api/MyOccupationalPositions";
import SelectControla from "../components/selects/selectControla";
import DialogConfirmControla from "../components/dialog/dialogConfirmControla";
import { useAppContext } from "../context/AppContext";
import TableControla from "../components/tables/table-controla";
import LoadingControla from "../components/load/loadingControla";
import ListOccupationalPositions from "./components/list-occupationalPositions";
import MyUsers from "../api/MyUsers";
import ButtonMailboxControla from "../components/buttons/buttonMailboxControla";

//colors
const purple = "#8B8BD8";
const skyblue = "#2D9CDB";
const blue = "#034AFF";

function Index() {
  let { permissions } = useAppContext();

  //Variables
  const [rowPositions, setRowPositions] = useState([]);

  const [idPosition, setIdPosition] = useState("");
  const [positionName, setPositionName] = useState("");

  const [areas, setAreas] = useState([]);
  const [positionArea, setPositionArea] = useState(0);
  //Modals
  const [openModalCreate, setOpenModalCreate] = useState(false);

  const handleOpenModalCreate = () => {
    setOpenModalCreate(true);
    setPositionName("");
    setPositionArea(0);
    handleGetAreas();
  };
  const handleCloseModalCreate = () => {
    setOpenModalCreate(false);
  };

  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenModalEdit = (idPosition, positionName, positionArea) => {
    setIdPosition(idPosition);
    setPositionName(positionName);
    setPositionArea(positionArea);
    setOpenModalEdit(true);
    handleGetAreas();

  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };
  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdPosition(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        handleDeletePositions(idPosition);
        break;
    }
    setOpenModalConfirm(false);
  };

  //CRUD functions
  const handleGetPositions = async () => {
    handleOpenLoading();

    const response = await MyOccupationalPositions.getPositions();
    setRowPositions(response);

    setOpenLoading(false);
    return response;
  };

  const handleCreatePositions = async () => {
    handleOpenLoading();
    const response = await MyOccupationalPositions.createPositions({
      position: positionName,
      idArea: positionArea,
    });

    if (response.success == true) {
      handleGetPositions();
      handleOpenAlert("Cargo ocupacional agregado con éxito", "success");

    } else {
      handleOpenAlert("Ocurrio un error", "error");
    }

    handleCloseModalCreate();

    return response;
  };

  const handleUpdatePositions = async () => {
    handleOpenLoading();
    const response = await MyOccupationalPositions.updatePositions({
      idPosition: idPosition,
      name: positionName,
      idArea: positionArea,
    });

    if (response.success == true) {
      handleGetPositions();
      handleOpenAlert("Cargo ocupacional editado con éxito", "success");

    } else {
      handleOpenAlert("Ocurrio un error", "error");

    }
    handleCloseModalEdit();

    return response;
  };

  const handleDeletePositions = async (idPosition) => {
    handleOpenLoading();

    const response = await MyOccupationalPositions.deletePositions({
      idPosition: idPosition,
    });

    if (response.success == true) {
      handleGetPositions();
      handleOpenAlert("Cargo ocupacional eliminado con éxito", "success");

    } else {
      handleOpenAlert("Ocurrio un error", "error");
    }

    return response;
  };

  //Get Areas for dropdown
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setAreas(response);
    return response;
  };

  const [positionsReferenced , setPositionsReferenced] = useState([]);

  const handleGetPositionsReferenced = async()=>{
    const resp = await MyUsers.users();
    setPositionsReferenced(resp.users_actives)
  }

  

  //UseEffect
  useEffect(() => {
    handleGetPositions();
    handleGetPositionsReferenced();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/settings" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <ButtonMailboxControla
                originUrl={"/settings/positions"}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BannerControla
                image=""
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Cargos Ocupacionales"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ mt: 2 }}
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <ButtonControla
                roles={permissions.CONF_CARGO_AGREGAR}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar Cargo"}
                functionOnClick={() => handleOpenModalCreate()}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TableControla
                loading={openLoading}
                tabs={[
                  {
                    columnNames: ["Nombre", "Areas", "Acciones"]
                  }
                ]}
              >
                <ListOccupationalPositions
                  rowPositions={rowPositions}
                  positionsReferenced={positionsReferenced}
                  colorCell={purple}
                  handleOpenModalConfirm={handleOpenModalConfirm}
                  handleOpenModalEdit={handleOpenModalEdit}
                />

              </TableControla>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalCreate}
        onClose={() => handleCloseModalCreate()}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Agregar nuevo Cargo"
          functionOnClose={() => handleCloseModalCreate()}
        />
        {/* <form onSubmit={() => handleCreateAreas()}> */}
        <DialogContent sx={{ paddingTop: "0" }}>
          <InputControlaVertical
            text={"Nombre"}
            inputType={"text"}
            inputValue={setPositionName}
            value={positionName}
            modalType={true}
            required
          />
          <SelectControla
            text="Areas:"
            inputValue={setPositionArea}
            modalType={false}
            childrenRows={areas.map((area) => (
              <MenuItem key={area.id_areas} value={area.id_areas}>
                {area.area}
              </MenuItem>
            ))}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="success"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleCloseModalCreate()}
          >
            Cancelar
          </Button>
          <Button
            //   type="submit"
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              // backgroundColor: '#169073',
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleCreatePositions()}
          >
            Crear
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      <Dialog
        open={openModalEdit}
        onClose={() => handleCloseModalEdit()}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Editar Área"
          functionOnClose={() => handleCloseModalEdit()}
        />
        <DialogContent sx={{ paddingTop: "0" }}>
          <InputControlaVertical
            text={"Nombre"}
            inputType={"text"}
            inputValue={setPositionName}
            value={positionName}
            modalType={true}
            required
          />
          <SelectControla
            text="Areas:"
            inputValue={setPositionArea}
            modalType={true}
            value={positionArea}
            childrenRows={areas.map((area) => (
              <MenuItem key={area.id_areas} value={area.id_areas}>
                {area.area}
              </MenuItem>
            ))}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="success"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleCloseModalEdit()}
          >
            Cancelar
          </Button>
          <Button
            //   type="submit"
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              // backgroundColor: '#169073',
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleUpdatePositions()}
          >
            Editar
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

    </Box>
  );
}

export default Index;
