import { useState, useEffect } from 'react';
import {
    Alert,
    Backdrop,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Stack,
    Tab,
    Tabs,
    Snackbar,
} from '@mui/material';
import DialogTitleControla from "./dialogTitleControla";

function DialogInformativeControla({open, onClose , message}) {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitleControla
                titleText={"Aviso"}
                functionOnClose={onClose}
            />
            <DialogContent sx={{ paddingTop: "0" }}>
                <Typography
                    sx={{
                        fontSize: '18px',
                        color: "#1638F2",
                    }}
                >
                   {message}
                </Typography>

            </DialogContent>

        </Dialog>
    );

}

export default DialogInformativeControla;
