import { React, useState, useEffect } from "react";
import SubtitleText from '../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid, Stack } from '@mui/material';
import {ArrowBackOutlined, Edit } from "@mui/icons-material";

import TitleText from '../components/text/titleText';
import InputControlaVertical from '../components/textfields/inputControlaVertical';
import CompanyLogoControla from '../components/images/companyLogoControla';
import ButtonControla from "../components/buttons/buttonController";
import MyStandards from "../api/MyStandards";
import LoadingControla from "../components/load/loadingControla";
import { useNavigate } from "react-router-dom";

const colorTitle = "#9191B0";


function Model() {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const idStandard = params.get('id_standard');

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    //fin de alerts

    const [code, setCode] = useState(null);
    const [version, setVersion] = useState(null);
    const [title, setTitle] = useState(null);
    const [objective, setObjective] = useState(null);
    const [scope, setScope] = useState(null);
    const [legalReferences, setLegalReferences] = useState(null);
    const [specifications, setSpecifications] = useState(null);
    const [responsibles, setResponsibles] = useState(null);
    const [registers, setRegisters] = useState(null);
    const [review, setReview] = useState(null);

    const getStandardById = async () => {
        handleOpenLoading();

        const resp = await MyStandards.getStandardById(idStandard);

        setCode(resp.data.code);
        setVersion(resp.data.version);
        setTitle(resp.data.document_title);
        setObjective(resp.data.objective);
        setScope(resp.data.scope);
        setLegalReferences(resp.data.legal_references_and_other_standards);
        setSpecifications(resp.data.responsible_persons);
        setRegisters(resp.data.records_controls_and_documentation);
        setReview(resp.data.review_and_continuous_improvement);

        setOpenLoading(false);
    }

    const handlePostModelActiveStandard = async () => {
        handleOpenLoading();
        const resp = await MyStandards.postActiveStandard(idStandard);

        if (resp.success == true) {
            const params = {
                readOnly: false
            }
            navigate(`/documentation/documents/standards/edit?id_standard=${resp.data.id_standards}`, { state: params });
        } else {
            handleOpenAlert("Ocurrió un error", "error");
        }
    }

    const handleUpdateModelStandard = async () => {
        handleOpenLoading();

        const data = {
            code: code,
            version: version,
            document_title: title,
            objective: objective,
            scope: scope,
            legal_references_and_other_standards: legalReferences,
            standard_specifications: specifications,
            responsible_persons: responsibles,
            records_controls_and_documentation: registers,
            review_and_continuous_improvement: review,
        }

        const resp = await MyStandards.updateStandardById(data, idStandard);

        if (resp.success == true) {
            await getStandardById();
            handleOpenAlert("Se actualizó el modelo con éxito", "success");

        } else {
            handleOpenAlert("Ocurrió un error al actualizar", "error");
        }

    }


    useEffect(() => {
        getStandardById();
    }, [])

    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <Container>
                <Box >
                    <Grid container columnSpacing={2} rowSpacing={2} alignItems="center">
                        <Grid item xs={11} sm={8} md={9} lg={10} xl={10}>
                            <SubtitleText text={"ESTÁNDARES"} color={colorTitle}></SubtitleText>
                            <TitleText text={"Editar o usar modelo de estándar"} />

                        </Grid>
                        <Grid item xs={1} sm={4} md={3} lg={2} xl={2} sx={{ textAlign: 'right' }}>
                            <CompanyLogoControla />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputControlaVertical
                                text={"Código"}
                                inputType={"text"}
                                inputValue={setCode}
                                value={code}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputControlaVertical
                                text={"Versión"}
                                inputType={"text"}
                                inputValue={setVersion}
                                value={version}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputControlaVertical
                                text={"Título documento"}
                                inputType={"text"}
                                inputValue={setTitle}
                                value={title}
                                modalType={false}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                text={"Objetivo"}
                                inputType={"textArea"}
                                inputValue={setObjective}
                                value={objective}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                text={"Alcance"}
                                inputType={"textArea"}
                                inputValue={setScope}
                                value={scope}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                text={"Referencias legales y otras normas"}
                                inputType={"textArea"}
                                inputValue={setLegalReferences}
                                value={legalReferences}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                text={"Especificaciones del estándar"}
                                inputType={"textArea"}
                                inputValue={setSpecifications}
                                value={specifications}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                text={"Responsables"}
                                inputType={"textArea"}
                                inputValue={setResponsibles}
                                value={responsibles}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                text={"Registros, controles y documentación"}
                                inputType={"textArea"}
                                inputValue={setRegisters}
                                value={registers}
                                modalType={false}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <InputControlaVertical
                                text={"Revisión y mejora continua"}
                                inputType={"textArea"}
                                inputValue={setReview}
                                value={review}
                                modalType={false}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                                <Box>
                                    <ButtonControla
                                        iconButton={<ArrowBackOutlined sx={{ color: "white" }} />}
                                        backgroundColor={"#CBCBFF"}
                                        textButton={"Regresar"}
                                        backgroundColorHover={"#CBCBFF"}
                                        typeButton={"button"}
                                        isNavegationOrigin={true}
                                        url={"/documentation/documents/standards?idTabs=0"}
                                    />
                                </Box>
                                <Box>
                                    <ButtonControla
                                        iconButton={<Edit sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Actualizar modelo"}
                                        typeButton={"button"}
                                        functionOnClick={handleUpdateModelStandard}
                                    />
                                </Box>
                                <Box>
                                    <ButtonControla
                                        backgroundColor={"#F2994A"}
                                        backgroundColorHover={"#FF881E"}
                                        textButton={"Usar modelo"}
                                        typeButton={"button"}
                                        functionOnClick={handlePostModelActiveStandard}
                                    />
                                </Box>

                            </Stack>

                        </Grid>
                    </Grid>
                </Box>
            </Container >

        </Box >
    );
}

export default Model;

