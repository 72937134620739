import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
    Alert,
    Container,
    Backdrop,
    Grid,
    MenuItem,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    Snackbar,
    InputLabel,
    Table,
    Paper,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    FormControl,
    Radio,
    Select
} from "@mui/material";
import {
    Add,
    Edit,
    DeleteForever,
    BorderColorOutlined,
    Delete,
    LoginOutlined,
    HistoryEduOutlined,
} from "@mui/icons-material";

import { styled } from "@mui/system";
import Checkbox from '@mui/material/Checkbox';

import SideNav from "../../../../components/sidenav/sideNav";
import SubtitleText from "../../../../components/text/subtitleText";
import TitleText from "../../../../components/text/titleText";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import SelectControla from "../../../../components/selects/selectControla";
import MyProceedingsBook from "../../../../api/MyProceedingsBook";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";

import { useNavigate } from 'react-router-dom';
import MyUsers from "../../../../api/MyUsers";

const grey = "#9191B0";
const blue = "#034AFF";
const skyblue = "#2D9CDB";

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em",
});

function EditProceedings (){
    const navigate = useNavigate();

    const [responsibleSelected, setResponsibleSelected] = useState("");
    const [place, setPlace] = useState("");
    const [dateProceedings, setDateProceedings] = useState("");
    const [hourStart, setHourStart] = useState("");
    const [hourEnd, setHourEnd] = useState("");
    const [session, setSession] = useState("");
    const [modality, setModality] = useState("");
    const [quorum, setQuorum] = useState(0);

    const typeSession = [{ id_session: 1, nombre: "ordinario" }, { id_session: 2, nombre: "extraordinario" }];
    const typeModality = [{ id_modality: 1, nombre: "presencial" }, { id_modality: 2, nombre: "remoto" }];

    const params = new URLSearchParams(window.location.search);
    const id_proceeding = params.get("id_proceeding");

    const [participants , setParticipants] = useState([]);
    const [guests, setGuests] = useState([]);

    const [listThemesSelected, setListThemesSelected] = useState([]);

    const getParticipantsByProceeding = async() =>{
        const response = await MyProceedingsBook.getParticipantsByProceedings(id_proceeding);
        // agregar campo de firma
        const arrayParticipants = response.data.map((p)=>{
            return {...p, firm_url:null};
        });

       // cargar firma por cada miembro 
        const resUser = await MyUsers.users();
        const signatureParticipants = arrayParticipants.map((p)=>{
            resUser.users_actives.forEach((u)=>{
                if(u.id_users === p.id_users ){
                   p.firm_url = u.firm_url === null ? "No firma" : u.firm_url 
                }
            })
            return p;
        });

        setParticipants(signatureParticipants);

        const res  = await MyProceedingsBook.getGuestByProceeding(id_proceeding);
        setGuests(res.data);

        const resp  = await MyProceedingsBook.getThemesByProceedingId(id_proceeding);
        setListThemesSelected(resp.data);
    }
    const getProceeding = async()=>{
        const resp = await MyProceedingsBook.getProceedingById(id_proceeding);
        setResponsibleSelected(resp.data.responsible)
        setDateProceedings(resp.data.date);
        setHourStart(resp.data.start_time === null ? "" :resp.data.start_time.substr(0, 5));
        setHourEnd(resp.data.end_time === null ? "" : resp.data.end_time.substr(0, 5));
        setSession(resp.data.session);
        setModality(resp.data.modality);
        setQuorum(resp.data.quorum);
    }

    //listar responsables 
    const [responsibles , setResponsibles] = useState([]);
    const getListResponsibles = async () => {
        const response = await MyProceedingsBook.getParticipantsByProceedings(id_proceeding);
        const res = await MyUsers.getUsersByRole(7);

        const headline = response.data.filter((p)=> p.committee_position == 1); 

        setResponsibles(res.concat(headline));
        
    }


    useEffect(()=>{
        getParticipantsByProceeding();
        getProceeding();
        getListResponsibles();
    }, []);

    return(
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack="" />

            <Container sx={{ paddingBottom: 4 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SubtitleText
                            text={"Libro de Actas"}
                             color={grey}
                            ></SubtitleText>
                        </Grid>

                        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                            <TitleText
                                text={"Acta de reunión"}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>

                        <Grid item xs={6} md={6} lg={6} xl={6}>
                                <SelectControla
                                 text="Responsable:"
                                 inputValue={setResponsibleSelected}
                                 modalType={false}
                                 required
                                 value={responsibleSelected || ''}
                                 childrenRows={responsibles.map((rows) => (
                                    <MenuItem key={rows.id_users} value={rows.id_users}>
                                        {rows.name == undefined ? (rows.first_name + " " + rows.last_name): (rows.name + " " + rows. last_name ) }
                                    </MenuItem>
                                 ))}
                                 readOnly={true}
                                />

                        </Grid>
                        <Grid item xs={6} md={6} lg={6} xl={6}></Grid>

                        <Grid item xs={2} md={2} lg={2} xl={2}>
                            <InputControlaVertical
                                text={"Fecha"}
                                inputType={"date"}
                                inputValue={setDateProceedings}
                                modalType={false}
                                value={dateProceedings}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <InputControlaVertical
                                text={"Hora de Inicio"}
                                inputType={"time"}
                                inputValue={setHourStart}
                                modalType={false}
                                value={hourStart}
                                readOnly={true}
                                required
                            />
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <InputControlaVertical
                                text={"Hora de Fin"}
                                inputType={"time"}
                                inputValue={setHourEnd}
                                modalType={false}
                                value={hourEnd}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <SelectControla
                                text="Sesión:"
                                inputValue={setSession}
                                modalType={false}
                                required
                                value={session || ''}
                                childrenRows={typeSession.map((rows) => (
                                    <MenuItem key={rows.id_session} value={rows.id_session}>
                                        {rows.nombre}
                                    </MenuItem>
                                ))}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                            <SelectControla
                                text="Modalidad:"
                                inputValue={setModality}
                                modalType={false}
                                required
                                value={modality || ''}
                                childrenRows={typeModality.map((rows) => (
                                    <MenuItem key={rows.id_modality} value={rows.id_modality}>
                                        {rows.nombre}
                                    </MenuItem>
                                ))}
                                readOnly={true}
                            />
                        </Grid>
                        {
                            modality == "1"  ? ( 
                                <Grid item xs={8} md={8} lg={8} xl={8}>
                                    <InputControlaVertical
                                        text={"Lugar"}
                                        inputType={"text"}
                                        inputValue={setPlace}
                                        modalType={false}
                                        value={place}
                                        readOnly={true}
                                    />
                                </Grid>
                            ) : ''
                        }


                        <Grid item xs={2}  md={2} lg={2} xl={2}>
                            <InputControlaVertical
                                text={"Quorum"}
                                inputType={"text"}
                                inputValue={setQuorum}
                                modalType={false}
                                value={quorum}
                                readOnly={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Participantes
                            </StyledTypography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Participante
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Tipo de Miembro
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Tipo
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Cargo
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Asistencia
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Firma
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {participants.map((p, index) => (
                                            <TableRow
                                                hover
                                                key={index}
                                            >
                                                <TableCell align="center">
                                                    {p.name + " " + p.last_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {p.committee_position === 1 ? "Titular" : "Suplente"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {p.selection_type === 1 ? "Representante del Empleador" : "Representante de los trabajadores"}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {p.position_name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Checkbox
                                                        key={index}
                                                        checked={p.attended === 0 ? false : true}
                                                        // onChange={(event) => handleChangeAttendance(event, p.id_minutes, p.id_minutes_participant)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        color="success" />

                                                </TableCell>
                                                <TableCell align="center">
                                                    { p.signature === "1"? (
                                                        <img src={p.firm_url} alt="Firma" width={"100px"}></img>
                                                    )
                                                    : ("No firmado")
                                                    }
                                                    
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Invitados de la Reunión
                            </StyledTypography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Nombre
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Email
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {guests.map((guest)=>(
                                            <TableRow
                                                hover
                                            >
                                                <TableCell align="center">
                                                {guest.name}
                                                </TableCell>
                                                <TableCell align="center">
                                                {guest.email}
                                                </TableCell>
                                            </TableRow>
                                         ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <StyledTypography
                                sx={{
                                    padding: "8px",
                                    fontSize: "20px",
                                    color: "#305AD9",
                                    fontWeight: "bold",
                                }}
                            >
                                Temas a tratar
                            </StyledTypography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Descripción
                                            </TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                Acciones
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listThemesSelected.map((t,i)=>(
                                            
                                            <TableRow
                                                hover
                                                key={i}
                                            >
                                                <TableCell align="center">
                                                    {t.name}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Stack
                                                    direction="row"
                                                    justifyContent="end"
                                                    alignContent="center"
                                                    >
                                                        <ButtonIconControla
                                                            roles={[1, 7, 8]}
                                                            icon={<LoginOutlined sx={{ color: "white" }} />}
                                                            backgroundColor={"#24996A"}
                                                            backgroundColorHover={"#1AA483"}
                                                            textTooltip={"Ver documento"}
                                                            functionOnClick={() => { 
                                                                navigate(`/committee/proceedingsBook/addProceedingsBook/proceeedingsMeeting/informationTheme/viewInformationTheme?id_theme=${t.id_committee_minutes_theme}&id_proceeding=${id_proceeding}`);
                                                             }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Grid>
                </Box>

            </Container>

        </Box>
    );

}
export default EditProceedings;