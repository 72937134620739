// colocar lo de Workaccidents.jsx
import * as React from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";

import BannerControla from "../../../../components/cards/bannerControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";

import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import MyOccupationalDiseases from "../../../../api/MyOccupationalDiseases";
import { useAppContext } from "../../../../context/AppContext";
import ButtonMailboxControla from "../../../../components/buttons/buttonMailboxControla";
import { useNavigate } from "react-router-dom";

const colorTitle = "#9191B0";

function Index() {
  const { permissions } = useAppContext();
  const navigate = useNavigate();
  const [code, setCode] = React.useState("");

  const [rowsActives, setRowsActives] = React.useState([]);
  const [openModalUpload, setOpenModalUpload] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  /**** Message Alert */
  const [stateAlert, setStateAlert] = React.useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenModalUpload = () => {
    setCode(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };

  const handleGetOccupationalDiseases = async () => {
    const response = await MyOccupationalDiseases.occupationalDiseases();

    if (response.success === false) {
      handleClickAlert("error", "Error al guardar el documento!");
    } else {
      setRowsActives(response);
  
    }
    return response;
  };

  const handlePostCreateOccupationalsDiseases = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyOccupationalDiseases.createOccupationalsDiseases({
      code,
    });
    if (response.success === true) {
      handleCloseBackdrop();
      handleCloseModalUpload();
     
      navigate("/documentation/registers/generals/occupationaldiseases/edit?idRegistersOcupationalDiseases=" +
      response.id_registers_occupational_diseases);
    } else {
      handleClickAlert("error", "Error al guardar el documento!");
    }

    return response;
  };

  React.useEffect(() => {
    handleGetOccupationalDiseases();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
       <SideNav returnBack="" />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <ButtonMailboxControla
               originUrl={"/documentation/registers/generals/occupationaldiseases"}
              />
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannerworkaccidents.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Enfermedades ocupacionales"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                roles ={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_CREAR_REGIS}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Crear nuevo registro"}
                functionOnClick={handleOpenModalUpload}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid item md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          // align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "50%",
                          }}
                        >
                          Código
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "30%",
                          }}
                        >
                          Estado
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rowsActives.map((row) => (
                        <TableRow
                          key={row.id_registers_occupational_diseases}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "4px",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.code}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.statuses}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >{row.statuses === 'Creado' ? (
                              <ButtonIconControla
                                roles ={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_EDITAR}
                                icon={
                                  <BorderColorOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                isNavegationOrigin={true}
                                directionUrl={"/documentation/registers/generals/occupationaldiseases/edit?idRegistersOcupationalDiseases="+ row.id_registers_occupational_diseases}                     

                              />  ) : ''}   
                              {row.statuses === 'Finalizado' ? (                       

                              <ButtonIconControla
                                roles={permissions.DOC_REGIS_ENFERMEDADES_OCUPAC_VER}
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                isNavegationOrigin={true}
                                directionUrl={"/documentation/registers/generals/occupationaldiseases/edit?idRegistersOcupationalDiseases="+ row.id_registers_occupational_diseases}

                              /> ) : ''} 
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalUpload}
        onClose={handleCloseModalUpload}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar código de la enfermedad ocupacional
          </Typography>
        </DialogTitle>
        <form onSubmit={handlePostCreateOccupationalsDiseases}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <InputControlaVertical
              text={"Código"}
              inputType={"text"}
              inputValue={setCode}
              modalType={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUpload}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        {/* <CircularProgress color="inherit" /> */}
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Index;
