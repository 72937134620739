import  { React, useEffect,useState }from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from "@mui/material";
import {
  Add,
} from "@mui/icons-material";

import BannerControla from "../../../../components/cards/bannerControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import { useNavigate } from "react-router-dom";
import MyEvaluationSSO from "../../../../api/MyEvaluationSSO";
import TableControla from "../../../../components/tables/table-controla";
import ListEvaluations from "./components/list-evaluations";
import LoadingControla from "../../../../components/load/loadingControla";
import { useAppContext } from "../../../../context/AppContext";



const colorTitle = "#9191B0";

function Index() {
  const navigate = useNavigate();
  const {permissions} = useAppContext();

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  const [evaluations, setEvaluations] = useState([])

  const [code, setCode] = useState("");

  const handleGetEvalutionsMype = async () => {
    handleOpenLoading();
    try {
      const res = await MyEvaluationSSO.getEvaluationsSSO() ?? [];
      setEvaluations(res);
      setOpenLoading(false);
    } catch (e) {
      handleOpenAlert("Problema al traer lista de evaluaciones", "error");
    }
  }

  const [openModalCreateEvaluation, setOpenModalCreateEvaluation] = useState(false);

  const handleOpenModalCreateEvaluation = () => {
    setOpenModalCreateEvaluation(true);
  }

  const handleCloseModalCreateEvaluation = () => {
    setOpenModalCreateEvaluation(false);
  }

  const handlePostCreateEvaluations = async (e) => {
    e.preventDefault();
    const data = {
      nro_register: code
    }

    try {
      const res = await MyEvaluationSSO.postEvaluation(data);
      navigate(`/documentation/registers/mypes/evaluationSSO/edit?id_evaluation=${res.id}`);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    handleGetEvalutionsMype()
  }, [])
  return (

    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannermypegeneral.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Evaluación del sistema SSO"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                roles ={permissions.DOC_REGISMYPES_EVALUAC_SSO_CREAR}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Crear nuevo registro"}
                functionOnClick={() => {
                  handleOpenModalCreateEvaluation()
                }}

              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid item md={12}>
                <TableControla
                  loading={openLoading}
                  tabs={[
                    {
                      columnNames: ["Código", "Estado", "Acciones"]
                    }
                  ]}
                >
                  <ListEvaluations
                  evaluations={evaluations}
                  />

                </TableControla>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* modal crear evaluacion */}
      <Dialog
        open={openModalCreateEvaluation}
        onClose={handleCloseModalCreateEvaluation}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Agregar código de evaluación"
          functionOnClose={handleCloseModalCreateEvaluation}
        />

        <form onSubmit={handlePostCreateEvaluations}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <InputControlaVertical
              text={"Código"}
              inputType={"text"}
              inputValue={setCode}
              modalType={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <ButtonControla
                textButton={"Cancelar"}
                backgroundColor={"#CBCBFF"}
                functionOnClick={handleCloseModalCreateEvaluation}
              />

              <ButtonControla
                textButton={"Crear"}
                backgroundColor={"#389532"}
                typeButton={"submit"}
              />
            </Stack>

          </DialogActions>
        </form>
      </Dialog>

    </Box>
  );

}
export default Index;