import { React, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import SideNav from "../../../components/sidenav/sideNav";
import BannerControla from '../../../components/cards/bannerControla';
import SelectControla from "../../../components/selects/selectControla";
import Container from '@mui/material/Container';
import { 
    Grid,
    MenuItem
 } from '@mui/material';

import MyMinuteBookRecommendations from "../../../api/MyMinuteBookRecommendations";
import MyProceedingsBook from "../../../api/MyProceedingsBook";
import TableControla from "../../../components/tables/table-controla";
import LoadingControla from "../../../components/load/loadingControla";
import ListRecommendations from "./components/list-recommendations";
import ButtonMailboxControla from "../../../components/buttons/buttonMailboxControla";

const blue = "#034AFF";
const purple = "#8B8BD8";

function RecommendationsBook() {

     // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

    const [recommendations, setRecommendations] = useState([]);
    const [proceedingSelected, setProceedingSelected] = useState("");
    const [proceedings, setProceedings] = useState([]);

    const handleGetRecommendationsBook = async () => {
        handleOpenLoading();
        const resp = await MyMinuteBookRecommendations.getRecommendationsAll();
        setRecommendations(resp.data);

        setOpenLoading(false);
    }
    const handleGetProceedings = async()=>{
        const resp = await MyProceedingsBook.listProceedingsBook();
        const activeBook = resp.data.filter((book)=> book.active === 1)[0].id_minute_book;
        
        const response = await MyProceedingsBook.getMinutesByIdBook(activeBook);
  
        setProceedings(response.data);
    }

    const handleSelectProceeding = async(value)=>{

        handleOpenLoading();

        setProceedingSelected(value);

        const resp = await MyMinuteBookRecommendations.getRecommendationsByIdProceeding(value);
        if(resp.success === true){
            setRecommendations(resp.data);
            handleOpenAlert("Se listó las recomendaciones del acta buscada con éxito", "success");
        }else{
            console.log("No se encontraron recomendaciones para el acta seleccionada");
            setRecommendations([]);
            handleOpenAlert("No se encontraron recomendaciones para el acta seleccionada", "error")
        }

    }

    useEffect(() => {
        handleGetRecommendationsBook();
        handleGetProceedings();
    }, []);
    return (
        <Box sx={{ display: "flex" }}>
            <SideNav returnBack="" />

            <LoadingControla
             openLoading={openLoading}
             openAlert={openAlert}
             handleCloseAlert={handleCloseAlert}
             messageAlert={messageAlert}
             typeAlert={typeAlert}
            />

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={12} md={12} >
                            <ButtonMailboxControla
                                originUrl={"/committee/proceedingsBook/recommendationBook"}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image="/assets/images/banners/recommendationsBook/bannerLibroRecomendaciones.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Recomendaciones de libros de actas"
                            />
                        </Grid>

                        <Grid container direction={"row"} justifyContent={"center"} sx={{ mt: 2 }}>
                            <Grid item>
                                <SelectControla
                                    text="Búsqueda por actas:"
                                    inputValue={(e) => { handleSelectProceeding(e) }}
                                    modalType={true}
                                    required
                                    value={proceedingSelected || ''}
                                    childrenRows={proceedings.map((rows) => (
                                        <MenuItem key={rows.id_minutes} value={rows.id_minutes}>
                                            {rows.code}
                                        </MenuItem>
                                    ))}
                                />
                            </Grid>
                        </Grid>

                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <TableControla
                                    loading={openLoading}
                                    tabs={[{

                                        columnNames: ["Descripción", "Origen", "Responsable", "Fecha de inicio", "Fecha de fin", "Estado"]
                                    }]
                                    }
                                >

                                    <ListRecommendations
                                        recommendations={recommendations}
                                        colorCell={purple}
                                    />

                                </TableControla>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>

    );
}

export default RecommendationsBook ;
