import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import {
    Unarchive
  } from "@mui/icons-material";
  import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useAppContext } from "../../../context/AppContext";

function ListArchived(props) {
    const {permissions}  = useAppContext();

    const {
        totalRisst,
        colorCell,
        handleOpenModalConfirm
    } = props

    return (
        <TableBody >
            {totalRisst.map((row) => (
                <TableRow
                    hover
                    key={row.id}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                >
                    <TableCell sx={{ color: colorCell }}>
                        {row.title}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.document_text === null ||
                            row.document_text === undefined
                            ? "Archivo"
                            : row.document_url !== null
                                ? "Sistema"
                                : ""}{" "}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        {row.register_status === 1 ? "Creado" : ""}
                        {row.register_status === 7
                            ? "Enviado a trabajadores"
                            : ""}
                        {row.register_status === 3
                            ? "Enviado a comité"
                            : ""}
                        {row.register_status === 4 ? "Publicado" : ""}
                        {row.register_status === 5 ? "Archivado" : ""}
                    </TableCell>
                    <TableCell sx={{ color: colorCell }}>
                        <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                        >
                            {row.document_url !== undefined ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_RISST_ARCHIV_VER}
                                    icon={
                                        <VisibilityOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver"}
                                    directionUrl={row.document_url}
                                    directionTarget={"_blank"}
                                />
                            ) : (
                                <ButtonIconControla
                                    roles={permissions.DOC_RISST_ARCHIV_VER}
                                    icon={
                                        <VisibilityOutlined
                                            sx={{ color: "white" }}
                                        />
                                    }
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver"}
                                    directionUrl={
                                        process.env.REACT_APP_API_DJANGO + "report/" +
                                        row.id
                                    }

                                    directionTarget={"_blank"}
                                />
                            )}
                            {/* desarchivar tipo archivo */}
                            {row.document_text === null ||
                                row.document_text === undefined ? (
                                <ButtonIconControla
                                    roles={permissions.DOC_RISST_ARCHIV_DESARCHIVAR}
                                    icon={<Unarchive sx={{ color: "white" }} />}
                                    backgroundColor={"#FF5733"}
                                    backgroundColorHover={"#FF5733"}
                                    textTooltip={"Desarchivar"}
                                    functionOnClick={() =>
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de desarchivar el documento?",
                                            'Documento a desarchivar:  "' +
                                            row.title +
                                            '"',
                                            "Si, desarchivar",
                                            "unarchivedRisst"
                                        )
                                    }
                                />

                            ) : row.document_url !== null ? (
                                // desarchivar tipo sistema
                                <ButtonIconControla
                                    roles={[1, 7, 8]}
                                    icon={<Unarchive sx={{ color: "white" }} />}
                                    backgroundColor={"#FF5733"}
                                    backgroundColorHover={"#FF5733"}
                                    textTooltip={"Desarchivar"}
                                    functionOnClick={() =>
                                        handleOpenModalConfirm(
                                            row.id,
                                            "¿Estás seguro de desarchivar el documento?",
                                            'Documento a desarchivar:  "' +
                                            row.title +
                                            '"',
                                            "Si, desarchivar",
                                            "unarchivedTemplate"
                                        )
                                    }
                                />
                            ) : ''}
                        </Stack>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    )

}
export default ListArchived;