// colocar lo de Workaccidents.jsx
import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add,
  InfoOutlined,
} from "@mui/icons-material";

import SideNav from "../../../components/sidenav/sideNav";
import SubtitleText from "../../../components/text/subtitleText";
import BannerControla from "../../../components/cards/bannerControla";
import ButtonControla from "../../../components/buttons/buttonController";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import LoadingControla from "../../../components/load/loadingControla";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import styled from "styled-components";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import MySecuritySheetMiscellaneous from "../../../api/MySecuritySheetMiscellaneous";
import { useAppContext } from "../../../context/AppContext";
import { validationEditInputsByWorker } from "../../../utils/validation_edit";
import TableControla from "../../../components/tables/table-controla";
import ListActives from "../components/list-actives";
import ButtonMailboxControla from "../../../components/buttons/buttonMailboxControla";

const colorTitle = "#9191B0";
const skyblue = "#2D9CDB";
const purple = "#8B8BD8";

function Index() {
  const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: skyblue,
    borderRadius: 16,
  });

  const {permissions} = useAppContext();
  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  // alerts
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
  const [titleText, setTitleText] = React.useState("");
  const [contentText, setContentText] = React.useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState("");
  const [buttonActionType, setButtonActionType] = React.useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdSecuritySheet(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "delete":
        handleDeleteSecuritySheet();
        break;
        case "public":
          updateStatus(4);
          break;
        case "archived":
          updateStatus(5);
          break;
        case "unarchived":
          updateStatus(4);
          break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  const [openModalAddSecuritySheet, setOpenModalAddSecuritySheet] =
    React.useState(false);
  const handleOpenModalAddSecuritySheet = () => {
    setName("");
    setOpenModalAddSecuritySheet(true);
  };

  const [securitySheet, setSecuritySheet] = React.useState([]);
  const [name, setName] = React.useState("");
  const [file, setFile] = React.useState("");

  const handleAddSecuritySheet = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response =
      await MySecuritySheetMiscellaneous.createSecuritySheet({
        name,
        file,
      });
    handleGetSecuritySheet();
    setOpenModalAddSecuritySheet(false);
    handleOpenAlert("Se crearon los datos de forma correcta", "success");
    return response;
  };

  const handleGetSecuritySheet = async () => {
    handleOpenLoading();

    const response =
      await MySecuritySheetMiscellaneous.getSecuritySheet();
    setSecuritySheet(response);

    setOpenLoading(false);

    return response;
  };

  //   // eliminar
  const [idSecuritySheet, setIdSecuritySheet] = React.useState();

  const handleDeleteSecuritySheet = async () => {
    handleOpenLoading();

    const response =
      await MySecuritySheetMiscellaneous.deleteSecuritySheet({
        idSecuritySheet: idSecuritySheet,
      });
    if (response.success === true) {
      handleGetSecuritySheet();
      handleOpenAlert(
        "Los datos fueron eliminados de forma correcta",
        "success"
      );
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  //   infromacion del mapa de riesgos
  const StyledModalTitle = styled("h2")({
    color: purple,
  });
  const StyledModalSubtitle = styled("h3")({
    fontWeight: "bold",
  });

  const [openModalInfo, setOpenModalInfo] = React.useState(false);

  const handleOpenModalInfo = () => {
    setOpenModalInfo(true);
  };

  // document type
  const [documentType, setDocumentType] = React.useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  // actualizar estado
  const updateStatus = async (idStatus) => {
    handleOpenLoading();
    const response = await MySecuritySheetMiscellaneous.updatestatus({
      idSecuritySheet: idSecuritySheet,
      register_status: idStatus,
    });
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleGetSecuritySheet();
      handleOpenAlert("La operación se realizó con éxito", "success");
    }
    return response;
  };

  const isworker  = validationEditInputsByWorker();

  React.useEffect(() => {
    handleGetSecuritySheet();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Grid item xs={5} md={5}>
              <ButtonMailboxControla
                originUrl={"/documentation/securitysheet/miscelaneous"}
              />
            </Grid>

            <Grid item xd={7} md={7} style={{ textAlign: "right" }}>
              <StyledAddButton
                variant="text"
                startIcon={<InfoOutlined />}
                disableElevation
                size="large"
                style={{
                  backgroundColor: "#305AD9",
                  borderRadius: "15px",
                  color: "white",
                  padding: "8px 25px",
                }}
                onClick={() => handleOpenModalInfo()}
              >
                ¿Qué sólidos inflamables comprenden?{" "}
              </StyledAddButton>
            </Grid>

          </Grid>
          <Grid container>
            <Grid item xs={2} md={2.5}>
              <SubtitleText
                text={"Hojas de seguridad"}
                color={colorTitle}
              ></SubtitleText>
            </Grid>

            <Grid item xs={12} md={12}>
              <BannerControla
                image="/assets/images/banners/documents/bannermiscelaneous.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="MSDS - Clase 9 - Misceláneos"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                roles={permissions.DOC_HOJAS_SEGURIDAD_AGREGAR_MSDS}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar nuevo MSDS"}
                functionOnClick={handleOpenModalAddSecuritySheet}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid item md={12}>
                <TableControla
                  loading={openLoading}
                  tabs={[
                    {
                      label: "Activos",
                      columnNames: ["Nombre del documento", "Estado"]
                    }
                  ]}
                >
                  <ListActives
                    securitySheet={securitySheet}
                    isworker={isworker}
                    handleOpenModalConfirm={handleOpenModalConfirm}
                  />

                </TableControla>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalAddSecuritySheet}
        onClose={() => setOpenModalAddSecuritySheet(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Nuevo MSDS - Misceláneos{" "}
          </Typography>
        </DialogTitle>
        <form onSubmit={handleAddSecuritySheet}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre:"}
                  inputType={"text"}
                  inputValue={setName}
                  value={name}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Archivo"}
                  inputType={"file"}
                  inputValue={setFile}
                  value={file}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalAddSecuritySheet(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de información */}
      <Dialog
        open={openModalInfo}
        onClose={() => setOpenModalInfo(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{
          padding: "20px",
        }}
      >
        <DialogTitleControla titleText="MSDS - Clase 9 - Misceláneos" />
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              <StyledModalTitle>Clase 9 - Misceláneos </StyledModalTitle>
            </Grid>

            <Grid item>
              <ol>
                <li>
                  Cargas peligrosas que están reguladas en su transporte pero no
                  pueden ser incluidas en ninguna de las clases antes
                  mencionadas.
                </li>
                <li>Sustancias peligrosas para el medioambiente.</li>
                <li>Residuo peligroso.</li>
              </ol>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                Simbología
              </StyledModalSubtitle>
              <img src="/assets/images/info/Clase 9.jpeg" alt="simbologia" />

            </Grid>
            <Grid item>
              Fuente: Libro Naranja de las Naciones Unidas; ICS (International Classification System).
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalInfo(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Index;
