import React, { Component, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MyConfigurationApi from "../../api/MyConfigurationApi";

export const EditorAnnualPlanControla = ({
  dataEdit,
  handleDataEditor = alert,
  disabled = false
}) => {
  const company = MyConfigurationApi.companyData().name;
  console.log(company);

  const template = `
  <h2>Plan Anual de SSO</h2>
  <h3>I. Introducción</h3>
  <p> ${company} es una empresa dedicada a la _________________ y en cumplimiento del artículo 38° de la Ley N°
      29783, Ley de Seguridad y Salud en el Trabajo, su reglamento y normas sectoriales elabora el presente Plan Anual de
      Seguridad y Salud Ocupacional del año _____, en base a los resultados obtenidos de la evaluación inicial o estudio
      de línea base del Sistema de Gestión de Seguridad y Salud Ocupacional y su mejora continua.</p>
  <p>Considerando que en ${company} el trabajador es el elemento más importante dentro de la organización, los
      objetivos del presente plan están orientados a alcanzar la protección de la vida y la salud de todos los
      trabajadores de la organización, así como el compromiso de la misma de generar y mantener ambientes de trabajo
      seguros y saludables de manera permanente.</p>
  <h3>II. Alcance</h3>
  <p>El presente Plan Anual de Seguridad y Salud Ocupacional es de aplicación para todos los trabajadores de NOMBRE DE LA
      EMPRESA, así como a todos sus contratistas y visitantes.</p>
  <h3>III. Requisitos legales y otros</h3>
  <p>A fin de elaborar el presente plan, la empresa ha realizado la identificación del grado de cumplimiento de la
      normativa legal general y sectorial que tiene que cumplir así como otros requisitos que la organización elige
      cumplir, los cuales deben ser actualizados y difundidos oportunamente para su cumplimiento. Cabe señalar que la
      empresa debe mantener y conservar información documentada sobre los requisitos legales y otros requisitos en materia
      de seguridad y salud ocupacional, como parte de la planificación de este sistema y el compromiso del empleador.</p>
  <a href="/documentation/legalmatrix">VER MATRIZ</a>
  <h3>IV. Política de Seguridad y Salud Ocupacional</h3>
  <p>La alta dirección define y aprueba mediante su firma la POLITICA DE SEGURIDAD Y SALUD /POLITICA INTEGRADA CON
      MEDIO AMBIENTE asegurando que esta sea adecuada para las actividades de la empresa y esté disponible
      para todos los trabajadores, así como para contratistas y terceros.</p>
  <h3>V. Objetivos y metas</h3>
  <p>Teniendo en cuenta los resultados de la evaluación de riesgo, requisitos legales y otros requisitos identificados, a
      continuación, se establecen los objetivos anuales, los cuales son consistentes con la innovación tecnológica de ser
      el caso, recursos financieros, operacionales y de negocios; considerando la mejora continua del sistema</p>
  <p>Los objetivos definidos líneas abajo, cumplen con la metodología SMART:</p>
  <table>
      <tr>
          <th colspan="2">Metodología Smart</th>
      </tr>
      <tr>
          <td>S</td>
          <td>Específico: Claro y preciso</td>
      </tr>
      <tr>
          <td>M</td>
          <td>Medible: Susceptible a ser medido</td>
      </tr>
      <tr>
          <td>A</td>
          <td>Alcanzable: Desafieante pero realista</td>
      </tr>
      <tr>
          <td>R</td>
          <td>Relevante: Motivador e importante</td>
      </tr>
      <tr>
          <td>T</td>
          <td>Tiempo definido: Con un límite de tiempo señalado</td>
      </tr>
  </table><a href="/documentation/programs/sso">Ir al Programa Anual de Seguridad y Salud Ocupacional</a>
  <h3>VI. Comité de Seguridad y Salud Ocupacional o Supervisor y Reglamento Interno de Seguridad y Salud(RISST)</h3>
  <p>El Comité Paritario de Seguridad y Salud Ocupacional, en ${company} es un órgano bipartito y
      paritario constituido por representantes del empleador y de los trabajadores, en base a las obligaciones y
      facultades previstas en la legislación, está destinado a la consulta regular y periódica de las actuaciones del
      empleador en materia de prevención de riesgos en seguridad y salud ocupacional.</p>
  <p>Entre sus principales funciones se encuentran:</p>
  <ul>
      <li>Aprobar y vigilar el reglamento interno de seguridad y salud ocupacional.</li>
      <li>Aprobar y vigilar el plan anual de salud y seguridad ocupacional.</li>
      <li>Conocer, aprobar y dar seguimiento al programa anual de seguridad y salud ocupacional, programa anual del
          servicio de seguridad y salud y programa anual de capacitaciones en seguridad y salud ocupacional, entre otros
          programas con los que la empresa pueda contar.</li>
      <li>Programar y realizar las reuniones mensuales ordinarias del comité de seguridad y salud ocupacional para
          analizar y evaluar el avance de los objetivos establecidos en el programa anual y los resultados del mes
          anterior. En forma extraordinaria el comité se reunirá para analizar accidentes que revistan gravedad o cuando
          las circunstancias lo exijan.</li>
      <li>Llevar el libro de actas de todas sus reuniones, así como el control del cumplimiento de los acuerdos
          registrados.</li>
      <li>Revisar y analizar mensualmente las estadísticas de los incidentes, incidentes peligrosos, accidentes de trabajo
          y enfermedades ocupacionales ocurridas en el lugar de trabajo.</li>
      <li>Realizar inspecciones periódicas del lugar de trabajo, así como de sus instalaciones, materiales y equipos.</li>
      <li>Investigar las causas de todos los incidentes, accidentes y enfermedades ocupacionales que ocurran en el lugar
          de trabajo.</li>
      <li>Otras funciones que la normativa nacional y sectorial establezcan.</li>
  </ul>
  <p>El comité realiza sus funciones en coordinación con el servicio de seguridad y salud.</p>
  <p>La ${company} cuenta con un reglamento interno de seguridad y salud ocupacional, el cual es puesto
      en conocimiento de todos los trabajadores a través de medios físicos y/o digitales bajo cargo. Asimismo, el
      reglamento interno de seguridad y salud ocupacional es remitido o entregado a los trabajadores en régimen de
      intermediación y tercerización, personas en modalidad formativa y todo aquél que preste servicios subordinados o
      autónomos de manera permanente o esporádica en las instalaciones de la empresa.</p>
  <h3>Subcomité de Seguridad y Salud Ocupacional:</h3>
  <p>El subcomité es un órgano bipartito y paritario que tiene como funciones principales:</p>
  <ul>
      <li>Emitir sugerencias y recomendaciones al comité central de seguridad y salud ocupacional, para que sean
          consideradas en la aprobación de documentos del sistema de gestión como el reglamento interno de seguridad y
          salud ocupacional, programa anual de seguridad y salud ocupacional, programa anual de capacitación y programa
          anual del servicio de seguridad y salud en el trabajo.</li>
      <li>Coordinar permanentemente con el comité de seguridad y salud ocupacional, respetando los acuerdos que éste
          adopte.</li>
      <li>Otras funciones señaladas en la legislación de seguridad y salud ocupacional.</li>
  </ul>
  <h3>VII. Supervisor de Seguridad y Salud ocupacional</h3>
  <p>El supervisor de seguridad y salud ocupacional es elegido dentro de los trabajadores de la empresa, y tiene como
      finalidad la prevención y protección de la seguridad y salud de los trabajadores. Entre sus principales funciones se
      encuentran:</p>
  <ul>
      <li>Aprobar y vigilar el plan anual de salud y seguridad ocupacional.</li>
      <li>Conocer, aprobar y dar seguimiento al programa anual de seguridad y salud ocupacional, programa anual del
          servicio de seguridad y salud y programa anual de capacitaciones en seguridad y salud ocupacional, entre otros
          programas con los que la empresa pueda contar.</li>
      <li>Llevar un registro de los acuerdos adoptados con la máxima autoridad de la empresa.</li>
      <li>Revisar y analizar mensualmente las estadísticas de los incidentes, incidentes peligrosos, accidentes de trabajo
          y enfermedades ocupacionales ocurridas en el lugar de trabajo.</li>
      <li>Realizar inspecciones periódicas del lugar de trabajo, así como de sus instalaciones, materiales y equipos.</li>
      <li>Investigar las causas de todos los incidentes, accidentes y enfermedades ocupacionales que ocurran en el lugar
          de trabajo.</li>
      <li>Otras funciones que la normativa nacional y sectorial establezcan.</li>
  </ul>
  <p>De ser el caso, el supervisor realiza sus funciones en coordinación con el servicio de seguridad y salud.</p>
  <h3>VIII. Identificación de peligros y evaluación de riesgos y mapa de riesgos</h3>
  <p>La identificación de peligros y evaluación de riesgos y medidas de control (IPERC) es el elemento que permite estimar
      la criticidad de las actividades de la empresa con el objetivo de reducir el nivel de riesgo a través de la
      implementación de acciones de control. La IPERC tiene las siguientes características:</p>
  <ul>
      <li>Es elaborada y actualizada anualmente.</li>
      <li>Se realiza en cada puesto de trabajo, con la participación del personal competente, en consulta con los
          trabajadores y los representantes del comité / subcomité o supervisor de seguridad y salud ocupacional.</li>
      <li>Contempla las actividades rutinarias y no rutinarias del puesto de trabajo, así como situaciones de emergencia.
      </li>
      <li>Otros requisitos señalados en la normativa nacional y sectorial.</li>
  </ul>
  <p>El IPERC debe ser conocido por todos los trabajadores y publicado en las áreas de trabajo de acuerdo a los puestos de
      trabajo.</p><a href="/documentation/documents/procedures/iperc">Ver IPERC</a>
  <p>El mapa de riesgos es un documento del sistema de gestión que tiene como base el IPERC, mediante el cual se coloca en
      un plano de la empresa las señales de peligros, prohibiciones y uso de equipos de protección personal (EPP) para
      cada zona.</p>
  <p>El mapa de riesgos se elabora con la participación de todas las partes interesadas y se coloca en un lugar visible
      para todas las personas, es decir trabajadores, visitantes y terceros.</p>
  <h3>IX. Organización y responsabilidades</h3>
  <h3>IX.I. Gerencia General</h3>
  <p>Entre otras, sus principales responsabilidades son:</p>
  <ul>
      <li>Establecer la política de seguridad y salud ocupacional de la empresa.</li>
      <li>Liderar y hacer cumplimiento del contenido del programa anual en seguridad y salud ocupacional.</li>
      <li>Delegar autoridad y proporcionar recursos dentro de la empresa para garantizar el cumplimiento de los planes y
          programas preventivos establecidos.</li>
      <li>Garantizar que la seguridad y salud en el trabajo sea una responsabilidad conocida y aceptada en todos los
          niveles de la empresa.</li>
      <li>Analizar y verificar que el sistema de gestión en seguridad y salud ocupacional funcione.</li>
  </ul>
  <h3>IX.II Gerente de Seguridad y Salud/Supervisor del área de Seguridad y Salud/ Coordinador HSE</h3>
  <ul>
      <li>Planificar, organizar, ejecutar, dirigir y controlar el programa anual de Seguridad y Salud en el Trabajo.</li>
      <li>Realizar inspecciones de Seguridad y Salud en el Trabajo.</li>
      <li>Efectuar un acompañamiento permanente e intensivo, mediante el asesoramiento y capacitación al personal de
          ${company} en relación a los riesgos de seguridad y salud asociados a las actividades
          desarrolladas en las distintas áreas.</li>
  </ul>
  <h3>IX. III Trabajadores</h3>
  <ul>
      <li>Cumplir las disposiciones del Reglamento Interno de Seguridad y Salud en el Trabajo, normas y procedimientos,
          reglas básicas o cartillas de instrucción que se deriven.</li>
      <li>Realizar toda acción conducente a prevenir incidentes, accidentes y en caso de ocurrir informar a su jefe
          inmediato, miembro del grupo de apoyo y Supervisor o Comité de Seguridad y Salud en el Trabajo.</li>
      <li>Participar activamente en el mejoramiento continuo de las condiciones físicas de la edificación, condiciones de
          trabajo y bienestar del trabajador.</li>
      <li>Cumplir con los lineamientos establecidos asumiendo actitudes preventivas en todas las tareas que deban
          emprender, priorizando las actividades que protejan a las personas y los bienes de la empresa.</li>
  </ul>
  <h3>X. Capacitaciones en Seguridad y Salud Ocupacional</h3>
  <p>Los colaboradores recibirán formación y capacitación apropiada en temas de SSO, mediante inducciones, charlas,
      capacitaciones específicas, cursos teóricos y prácticos, para lo cual se establece un programa anual de capacitación
      que será ejecutado por el personal calificado en la materia. Toda actividad de este componente es registrada y
      controlada y se verificará la comprensión, entendimiento y registro de la eficacia de las capacitaciones dadas.</p>
  <p>Los objetivos de la capacitación en materia de seguridad y salud ocupacional son:</p>
  <ul>
      <li>Concientizar sobre la importancia del cumplimiento de la política y de los procedimientos de seguridad y salud
          ocupacional.</li>
      <li>Brindar los elementos del Sistema de Gestión de SSO, así como los roles y responsabilidades de los jefes y los
          colaboradores cumpliendo con la normativa vigente.</li>
      <li>Brindar las herramientas adecuadas para que los colaboradores sean capaces de identificar peligros, evaluar
          riesgos y tomar medidas de prevención en el desempeño de sus labores.</li>
  </ul>
  <p>A continuación se detallan algunas de las capacitaciones que se llevarán a cabo:</p>
  <h3>X.I. Inducción general de personal nuevo</h3>
  <p>Esta inducción se desarrolla para todos los trabajadores nuevos antes de iniciar sus labores y comprende el
      desarrollo de una orientación general acerca de las políticas de la empresa, procedimientos de seguridad y salud
      ocupacional y las obligaciones legales que debe conocer el trabajador para el cumplimiento de sus funciones. El
      personal debe estar informado de la importancia que tienen la Seguridad y Salud Ocupacional en la empresa.</p>
  <h3>X.II. Inducción especíica en el área de trabajo</h3>
  <p>Esta inducción consiste en la capacitación en la labor de los trabajadores cuando:</p>
  <ol type="a">
      <li>Son nuevos (luego de haber recibido la inducción general).</li>
      <li>Son transferidos o trasladados a otra área y/o puesto de trabajo.</li>
      <li>Se reintegran luego de un descanso médico prolongado y/o de vacaciones.</li>
  </ol>
  <h3>X.III Charlas preventivas de cinco minutos - Reuniones de sensibilización diarias</h3>
  <p>Las charlas preventivas de cinco minutos y/o de sensibilización diarias son coordinadas por los supervisores/jefe del
      área antes del inicio de la jornada laboral. El desarrollo de estas charlas / sensibilizaciones son requisito para
      el inicio de actividades diarias.</p>
  <p>Tienen como objetivo dar a conocer procedimientos de trabajo, importancia del uso de equipos de protección personal,
      normas de seguridad, entre otros, a fin de reforzar el comportamiento proactivo del personal, ante los peligros
      asociados al trabajo que realizan.</p><a href="/documentation/programs">Ver Programas de capacitación</a>
  <h3>XI. Procedimientos</h3>
  <p>De esta manera se implementarán los controles y procedimientos operacionales necesarios y de acuerdo al IPERC de la
      empresa para asegurar que las actividades de trabajo se desempeñen con seguridad, protegiendo la salud de los
      colaboradores y se cumpla con la normativa legal vigente. Así, los procedimientos a desarrollar y/o actualizar, se
      establecen de acuerdo a las actividades de la empresa.</p>
  <p>Cabe señalar que los procedimientos precisan de la participación directa del personal involucrado en la actividad
      para la cual se generará.</p>
  <p>Finalmente, estos procedimientos de trabajo deberán ser difundidos a todo el personal involucrado, dando seguimiento
      a su cumplimiento.</p><a href="/documentation/documents/procedures">Ver procedimientos</a>
  <h3>VII. Inspecciones Internas de Seguridad y Salud Ocupacional</h3>
  <p>Las inspecciones internas tienen la finalidad de identificar las deficiencias en el desarrollo de los trabajos, que
      comprende, los equipos, materiales, instalaciones, entre otros aspectos para determinar e implementar a tiempo las
      medidas correctivas y reducir de esta manera la ocurrencia de accidentes. Entre otros aspectos, los objetivos
      específicos del desarrollo de las inspecciones de seguridad es de identificar:</p>
  <ul>
      <li>Condiciones sub estándares.</li>
      <li>Condiciones de riesgo que no se consideraron en la etapa de diseño y construcción de la infraestructura.</li>
      <li>Deficiencias de los equipos</li>
      <li>Acciones inapropiadas de los trabajadores.</li>
  </ul><a href="/documentation/programs">VER OTROS PROGRAMAS DE SSO</a>
  <h3>XIII. Salud Ocupacional</h3>
  <h3>XIII.I. Gestión de la salud ocupacional</h3>
  <p>Nuestra gestión de salud ocupacional promueve y mantiene el mayor grado de bienestar físico, mental y social de
      nuestros colaboradores en sus ocupaciones, con el fin de prevenir todo daño a la salud causado por las condiciones
      de trabajo y por los factores de riesgo, teniendo presente la adecuación del trabajo al colaborador según sus
      aptitudes y capacidades.</p>
  <p>Asimismo, la gestión de la salud ocupacional dentro de ${company} incluye el reporte de los accidentes,
      incidentes peligrosos y enfermedades ocupacionales a las autoridades competentes, así como las estadísticas de esta
      gestión, con el objetivo de coadyuvar con la información necesaria para la gestión de la salud pública.</p>
  <p>La gestión de la salud ocupacional en nuestra empresa implica:</p>
  <ol type="a">
      <li>La evaluación y reconocimiento de la salud de los trabajadores con relación a su exposición a factores de riesgo
          de origen ocupacional, incluyendo el conocimiento de los niveles de exposición y emisión de las fuentes de
          riesgo.</li>
      <li>La incorporación de prácticas y procedimientos seguros y saludables a todo nivel de la operación.</li>
      <li>El registro de enfermedades ocurridas por exposición ocupacional, descansos médicos, ausentismo por
          enfermedades, planes de acción y evaluación estadística de los resultados.</li>
      <li>El asesoramiento técnico y participación en materia de control de salud del trabajador a profesionales de la
          salud, de enfermedad ocupacional, primeros auxilios, atención de urgencias y emergencias médicas por accidentes
          de trabajo y enfermedad ocupacional y Equipos de Protección Personal (EPP).</li>
      <li>La participación del Comité / Subcomité / Supervisor de SSO respecto a los aspectos de salud ocupacional.</li>
      <li>La promoción de la participación de los trabajadores en el desarrollo e implementación de actividades de salud
          ocupacional.</li>
  </ol>
  <h3>XIII.II Vigilancia de la Salud Ocupacional</h3>
  <p>La vigilancia de la Salud Ocupacional es el conjunto de acciones que permite a nuestra empresa:</p>
  <ol type="a">
      <li>El control y conocimiento de los problemas de salud de nuestro colaboradores.</li>
      <li>La detección de los factores determinantes de la salud.</li>
      <li>La implementación de medidas correctivas y de mejora con relación a la salud.</li>
  </ol>
  <p>Para el mejor cumplimiento de estas acciones, es necesario la realización de los exámenes médicos ocupacionales (EMO)
      de acuerdo a lo establecido por la legislación vigente y según las necesidades adicionales señaladas o requeridas
      por el servicio de salud de acuerdo a las actividades específicas de cada trabajador.</p>
  <p>Es importante señalar que:</p>
  <ol type="a">
      <li>Los resultados de los EMO respetan la confidencialidad de la información del trabajador, usándose la
          terminología referida a aptitud, a excepción que lo autorice el trabajador o la autoridad competente. <a
              href="/documentation/registers/generals/competencycertificate">Ver Certificados de Aptitud</a>
      </li>
      <li>De acuerdo a la normativa vigente, los resultados de los EMO son archivados por nuestra empresa hasta cinco (05)
          años después de finalizar el vínculo laboral con el trabajador (archivo activo). Posteriormente, los EMO son
          guardados en un archivo pasivo hasta veinte (20) años.</li>
      <li>Los EMO son: (i) de ingreso; (ii) periódico y; (iii) de retiro, que se realizan de acuerdo a la normativa
          vigente.</li>
  </ol>
  <h3>XIV. Clientes, empresas contratistas y proveedores</h3>
  <p>De acuerdo al Principio de Prevención señalado en la Ley N° 29783, nuestra empresa garantiza el establecimiento de la
      evaluación y la verificación de los requisitos necesarios para el ingreso y estadía de nuestros clientes,
      trabajadores de empresas contratistas y proveedores.</p>
  <p>Asimismo, bajo los procedimientos y lineamientos importantes en nuestro Sistema de Gestión, la empresa asegura que
      clientes, trabajadores de empresas contratistas y proveedores cumplan con las disposiciones de seguridad y salud
      ocupacional establecidas.</p>
  <h3>XV. Plan de Contingencia</h3>
  <p>El Plan de Contingencia es un instrumento de gestión que define los objetivos, estrategias y programas que dirigen
      las actividades de la empresa para la prevención, reducción de riesgos, atención de emergencias y rehabilitación
      ante la eventualidad de incidentes, accidentes o estados de emergencia ocasionados por la naturaleza o por las
      acciones antropogénicas que pudieran ocurrir dentro de las instalaciones de la empresa o fuera de ellas.</p>
  <p>Objetivo: Preparar al personal para que pueda enfrentar una potencial emergencia, previsible dentro de su entorno, a
      fin de reducir al mínimo cualquier impacto adverso en la seguridad o salud de las personas.</p>
  <p>Descripción: Todo el personal debe estar adecuadamente preparado y tener los recursos necesarios para responder ante
      una potencial situación de emergencia de acuerdo al plan de contingencia.</p>
  <p>La empresa asegura que su personal, visitantes y/o empresas contratistas sean capacitados para lograr una respuesta
      adecuada.</p><a>VER PLAN DE CONTINGENCIA</a>
  <h3>XVI. Investigación de accidentes, incidentes y enfermedades ocupacionales</h3>
  <p>La investigación de los accidentes e incidentes que ocurran con ocasión del trabajo permite:</p>
  <ol type="a">
      <li>Dar a conocer a la gerencia, la información necesaria sobre los accidentes, los incidentes y las enfermedades
          ocupacionales.</li>
      <li>Proponer acciones correctivas así como la implementación de mejoras que eviten la repetición de los accidentes.
      </li>
  </ol>
  <p>Para realizar la investigación se seguirá el procedimiento de investigación de accidentes e incidentes de
      ocupacionales, que contempla las acciones de reporte e investigación y los formatos respectivos.</p>
  <p>La empresa debe contar con un registro de accidentes e incidentes y enfermedades ocupacionales a fin de generar la
      estadística necesaria para realizar la medición del Sistema de Gestión de SSO.</p>
  <h3>XVII. Auditorías</h3>
  <p>La auditoría es un proceso sistemático, independiente y documentado para evaluar periódicamente el desempeño del
      Sistema de Gestión de Seguridad y Salud Ocupacional de la empresa de acuerdo a la normativa vigente, y concluir si
      el sistema es conforme con los objetivos y la Política de Seguridad y Salud Ocupacional de (${company}) y
      si se mantiene eficazmente.</p>
  <p>Las auditorías se realizan por un auditor externo registrado ante el Ministerio de Trabajo y Promoción del Empleo
      (MTPE) de acuerdo a la temporalidad establecida por la normativa vigente.</p>
  <p>Cabe señalar que para la elección del auditor, es necesaria la consulta y participación de los trabajadores a través
      de la publicación de la lista de dos candidatos como mínimo, a fin de que los mismos opinen al respecto.</p>
  <h3>XVIII. Estadísticas</h3>
  <p>Las estadísticas brindan información de rápida observación que permite a la empresa realizar un análisis del Sistema
      de Gestión de SSO, al establecer indicadores que evalúan la accidentabilidad y gestión de la seguridad y salud
      ocupacional. Estos datos son almacenados en el registro correspondiente.</p><a
      href="/documentation/registers">REGISTRO DE LA EMPRESA/PYMES</a>
  <p>Con esta información la empresa elabora los reportes mensuales de estadística, los cuales deben ser enviados a la
      Gerencia General.</p>
  <h3>XIX. Implementación del Plan</h3>
  <p>La etapa de implementación consiste en poner en funcionamiento el Plan Anual de Gestión de Seguridad y Salud
      Ocupacional. Para tal fin, se proporcionan los recursos humanos, materiales, tecnológicos y financieros necesarios.
  </p>
  <p>Además se tiene definido al responsable de facilitar la efectividad del sistema de gestión de SSO.</p>
  <h3>XX. Mantenimiento de registros</h3>
  <p>Es obligación de la empresa establecer y mantener registros formales en medios físicos y electrónicos con el nivel de
      detalle suficiente para describir los elementos del Sistema de Gestión, así como sus interrelaciones.</p>
  <ul>
      <li>El registro de enfermedades ocupacionales debe conservarse por un período de veinte (20) años; los registros de
          accidentes de trabajo e incidentes peligrosos por un periodo de diez (10) años posteriores al suceso; y los
          demás registros por un periodo de cinco (5) años posteriores al suceso.</li>
      <li>Para la exhibición a que hace referencia el artículo 88º de la Ley de Seguridad y Salud en el Trabajo (Registro
          de accidentes de trabajo, enfermedades ocupacionales e incidentes peligrosos), el empleador cuenta con un
          archivo activo donde figuran los eventos de los últimos doce (12) meses de ocurrido el suceso, luego de lo cual
          pasa a un archivo pasivo que se deberá conservar por los plazos señalados en el párrafo precedente.</li>
  </ul>
  <h3>XXI. Revisión del Sistema de Gestión de Seguridad y Salud Ocupacional por (${company})</h3>
  <p>La responsabilidad de la revisión del Sistema de Gestión de Seguridad y Salud Ocupacional recae sobre el empleador y
      se realiza por lo menos una vez al año para asegurar su conveniencia, adecuación y eficacia continua, determinando
      si fuera el caso oportunidades de mejoras o la necesidad de efectuar cambios.</p>
  <p>Se deben tener en cuenta los siguientes indicadores:</p>
  <ul>
      <li>Los resultados de las auditorías internas y evaluación de cumplimiento con los requisitos y otros requisitos que
          la organización tenga suscrito.legales</li>
      <li>Los resultados del proceso de consulta o participación.</li>
      <li>El desempeño del Sistema de Gestión de SSO.</li>
      <li>El grado de cumplimiento de los objetivos y metas.</li>
      <li>El estado de la investigación de incidentes, acciones correctivas y preventivas.</li>
      <li>El seguimiento de las acciones resultantes de las revisiones llevadas a cabo.</li>
      <li>Cambio de las circunstancias, incluyendo evolución de los requisitos legales y otros requisitos relacionados a
          Seguridad y Salud en el Trabajo</li>
      <li>Las recomendaciones para la mejora continua.</li>
  </ul>
  `;
  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        data={dataEdit === null ? template : dataEdit}
        disabled={disabled}
        onReady={(editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // You can store the "editor" and use when it is needed.
          // console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // console.log({ data });
        }}
        // onChange={(event, editor) => dataEditor(editor.getData())}

        onBlur={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
        onFocus={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
      />
    </div>
  );
};
