import {
    Backdrop,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import SideNav from "../../components/sidenav/sideNav";
import BannerControla from "../../components/cards/bannerControla";
import { Add, BorderColorOutlined, Delete, FilterAlt, VisibilityOutlined } from "@mui/icons-material";
import ButtonControla from "../../components/buttons/buttonController";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import MyAreas from "../../api/MyAreas";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import MyTrainings from "../../api/MyTrainings";
import SelectControla from "../../components/selects/selectControla";
import MyUsers from "../../api/MyUsers";
import MyCampus from "../../api/MyCampus";
import { TableTrainings } from "./components/table";
import FiltersPersonal from "./components/filters-personal";
import { useAppContext } from "../../context/AppContext";
import ButtonMailboxControla from "../../components/buttons/buttonMailboxControla";

//colors
const purple = "#8B8BD8";
const skyblue = "#2D9CDB";
const blue = "#034AFF";

function Index() {
    const {permissions} = useAppContext();
    const params = new URLSearchParams(window.location.search);

    //BackDrop
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };

    const [idSubject, setIdSubject] = useState("");
    const [name, setName] = useState("");


    //Modals
    const [openModalCreate, setOpenModalCreate] = useState(false);

    const handleOpenModalCreate = () => {
        setOpenModalCreate(true);
    };
    const handleCloseModalCreate = () => {
        setOpenModalCreate(false);
    };

    //Filters
    const [openModalFilters, setOpenModalFilters] = useState(false);

    const handleOpenModalFilters = () => {
        setOpenModalFilters(true);
    };
    const handleCloseModalFilters = () => {
        setOpenModalFilters(false);
    };

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    // Dialogos de confirmacion INICIO

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");

    const handleOpenModalConfirm = (
        id,
        titleText,
        contentText,
        buttonTextConfirm,
        buttonActionType
    ) => {
        setIdSubject(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    };

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            default:
                break;
        }
        setOpenModalConfirm(false);
    };

    const [subjectFilter, setSubjectFilter] = useState(params.get("id_trainings_subjects"));
    const [typeFilter, setTypeFilter] = useState(params.get("id_trainings_types"));
    const [modalityFilter, setModalityFilter] = useState(params.get("id_trainings_modalities"));
    const [statusFilter, setStatusFilter] = useState(params.get("id_trainings_statuses"));
    const [yearFilter, setYearFilter] = useState(params.get("year"));
    const [campusFilter, setCampusFilter] = useState(params.get("id_campus") || '');
    const [responsibleFilter, setResponsibleFilter] = useState(params.get("id_users_responsible"));
    const [startFilter, setStartFilter] = useState(params.get("timestamp_start"));
    const [endFilter, setEndFilter] = useState(params.get("timestamp_end"));
    const [currentPage, setCurrentPage] = useState(1);


    const [rowsTypes, setRowsTypes] = useState([]);
    const handleGetTypes = async () => {
        const response = await MyTrainings.getTypes();
        setRowsTypes(response);
    };

    const [rowSubjects, setRowSubjects] = useState([]);
    const handleGetSubjects = async () => {
        const response = await MyTrainings.getSubjects();
        setRowSubjects(response);
    };

    const [rowStatuses, setRowStatuses] = useState([]);
    const handleGetStatuses = async () => {
        const response = await MyTrainings.getStatuses();
        setRowStatuses(response);
    };

    const [rowModalities, setRowModalities] = useState([]);
    const handleGetModalities = async () => {
        const response = await MyTrainings.getModalities();
        setRowModalities(response);
    };

    const [rowResponsible, setRowResponsible] = useState([]);
    const handleGetResponsible = async () => {
        const response = await MyUsers.usersTrust();
        setRowResponsible(response);
    };

    const [rowCampus, setRowCampus] = useState([]);
    const handleGetCampus = async () => {
        const response = await MyCampus.campus();
        setRowCampus(response);
    };

    const [rowYears, setRowYears] = useState([]);
    const handleGetYears = async () => {
        const today = new Date();
        const year = today.getFullYear();
        const margin = year - 2021;
        let years = [];
        for (let i = 0; i < margin; i++) {
            years.push(year - i);
        }
        years.push(year + 1);
        const response = years.sort();
        setRowYears(response);
    };

    //CRUD functions
    const [rowTrainings, setRowTrainings] = useState({
        data: [],
        last_page: 1
    });

    const [loading, setLoading] = useState(false);

    const handleGetTrainings = async (page = 1) => {
        setLoading(true)
        const response = await MyTrainings.getPersonalTrainings({ 
            id_trainings_subjects: subjectFilter, 
            id_trainings_types: typeFilter, 
            id_trainings_statuses: statusFilter, 
            id_trainings_modalities: modalityFilter, 
            id_users_responsible: responsibleFilter, 
            id_campus: campusFilter, 
            timestamp_start: startFilter, 
            timestamp_end: endFilter, 
            year: yearFilter,
            page: page,
            size: 20
         });

         setRowTrainings({
            data: response.data.data ?? [],
            last_page: response.data.last_page
        });
        setLoading(false)
    };

    //UseEffect
    useEffect(() => {
        handleGetSubjects();
        handleGetTypes();
        handleGetStatuses();
        handleGetModalities();
        handleGetResponsible();
        handleGetCampus();
        handleGetYears();
        handleGetTrainings();
    }, []);

    const handleFilterSubmit = (e) => {
        e.preventDefault()
        handleGetTrainings();
        setOpenModalFilters(false)
    }

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav />
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>                     
                        <Grid item xd={12} md={12} >
                            <ButtonMailboxControla
                              originUrl={"/trainings/personal"}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image=""
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Mis capacitaciones"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        sx={{ mt: .5 }}
                        justifyContent="space-between"
                        direction="row"
                        alignItems="center"
                    >
                        <Grid item>
                        </Grid>

                        <Grid item>
                            <ButtonControla
                                roles={permissions.CAPACIT_MIS_CAPACIT_FILTROS}
                                iconButton={<FilterAlt sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Filtros"}
                                functionOnClick={() => handleOpenModalFilters()}
                            />
                        </Grid>
                    </Grid>
                    <TableTrainings 
                        handleGetTrainings={handleGetTrainings}
                        rowTrainings={rowTrainings}
                        currentPage={currentPage}
                        loading={loading}
                    />
                </Box>
            </Container>

            <Dialog
                open={openModalFilters}
                onClose={() => handleCloseModalFilters()}
                maxWidth="md"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Filtros de busqueda"
                    functionOnClose={() => handleCloseModalFilters()}
                />
                <FiltersPersonal
                    onSubmit={handleFilterSubmit}
                    handleCloseModalFilters={handleCloseModalFilters}
                    subjectFilter={subjectFilter}
                    typeFilter={typeFilter}
                    modalityFilter={modalityFilter}
                    statusFilter={statusFilter}
                    yearFilter={yearFilter}
                    responsibleFilter={responsibleFilter}
                    setSubjectFilter={setSubjectFilter}
                    setTypeFilter={setTypeFilter}
                    setModalityFilter={setModalityFilter}
                    setStatusFilter={setStatusFilter}
                    setYearFilter={setYearFilter}
                    setResponsibleFilter={setResponsibleFilter}
                    rowsTypes={rowsTypes}
                    rowSubjects={rowSubjects}   
                    rowStatuses={rowStatuses}
                    rowModalities={rowModalities}
                    rowResponsible={rowResponsible}
                    rowYears={rowYears}
                    setStartFilter={setStartFilter}
                    startFilter={startFilter}
                    setEndFilter={setEndFilter}
                    endFilter={endFilter}
                />
            </Dialog>


            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>
    );
}

export default Index;
