import { BrowserRouter as Router, Routes, Route, Outlet, Navigate, createBrowserRouter, RouterProvider } from "react-router-dom";
import Committee from "../committee/committee";
import { useAppContext } from "../context/AppContext";
import Login from "../login/main";
import ErrorViews from "../components/errorsViews"
import HomeView from "../components/home/homeView";
import { AuthGuard } from "../auth/auth-guard";
import routes from "./routes";
import { useEffect, useLayoutEffect, useState } from "react";
import DynamicModules from "../ModulesDynamic/ModulesDynamic";
import DynamicSubModules from "../ModulesDynamic/SubModulesDynamic";

const AppRoutes = () => {
  const { sectionsModules, setSectionsModules, routesModules, setRoutesModules } = useAppContext();
  const [isContentModules, setIsContentModules] = useState((sectionsModules.length > 0) || (routesModules.length > 0) ? true : false)
  const [ routesDynamic, setRoutesDynamic ] = useState(routes)

  useLayoutEffect(() => {
    const storedSectionsModules = JSON.parse(localStorage.getItem('sectionsModules'));
    const storedRoutesModules = JSON.parse(localStorage.getItem('routesModules'));

    if ((storedSectionsModules && storedSectionsModules.length > 0) || (storedRoutesModules && storedRoutesModules.length > 0)) {
      setSectionsModules(storedSectionsModules);
      setRoutesModules(storedRoutesModules)
    } else {
      fetchDataAndSave();
    }
  }, [isContentModules]);

  useLayoutEffect(() => {
    setIsContentModules((sectionsModules.length > 0) || (routesModules.length > 0) ? true : false)
  }, [sectionsModules, routesModules])

  const fetchDataAndSave = () => {
    const updatedSectionsModules = sectionsModules;
    const updatedRoutesModules = routesModules;

    localStorage.setItem('sectionsModules', JSON.stringify(updatedSectionsModules));
    localStorage.setItem('routesModules', JSON.stringify(updatedRoutesModules));

    setSectionsModules(updatedSectionsModules);

    const dynamicRoutesWithoutStaticMatch = updatedRoutesModules.filter((dynamicRoute) => {
      // Busca la ruta estática correspondiente a la ruta dinámica actual
      const staticMatch = routes.find((route) =>
        route.type === 'guard' &&
        route.children.some((staticRoute) => staticRoute.path === dynamicRoute)
      );
      // Devuelve true si no hay coincidencia estática
      return !staticMatch;
    });

    setRoutesModules(dynamicRoutesWithoutStaticMatch)
  };

  useLayoutEffect(() => {
    // Verificar si sectionsModules tiene datos antes de mapear
    if (sectionsModules && sectionsModules.length > 0) {
      const rutas_dinamicas_secciones = sectionsModules.map((section) => ({
        path: section.url,
        element: <DynamicModules id_section={section.id_section} title={section.label}  imageBanner={section.image} />,
      }));

      setRoutesDynamic(prev => [
        ...prev.map(route => {
          if (route.type === 'guard') {
            route.children = [...route.children, ...rutas_dinamicas_secciones];
          }
          return route; // No olvides devolver la ruta incluso si no es de tipo 'guard'
        }),
      ]);
    }

    if (routesModules && routesModules.length > 0) {
      const rutas_dinamicas_modulos = routesModules.map((route) => ({
        path: route,
        element: <DynamicSubModules />,
      }));

      // Filtra las rutas dinámicas que no tienen una coincidencia en las rutas estáticas
      const dynamicRoutesWithoutStaticMatch = rutas_dinamicas_modulos.filter((dynamicRoute) => {
        // Busca la ruta estática correspondiente a la ruta dinámica actual
        const staticMatch = routes.find((route) =>
          route.type === 'guard' &&
          route.children.some((staticRoute) => staticRoute.path === dynamicRoute.path)
        );
        // Devuelve true si no hay coincidencia estática
        return !staticMatch;
      });

      setRoutesDynamic(prev => [
        ...prev.map(route => {
          if (route.type === 'guard') {
            route.children = [...route.children, ...dynamicRoutesWithoutStaticMatch];
          }
          return route; // No olvides devolver la ruta incluso si no es de tipo 'guard'
        }),
      ]);
    }
}, [sectionsModules, sectionsModules]);

  const createRoutes = createBrowserRouter(routesDynamic);

  return (
    <RouterProvider router={createRoutes}/>
  );
};

export default AppRoutes;