import { React, useState, useEffect } from "react";
import SubtitleText from "../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Typography,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  DialogActions,
} from "@mui/material";
import {
  Add,
  PlagiarismRounded,
  BorderColorOutlined,
  UploadFileRounded,
  Delete,
  Print,
  ArrowBackOutlined,
  LoginOutlined,
  Visibility,
  InfoOutlined,
} from "@mui/icons-material";
import TitleText from "../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/system";
import InputControla from "../../../components/textfields/inputControla";
import LabelControla from "../../../components/textfields/labelControla";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import MyPrograms from "../../../api/MyPrograms";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import LoadingControla from "../../../components/load/loadingControla";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import CompanyLogoControla from "../../../components/images/companyLogoControla";
import ButtonControla from "../../../components/buttons/buttonController";
import { Edit as EditIcon } from "@mui/icons-material";

const colorTitle = "#9191B0";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";

const StyledSaveButton = styled(Button)({
  textTransform: "none",
  borderRadius: 14,
  backgroundColor: green,
  color: "white",
  width: "100%",
});

const StyledCancelButton = styled(Button)({
  textTransform: "none",
  borderRadius: 14,
  backgroundColor: lilac,
  color: "black",
  width: "100%",
});

const StyledTypography = styled(Typography)({
  color: "#305AD9",
  fontSize: 18,
  fontWeight: 600,
  gap: "2%",
  padding: "0.5em",
});

const StyledAddButton = styled(Button)({
  textTransform: "none",
  backgroundColor: skyblue,
  borderRadius: 16,
});

function EditPrograms() {

  const params = new URLSearchParams(window.location.search);
  const idprograms = params.get("id_programs");

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Pantallas de carga y alertas FIN

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdObjectiveGeneral(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        handleDeleteObjectiveGeneral();
        break;
      // case "revision":
      //   updatestatusesPets(2);
      //   break;
      // case "public":
      //   updatestatusesPets(3);
      //   break;
      // case "archived":
      //   updatearchivedPets();
      //   break;
      // case "unarchived":
      //   updateunarchivedPets();
      //   break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  // mostrar datos del programa creado
  const [code, setCode] = useState("");
  const [version, setVersion] = useState("");
  const [document_url, setDocumentUrl] = useState("");
  const [name, setName] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [year, setYear] = useState("");
  const [progrmapType, setProgramType] = useState("");

  const handleSearchPrograms = async () => {
    const response = await MyPrograms.editPrograms({ idprograms });
    if (response.success === false) {
    } else {
      setCode(response.code);
      setVersion(response.version);
      setName(response.name);
      setDateCreated(
        response.date_created === null ? " " : response.date_created
      );
      setYear(response.year);
      setProgramType("Capacitacion");
      setDocumentUrl(response.document_url);
    }
    return response;
  };

  useEffect(() => {
    handleSearchPrograms();
    handleGetObjectiveGeneral();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePostUpdatePrograms = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyPrograms.updatePrograms({
      idprograms,
      code,
      version,
      document_url,
      name,
      date_created: dateCreated,
      year: year,
    });
    if (response.success !== false) {
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  const [openModalAddObjetive, setOpenModalAddObjetive] = useState(false);
  const [openModal2, setOpen2] = useState(false);
  const [openModal3, setOpen3] = useState(false);
  const [openModal4, setOpen4] = useState(false);

  const openAgregarObjetivo = () => {
    setNumObjectiveGeneral("");
    setObjectiveGeneral("");
    setMoneda(null);
    setOpenModalAddObjetive(true);
  };

  const closeAgregarObjetivo = () => {
    setOpenModalAddObjetive(false);
  };

  const closeAgregarActividad = () => {
    setOpen2(false);
  };

  const closeAgregarColumna = () => {
    setOpen3(false);
  };

  const closeAgregarProgramacion = () => {
    setOpen4(false);
  };

  /* MOSTRAR OBJETIVOS GENERALES */
  const [rowsObjectiveGeneral, setRowsObjectiveGeneral] = useState([]);

  const handleGetObjectiveGeneral = async () => {
    const response = await MyPrograms.getObjetiveGeneral({ idprograms });

    if (response.success === false) {
    } else {
      console.log(response);
      setRowsObjectiveGeneral(response);
    }
    return response;
  };

  /* CREAR  OBJETIVO GENERAL */
  const [idObjectiveGeneral, setIdObjectiveGeneral] = useState(0);
  const [objectiveGeneral, setObjectiveGeneral] = useState(null);
  const [numObjectiveGeneral, setNumObjectiveGeneral] = useState(0);
  const [moneda, setMoneda] = useState("");

  const handleChangeMoneda = (event) => {
    setMoneda(event.target.value);
  };

  const handlePostCreateObjectiveGeneral = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPrograms.createObjetiveGeneral({
      idprograms,
      objectiveGeneral,
      numObjectiveGeneral,
      currency_symbol: moneda,
    });
    if (response.success !== false) {
      handleGetObjectiveGeneral();
      closeAgregarObjetivo();
      handleOpenAlert(response.msg, "success");
    }
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  /* MODAL EDITAR OBEJTIVO GENERAL */

  const handleClickOpenEditEpps = (
    id_programs_objectives_general,
    programs_objectives_general,
    order_number,
    current_simbol
  ) => {
    setIdObjectiveGeneral(id_programs_objectives_general);
    setObjectiveGeneral(programs_objectives_general);
    setNumObjectiveGeneral(order_number);
    setMoneda(current_simbol);
    openEditObjetivo(true);
  };

  const openEditObjetivo = () => {
    setOpenEditOG(true);
  };

  const closeEditObjetivo = () => {
    setOpenEditOG(false);
  };

  const [openModalEditOG, setOpenEditOG] = useState(false);

  // const [idObjectiveGeneral, setIdObjectiveGeneral] = useState(null);

  /* ACTUALIZAR OBJETIVOS GENERALES */
  const handleUpdateObjectiveGeneral = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyPrograms.updateObjetiveGeneral({
      idObjectiveGeneral,
      objectiveGeneral: objectiveGeneral,
      numObjectiveGeneral: numObjectiveGeneral,
      moneda: moneda,
    });
    if (response.success !== false) {
      handleOpenAlert(response.msg, "success");
      handleGetObjectiveGeneral();
      closeEditObjetivo();
    } else {
    }
    return response;
  };

  // eliminr objetivo general
  const handleDeleteObjectiveGeneral = async () => {
    handleOpenLoading();
    const response = await MyPrograms.deleteObjetiveGeneral({
      id_programs_objectives_general: idObjectiveGeneral,
    });
    if (response.success !== false) {
      handleGetObjectiveGeneral();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  console.log(rowsObjectiveGeneral);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container sx={{ paddingBottom: 4 }}>
        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handlePostUpdatePrograms}>
            <Grid container spacing={2} sx={{ p: 4 }} justifyContent="center">
              <Grid item xs={12} md={8}>
                <SubtitleText
                  text={"Programa anual de capacitaciones"}
                  color={colorTitle}
                ></SubtitleText>
                <Stack direction="row" justifyContent={"flex-first"}>
                  <TitleText text={name} />
                  <ButtonIconControla
                    icon={<InfoOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#F4D03F"}
                    backgroundColorHover={"#FFC300"}
                    textTooltip={`Para cumplir con el objetivo general propuesto puedes trabajar de dos maneras:
                  a) Usando las opciones objetivos específicos + actividades
                  
                  b) Usando solo la opción actividades`}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} md={2}></Grid>

              <Grid
                item
                xs={12}
                md={2}
                justifyContent="flex-end"
                style={{ textAlign: "center" }}
              >
                <CompanyLogoControla />
              </Grid>

              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Código:"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Versión:"}
                  inputType={"text"}
                  inputValue={setVersion}
                  value={version}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Fecha:"}
                  inputType={"date"}
                  inputValue={setDateCreated}
                  value={dateCreated}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                <InputControlaVertical
                  text={"Tipo de programa:"}
                  inputType={"text"}
                  inputValue={setProgramType}
                  value={progrmapType}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Periodo(año):"}
                  inputType={"text"}
                  inputValue={setYear}
                  value={year}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Título documento:"}
                  inputType={"text"}
                  inputValue={setName}
                  value={name}
                />
              </Grid>
              {/* </Grid>

            <Grid
              container
              spacing={2}
              sx={{ mt: 3 }}
              style={{ backgroundColor: "white", borderRadius: "15px" }}
            > */}
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ mt: 2 }}>
                <StyledTypography
                  sx={{
                    padding: "8px",
                    fontSize: "20px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Objetivo general
                </StyledTypography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                textAlign="right"
                sx={{ mt: 2 }}
              >
                <Stack spacing={2} direction="row" justifyContent={"end"}>
                  <Box>
                    <ButtonControla
                      iconButton={<Visibility sx={{ color: "white" }} />}
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#2D9CDB"}
                      textButton={"Vista extendida"}
                      typeButton={"button"}
                      isNavegationOrigin={true}
                      url={
                        "/documentation/programs/training/extends?id_programs=" +
                        idprograms
                      }
                    />
                  </Box>
                  <Box>
                    <ButtonControla
                      iconButton={<Add sx={{ color: "white" }} />}
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#2D9CDB"}
                      textButton={"Agregar objetivo general"}
                      typeButton={"button"}
                      functionOnClick={openAgregarObjetivo}
                    />
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                  <Table
                    stickyHeader
                    sx={{ width: "100%", minWidth: "100%" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Orden
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Objetivo General
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Moneda
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsObjectiveGeneral.map((rowsObjectiveGeneral) => (
                        <TableRow
                          hover
                          key={
                            rowsObjectiveGeneral.id_programs_objectives_general
                          }
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            {rowsObjectiveGeneral.order_number === null
                              ? 0
                              : rowsObjectiveGeneral.order_number}
                          </TableCell>
                          <TableCell align="left">
                            {rowsObjectiveGeneral.programs_objectives_general}
                          </TableCell>
                          <TableCell align="center">
                            {rowsObjectiveGeneral.currency_symbol}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              <ButtonIconControla
                                icon={
                                  <BorderColorOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#2D9CDB"}
                                textTooltip={"Editar"}
                                // functionOnClick={() => window.location.href = 'training/editprograms?id_programs=' + rowsObjectiveGeneral.id_programs}

                                functionOnClick={() =>
                                  handleClickOpenEditEpps(
                                    rowsObjectiveGeneral.id_programs_objectives_general,
                                    rowsObjectiveGeneral.programs_objectives_general,
                                    rowsObjectiveGeneral.order_number,
                                    rowsObjectiveGeneral.currency_symbol
                                  )
                                }
                              />
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                // functionOnClick={() => {
                                //   handleDeleteObjectiveGeneral(
                                //     rowsObjectiveGeneral.id_programs_objectives_general
                                //   );
                                // }}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rowsObjectiveGeneral.id_programs_objectives_general,
                                    "¿Esta seguro de eliminar el modelo?",
                                    'Una vez eliminado no se podra recuperar todo lo incluido en el objetivo general:  "' +
                                    rowsObjectiveGeneral.programs_objectives_general +
                                    '"',
                                    "Si, Eliminar",
                                    "delete"
                                  );
                                }}
                              />

                              <ButtonIconControla
                                icon={<LoginOutlined sx={{ color: "white" }} />}
                                backgroundColor={"#24996A"}
                                textTooltip={"Ingresar"}
                                functionOnClick={() =>
                                (window.location.href =
                                  "/documentation/programs/training/objectivegeneral?id_og=" +
                                  rowsObjectiveGeneral.id_programs_objectives_general +
                                  "&name_og=" +
                                  rowsObjectiveGeneral.programs_objectives_general +
                                  "&id_programs=" +
                                  idprograms)
                                }
                              //functionOnClick={() => handleClickOpenEditEpps(rowsObjectiveGeneral.id_programs_objectives_general,rowsObjectiveGeneral.programs_objectives_general, rowsObjectiveGeneral.currency_symbol)}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {/* <Grid
            container
            justifyContent="center"
            gap="2em"
            paddingBottom={10}
            sx={{ mt: 3 }}
          >
            <Grid item md={1.3}>
              <StyledSaveButton onClick={handlePostUpdatePrograms}>
                Guardar
              </StyledSaveButton>
            </Grid>
            <Grid item md={1.3}>
              <StyledCancelButton href={"/documentation/programs/training/"}>
                Cancelar
              </StyledCancelButton>
            </Grid>
          </Grid> */}
            {/* BOTONES DE CERRAR */}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ paddingTop: 8 }}
            >
              <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                <Box>
                  <ButtonControla
                    iconButton={<ArrowBackOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#CBCBFF"}
                    textButton={"Regresar"}
                    backgroundColorHover={"#CBCBFF"}
                    typeButton={"button"}                 
                    isNavegationOrigin={true}
                    url={"/documentation/programs/training/"}
                  />
                </Box>
                <Box>
                  <ButtonControla
                    iconButton={<Print sx={{ color: "white" }} />}
                    backgroundColor={"#F2994A"}
                    backgroundColorHover={"#FF881E"}
                    textButton={"Imprimir"}
                    typeButton={"button"}
                  />
                </Box>
                <Box>
                  <ButtonControla
                    iconButton={<EditIcon sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar Programa"}
                    typeButton={"submit"}
                  />
                </Box>
              </Stack>
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog para agregar nuevo objetivo */}
      <Dialog
        open={openModalAddObjetive}
        onClose={closeAgregarObjetivo}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar nuevo objetivo general
          </Typography>
        </DialogTitle>
        <form onSubmit={handlePostCreateObjectiveGeneral}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Número de objetivo"}
                  inputType={"number"}
                  inputValue={setNumObjectiveGeneral}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Moneda
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={moneda}
                  onChange={handleChangeMoneda}
                >
                  <MenuItem value={"Soles"}>Soles</MenuItem>
                  <MenuItem value={"Dólares"}>Dólares</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Objetivo general"}
                  inputType={"text"}
                  inputValue={setObjectiveGeneral}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>

            {/* <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item md={2} sx={{ mr: 3 }}>
              <StyledCancelButton onClick={closeAgregarObjetivo}>
                Cancelar
              </StyledCancelButton>
            </Grid>
            <Grid item md={2}>
              <StyledSaveButton onClick={handlePostCreateObjectiveGeneral}>
                Agregar
              </StyledSaveButton>
            </Grid>
          </Grid> */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalAddObjetive(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* EDITAR OBJETIVO GENERAL */}
      <Dialog
        open={openModalEditOG}
        onClose={closeEditObjetivo}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Editar objetivo general
          </Typography>
        </DialogTitle>

        <form onSubmit={handleUpdateObjectiveGeneral}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Objetivo general"}
                  inputType={"text"}
                  inputValue={setObjectiveGeneral}
                  value={objectiveGeneral}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Número de objetivo"}
                  inputType={"number"}
                  inputValue={setNumObjectiveGeneral}
                  value={numObjectiveGeneral}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Moneda
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={moneda}
                  onChange={handleChangeMoneda}
                >
                  <MenuItem value={"Soles"}>Soles</MenuItem>
                  <MenuItem value={"Dólares"}>Dólares</MenuItem>
                </Select>
              </Grid>
            </Grid>

            {/* <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item md={2} sx={{ mr: 3 }}>
              <StyledCancelButton onClick={closeEditObjetivo}>
                Cancelar
              </StyledCancelButton>
            </Grid>
            <Grid item md={2}>
              <StyledSaveButton onClick={handleUpdateObjectiveGeneral}>
                Actualizar
              </StyledSaveButton>
            </Grid>
          </Grid> */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                closeEditObjetivo();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={openModal2}
        onClose={closeAgregarActividad}
        maxWidth="md"
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: "#F5F5F5",
            width: "55%",
          },
        }}
      >
        <DialogTitle>
          {" "}
          <TitleText text={"Agregar actividad"}></TitleText>
          <IconButton
            aria-label="close"
            onClick={closeAgregarActividad}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={3} md={4}>
              <LabelControla text="Actividad"></LabelControla>
            </Grid>
            <Grid item xs={9} md={8}>
              <InputControla inputType={"text"} />
            </Grid>

            <Grid item xs={3} md={4}>
              <LabelControla text="Número de actividad"></LabelControla>
            </Grid>
            <Grid item xs={3} md={8}>
              <InputControla inputType={"text"} />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item md={2} sx={{ mr: 3 }}>
              <StyledCancelButton>Cancelar</StyledCancelButton>
            </Grid>
            <Grid item md={2}>
              <StyledSaveButton>Agregar</StyledSaveButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModal3}
        onClose={closeAgregarColumna}
        maxWidth="md"
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: "#F5F5F5",
            width: "55%",
          },
        }}
      >
        <DialogTitle>
          {" "}
          <TitleText text={"Agregar columna"}></TitleText>
          <IconButton
            aria-label="close"
            onClick={closeAgregarColumna}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={3} md={4}>
              <LabelControla text="Nombre de columna"></LabelControla>
            </Grid>
            <Grid item xs={9} md={8}>
              <InputControla inputType={"text"} />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item md={2} sx={{ mr: 3 }}>
              <StyledCancelButton>Cancelar</StyledCancelButton>
            </Grid>
            <Grid item md={2}>
              <StyledSaveButton>Agregar</StyledSaveButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openModal4}
        onClose={closeAgregarProgramacion}
        maxWidth="md"
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: "#F5F5F5",
            width: "55%",
          },
        }}
      >
        <DialogTitle>
          {" "}
          <TitleText text={"Agregar programación"}></TitleText>
          <IconButton
            aria-label="close"
            onClick={closeAgregarProgramacion}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={3} md={4}>
              <LabelControla text="Nombre de actividad"></LabelControla>
            </Grid>
            <Grid item xs={9} md={8}>
              <InputControla inputType={"text"} />
            </Grid>

            <Grid item xs={3} md={4}>
              <LabelControla text="Responsable"></LabelControla>
            </Grid>
            <Grid item xs={3} md={2}>
              <InputControla inputType={"text"} />
            </Grid>
            <Grid item xs={3} md={3}>
              <LabelControla text="Recursos"></LabelControla>
            </Grid>
            <Grid item xs={3} md={3}>
              <InputControla inputType={"text"} />
            </Grid>

            <Grid item xs={3} md={4}>
              <LabelControla text="Presupuesto"></LabelControla>
            </Grid>
            <Grid item xs={9} md={8}>
              <InputControla inputType={"text"} />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            <Grid item md={2} sx={{ mr: 3 }}>
              <StyledCancelButton>Cancelar</StyledCancelButton>
            </Grid>
            <Grid item md={2}>
              <StyledSaveButton>Agregar</StyledSaveButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default EditPrograms;